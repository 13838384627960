import React from 'react';
import { connect } from 'react-redux'
import {
    StylePrizeContainer,
    StylePrizeItemContainer, StylePrizeItemDollar,
    StylePrizeItemEnd, StylePrizeItemResults, StylePrizeItemSharl, StylePrizeItemSharlImg
} from "../../../styles/games";

function SharlPrizeEnd(props) {
    const prize = props.GameReducer.prize[0]
    const prizeSharl = props.GameReducer.prizesharl

    let prizeImage = ""
    switch (prize.item) {
        case 3:
            prizeImage = "https://www.sharl.info/media-files/pixil-frame-0_ErEp4a2.png";
            break;
        case 4:
            prizeImage = "https://www.sharl.info/media-files/pixil-frame-0_1.png";
            break;
        case 5:
            prizeImage = "https://www.sharl.info/media-files/pixil-frame-0_3.png";
            break;
        case 6:
            prizeImage = "https://www.sharl.info/media-files/pixil-frame-0_2.png";
            break;
        default:
            prizeImage = "../../../assets/images/sharl1.png";

    }
    return (
        <StylePrizeContainer>
            <StylePrizeItemSharl>
                <StylePrizeItemSharlImg>
                    <img src={require("../../../assets/images/games/sharls/"+prizeSharl+".png")} alt={"top sharl"}/>
                </StylePrizeItemSharlImg>
                <h2>It's {prizeSharl}!</h2>
                <p>I'm so happy it's my Top Sharl day!!</p>
                <p>I hope you like these presents!</p>
            </StylePrizeItemSharl>
            <StylePrizeItemResults>
                <StylePrizeItemContainer>
                    <StylePrizeItemDollar>
                        <p> {prize.amount}</p>
                    </StylePrizeItemDollar>
                    <StylePrizeItemEnd>
                        <img src= {prizeImage} alt="sharl_prize" />
                    </StylePrizeItemEnd>
                </StylePrizeItemContainer>
            </StylePrizeItemResults>
        </StylePrizeContainer>
        )
}

const mapStateToProps = state => {
    return {
      ...state,
      };
    };

export default connect(mapStateToProps)(SharlPrizeEnd);
