import styled from "styled-components";


export const StyleTownContainer = styled.div`
    height: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    p {
     line-height: 3; 
     font-size: 3.5vh; 
     overflow: hidden;
     font-weight: bold;
    }
    
    a:-webkit-any-link {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        p {
            line-height: 2; 
            font-size: 3.5vh; 
            overflow: hidden;
            font-weight: normal;
            color: black
        }
    }
  
    `;

export const StyleTownImageContainer = styled.div`
    height: 100%;
    width: 100%;
    background-color: #191a31ff;
    display: flex;
    align-items: center;
    justify-content: center;    
    img {
        height: 100%;
        width: 99.9%;
        object-fit: cover;
    }  
    position: relative;
    `;


export const StyleTownLinkContainer = styled.div`
    height: ${props => props.height || "4%"};
    width: ${props => props.width || "7%"};
    top: ${props => props.top || "10%"};
    left: ${props => props.left || "10%"};
    background-color: #282828;
    border: 0.5px solid white;
    position: absolute;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;    
   
    a:-webkit-any-link {
        text-decoration: none;
     
        p {
           font-size: 1vw;
           color: white;
           text-align: center; 
           line-height: 1;
            }
        }   
    `;
