import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";

import HTMLFlipBook from "react-pageflip";
import {
    StyleHistoryBookContainer,
    StyleHistoryPageContainer,
    StyleMuseum,
    StyleMuseumBookMiddle,
    StyleMuseumBookSides,
    StyleMuseumContainer,
    StyleHistoryCoverPageContainer,
    StyleMuseumBackButton,
    StyleClassFactsContainer,
    StyleClassFactsImageContainer,
    StyleClassFactsFact,
    StyleClassFactsImageLeft, StyleClassFactsImageRight
} from "../../styles/museum";
import {clearMuseumStageFunction} from "../../store/actions/Museum/clearMuseumStageAction";


const PageCover = React.forwardRef((props, ref) => {
    return (
      <div className="page page-cover" ref={ref} data-density="hard">
        <div className="page-content">
          <h2>{props.children}</h2>
        </div>
      </div>
    );
  });

const Page = React.forwardRef((props, ref) => {
        if (props.number === "front" || props.number === "back") {
            return (
                <StyleHistoryCoverPageContainer ref={ref}>
                        <p>{props.children}</p>
                </StyleHistoryCoverPageContainer>
            );
        } else if (props.number === "blank") {
            return (
                <StyleHistoryPageContainer ref={ref}>
                        <p>{props.children}</p>
                </StyleHistoryPageContainer>
            );
        } else {
            return (
                <StyleHistoryPageContainer ref={ref}>
                        {props.children}
                  </StyleHistoryPageContainer>
            );
        }
    });

function SharkSecretsBook(props) {
    const [pages,setPages] = useState([])
    const [classFactsBook, setClassFactsBook] = useState([])

    useEffect(() => {
       const history = props.MuseumReducer.research.filter(sharlFact =>
            sharlFact.category.name === "Shark Secrets");
       const splitBook = splitBookHandler(history,5)
            setClassFactsBook(splitBook)
        // eslint-disable-next-line
        },[]);

    const splitBookHandler = (array, size) => {
        const chunked_arr = [];
        for (let i = 0; i < array.length; i++) {
            const last = chunked_arr[chunked_arr.length - 1];
            if (!last || last.length === size) {
                chunked_arr.push([array[i]]);
            } else {
            last.push(array[i]);
        }
    }
        return chunked_arr;
}
    useEffect(() => {
        if (classFactsBook.length > 0) {
            const newPages = [];
            newPages.push(renderCover(props))
            for (let i = 0; i < classFactsBook.length; i++) {
                newPages.push(renderBlankPage(i, props))
                newPages.push(renderPage(i, props))
            }

            if (classFactsBook.length < 4) {
                newPages.push(renderToBeCont(props))
            }
            newPages.push(renderBackCover(props))
            setPages(newPages)}
            // eslint-disable-next-line
        },[classFactsBook]);

    function renderPage(i, props) {
        const pageContent = classFactsBook[i];
        return (
            <Page id={i} key={i} number={i+1}>
                <StyleClassFactsContainer>
                    <StyleClassFactsImageContainer>
                        <StyleClassFactsImageLeft>
                             <img src={"https://www.sharl.info/" + pageContent[0].image} alt={"Narl"} />
                        </StyleClassFactsImageLeft>
                        <StyleClassFactsImageRight>
                           <p>{pageContent[0].fact}</p>
                        </StyleClassFactsImageRight>
                    </StyleClassFactsImageContainer>
                    <StyleClassFactsFact>
                        {pageContent.length > 1 ? <p>{pageContent[1].fact}</p> : ""}
                    </StyleClassFactsFact>
                    <StyleClassFactsFact>
                        {pageContent.length > 2 ? <p>{pageContent[2].fact}</p> : ""}
                    </StyleClassFactsFact>
                    <StyleClassFactsFact>
                        {pageContent.length > 3 ? <p>{pageContent[3].fact}</p> : ""}
                    </StyleClassFactsFact>
                    <StyleClassFactsFact>
                        {pageContent.length > 4 ? <p>{pageContent[4].fact}</p> : ""}
                    </StyleClassFactsFact>
                </StyleClassFactsContainer>
            </Page>
        )
    }


    function renderCover(props) {
        return (
            <Page id={"frontCover"} key={"frontCover"} number={"front"}>Shark Secrets</Page>
        )
    }

    function renderBlankPage(i,props) {
        return (
            <Page id={"blank"+i} key={"blank"+i} number={"blank"}></Page>
        )
    }

    function renderBackCover(props) {
        return (
            <Page id={"backCover"} key={"backCover"} number={"back"}></Page>
        )
    }
    function renderToBeCont(props) {
        return (
            <Page id={"toBeCont"} key={"toBeCont"} number={""}>To be continued.. </Page>
        )
    }
    const clearBookHandler = () => {
         props.dispatch(clearMuseumStageFunction())
    }
    
    return (
        <StyleMuseum>
            <StyleMuseumContainer>
                <StyleMuseumBookSides>
                </StyleMuseumBookSides>
                <StyleMuseumBookMiddle>
                        <StyleHistoryBookContainer>
                            <HTMLFlipBook
                                width={710}
                                height={650}
                                size="stretch"
                                minWidth={315}
                                maxWidth={1000}
                                minHeight={400}
                                maxHeight={1533}
                                autoSize={true}
                            >
                            <PageCover/>
                            {pages}
                        </HTMLFlipBook>
                    </StyleHistoryBookContainer>
                </StyleMuseumBookMiddle>
                <StyleMuseumBookSides>
                    <StyleMuseumBackButton onClick={(e) => clearBookHandler()}>Back to Museum</StyleMuseumBackButton>
                </StyleMuseumBookSides>
            </StyleMuseumContainer>
        </StyleMuseum>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(SharkSecretsBook);
