import React from 'react';
import Body from './Body.js';

function MainApp() {
  return (
        <Body/>
  );
}

export default MainApp;
