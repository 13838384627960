import React from 'react';
import SharlMarketBody from "./MarketBody";

function Market() {
  return (
        <SharlMarketBody/>
  );
}

export default Market;;
