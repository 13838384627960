import {setselecteditem} from "../../types";

export const selectItemAction = (data) => ({
    type: setselecteditem,
    payload: data,
});


const selectItemFunction = (selectedItem) => (dispatch) => {
        dispatch(selectItemAction(selectedItem));
};

export default selectItemFunction;