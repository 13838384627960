import React from 'react';
import SharlGamesBody from "./SharlGamesBody/SharlGamesBody";

function Games() {
  return (
        <SharlGamesBody/>
  );
}

export default Games;
