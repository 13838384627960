import React from 'react';


function PrivacyPolicy() {
  return (
        <>
          <p>Privacy Policy</p>
        </>
  );
}

export default PrivacyPolicy;
