import {updateBalanceAction} from "../MainApp/updateBalanceAction";


const claimFishPrizeFunction = (props,userBal,highScore,trashBal) => (dispatch) => {

    const balance = userBal;
    const fish_high_score = {"fish_high_score": highScore};
    const trash_balance = {"trash_balance": trashBal};
    const prize_data = Object.assign(balance,fish_high_score,trash_balance);

    const URL = "https://www.sharl.info/backend/api/users/me/";
    const token = props.SignInReducer.token;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'PATCH',
        body: JSON.stringify(prize_data),
        headers,
    };

     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        dispatch(updateBalanceAction(data));
    })
};

export default claimFishPrizeFunction;