import {showsharlitems} from "../../types";

export const showSharlItemsAction = () => ({
    type: showsharlitems,
});


const showSharlItemsFunction = () => (dispatch) => {
    dispatch(showSharlItemsAction());
};

export default showSharlItemsFunction;