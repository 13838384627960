import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    StyleShopBodyContent, StyleShopComplete
} from "../../styles/shop";

function ShopComplete(props) {
    const [orderID, setOrderID] = useState(props.ShopReducer.order);

    useEffect(()=>{
        setOrderID(props.ShopReducer.order)
    },[props.ShopReducer.order])

    return (
        <StyleShopBodyContent>
            <StyleShopComplete>
                <p>Thank you for your order <b>{orderID}</b></p>
                <p>You should receive a confirmation by email.</p>
                <p>Please give the Sharls a minute or two to deliver the items!</p>
            </StyleShopComplete>
        </StyleShopBodyContent>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(ShopComplete);
