import styled from "styled-components";

export const WheelContainer = styled.div`
  display: block;
  position: relative;
  box-sizing: content-box;
  width: calc(400px + 2 * 5px);
  height: calc(400px + 2 * 5px);
  padding: 3px;
  margin: auto;
  background-color: white;
  border: solid #da3768 3px;
  border-radius: 50%;
  user-select: none;
  ::before,
  ::after {
    content: '';
    display: block;
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    border: solid transparent 20px;
    border-left-width: 0;
    }
  ::before {
    right: 0px;
    border-right-color: #da3768;
      }
  ::after {
    right: -5px;
    border-right-color: white;
    }
  `;


export const WheelMain = styled.div`
    display: block;
    position: relative;
    box-sizing: content-box;
    margin: auto;
    width: 400px;
    height: 400px;
    overflow: hidden;
    border-radius: 50%;
    border: solid #da3768 5px;
    background-color: #da3768;
    cursor: pointer;
    transition: transform 4s;
    transform: ${props => (props.spinning ? `rotate(calc(6 * var(--time-var) - 360deg))` : "")};
    

    ::after {
    display: block;
    position: absolute;
    content: '';
    background-color: white;
    width: 25px;
    height: 25px;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

    `;

export const WheelItem = styled.div`
    display: block;
    position: absolute;
    box-sizing: border-box;

    top: 50%;
    left: 50%;
    width: 50%;
    transform-origin: center left;
    transform: translateY(-50%) rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));

    color: white;
    text-align: right;
    padding: 0 25px 0 50px;
    :before {
        content: ' ';
        display: block;
        position: absolute;
        box-sizing: border-box;
        z-index: -1;
        width: 0;
        height: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-left: 0px;
        opacity: 0.25;

        --slice-max-width: calc(3.14159265358979 * 400px + 400px / 2);
        --slice-width: calc((var(--slice-max-width) / var(--nb-item)) - 50px);
        border: solid transparent calc(var(--slice-width) / 2);
        border-left: solid transparent 0;
        border-right: solid white calc(400px / 2);
    }z
    `;

export const WheelMainContainer = styled.div`
    height: 100%;
    width: 100%;
    
    `;

