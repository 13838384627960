import React from 'react';
import SignInForm from "./SignInForm";

function SignUp() {
  return (
        <SignInForm/>
  );
}

export default SignUp;
