import React from 'react';
import {connect} from "react-redux";
import {
    StyleShopBodyContent
} from "../../styles/shop";

function ShopHome() {

    return (
        <StyleShopBodyContent>
        </StyleShopBodyContent>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(ShopHome);
