import styled from "styled-components";


export const StyleResearchButton = styled.button`
    height: 30%;
    width: 50%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 1vw;
    border: none;
  
 `;


export const StyleResearchContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 
    background: linear-gradient(to top, rgba(150, 255, 255, 0.7) 0%, rgba(51, 102, 153, 0.6) 100%), url('../images/backgrounds/seabg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    `;

export const StyleResearchBody = styled.div`
    height: 90%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    `;

export const StyleResearchBodyContent = styled.div`
    height: 100%;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background-color: white;
    flex-direction:column;
    `;


export const StyleResearchRight = styled.div`
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 24px;
    margin-left: 10%;
 
    `;


export const StyleResearchEnd = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    `;


export const StyleResearchEndItem = styled.div`
    height: 25%;
    width: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
    height: 100%;
    width: auto;
    }
    h1 {
    font-size: 1.6vw;
    margin: 2%;
    }
    h2 {
        text-align: center;
        font-size: 1.4vw;
        flex-wrap: wrap;
        padding: 20%;
        font-weight: 400;
    }
    p {
    font-size: 1.2vw;
    padding: 2%;
    margin-top: 2%;
    text-align: center;
    }
    `;


export const StyleResearchRightTop = styled.div`
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;


export const StyleResearchTotalHeader = styled.div`
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    h1 {
        font-size: 1.2vw;
        padding: 1%; 
    }
   
    p {
        font-size: 1.5vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    `;

export const StyleResearchTotalBody = styled.div`
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    
    `;
export const StyleResearchTotalCost = styled.div`
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
        font-size: 1.5vw;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${props => props.pColor || "red"};

    }
    img {
        height: 30%;
        width: auto;
        margin: 2%;
    }
    `;


export const StyleResearchTotalDuration = styled.div`
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
        font-size: 1.5vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    `;

export const StyleResearchTotalItems = styled.div`
    height: 40%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

export const StyleResearchRightBottom = styled.div`
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 0px 0px 24px 24px;
    `;

export const StyleResearchMiddle = styled.div`
    height: 40%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

export const StyleResearchLeft = styled.div`
    height: 60%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;   
    border-radius: 24px 24px 0px 0px;
    background-color: #336699;
    `;

export const StyleResearchHat = styled.div`
    height: 40%;
    width: auto;
    z-index: 10;
    position: absolute;
    bottom: 57%;
    overflow: hidden;
    
    img {
        height: 90%;
        width: auto;
    }
    `;


export const StyleResearchCatHat = styled.div`
    height: 40%;
    width: auto;
    z-index: 10;
    position: absolute;
    bottom: 60%;
    overflow: hidden;
    
    img {
        height: 90%;
        width: auto;
    }
    `;

export const StyleResearchCatInProgHat = styled.div`
    height: 40%;
    width: auto;
    z-index: 10;
    position: absolute;
    bottom: 60%;
    overflow: hidden;
    
    img {
        height: 90%;
        width: auto;
    }
    `;


export const StyleResearchCatSharlImg = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    `;
export const StyleResearchCatSharlImgCont = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

export const StyleResearchAvatar = styled.div`
    height: 60%;
    width: 100%;
    display: flex;
    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    `;


export const StyleResearchAvatarImg = styled.div`
    height: 100%;
    width: auto%;
    position: relative;
    overflow: hidden;
    
    img {
    height: 100%;
    width: auto;
    }
    `;

export const StyleResearchSharlContainer = styled.div`
    height: 100%;
    width: 100%;
    
    display: flex;
    flex-wrap: wrap;   
    align-items: center;
    justify-content: center;
    
    `;

export const StyleResearchCategoryContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;  
    `;


export const StyleResearchSharl = styled.div`
    height: 70%;
    width: 20%;
    margin: 2%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    border-radius: 24px;
    div {
        background-color: ${props => props.bgColor || "white"};
        border-radius: 24px;
    }
    p {
      font-weight: 300;    
    }
    
    h1{
      font-weight: bold;
      font-size: 1.2vw;    
    }
    :hover {
        background-color: green;
        opacity: 0.5;
        }
       `;

export const StyleResearchCategory = styled.div`
    height: 40%;
    width: 60%;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    border-radius: 24px;
    text-align: center;
    background-color: pink;
    
    p {
      font-size: 0.8vw;
      line-height: 1.5;
 
    }
    h1{  
      font-size: 1.2vw;
       line-height: 1.5;
 
    }
    
    h2{
      font-weight: 300;  
      font-size: 0.9vw;
      font-style: italic;
      padding: 1%;  
    }
       `;

export const StyleResearchCategorySharls = styled.div`
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    `;

export const StyleResearchSharlBox = styled.div`
    height: 90%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.bgColor || "white"};

    `;

export const StyleResearchName = styled.div`
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    p {
    line-height: 2;
    font-size: 2.5vh;
    overflow: hidden;
    }
   
    `;

export const StyleResearchMid = styled.div`
    height: 50%;
    width: 100%;
    display: flex;
    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    `;


export const StyleResearchMidImg = styled.div`
    height: 100%;
    width: auto%;
    position: relative;
    overflow: hidden;
    
    img {
    height: 100%;
    width: auto;
    }
    `;

export const StyleResearchMidBackground = styled.div`
    height: 40%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.bgColor || "white"};
    border-radius: 24px 24px 0px 0px;
    `;

export const StyleResearchMidText = styled.div`
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    `;
export const StyleResearchMidTextItem = styled.div`
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    p {
    font-size: 1.2vw;
    padding-top: 10%;
    }
    `;

export const StyleResearchMidHat = styled.div`
    height: 45%;
    width: auto%;
    z-index: 10;
    position: absolute;
    bottom: 55%;
    overflow: hidden;
    
    img {
        height: 90%;
        width: auto;
    }
    `;

export const StyleResearchMidButton = styled.button`
    height: 30%;
    width: 38%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 2.5vh;
    border: none;
    margin: 2%;
 `;


export const StyleResearchEndButton = styled.button`
    height: 40%;
    width: 40%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 2.5vh;
    border: none;
 `;

