import React from 'react';
import {connect} from "react-redux";

import {Link} from "react-router-dom";
import {
    StyleTank,
    StyleTankActiveBody,
    StyleTankActiveButton,
    StyleTankActiveFooter, StyleTankActiveLeft, StyleTankActiveRight,
    StyleTankContainer,
    StyleTankImg,
    StyleTankMain
} from "../../../styles/tank";

import DragTank from "./DragTank/index";
import ActiveContainer from "./ActiveTank/ActiveContainer";
import TankInfo from "./TankInfo/TankInfo";


function TankBody(props) {

    const tankBGHandler = () => {
        const userTank = props.UserReducer.user.tank
        if (userTank){
            if (userTank.tank_level===1){
                return   "../../images/tank/tank_1.png"
            } else if (userTank.tank_level===2){
                return   "../../images/tank/tank_2.png"
            } else if (userTank.tank_level===3){
                return   "../../images/tank/tank_3.png"
            } else if (userTank.tank_level===4){
                return   "../../images/tank/tank_4.png"
            }
        }
    }

    return (
            <StyleTank>
                <StyleTankContainer>
                    <StyleTankMain>
                        <StyleTankImg  bgImage={tankBGHandler()}>
                            <DragTank props={props}/>
                        </StyleTankImg>
                    </StyleTankMain>
                    <StyleTankActiveLeft>
                        <ActiveContainer props={props} />
                    </StyleTankActiveLeft>
                    <StyleTankActiveRight>
                        <StyleTankActiveBody>
                            <TankInfo props={props} />
                        </StyleTankActiveBody>
                        <StyleTankActiveFooter>
                            <StyleTankActiveButton><Link to="/tank/feed">Feed Sharls</Link></StyleTankActiveButton>
                            <StyleTankActiveButton><Link to="/tank">Back to Tank</Link></StyleTankActiveButton>
                        </StyleTankActiveFooter>
                    </StyleTankActiveRight>
                </StyleTankContainer>
            </StyleTank>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(TankBody);
