import {clearuser} from "../../types";

export const clearUserAction = () => (
    {
    type: clearuser,
});


const clearUserFunction = () => (dispatch) => {
    dispatch(clearUserAction())
}

export default clearUserFunction
