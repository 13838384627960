function generatePrize() {
    const prize = []
    const prizeAmount = Math.floor(Math.random() * (100 - 50) + 50);
    const prizeItemNum = Math.floor(Math.random() * 100);
    let prizeItem = 0;

    if (prizeItemNum < 50) {
        prizeItem = 3;
    } else if (prizeItemNum >= 50 && prizeItemNum < 70) {
        prizeItem = 4;
    } else if (prizeItemNum >=70 && prizeItemNum < 90) {
        prizeItem = 5;
    } else if (prizeItemNum >=90) {
        prizeItem = 6;
    } else {
        prizeItem =1;
    }

    prize.push({"amount": prizeAmount, "item": prizeItem})
    return(prize);
}
export default generatePrize;