import React from 'react';
import {connect} from "react-redux";
import {StyleBodyItem} from "../../../styles/app";
import {StyleBodyButton, StyleBodyEmail, StyleBodyEmailItem} from "../../../styles/signup";
import {confirmPasswordEmailFunction} from "../../../store/actions/Auth/confirmPasswordEmailAction";


function PasswordConfirmEmail(props) {

    const confirmEmailHandler = (e) => {
      e.preventDefault();
      props.dispatch(confirmPasswordEmailFunction())
    }

  return (
          <>
            <StyleBodyItem>
                <img src={require("../../../assets/images/misc/sharl_swim.png")} alt={"sharl"} />
            </StyleBodyItem>
            <StyleBodyItem>
              <StyleBodyEmail>
                <StyleBodyEmailItem>
                    <h2>Success!</h2>
                </StyleBodyEmailItem>
                 <StyleBodyEmailItem>
                    <p>A confirmation code has been sent to {props.PasswordResetReducer.email }</p>
                 </StyleBodyEmailItem>
                 <StyleBodyEmailItem>
                    <StyleBodyButton onClick={(e) => confirmEmailHandler(e)}>
                        CONTINUE
                    </StyleBodyButton>
                 </StyleBodyEmailItem>
                 <StyleBodyEmailItem>
                 </StyleBodyEmailItem>

               </StyleBodyEmail>
            </StyleBodyItem>
          </>
);
}

const mapStateToProps = state => {
    return {
      ...state,
      };
    };

export default connect(mapStateToProps)(PasswordConfirmEmail);
