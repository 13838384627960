import styled from "styled-components";

export const StyleDropContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;

export const StyleDropDragContainer = styled.div`
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: salmon;
    `;
export const StyleDropDropContainer = styled.div`
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lavender;
    `;


export const StyleDropDrag = styled.div`
    height: 50%;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 1%;
    div {
        background-color: ${props => props.bgColor || "white"};
    }
    img {
        min-height: 200px;
    }
    p {
    font-weight: bold;
    font-size: 1.5vw;
    }
    `;


export const StyleDropOuter = styled.div`
    height: 80%;
    width: 20%;
    margin: 1%;
    border: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lavender;
    div{
        min-height: 200px;
        min-width: 200px;
    }
    
    `;



export const StyleResearchTestContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 24px;
    `;

export const StyleResearchTestDrag = styled.div`
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: lavender;
    border-radius: 24px;
    `;

export const StyleResearchTestDragContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;

export const StyleResearchTestDrop = styled.div`
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: salmon;
    border-radius: 24px;
    `;

export const StyleResearchDragSharl = styled.div`
    height: 40%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1%;    
 `;


export const StyleResearchCategoryBgTest = styled.div`
    height: 80%;
    width: 20%;
    margin: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    border-radius: 24px;
    background: url('../images/misc/desk1.png');
    background-size: cover;
    background-repeat: no-repeat;       
    `;


export const StyleBlankResearchDrop = styled.div`
    height: 80%;
    width: 22%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        height: 100%;
        width: auto;
    }
    border: 1px solid black;
    
 `;

export const StyleResearchDragSharlContainer = styled.div`
    height: 60%;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1%;
    background-color: ${props => props.bgColor || "white"};
    border-radius: 24px;
 `;

export const StyleResearchDragTestSharl = styled.div`
    height: 100%;
    width: 100%;
    margin: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
    border-radius: 24px;
    p {
      font-weight: 300;    
    }
    
    h1{
      font-weight: bold;
      font-size: 1.2vw;    
    }
       `;


export const StyleResearchTestDropContainer = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
 `;

export const StyleResearchCont100 = styled.div`
    height: 100%;
    width: 100%;
 `;

export const StyleResearchSelectedSharl = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
 `;