import React from 'react';
import {StyleBodyItem} from "../../styles/app";

function Body() {
  return (
    <>
      <StyleBodyItem>
      <img src={require("../../assets/images/misc/sharl_swim.png")} alt={"sharl"} />
    </StyleBodyItem>
    <StyleBodyItem>
    <h2>Sharl Facts</h2>
      <ul>
      <li>Sharls are apex predators</li>
      <li>There are many types of Sharls like Round Sharl, Sleepy Sharl and the Cheeky Sharls</li>
      <li>Sharls like to dance the hula</li>
      <li>More Sharl facts coming soon..</li>
      </ul>
    </StyleBodyItem>
    </>
);
}

export default Body;
