import updateResearchFunction from "./updateResearchAction";
import {getResearchAction} from "./getResearchAction";
import setResearchPrizeFunction from "./setResearchPrizeAction";
import {updateSharlsAction} from "../Sharls/saveSharlHatAction";
import {updateItemsAction} from "../Market/buyNarlItemAction";
import {selectResearchAction} from "./selectResearchAction";


const finishResearchFunction = (props, research_data) => (dispatch) => {
    const userID = props.UserReducer.user.id
    const URL = `https://www.sharl.info/backend/api/facts/complete/${userID}/`;
    const token = props.SignInReducer.token;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'PATCH',
        body: JSON.stringify(research_data),
        headers,
    };

     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        const researchPrize = data.prize
        if (researchPrize.length > 0) {
          dispatch(updateResearchFunction("end"));
        //sharls goes to ResearchReducer.researchPrize
          dispatch(setResearchPrizeFunction(researchPrize, research_data))
        } else {
            dispatch(updateResearchFunction("start"));
            dispatch(selectResearchAction({"research":[],"sharl":[]}))

        }
        //sharl facts go to Research Reducer (ResearchReducer.user_research)
        dispatch(getResearchAction(data.sharl_facts))
        //sharls go to UserReducer.sharls
        dispatch(updateSharlsAction(data.sharls))
        //items go to UserReducer.items
        dispatch(updateItemsAction(data.items))
    })
};

export default finishResearchFunction;;