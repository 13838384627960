import {
    clearuser,
    updatebalance,
    userdata,
    usererror,
    deleteitem,
    dragsharl,
    updateitems, setselecteditem, clearitem, infosharl, updatesharls
} from "../types";

const initialState = {
    user: '',
    user_error: '',
    sharls: [],
    items: [],
    selected_item: [],
    selectedSharl: [],
};


const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case userdata: {
            return {
                ...state,
                user: action.payload.user,
                sharls: action.payload.sharls,
                items: action.payload.items,
            }
        }
        case updateitems: {
            return {
                ...state,
                items: action.payload,
            }
        }
        case updatesharls: {
            return {
                ...state,
                sharls: action.payload,
            }
        }
        case usererror: {
            return {
                ...state,
                user_error: action.payload,
            }
        }
          case clearuser: {
            return {
                ...state,
                user: '',
            }
        }
        case updatebalance: {
            return {
                ...state,
                user: action.payload,
            }
        }
       case dragsharl:
          return {
            ...state,
                sharls: action.payload.sharls,
                selectedSharl: action.payload.selected,
        }
        case infosharl: {
          return {
              ...state,
              selectedSharl: action.payload,
          }
      }

        case deleteitem: {
            return {
                ...state,
                user: action.payload,
            }
        }
         case setselecteditem:
            return {
            ...state,
            selected_item: action.payload,
            }
        case clearitem:
            return {
            ...state,
            selected_item: [],
            }


      default: {
            return state
        }
    }
    
}
export default UserReducer;