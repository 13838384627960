import {addtocart} from "../../types";

export const addToCartAction = (cart) => ({
    type: addtocart,
    payload: cart
});


const addToCartFunction = (cart) => (dispatch) => {
    dispatch(addToCartAction(cart));
};

export default addToCartFunction;