import styled from "styled-components";

export const StyleGamesMain = styled.div `
   height: 100%;
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   line-height: 2;
   a:-webkit-any-link {
    font-size: 2vw;
    color: black;
    text-decoration: none;
    }
`;

export const StyleGamesContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
   
    background: linear-gradient(to top, rgba(150, 255, 255, 0.7) 0%, rgba(51, 102, 153, 0.6) 100%), url('../images/backgrounds/seabg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    `;


export const StyleGamesBody = styled.div`
    height: 85%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 24px;
    `;

export const StyleFishBody = styled.div`
    height: 90%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 24px;
    `;

export const StyleFishBodyGame = styled.div`
    height: 100%;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    `;

export const StyleFishBodyGameMessage = styled.div`
    height: 30%;
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: white;
    position: absolute;
    z-index: 100;
    border-radius: 24px;
    `;

export const StyleFishBodyGameMini = styled.div`
    height: 50%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    color: white;
    font-size: 2vw;
    background-color: #336699;
    position: absolute;
    z-index: 100;
    border-radius: 24px;
    `;

export const StyleFishBodyGameMiniFish = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    `;

export const StyleFishBodyGameMiniImg = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
    img {
    transition: transform 3s;
    transform: ${props => (props.spinning ? `rotate(calc(6 * var(--time-var) - 360deg))` : "")};
  
        height: 50%;
        width: auto;
       }
    
    `;
export const StyleFishBodyGameButtonReset = styled.button`
    height: 35%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #336699;
    background-color: white;
    border: none;
    font-size: 1vw;
    border-radius: 20px;
    margin: 2%;
    `;

export const StyleGamesBWNBody = styled.div`
    height: 85%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    `;

export const StyleGamesInvest = styled.div`
    height: 80%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    padding-bottom: 1%;
    `;

export const StyleGamesInvestItem = styled.div`
    height: 100%;
    width: 30%;
    margin-left: 1%;
    margin-right: 1%:
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 24px;
    `;

export const StyleGamesFooter = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

export const StyleGamesFishFooter = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    `;

export const StyleInvestTop = styled.div`
    height: 65%;
    width: 100%;
    background-color: #336699;
    display: flex;
    align-items: center;
    justify-content: flex-start; 
    border-radius: 24px 24px 0px 0px;
    position: relative;
    img {
        height: 100%;
        width: 100%;
        border-radius: 24px 24px 0px 0px;
    }
    `;

export const StyleInvestTopSharl = styled.div`
    height: 80%;
    width: 90%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end; 
    border-radius: 24px 24px 0px 0px;
    position: absolute;
    z-index: 20;
    img {
        height: 60%;
        width: auto;
    }
    `;

export const StyleInvestTopName = styled.div`
    height: 50%;
    width: 50%;
    display: flex;
    left: 20%;
    top: 50%;
    justify-content: flex-start; 
    position: absolute;
    z-index: 10;
    img {
        height: 60%;
        width: auto;
    }
    `;

export const StyleInvestBottom = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center; 
    border-radius: 0px 0px 24px 24px;
    background-color: #336699;
    color: white;
    `;


export const StyleInvestMid = styled.div`
    height: 25%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    background: url('../images/misc/bubble.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #336699;

    font-size: 1vw;
    text-align: center;
    line-height: 1.2;
    p{
    margin-top: 3%;
    margin-left: 10%;
    margin-right: 10%;
    }    
    `;

export const StyleInvestMidBlank = styled.div`
    height: 25%;
    width: 100%;
    background-color: #336699;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0;
    margin: 0;
    p {
        font-size: 2vw;
        line-height: 2;
    }    
    h1 {
        text-align: center;
        font-size: 1.2vw;
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: center;
    img {
         height: 100%;
         width: 10%;
         padding-left: 1%;
         padding-right: 1%;
            }   
    }
    `;

export const StyleGamesInvestButton = styled.button`
    height: 90%;
    width: 40%;
    background-color: white;
    border-radius: 24px;
    color: white;
    font-size: 1.5vw;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: #336699;
    img {
    height: 80%;
    width: 20%;
    padding-left: 5%;
    }
 `;

export const StyleGamesButton = styled.button`
    height: 100%;
    width: 100%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 2.5vh;
    border: none;
    a {
    color: white;
    }
 `;

export const StyleGamesPrizeButton = styled.button`
    height: 10%;
    width: 40%;
    background-color: #336699;
    color: white;
    border-radius: 24px;
    font-size: 2.5vh;
    border: none;
    a {
    color: white;
    }
    
 `;

export const StyleGamesButtonContainer = styled.div`
    height: 70%;
    width: 20%;
    background-color: #336699;
    color: white;
    font-size: 2.5vh;
    border-radius: 24px;
    border: none;
    a {
    color: white;
    }
   a:-webkit-any-link {
    color: white;
    text-decoration: none;
    height: 100%;
    width: 100%;
    }   

 `;

export const StylePrizeContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    `;

export const StylePrizeItem = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;

export const StylePrizeItemResults = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #336699;
    border-radius: 0px 24px 24px 0px;
    `;

export const StylePrizeItemStartLeft = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #336699;
    border-radius: 24px 0px 0px 24px;
    color: white;
    h2 {
      margin-top: 5%;
      line-height: 3;
      font-size: 1.8vw;
    }
    p {
      font-size: 1.2vw;
    }
    img {
      height: 20%;
      width: auto;
    }
    `;

export const StylePrizeItemSharl = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p { 
        font-size: 1.0vw;
        font-weight: 300;
    }
    h2 {
        margin-top: 5%;
        line-height: 3;     
        font-size: 1.5vw;
    }  
    `;

export const StylePrizeItemSharlImg = styled.div`
    height: 60%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5d442; 
    border-radius: 24px;
    img {
    height: 80%;
    width: auto;
    }
    `;


export const StylePrizeItemContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    `;


export const StylePrizeItemEnd = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;


export const StylePrizeItemDollar = styled.div`
    height: 35%;
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url('../images/misc/shadollar_empty.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;

    p{
    font-size: 5vh;
    }
  
    `;

export const StyleWheelContainer = styled.div`
   padding: 5%;
    `;

//Sharl quiz pages
export const StyleQuizHome = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    h2 {
        font-weight: bold;
        line-height: 3;
        font-size: 2vw;
    }
    p{
        font-weight: normal;
         line-height: 3;
         font-size: 1.5vw;
    }
    
    `;


export const StyleQuizResults = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    `;

export const StyleQuizResultsImg = styled.div`
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px 24px 0px 0px;
    img {
        height: 50%;
        width: auto;
    }
    background-color: #336699;
    `;

export const StyleQuizResultsText = styled.div`
    height: 40%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    `;

export const StyleQuizResultsTextContainer = styled.div`
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
      h2 {
        font-weight: bold;
        line-height: 2;
        font-size: 2vw;
    }
    p{
        font-weight: normal;
         font-size: 1.5vw;
    }
  
    `;


export const StyleQuizEndButton = styled.button`
    height: 70%;
    width: 15%;
    white-space: normal;
    background-color: #336699;
    border-radius: 50%;
    color: white;
    font-size: 1.5vw;
    border: none;
    margin: 1%;
 `;

export const StyleQuizStartButton = styled.button`
    height: 20%;
    width: 10%;
    background-color: #336699;
    border-radius: 50%;
    color: white;
    font-size: 2vw;
    border: none;
    a {
    color: white;
    }
    margin: 5%;
 `;

export const StyleQuizNextButton = styled.button`
    height: 15%;
    width: 40%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 1.5vw;
    border: none;
    a {
    color: white;
    }
 `;


export const StyleQuizQuestions = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    
    `;


export const StyleQuizForm = styled.form`
    height: 100%;
    width: 100%;
    padding-left: 10%;
    line-height: 2.5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 1.8vw;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    label {
    margin-left: 10px;
    }
    `;


export const StyleQuizInputBox = styled.input`
  height: 25px;
  width: 25px;
  border: solid 1px #ebebeb;
  padding: 0;
  display: inline-block;  
  :focus {
    outline:none;
    }  
  background-color: blue;  
   `;

export const StyleQuizQuestionsItems = styled.div`
    height: 70%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: ${props => props.textAlign || "center"};
    justify-content: center;
    `;

export const StyleQuizQuestionsHeader = styled.div`
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;


export const StyleGamesBWNContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

export const StyleGamesBWNPlayLeft = styled.div`
    height: 100%;
    width: 28%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    margin-left: 1%;
    margin-right: 1%;
    font-size: 1.5vw;
    p{
        margin-bottom: 2%;
    }
    `;

export const StyleGamesBWNPlayMiddle = styled.div`
    height: 100%;
    width: 48%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    margin-left: 1%;
    margin-right: 2%;
    `;

export const StyleGamesOpponentImage = styled.div`
    height: 40%;
    width: 100%;
    background-color: #b48ce6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
    height: 70%;
    width: auto;
    }
    border-radius: 24px 24px 0px 0px;
    `;

export const StyleGamesOpponentText = styled.div`
    height: 60%;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    p {
    font-size: 1.6vw;
    margin-bottom: 5%;
    }
    `;

export const StyleGamesOpponentTextContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 24px 24px;
    background-color: pink;
    text-align: center;
    

    `;

export const StyleGamesOpponentTextItems = styled.div`
    height: 100%;
    width: 33.3%;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        height: 40%;
        width: auto;
    }
    p {
    margin: 5%;
    font-size: 1.2vw;
    }
    `;

export const StyleGamesBWNTotal = styled.div`
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    
    `;

export const StyleGamesBWNTotalText = styled.div`
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    color: white;
    text-align: center;
    p {
        margin-bottom: 5%;
        line-height: 1.5;
        font-size: 1.2vw;      
    }
    
    `;

export const StyleGamesBWNTotalButtons = styled.div`
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    color: white;    
    `;

export const StyleGamesBWNPlaySharls = styled.div`
    border: 2px solid #336699;
    height: 25%;
    width: 60%;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background-color: ${props => props.bgColor || "#336699"};
    color: white;
    img {
    height: 80%;
    width: auto;
    }    
    `;


export const StyleGamesBWNButton = styled.button`
    height: 20%;
    width: 60%;
    background-color: white;
    border-radius: 24px;
    color: #336699;
    font-size: 1vw;
    font-weight: 700;
    border: none;
    margin: 2%;
 `;

export const StyleGamesBWNStartButton = styled.button`
    height: 15%;
    width: 40%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-weight: 700;
    border: none;
    margin: 2%;
    font-size: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
    margin-left: 5%;
    height: 80%;
    width: 20%;
    }
 `;

export const StyleGamesMatchTop = styled.div`
    height: 75%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px 24px 0px 0px;
    `;

export const StyleGamesMatchTopSharl = styled.div`
    height: 100%;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px 0px 0px 0px;
    background-color: #336699;
    color: white;    
    img {
    height: 40%;
    width: auto:
    }
    p {
    font-size: 1.5vw;
    line-height: 3;
    font-weight: 700;
    }
    `;

export const StyleGamesMatchTopContestants = styled.div`
    height: 100%;
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 24px 0px 0px;
    position: relative;
    background-color:#ff8b80;
    `;

export const StyleGamesPrizeClaimed = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    text-align: center;
    overflow: break;
    h1 {
        line-height: 3;
    }
    p {
        font-size: 1.2vw;
    }
    img {
         background-color: #f5d442; 
         border-radius: 24px;
         height: 40%;
         width: auto;
    }
    `;

export const StyleGamesMatchTopQ1Box = styled.div`
    height: 8%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border: 2px solid #336699;
    background-color: white;
    p{
    font-size: 1.2vw;
    }
    z-index: 10;
    top: 50%;
    `;

export const StyleGamesMatchTopCItems = styled.div`
    height: 100%;
    width: 33.3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.bgColor || "white"};
    border-radius: ${props => props.bRadius || "0px"};
    `;


export const StyleGamesMatchTopCIImg = styled.div`
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  
    img {
    height: 80%;   
    }
    `;


export const StyleGamesMatchContinueButton = styled.button`
    height: 40%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;  
    background: white;
    border-radius: 24px;
    font-size: 1.2vw;
    font-weight: bold;
    color: #336699;
    img {
    margin-left: 2%;
    height: 80%;
    width: 25%;
    }
    `;
export const StyleGamesMatchTopCIAnswers = styled.div`
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    p{
    font-size: 1vw;
    font-weight: 400;
    }
    `;


export const StyleGamesMatchSharlAnswers1 = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    `;

export const StyleGamesMatchSharlAnswers2 = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 36%;
    `;

export const StyleGamesMatchSharlAnswers3 = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 3%;
    `;

export const StyleGamesMatchBottom = styled.div`
    height: 25%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 24px 24px;
    background-color: #336699;
    `;

export const StyleGamesMatchBLeft = styled.div`
    height: 100%;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 0px 24px;
    img {
    height: 90%;
    width: auto;
    border-radius: 24px;
    }
    `;

export const StyleGamesMatchBMid = styled.div`
    height: 100%;
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    text-align: center;
    color: white;
    p {
    font-size: 1.2vw;
    margin-top: 3%;
    }
    `;

export const StyleGamesMatchBRight = styled.div`
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 24px 0px;
    
    `;



export const StyleGamesQuizQuestionButton = styled.button`
    height: 30%;
    width: 80%;
    background-color: white;
    border-radius: 24px;
    color: #336699;
    font-size: 1vw;
    font-weight: bold;
    border: none;
    margin: 2%;
 `;


export const StyleCanvasInfoItem = styled.div`
    height: ${props => props.height || "16%"};
    width: 100%;
    border: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
   
    progress {
      margin-left: 2%;
    }
    `;

export const StyleFishGameModeItem = styled.div`
    height:25%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: ${props => props.bgColor || "white"};
    p {
        border: none;
        font-size: 1.2vw;
        font-weight: normal;
        color: ${props => props.textColor || "black"};
    }
    `;

export const StyleCanvasInfoGameModeItem = styled.div`
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;

export const StyleCanvasInfoGameMode = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
        font-size: 1.2vw;
        line-height: 1;
    }
    h1 {
        font-size: 1.2vw;
        font-weight: bold;
        border-bottom: 1px solid black;
        line-height: 1.5;
    }
    `;
export const StyleFishCanvasInfo = styled.div`
    height: 100%;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;

export const StyleFishCanvasContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;

export const StyleFishCanvas = styled.div`
    height: 90%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 24px;
    border: 2px solid black;
    canvas{
    background-color: black;
    }
    `;
export const StyleTrashExchange = styled.div`
    height: 90%;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color white;
    border-radius: 24px;
    `;

export const StyleTrashExchangeContainer = styled.div`
    height: 90%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

export const StyleTrashExchangeImage = styled.div`
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color #336699;
    border-radius: 24px 0px 0px 24px;
    color: white;
    `;

export const StyleTrashExchangeItems = styled.div`
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    `;

export const StyleTrashExchangeItemsTop = styled.div`
    height: 70%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    `;

export const StyleTrashExchangeItemsBottom = styled.div`
    height: 30%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid black;
    background-color: pink;
    `;

export const StyleTrashExchangeSale = styled.div`
    height: 90%;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        height: 80%;
        width: 60%;
    }
    p{
    font-size: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
        padding-left: 5%;
        height: 25%;
        width: 25%;
    }
    } 
    `;

export const StyleTrashConfirmButton = styled.button`
    height: 50%;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #336699;
    color: white;
    border: none;
    font-size: 2vw;
    border-radius: 20px;
    margin: 2%;
    `;
export const StyleTrashItem = styled.div`
    height: 35%;
    width: 18%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 2px solid #336699;
    background-color: ${props => props.bgColor || "white"};
    color: ${props => props.textColor || "black"};

    img {
        height: 80%;
        width: 80%;
    }
    
    `;
