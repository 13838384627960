import {selecttoken} from "../../types";

export const selectAdoptTokenAction = (data) => ({
    type: selecttoken,
    payload: data,
});


const selectAdoptTokenFunction = (data) => (dispatch) => {
    dispatch(selectAdoptTokenAction(data));
}

export default selectAdoptTokenFunction;