import {buynarl, updateitems} from "../../types";
import calculateBalanceFunction from "../../../containers/MainApp/updateBalance";
import {updateBalanceAction} from "../MainApp/updateBalanceAction";


export const buyNarlItemAction = (data) => ({
    type: buynarl,
    payload: data,
});

export const updateItemsAction = (data) => ({
    type: updateitems,
    payload: data,
});


const buyNarlItemFunction = (props,itemId,totalCost,itemQuantity, narlNumber) => (dispatch) => {

    const balance = calculateBalanceFunction(props,"-", totalCost);
    const item = {"item_id": itemId};
    const quantity = {"quantity": itemQuantity};
    const narl_number = {"narl_number": narlNumber};
    const narl_data = Object.assign(balance,item,quantity,narl_number);

    const URL = "https://www.sharl.info/backend/api/narl/";
    const token = props.SignInReducer.token;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'PATCH',
        body: JSON.stringify(narl_data),
        headers,
    };

     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        dispatch(updateBalanceAction(data));
        dispatch(buyNarlItemAction(data));
        dispatch(updateItemsAction(data.items))
    })

};

export default buyNarlItemFunction;;