function generateTunaCanPrize(tunaCanName) {
    const prize = [];
    let prizeItem = 0;
    let prizeQuantity = 0;

    const prizeItemNum = Math.floor(Math.random() * 100);
    if (tunaCanName.includes("Blue")) {
        prizeQuantity = 1;
        if (prizeItemNum < 40) {
            prizeItem = 4005;
        } else if (prizeItemNum >= 40 && prizeItemNum < 80) {
            prizeItem = 4006;
        } else if (prizeItemNum >=80) {
            prizeItem = 4009;
        }
    } else if  (tunaCanName.includes("Pink")) {
        if (prizeItemNum < 30) {
            prizeItem = 4005;
            prizeQuantity = Math.floor(Math.random() * (3 - 2) + 2);
        } else if (prizeItemNum >= 30 && prizeItemNum < 60) {
            prizeItem = 4006;
            prizeQuantity = Math.floor(Math.random() * (3 - 2) + 2);
        } else if (prizeItemNum >=60 && prizeItemNum < 80) {
            prizeItem = 4007;
            prizeQuantity = 1;
        } else if (prizeItemNum >=90) {
            prizeItem = 4002;
            prizeQuantity = 1;
        }
    } else if (tunaCanName.includes("Yellow")) {
        if (prizeItemNum < 30) {
            prizeItem = 4005;
            prizeQuantity = Math.floor(Math.random() * (3 - 2) + 2);
        } else if (prizeItemNum >= 30 && prizeItemNum < 60) {
            prizeItem = 4009;
            prizeQuantity = Math.floor(Math.random() * (3 - 2) + 2);
        } else if (prizeItemNum >=60 && prizeItemNum < 80) {
            prizeItem = 4007;
            prizeQuantity = 1;
        } else if (prizeItemNum >=80) {
            prizeItem = 4010;
            prizeQuantity = 1;
        }
    }  else if  (tunaCanName.includes("Golden")) {
        if (prizeItemNum < 25) {
            prizeItem = 4007;
            prizeQuantity = Math.floor(Math.random() * (5 - 3) + 3);
        } else if (prizeItemNum >= 25 && prizeItemNum < 50) {
            prizeItem = 4002;
            prizeQuantity = Math.floor(Math.random() * (3 - 2) + 2);
        } else if (prizeItemNum >=50 && prizeItemNum < 75) {
            prizeItem = 4010;
            prizeQuantity = Math.floor(Math.random() * (3 - 2) + 2);
        } else if (prizeItemNum >=75 && prizeItemNum < 97) {
            prizeItem = 4005;
            prizeQuantity = Math.floor(Math.random() * (10 - 5) + 5);
        } else if (prizeItemNum === 97 || prizeItemNum === 98) {
            prizeItem = 2004;
            prizeQuantity = 1;
        } else if (prizeItemNum === 99){
            prizeItem = 9003;
            prizeQuantity = 1;
        } else {
            prizeItem = 4005;
            prizeQuantity = Math.floor(Math.random() * (10 - 5) + 5);
        }
    }
    prize.push({"quantity": prizeQuantity, "item": prizeItem})
    return(prize);
}
export default generateTunaCanPrize;