import {
    getprize,
    quizresults,
    quizstage,
    resetprize,
    setsharlprize,
    getinvest,
    investprize,
    checkprize
} from "../types";

const initialState = {
    prizestage: 1,
    prize: "",
    prizesharl: "",
    showbutton: false,
    quiz: 0,
    quiz_results: [],
    investments: [],
    invest_prize: [],
    already_claimed: "",
  };

const GameReducer = (state = initialState, action) => {
  switch (action.type) {
      case getprize:
        return {
            ...state,
            prize: action.payload,
            prizestage: 2,
        }
        case resetprize:
        return {
            ...state,
            prize: "",
            prizestage: 1,
            showbutton: false,
        }
        case setsharlprize:
            return {
                ...state,
            prizesharl: action.payload,
            showbutton: true,
            }
        case quizstage:
            return {
                ...state,
            quiz: action.payload,
            }
      case quizresults:
            return {
                ...state,
            quiz_results: action.payload,
            }
      case getinvest:
            return {
                ...state,
            investments: action.payload,
            }
      case investprize:
            return {
                ...state,
            invest_prize: action.payload,
            }
      case checkprize:
            return {
                ...state,
            already_claimed: action.payload,
            prizesharl: action.prizesharl,
            }
        default:
            return {
                ...state,
            };
  }

}

export default GameReducer;