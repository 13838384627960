import React, {useState} from 'react';
import {connect} from "react-redux";
import {StyleBodyItem} from "../../../styles/app";
import {
  StyleBodyButton,
  StyleBodyEmail,
  StyleBodyEmailItem,
  StyleBodyForm, StyleBodyInput,
  StyleBodyVerifylItem
} from "../../../styles/signup";
import {verifyPasswordFunction} from "../../../store/actions/Auth/verifyPasswordAction";


function PasswordFullForm(props) {
  const [password_token, setPasswordToken] = useState('');
  const [email, setEmail] = useState(props.PasswordResetReducer.email);
  const [password, setPassword] = useState('');
  const [password_repeat, setPasswordRepeat] = useState('');

  const verifyPWHandler = (e,props) => {
      e.preventDefault();
      const data = {
        password_token,
        email,
        password,
        password_repeat,
      };
      props.dispatch(verifyPasswordFunction(data,props));
    }
  return (
        <>
         <StyleBodyItem>
            <img src={require("../../../assets/images/misc/sharl_swim.png")} alt={"sharl"} />
         </StyleBodyItem>
         <StyleBodyItem>
            <StyleBodyEmail>
              <StyleBodyVerifylItem>
                <h2>Reset Password</h2>
                <StyleBodyForm onSubmit={verifyPWHandler} name="pw_verification_form">
                  <StyleBodyInput
                    name='password_token'
                    onChange={(e) => setPasswordToken(e.currentTarget.value)}
                    value={password_token}
                    placeholder='Reset Code'
                    type='text'
                  />
                  <StyleBodyInput
                    name='email'
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    value={email}
                    placeholder="Email"
                    type='text'
                  />
                  <StyleBodyInput
                    name='password'
                    onChange={(e) => setPassword(e.currentTarget.value)}
                    value={password}
                    placeholder='Password'
                    type='password'
                  />
                  <StyleBodyInput
                    name='password_repeat'
                    onChange={(e) => setPasswordRepeat(e.currentTarget.value)}
                    value={password_repeat}
                    placeholder='Password Repeat'
                    type='password'
                  />
              </StyleBodyForm>
                {props.PasswordResetReducer.error ? <p1>{props.PasswordResetReducer.error}</p1> : <p></p>  }
            </StyleBodyVerifylItem>
            <StyleBodyEmailItem>
              <StyleBodyButton onClick={(e) => verifyPWHandler(e, props)}>
                CONTINUE
              </StyleBodyButton>
          </StyleBodyEmailItem>
          <StyleBodyEmailItem>
          </StyleBodyEmailItem>
        </StyleBodyEmail>
      </StyleBodyItem>
    </>
  );
}
const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(PasswordFullForm);
