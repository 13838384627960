import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";

import AdoptStart from "./AdoptStart";
import SharlGenerator from "./SharlGenerator";
import AdoptForm from "./AdoptForm";
import AdoptFinal from "./AdoptFinal";
import TokenGenerator from "./TokenGenerator";
import {clearAdoptStageFunction} from "../../store/actions/Adopt/clearAdoptStageAction";


function AdoptBody(props) {
    const [adoptStage, setAdoptStage] = useState(1);

    useEffect(() => {
        setAdoptStage(props.AdoptReducer.adoptstage);
        // eslint-disable-next-line
        }, [props.AdoptReducer]);

    useEffect(() => {
        props.dispatch(clearAdoptStageFunction())
        // eslint-disable-next-line
        }, []);

    switch (adoptStage) {
        case 1: {
            return (
                <AdoptStart/>
        )}
        case 2: {
            return (
                <SharlGenerator/>
        )}
        case 3: {
            return (
                <AdoptForm/>
        )}
        case 4: {
            return (
                <AdoptFinal/>
        )}
        case 5: {
            return(
                <TokenGenerator/>
            )
        }
        default: {
            return (
                <AdoptStart/>
        )}
    }
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(AdoptBody);
