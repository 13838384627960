let selectedSharlPosition = [1, 7]
let observers = []

function emitChange() {
  observers.forEach((o) => o && o(selectedSharlPosition))
}
export function observe(o) {
  observers.push(o)
  emitChange()
  return () => {
    observers = observers.filter((t) => t !== o)
  }
}
export function canMoveSharl(props,toX, toY, sharlID) {
  const coords = toX + "/" + toY;
  const sharls = props.UserReducer.sharls;
  const takenPlaces = sharls.filter(sharl =>
      sharl.tank_position === coords);
  const selectedSharl = sharls.filter(sharl =>
    sharl.id === sharlID)
  const isResearching = selectedSharl[0].is_researching
  if (takenPlaces.length > 0) {
    return false
  } else if (isResearching === true) {
    return false
  }
  return true
}

