import {savehat, updatesharls} from "../../types";

export const saveSharlHatAction = (data) => ({
    type: savehat,
    payload: data
});

export const updateSharlsAction = (data) => ({
    type: updatesharls,
    payload: data
});

const saveSharlHatFunction = (props,headgear,sharlID) => (dispatch) => {

    const URL = `https://www.sharl.info/backend/api/sharls/update/${sharlID}/`;

    const token = props.SignInReducer.token;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'PATCH',
        body: JSON.stringify(headgear),
        headers,
    };

     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        dispatch(updateSharlsAction(data));
        const selected_sharl = data.filter(sharl =>
        sharl.id === sharlID)
        dispatch(saveSharlHatAction(selected_sharl[0]));
    })
};

export default saveSharlHatFunction;