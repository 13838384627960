import React from 'react';
import {connect} from "react-redux";

import {StyleGamesMain} from "../../../styles/games";
import {Link} from "react-router-dom";
//import resetPrizeFunction from "../../../store/actions/Games/resetPrizeAction";


function SharlGamesBody(props) {

    return (
        <StyleGamesMain>
            <Link to="/prize">
                <p>Sharl Prize</p>
            </Link>
            <Link to="/quiz">
                <p>Which Sharl Are You Quiz</p>
            </Link>
            <Link to="/invest">
                <p>Sharl Stocks</p>
            </Link>
            <Link to="/bwn">
                <p>Barl Wharl Narl</p>
            </Link>
            <Link to="/match">
                <p>Sharl Match</p>
            </Link>
            <Link to="/fish">
                <p>Fishing Game</p>
            </Link>
            <Link to="/trash">
                <p>Trash Exchange</p>
            </Link>
        </StyleGamesMain>
)}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(SharlGamesBody);
