import {updatehat} from "../../types";

export const updateSharlHatAction = (data) => ({
    type: updatehat,
    payload: data,
});


const updateSharlHatFunction = (hatURL) => (dispatch) => {
        dispatch(updateSharlHatAction(hatURL));
};

export default updateSharlHatFunction;