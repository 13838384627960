import React, {useEffect} from 'react';

import {connect} from "react-redux";
import {
    StyleProfileContainer,
    StyleProfileItem,
} from "../../styles/profile";
import {
    StyleAdoptFinalButton,
    StyleAdoptItemMain,
    StyleAdoptItemMainBox
} from "../../styles/adopt";
import {Link} from "react-router-dom";
import getUserItemsFunction from "../../store/actions/MainApp/getUserItemsAction";

function AdoptFinal(props) {

    useEffect(()=>{
        props.dispatch(getUserItemsFunction(props));
    // eslint-disable-next-line
    },[])

    return (
            <>
                <StyleProfileItem>
                    <StyleProfileContainer>
                    <StyleAdoptItemMain>
                    <StyleAdoptItemMainBox>
                    <>
                    <h2>Congratulations! </h2>
                    <h1>The adoption is complete!</h1>

                    <StyleAdoptFinalButton><Link to="/sharls">Go to my Sharls</Link></StyleAdoptFinalButton>

                    </>
                    </StyleAdoptItemMainBox>
                </StyleAdoptItemMain>
               </StyleProfileContainer>
                </StyleProfileItem>
            </>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(AdoptFinal);
