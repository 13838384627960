import {quizstage} from "../../types";


export const updateQuizStageAction = (data) => ({
    type: quizstage,
    payload: data,
});


const updateQuizStageFunction = (data) => (dispatch) => {
    dispatch(updateQuizStageAction(data))

}

export default updateQuizStageFunction;;