import { combineReducers } from 'redux';
import SignInReducer from './SignInReducer';
import UserReducer from './UserReducer';
import PageReducer from "./PageReducer";
import PasswordResetReducer from "./PasswordResetReducer";
import AdoptReducer from "./AdoptReducer";
import GameReducer from "./GameReducer";
import SharlReducer from "./SharlReducer";
import MarketReducer from "./MarketReducer";
import ResearchReducer from "./ResearchReducer";
import TunaCanReducer from "./TunaCanReducer";
import MuseumReducer from "./MuseumReducer";
import ShopReducer from "./ShopReducer";
import HelpReducer from "./HelpReducer";

const rootReducer = combineReducers({
UserReducer,
PageReducer,
PasswordResetReducer,
AdoptReducer,
GameReducer,
SharlReducer,
MarketReducer,
ResearchReducer,
TunaCanReducer,
MuseumReducer,
ShopReducer,
SignInReducer,
HelpReducer,
})

export default rootReducer;