import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";

import HTMLFlipBook from "react-pageflip";
import {
    StyleHistoryBookContainer,
    StyleHistoryPageBody,
    StyleHistoryPageContainer,
    StyleHistoryPageFooter,
    StyleHistoryPageHeader,
    StyleMuseum,
    StyleMuseumBookMiddle,
    StyleMuseumBookSides,
    StyleMuseumContainer,
    StyleHistoryCoverPageContainer,
    StyleMuseumBackButton
} from "../../styles/museum";
import {clearMuseumStageFunction} from "../../store/actions/Museum/clearMuseumStageAction";

const PageCover = React.forwardRef((props, ref) => {
    return (
      <div className="page page-cover" ref={ref} data-density="hard">
        <div className="page-content">
          <h2>{props.children}</h2>
        </div>
      </div>
    );
  });

const Page = React.forwardRef((props, ref) => {
        if (props.number === "front" || props.number === "back") {
            return (
                <StyleHistoryCoverPageContainer ref={ref}>
                        <p>{props.children}</p>
                </StyleHistoryCoverPageContainer>
            );
        } else {
            return (
                <StyleHistoryPageContainer ref={ref}>
                    <StyleHistoryPageHeader>
                        {props.number % 2 ?
                            <h2> </h2>
                            :
                            <h2>Sharl Facts</h2>
                        }
                    </StyleHistoryPageHeader>
                    <StyleHistoryPageBody>
                        <p>{props.children}</p>
                    </StyleHistoryPageBody>
                    <StyleHistoryPageFooter>
                        <p>{props.number}</p>
                    </StyleHistoryPageFooter>
                </StyleHistoryPageContainer>
            );
        }
    });

function SharlFactsBook(props) {
    const [pages,setPages] = useState([])
    const [sharlFactsBook, setSharlFactsBook] = useState([])


    useEffect(() => {
       const history = props.MuseumReducer.research.filter(sharlFact =>
            sharlFact.category.name === "Sharl Facts");
       setSharlFactsBook(history)
        // eslint-disable-next-line
        },[]);

    useEffect(() => {
        if (sharlFactsBook.length > 0) {
            const newPages = [];
            newPages.push(renderCover(props))
            for (let i = 0; i < sharlFactsBook.length; i++) {
                newPages.push(renderPage(i, props))
            }
            newPages.push(renderToBeCont(props))
            newPages.push(renderBackCover(props))
            setPages(newPages)}
            // eslint-disable-next-line
        },[sharlFactsBook]);

    function renderPage(i, props) {
        const pageContent = sharlFactsBook[i];
        return (
            <Page id={i} key={i} number={i+1}>{pageContent.fact}</Page>
        )
    }

    function renderCover(props) {
        return (
            <Page id={"frontCover"} key={"frontCover"} number={"front"}>Sharl Facts</Page>
        )
    }
    function renderBackCover(props) {
        return (
            <Page id={"backCover"} key={"backCover"} number={"back"}></Page>
        )
    }

    function renderToBeCont(props) {
        if (sharlFactsBook.length < 25) {
        return (
            <Page id={"toBeCont"} key={"toBeCont"} number={""}>To be continued.. </Page>
        )} else {
        return (
            <Page id={"theEnd"} key={"theEnd"} number={""}>The End.. </Page>
        )}
    }
    const clearBookHandler = () => {
         props.dispatch(clearMuseumStageFunction())
    }
    
    return (
        <StyleMuseum>
            <StyleMuseumContainer>
                <StyleMuseumBookSides>
                </StyleMuseumBookSides>
                <StyleMuseumBookMiddle>
                        <StyleHistoryBookContainer>
                            <HTMLFlipBook
                                width={710}
                                height={650}
                                size="stretch"
                                minWidth={315}
                                maxWidth={1000}
                                minHeight={400}
                                maxHeight={1533}
                                autoSize={true}
                            >
                            <PageCover/>
                            {pages}
                        </HTMLFlipBook>
                    </StyleHistoryBookContainer>
                </StyleMuseumBookMiddle>
                <StyleMuseumBookSides>
                    <StyleMuseumBackButton onClick={(e) => clearBookHandler()}>Back to Museum</StyleMuseumBackButton>
                </StyleMuseumBookSides>
            </StyleMuseumContainer>
        </StyleMuseum>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(SharlFactsBook);
