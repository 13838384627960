import React from 'react';
import {connect} from "react-redux";
import {
    StyleTankFeedActiveSharls,
    StyleTankFeedBackground, StyleTankFeedButton,
    StyleTankFeedFood, StyleTankFeedFoodBack,  StyleTankFeedInactiveSharls,
    StyleTankFeedSharls,
} from "../../../styles/tanktest";
import {Link} from "react-router-dom";

import DragFishContainer from "./DragFishContainer";
import DropActiveContainer from "./DropActiveContainer";
import DropInactiveContainer from "./DropInactiveContainer";


function FeedSharls(props) {

    return (
        <StyleTankFeedBackground>
            <StyleTankFeedSharls>
                <StyleTankFeedActiveSharls>
                    <DropActiveContainer props={props}/>
                </StyleTankFeedActiveSharls>
                <StyleTankFeedInactiveSharls>
                    <DropInactiveContainer props={props}/>
                </StyleTankFeedInactiveSharls>
            </StyleTankFeedSharls>
            <StyleTankFeedFood>
                <DragFishContainer props={props} />
                <StyleTankFeedFoodBack>
                    <StyleTankFeedButton><Link to="/tank">Back to Tank</Link></StyleTankFeedButton>
                </StyleTankFeedFoodBack>
            </StyleTankFeedFood>
        </StyleTankFeedBackground>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(FeedSharls);
