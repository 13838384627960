import styled from "styled-components";

export const StyleMuseum = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
    background: linear-gradient(to top, rgba(150, 255, 255, 0.7) 0%, rgba(51, 102, 153, 0.6) 100%), url('../images/backgrounds/seabg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    `;

export const StyleMuseumContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

export const StyleMuseumBookSides = styled.div`
    height: 100%;
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;

export const StyleMuseumBookMiddle = styled.div`
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;


export const StyleHistoryBookContainer = styled.div`
    height: 95%;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    `;


export const StyleHistoryPageContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    margin: 0;
    padding: 0;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url('../images/backgrounds/paper.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;    
    `;

export const StyleHistoryCoverPageContainer = styled.div`
    height: 98%;
    width: 100%;
    background: url('../images/backgrounds/book_bg.jpg');
    background-repeat: no-repeat;
    background-size: 99% 99%;    
    p {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;    
        justify-content: center;
        color: white;
        font-size: 4vw;
       } 
        
`;


export const StyleHistoryPageHeader = styled.div`
    height: 8%;
    width: 100%;
    display: flex;

    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
        font-size: 1vw; 
    }
    h2 {
        font-size: 1.2vw;
        font-family: Indie Flower, cursive;    
    }

    `;

export const StyleHistoryPageBody = styled.div`
    height: 87%;
    width: 100%;
    display: flex;
    text-align: justify;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    white-space: pre-line;
    p {
    font-family: Indie Flower, cursive;    
    margin: 5%;
    line-height: 1.2;
    font-size: 1.0vw;
    }
    `;

export const StyleHistoryPageFooter = styled.div`
    height: 5%;
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;


export const StyleMuseumHomeContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  
    background: linear-gradient(to top, rgba(150, 255, 255, 0.7) 0%, rgba(51, 102, 153, 0.6) 100%), url('../images/backgrounds/seabg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
      `;

export const StyleMuseumHomeLeft = styled.div`
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url('../images/sharls/round_sharl.png');
    background-repeat: no-repeat;
    background-size: 20% auto;    
    background-position: center;    
    `;


export const StyleMuseumHomeLeftBody = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

export const StyleMuseumHomeLeftBodyLeft = styled.div`
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    `;
export const StyleMuseumHomeLeftBodyRight = styled.div`
    height: 100%;
    width: 17%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 5%;
    justify-content: center;
    `;

export const StyleMuseumHomeRight = styled.div`
    height: 80%;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 3%;
    
    `;
export const StyleMuseumHomeRightBody = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../images/backgrounds/book_bg.jpg');
    `;

export const StyleMuseumHomeRightInner = styled.div`
    height: 90%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    `;

export const StyleMuseumHomeBook = styled.div`
    height: 100%;
    width: 25%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    border-radius: 24px;
    background-color: ${props => props.bgColor};
    p {
      transform: rotate(-90deg);
      font-size: 3vw;
      white-space: nowrap; 
    }
    `;


export const StyleMuseumBackButton = styled.button`
    height: 20%;
    width: 70%;
    background-color: #336699;
    border-radius: 50%;
    color: white;
    font-size: 2.5vh;
    border: none;
    margin: 2%;
    a:-webkit-any-link {
    color: white;
    text-decoration: none;
    }   
    a {
    color: white;
    }
 `;


export const StyleMuseumTownButton = styled.button`
    height: 75%;
    width: 20%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 2.5vh;
    border: none;
    margin: 2%;
    a:-webkit-any-link {
    color: white;
    text-decoration: none;
    }   
    a {
    color: white;
    }
 `;


export const StyleClassFactsContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;

export const StyleClassFactsImageContainer = styled.div`
    height: 40%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

export const StyleClassFactsImageLeft = styled.div`
    height: 100%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: salmon;
    img {
        height: 100%;
        width: auto;
    }
    `;
export const StyleClassFactsImageRight = styled.div`
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    white-space: pre-line;      
    text-align: left;
    padding-left: 3%;
    line-height: 2;
    font-size: 1.3vw;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    `;

export const StyleClassFactsFact = styled.div`
    height: 15%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 4%;
    padding-top: 4%;
    text-align: left;
    p {
        font-size: 1.2vw;
    }
    `;

