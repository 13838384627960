export const signin = 'SIGNIN';
export const signinerror = "SIGNINERROR";
export const signout = 'SIGNOUT';
export const signup = 'SIGNUP';
export const passwordreset = 'PASSWORDRESET';
export const passwordtoken = 'PASSWORDTOKEN';
export const passwordconfirm = "PASSWORDCONFIRM";
export const passworderror = 'PASSWORDERROR';
export const passwordresetclear = "PASSWORDRESETCLEAR";
export const passwordupdate = "PASSWORDUPDATE";
export const verify = 'VERIFY';
export const verifyerror = 'VERIFYERROR';
export const notverified = "VERIFYTOKEN";
export const confirmemail = "CONFIRMEMAIL";
export const userdata = "USERDATA";
export const clearsignup = "CLEARSIGNUP";
export const takenemail = "TAKENEMAIL";
export const signuploading ="SIGNUPLOADING";
export const signuploaded ="SIGNUPLOADED";
export const usererror = "USERERROR";
export const showheadermenu = "SHOWHMENU";
export const clearuser = "CLEARUSER";
export const togglemainmenu = "SHOWMAINMENU";
export const hideheadermenu = 'HIDEHEADERMENU';
export const adoptstage = "ADOPTSTAGE";
export const chosensharl = "CHOSENSHARL";
export const gottoadoptsharls = "GOTOADOPTSHARLS";
export const gottoadopttoken = "GOTOADOPTTOKEN";
export const clearadoptstage = 'CLEARADOPTSTAGE';
export const adoptloading ="ADOPTLOADING";
export const adoptloaded ="ADOPTLOADED";
export const createsharl = 'CREATESHARL';
export const adopterror = "ADOPTERROR";
export const updatebalance = 'UPDATEBALANCE';
export const getprize = "GETPRIZE";
export const resetprize = "RESETPRIZE";
export const setsharlprize = "SETSHARLPRIZE";
export const getsinglesharl = "GETSINGLESHARL";
export const showsharlitems = "SHOWSHARLITEMS";
export const hidesharlitems = "HIDESHARLITEMS";
export const useritems = "USERITEMS";
export const updatehat = "UPDATEHAT";
export const savehat = "SAVEHAT";
export const removehat = "REMOVEHAT";
export const setselecteditem = "SETSELECTEDITEM";
export const deleteitem = "DELETEITEM";
export const clearitem = "CLEARITEM";
export const getnarl = "GETNARL";
export const gettank = "GETTANK";
export const clearnarl = "CLEARNARL";
export const selectnarl = "SELECTNARL";
export const buynarl = "BUYNARL";
export const dragsharl = "dragsharl";
export const infosharl = "infosharl";
export const researchsharl = "researchsharl";
export const researchcat = "researchcat";
export const getresearch = "getresearch";
export const updateresearch = "updateresearch";
export const resetresearch = "resetresearch";
export const researchprize = "researchprize";
export const dragtunacan = "dragtunacan";
export const tunaprize = "tunaprize";
export const adoptselect = "adoptselect";
export const selecttoken = "SELECTTOKEN";
export const getmuseum = "GETMUSEUM";
export const selectbook = "SELECTBOOK";
export const clearmuseumstage = "CLEARMUSEUM";
export const updateitems = "UPDATEITEMS";
export const updatesharls = "UPDATESHARLS";
export const updatemarket = "UPDATEMARKET";
export const cleartuna = "CLEARTUNA";
export const shopstage = "SHOPSTAGE";
export const quizstage = "QUIZSTAGE";
export const quizresults = "QUIZRESULTS";
export const getinvest = "GETINVEST";
export const investprize = "INVESTPRIZE";
export const checkprize = "CHECKPRIZE";
export const getshop = "GETSHOP";
export const addtocart = "ADDTOCART";
export const helptopic = "HELPTOPIC";
export const helppolicy = "HELPPOLICY";
export const orderid = "ORDERID";
export const selectfood = "SELECTFOOD";
