import React from 'react';
import {connect} from "react-redux";
import { PayPalScriptProvider} from "@paypal/react-paypal-js";

import ShopBody from "./ShopBody";

const initialOptions = {
    "client-id": "AWLCcNgL6XQpuwNbJP_bNPKpi5cQFl4HKcsAoVGjmSKrfvjUCHjtwCYz2DsexzjabpMwjMFm21gF0JYd",
    currency: "USD",
    intent: "capture",
//    "data-client-token": "abc123xyz==",
};

function Shop(props) {
    return (
        <PayPalScriptProvider options={initialOptions} >
            <ShopBody />
        </PayPalScriptProvider>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(Shop);
