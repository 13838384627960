import styled from "styled-components";


export const StyleSackContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    `;


export const StyleSackBody = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    `;


export const StyleSackBodyLeft = styled.div`
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(to top, rgba(150, 255, 255, 0.7) 0%, rgba(51, 102, 153, 0.6) 100%), url('../images/backgrounds/seabg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    
    `;

export const StyleSackBodyRight = styled.div`
    height: 100%;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid black;
    `;


export const StyleSackBodyRightMain = styled.div`
    height: 90%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
    height: 40%;
    }
    h1 {
    font-size: 3.5vh;
    font-weight: bold;
    margin: 5%;
    }
    p {
    font-size: 3vh;
    line-spacing: 2;
    margin: 2%;
    }
    

    `;


export const StyleSackFooter = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
        background-color: #336699;
     p {
     color: white;
     font-size: 2vh;       
     }

    `;

export const StyleSackFooterButton = styled.button`
    height: 70%;
    width: 90%;
    background-color: white;
    border-radius: 24px;
    color: #336699;
    font-size: 2.5vh;
    border: none;
    margin: 2%;
 `;


export const StyleSackRightButton = styled.button`
    height: 8%;
    width: 60%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 2.5vh;
    border: none;
    margin: 2%;
 `;

export const StyleSackFooterItem = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    `;



export const StyleUpgradeContainer = styled.div`
    height: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   `;
export const StyleUpgradeContainerMain = styled.div`
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 2;
    text-align: center;
   `;
export const StyleUpgradeContainerClose = styled.div`
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   `;

export const StyleUpgradeCancelButton = styled.button`
    height: 25%;
    width: 50%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 1.2vw;
    border: none;
   `;

export const StyleUpgradeButton = styled.button`
    height: 15%;
    width: 70%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 1.2vw;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2%;
    overflow-wrap: no-wrap;
    a {
    color: white;
    }
    img {
    height: 50%;
    width: 10%;
    }
 `;

export const StyleSharlSackItemQuantity = styled.div`
    position: absolute;
    right: 2%;
    bottom: 5%;
    height: 20%;
    width: 30%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
     p {
     font-weight: bold;
    font-size: 1.2vw;
    }
  
    `;


export const StyleSharlSackItem = styled.div`
    border-radius: 24px;
    position: relative;
    height: 28%;
    width: 17.8%;
    margin: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    img {
    height: 100%;
    width: auto%;
    }
       h1 {
        font-size: 0;
    }   
    :hover {
    h1 {
        font-size: 1.4vw;
        color: white;
        width: 100%;
        text-align: center;
        position: absolute;
        background-color: rgba(51,102,153,0.9);
        z-index: 10;
        font-weight: bold;   
    }    
    }
 
    `;

export const StyleSharlSackItemContainer = styled.div`
    height: 90%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

        `;


export const StyleSharlItemContainer = styled.div`
    height: 100%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;   

`;

export const StyleSharlItemOuter = styled.div`
    height: 70%;
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2%;
    padding-bottom: 2%;
    background-color: #336699;
    border-radius: 24px;
    h2 {
        font-size: 10vh;
        color: white;
    }
    h2[id="hide"] {
        color: #336699;
    }

`;


export const StyleSharlImage = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    `;

export const StyleTrashImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 50%;
    width: 100%;
    
    p {
    font-size: 3vh;
    font-weight: bold;
    }
    `;

export const StyleTrashImage = styled.img`
    height: 50%;
    width: 25%;
    overflow: none; 
    `;


export const StyleTrashImageDelete = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    button {
    margin: 10%;
    }

    `;

export const StyleSharlSelectedItem = styled.div`
    height: 33%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
    height: 100%;
    }
    
`;


export const StyleSackConfirmDeleteButton = styled.button`
    height: 40%;
    width: 50%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 2vh;
    border: none;
    margin: 2%;
 `;


export const StyleDeleteQuantityInput = styled.input`
 &::-webkit-slider-thumb {
     -webkit-appearance: none;
     width: 15px;
     height: 15px;
     border:1px solid black;
  }
  `;