import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';

import {
    StyleOtherSharlHatContainer, StyleOtherSharlItemImg,
    StyleOtherSharlItemSingle,
    StyleOtherSharlProfileItem,
    StyleProfileSharl,
    StyleSharlContainer,
    StyleSharlLeft,
    StyleSharlMiddle, StyleSharlOtherItemContainer,
} from "../../../styles/sharls";
import getSingleSharlFunction from "../../../store/actions/Sharls/getSingleSharlAction";


function OtherSharls(props) {
    const [sharls, setSharls] = useState("");

    useEffect(() => {
        const allSharls = props.UserReducer.sharls;
        const selectedSharl = props.SharlReducer.selected_sharl;

        if (allSharls && selectedSharl) {
            const otherSharls = allSharls.filter(sharl =>
                sharl.id !==selectedSharl.id && sharl.is_active === true);
                setSharls(otherSharls);
                }
           // eslint-disable-next-line
        },[props.SharlReducer, props.UserReducer]);

    const updateSharlHandler = (e) => {
        const sharlId = parseInt(e.currentTarget.id);
        const newSharl = sharls.filter(sharl =>
            sharl.id === sharlId
        );

         props.dispatch(getSingleSharlFunction(newSharl[0]));
    }

    const bgColorHandler = (color) => {
            return color;
     }

    if (sharls) {
        return (
              <>
          {sharls.map(sharl =>
            <StyleOtherSharlProfileItem id={sharl.id} key={sharl.id} onClick={(e) => updateSharlHandler(e)} bgColor={bgColorHandler(sharl.color)}>
                    <StyleOtherSharlItemImg bgColor={bgColorHandler(sharl.color)}>
                        <StyleSharlOtherItemContainer>
                            <img src={sharl.sharl_avatar} alt={sharl.sharl_avatar} />
                        </StyleSharlOtherItemContainer>
                        { sharl.headgear ?
                            <StyleOtherSharlHatContainer>
                        { sharl.headgear.image.includes("https") ?
                                <img src={sharl.headgear.image} alt={sharl.headgear.name} />
                            :
                                <img src={"https://www.sharl.info/" + sharl.headgear.image} alt={sharl.headgear.name} />
                         }
                            </StyleOtherSharlHatContainer>
                        : ""
                        }
                    </StyleOtherSharlItemImg>
                    <StyleOtherSharlItemSingle>
                        <h2>{sharl.name}</h2>
                    </StyleOtherSharlItemSingle>
               </StyleOtherSharlProfileItem>
                    )}
                    </>
        )
    } else {
            return (
                <StyleProfileSharl>
                <StyleSharlContainer>
             <StyleSharlLeft />
             <StyleSharlMiddle>
            <p>Loading..</p>
             </StyleSharlMiddle>
                </StyleSharlContainer>
                </StyleProfileSharl>
    )
    }
}


const mapStateToProps = state => {
    return {
      ...state,
      };
    };

export default connect(mapStateToProps)(OtherSharls);
