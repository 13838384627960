import React from 'react'
import {
    StyleDragHat,
    StyleDragMain,
    StyleDragSharlContainer,
    StyleInactiveSharl,
} from "../../../../styles/tank";
import { ItemTypes } from './Constants'
import { useDrag } from 'react-dnd'
import selectTankSharlFunction from "../../../../store/actions/Tank/selectTankSharlAction";


export const InactiveSharl = (props) => {
    const sharlID = props.id;

    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.SELECTEDSHARL, itemId: sharlID },
        collect: (monitor) => ({
            item: monitor.getItem(),
            isDragging: !!monitor.isDragging(),
        }),
    })

    const selectSharlHandler = (e) => {
        const selectedSharl = props.sharl;
        props.props.dispatch(selectTankSharlFunction(selectedSharl));
    }



  return (
        <StyleInactiveSharl
          id={props.sharl.id}
          onClick={(e) => selectSharlHandler(e)}
          ref={drag}
          style={{
            opacity: isDragging ? 0.5 : 1,
            cursor: 'move',
          }}>

     <StyleDragSharlContainer>
     <StyleDragMain>
            <img src={props.sharl.sharl_avatar} alt={props.sharl.sharl_avatar} />
                 <p> {props.sharl.name}</p>

        </StyleDragMain>
        { props.sharl.headgear ?
            <StyleDragHat>
          { props.sharl.headgear.image.includes("https") ?
            <img src={props.sharl.headgear.image} alt={props.sharl.headgear.name} />
            :
            <img src={"https://www.sharl.info/" + props.sharl.headgear.image} alt={props.sharl.headgear.name} />
          }
    </StyleDragHat>
    : ""
    }
    </StyleDragSharlContainer>
    </StyleInactiveSharl>
  )
}

export default InactiveSharl;