import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import SharlHistoryBook from "./SharlHistoryBook";
import MuseumHome from "./MuseumHome";
import getMuseumResearchFunction from "../../store/actions/Museum/getMuseumResearchAction";
import SharlFactsBook from "./SharlFactsBook";
import SharkSecretsBook from "./SharkSecretsBook";
import FriendBook from "./FriendBook";


function MuseumBody(props) {
    const [museumStage, setMuseumStage] = useState("sharl_history");

    useEffect(() => {
        props.dispatch(getMuseumResearchFunction(props));
    //    props.dispatch(clearMuseumStageFunction())
        // eslint-disable-next-line
        }, []);

    useEffect(() => {
        setMuseumStage(props.MuseumReducer.museumStage);
        // eslint-disable-next-line
        }, [props.MuseumReducer]);

    switch (museumStage) {
        case "home": {
            return (
                <MuseumHome/>
        )}
        case "sharl_facts": {
            return (
                <SharlFactsBook/>
        )}
        case "shark_secrets": {
            return (
                <SharkSecretsBook/>
        )}
        case "friend_book": {
            return (
                <FriendBook/>
        )}
        case "sharl_history": {
            return(
                <SharlHistoryBook />
            )
        }
        default: {
            return (
                <MuseumHome/>
        )}
    }
}


const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(MuseumBody);
