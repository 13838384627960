import React from 'react';


function TAndCPolicy() {
  return (
        <>
          <p>Terms and conditions</p>
        </>
  );
}

export default TAndCPolicy;
