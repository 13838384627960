export const getSharlTime = (research_end_date) => {
         const currentTime = new Date()
         const currentTimeUTC = (currentTime.getTime() + currentTime.getTimezoneOffset() * 60) / 1000;
         const endTime = new Date(research_end_date).getTime() / 1000;

         if (currentTimeUTC > endTime) {
             return 'completed';
         } else {
             let seconds = endTime - currentTimeUTC;
             let minutes = Math.floor(seconds / 60);
             let hours = Math.floor(minutes / 60);
             minutes = ('0' + String(minutes % 60)).slice(-2)
             hours = ('0' + String(hours % 24)).slice(-2)
             if (hours === "00") {
                 if (minutes === "00") {
                     return "finishing.."
                 } else {
                     return minutes + 'm'
                 }
             } else {
                 return hours + 'h ' + minutes + 'm '
             }

         }
     }