import {getUserItemsAction, setUserItemsAction} from "../MainApp/getUserItemsAction";


const feedSharlFunction = (props,feedData) => (dispatch) => {
    const userID = props.UserReducer.user.id
    const URL = `https://www.sharl.info/backend/api/sharls/feed/${userID}/`;
    const token = props.SignInReducer.token
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'PATCH',
        body: JSON.stringify(feedData),
        headers
    };

     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        dispatch(setUserItemsAction(data.items));
        const removeProperties = (object, ...keys) => Object.entries(object).reduce((prev, [key, value]) => ({...prev, ...(!keys.includes(key) && { [key]: value }) }), {})
        const allData = removeProperties(data, 'items', 'sharls')
        const concatData = {"user":allData, "sharls":data.sharls, "items":data.items}
        dispatch(getUserItemsAction(concatData));
    })
};

export default feedSharlFunction;