import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux'
import {StyleBodyButton, StyleBodyEmail, StyleBodyEmailItem, StyleBodyForm, DonutSpinner, StyleBodyInput} from "../../../styles/signup";
import {StyleBodyItem} from "../../../styles/app";
import {confirmPasswordEmailFunction} from "../../../store/actions/Auth/confirmPasswordEmailAction";
import {passwordResetFunction} from "../../../store/actions/Auth/passwordResetAction";

function PasswordEmail(props) {
  const [email, setEmail] = useState('');
  const [warning, setWarning] = useState(false)

     useEffect(() => {
        if (email) {
            setWarning(false);   }
        },[email]);


  const emailHandler = (e) => {
      e.preventDefault();
      if (!email || email===' '){
          setWarning("Email cannot be blank");
          }
      if (!email.includes('@')) {
          setWarning("Not a valid email address");
      }
      if (!email.includes('.')) {
          setWarning("Not a valid email address");
      }
      else {
        setWarning(false);
        const data = {
          email,
      };

        props.dispatch(passwordResetFunction(data))
      }
      }

    const confirmEmailHandler = (e) => {
      e.preventDefault();
      props.dispatch(confirmPasswordEmailFunction())
    }

  return (
      <>
    <StyleBodyItem>
       <img src={require("../../../assets/images/misc/sharl_swim.png")} alt={"sharl"} />
    </StyleBodyItem>
      <StyleBodyItem>
      <StyleBodyEmail>
        <StyleBodyEmailItem>
            <h2>Forgot Password?</h2>
        </StyleBodyEmailItem>
        <StyleBodyEmailItem>
        { props.LoadingReducer.SignUpLoading ?
             <DonutSpinner></DonutSpinner> :
            <StyleBodyForm
                onSubmit={emailHandler}>
                <StyleBodyInput
                    name='email'
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    value={email}
                    placeholder='Email'
                    type='text'
                />
            </StyleBodyForm>
        }
                  {warning ? <p1>{warning}</p1> :<p></p> }

        </StyleBodyEmailItem>
        <StyleBodyEmailItem>
        <StyleBodyButton onClick={(e) => emailHandler(e)}>
            CONTINUE
        </StyleBodyButton>
        </StyleBodyEmailItem>
     <StyleBodyEmailItem>
        <p onClick={(e) => confirmEmailHandler(e)}> Already have a code?</p>
        </StyleBodyEmailItem>
            </StyleBodyEmail>
    </StyleBodyItem>
    </>
);
}


const mapStateToProps = state => {
    return {
      ...state,
      };
    };

export default connect(mapStateToProps)(PasswordEmail);
