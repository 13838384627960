import {getresearch, researchcat, researchprize, resetresearch, updateresearch} from "../types";

const initialState = {
    selectedSharl: "",
    selectedResearch: "",
    user_research: [],
    researchStage: "start",
    researchPrize: [],
    };

const ResearchReducer = (state = initialState, action) => {
  switch (action.type) {
      case researchcat:
        return {
            ...state,
                selectedResearch: action.payload.research,
                selectedSharl: action.payload.sharl,
        }
      case getresearch:
          return {
              ...state,
              user_research: action.payload,
          }
      case updateresearch:
          return {
              ...state,
              researchStage: action.payload,
          }
      case resetresearch:
          return {
              ...state,
              researchStage: "start",
              selectedResearch: [],
              selectedSharl: [],
          }
      case researchprize:
          return {
              ...state,
              researchPrize: action.payload,
          }
        default:
            return state;
  }
}

export default ResearchReducer;