import {deleteitem} from "../../types";

export const deleteItemAction = (data) => ({
    type: deleteitem,
    payload: data,
});


const deleteItemFunction = (props,data) => (dispatch) => {

    const URL = `https://www.sharl.info/backend/api/users/delete-item/`;

    const token = props.SignInReducer.token;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'PATCH',
        headers,
        body: JSON.stringify(data),
    };

     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        dispatch(deleteItemAction(data));
    })

};

export default deleteItemFunction;