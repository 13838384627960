import {showsharlitems, hidesharlitems, getsinglesharl, useritems, updatehat, savehat, removehat} from "../types";

const initialState = {
    showSharlItems: false,
    selected_sharl: [],
    user_items: "",
    headgear: "",
  };

const SharlReducer = (state = initialState, action) => {
  switch (action.type) {
      case showsharlitems:
        return {
            ...state,
            showSharlItems: true,
        }
      case hidesharlitems:
        return {
            ...state,
            showSharlItems: false,
        }
        case getsinglesharl: {
            return {
                ...state,
                showSharlItems: false,
                selected_sharl: action.payload,
                headgear: action.headgear,
            }
        }
        case updatehat:
            return {
            ...state,
            headgear: action.payload,
            }

        case savehat:
            return {
            ...state,
            selected_sharl: action.payload,
            headgear: action.payload.headgear.image,
            }
        case useritems: {
              return {
                 ...state,
                  user_items: action.payload,
              }
        }
      case removehat: {
          return {
              ...state,
            selected_sharl: action.payload,
            headgear: action.payload.headgear.image,
          }
      }
        default:
            return state;
  }
}

export default SharlReducer;