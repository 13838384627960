import styled from "styled-components";

export const StyleAdoptFinalButton = styled.button`
    height: 20%;
    width: 45%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 2.5vh;
    border: none;
    margin: 2%;
    a:-webkit-any-link {
        color: white;
        text-decoration: none;
    }   
    a {
        color: white;
    }
 `;

export const StyleSharlAdoptItem = styled.div`
    height: 30%;
    width: 100%;
    display: flex;
    background-color: white;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;

export const StyleAdoptFormButton = styled.button`
    height: 50%;
    width: 50%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 2.5vh;
    border: none;
    margin: 2%;
    a:-webkit-any-link {
    color: white;
    text-decoration: none;
    }   
    a {
    color: white;
    }
 `;


export const StyleAdoptInput = styled.input`
  height: 15%;
  width: 80%;
  font-size: 2.2vh;  
  border: solid 1px #ebebeb;
  margin: 2%;  
  ::placeholder{
    color: #4c4c4c;
    font-size: 1vw;
  }
  :focus {
    outline:none;
    }  
   `;

export const StyleAdoptInputBox = styled.input`
  height: 25px;
  width: 25px;
  border: solid 1px #ebebeb;
  padding: 0;
  margin: 2% 2% 2% 10%;
  display: inline-block;  
  :focus {
    outline:none;
    }  
   `;

export const StyleAdoptCheckboxContainer = styled.div`
    height: 25%;
    width: 100%;
    p{
        overflow: visible;
        font-size: 1vw;  
        line-height: 1.1;
        text-align: left;
    }
    display: flex;
    align-items: center;
    justify-content: left;
 `;

export const StyleAdoptForm = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  
  p {
  font-size: 2vh;
  }
  h2 {
  font-size: 2vh;
  color: red;
  }
   `;

export const StyleSharlItemContainer = styled.div`
    height: 60%;
    width: 100%; 
    border-radius: 20px;
`;


export const StyleSharlSingleContainer = styled.div`
    height: 40%;
    width: 100%; 
    border-radius: 20px;
    background-color: ${props => props.bgColor || "white"};

    img {
    height: 80%;
    width: auto;
    }

`;

export const StyleTokenSingleContainer = styled.div`
    height: 40%;
    width: 100%; 
    border-radius: 20px;
    background-color: #f589d6;

    img {
    height: 80%;
    width: auto;
    }

`;
export const StyleSharlItemSingle = styled.div`
    height: 20%;
    width: 100%;
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    p {
    line-height: 1.5;
    font-size: 2.5vh;
    font-weight: bold;
    }
    
   `;

export const StyleSharlItem = styled.div`
    height: 25%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;     
    
    p {
        height: 100%;
        font-size: 2.2vh;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.5;
        
    }
    
    `;


export const FishRatingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 15%;
    height: 65%;
    margin: 2%
  }
 
`;

export const StyleSharlAdoptConfirm = styled.div`
    height: ${props => props.butHeight || "100%"};
    width: ${props => props.butWidth || "25%"};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    `;

export const StyleAdoptItemMain = styled.div`
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;

export const StyleAdoptItemMainBox = styled.div`
    height: 60%;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    h2 {
    line-height: 2;
    margin-bottom: 5%;
    }
    h1 {
    font-size: 3vh;
    margin: 1%;
    text-align: center;
    }
    p {
    font-size: 3vh;
    margin: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
        width: 10%;
        margin-left: 2%;
        }
    }
    `;

export const StyleAdoptItemButtons = styled.div`
    height: 40%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
`

export const StyleAdoptItemText = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const StyleAdoptFullButton = styled.button`
    height: 20%;
    width: 35%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 1.2vw;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2%;
    overflow-wrap: no-wrap;
    a:-webkit-any-link {
    color: white;
    text-decoration: none;
    }   
    a {
    color: white;
   }
 `;
export const StyleAdoptButton = styled.button`
    height: 40%;
    width: 47%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 1vw;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2%;
    overflow-wrap: no-wrap;
    a:-webkit-any-link {
    color: white;
    text-decoration: none;
    }   
    a {
    color: white;
    }
    img {
    height: 50%;
    width: 10%;
    }
 `;

export const StyleSelectTokenContainer = styled.div`
    height: 90%;
    width: 60%;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
`
export const StyleSelectTokenHeader = styled.div`
    border-radius: 20px;
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const StyleSelectTokenBody = styled.div`
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    
`;

export const StyleSelectToken = styled.div`
    height: 40%;
    width: 20%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1%;
    background-color: ${props => props.bgColor || "white"};
    color: ${props => props.pColor || "black"};

    img {
        height: 65%;
    }
    p {
        font-size: 1vw;
    }
`