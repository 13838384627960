import React from 'react'
import { ItemTypes } from '../Constants'
import { useDrag } from 'react-dnd'
import {
    StyleResearchAvatar,
    StyleResearchAvatarImg,
    StyleResearchHat,
} from "../../../styles/research";
import {StyleResearchDragTestSharl} from "../../../styles/test";
import selectResearchFunction from "../../../store/actions/Research/selectResearchAction";


export const DragSelectedSharl = ({ sharl, props }) => {
    const itemId = sharl.id;
    const thisSharl = sharl;

    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.RESEARCH, itemId: itemId, sharl: thisSharl },
        collect: (monitor) => ({
            item: monitor.getItem(),
            isDragging: !!monitor.isDragging(),
        }),
    })
    const clearResearchHandler = () => {
        props.dispatch(selectResearchFunction({"research":"", "sharl":""}))
    }

  return (
        <StyleResearchDragTestSharl
          id={sharl.id}
          ref={drag}
          onClick={()=>clearResearchHandler()}
          bgColor={sharl.color}
          style={{
            opacity: isDragging ? 0.1 : 1,
          }}>
               <StyleResearchAvatar>
                   <StyleResearchAvatarImg>
                       <img src={sharl.sharl_avatar} alt={sharl.sharl_avatar} />
                   </StyleResearchAvatarImg>
                   { sharl.headgear ?
                       <StyleResearchHat>
                           { sharl.headgear.image.includes("https") ?
                               <img src={sharl.headgear.image} alt={sharl.headgear.name} />
                               :
                               <img src={"https://www.sharl.info/" + sharl.headgear.image} alt={sharl.headgear.name} />
                           }
                       </StyleResearchHat>
                       : ""
                   }
               </StyleResearchAvatar>
    </StyleResearchDragTestSharl>
  )
}
