export const faqQuestions = [
    {"id":1, "question":"What is Sharl Info?", "answer":"Sharl Info is a fun, free game where you can adopt, play and dress up Sharls!"},
    {"id":2, "question":"What's the difference between a Sharl and a shark?",
        "answer":"There are many differences between Sharls and sharks. " +
            "For instance, Sharls are happy to chat with people.. Sharks however cannot even talk! For more shark vs sharl facts, send one of your sharls to research today!"},
    {"id":3, "question":"Where can I get Tokens for rare Sharls or Friends?",
        "answer":"Most tokens can be found around Sharl's Town. Try playing some games or checking out Barl's exotic wares.. "},
    {"id":4, "question":"Is Sharl Info free to play?", "answer":"Yes! While there are some paid features, like NFT creation, the main game is completely free to play!"},
    {"id":5, "question":"How can I get a hat for my Sharl?", "answer":"There are many places a simple Sharl can find a Hat. Sometimes in the place you'd least expect.." +
            "like a stinky tuna can"},
    {"id":6, "question":"What happens once I adopt a Sharl?", "answer":"They are yours forever! Sharl's get very attached and do not like to say goodbye!"},
    {"id":7, "question":"Can I release a Sharl after adoption?", "answer":"While Sharl's are forever, it is possible to release your Sharls to NFTs so that" +
            " they can always be by your side!"},
    {"id":8, "question":"Is it possible to rename a Sharl?", "answer":"No, a Sharl gets much too attached to the name you give them. Just like a Sharl, their name is forever."},
    {"id":9, "question":"Who is Top Sharl?", "answer":"I'm Top Sharl!"},
    {"id":10, "question":"Where can I find out more about the Sharls?", "answer":"Try sending your Sharls out to research. They'll fill up the museum in no time!"},
    {"id":11, "question":"What is Happy Sharl's Studio?", "answer":"Happy Sharl's Studio is where you can create NFTs with your sharls."},
    {"id":12, "question":"Where can I learn more about how to play?", "answer":"Visit the Sharl Info wiki for more detailed game play information"},
]
