import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    StyleGamesBody,
    StyleGamesButton,
    StyleGamesButtonContainer,
    StyleGamesContainer,
    StyleGamesFooter, StyleGamesMatchBLeft, StyleGamesMatchBMid,
    StyleGamesMatchBottom, StyleGamesMatchBRight,
    StyleGamesMatchContinueButton,
    StyleGamesMatchSharlAnswers1,
    StyleGamesMatchTop,
    StyleGamesMatchTopCIAnswers,
    StyleGamesMatchTopCIImg,
    StyleGamesMatchTopCItems,
    StyleGamesMatchTopContestants,
    StyleGamesMatchTopQ1Box,
    StyleGamesMatchTopSharl,
    StyleGamesQuizQuestionButton,
} from "../../../styles/games";
import {Link} from "react-router-dom";
import getMatchSharls from "./getMatchSharls";
import calculateBalanceFunction from "../../MainApp/updateBalance";
import updateBalanceFunction from "../../../store/actions/MainApp/updateBalanceAction";


function SharlMatch(props) {
    const [contestants,setContestants] = useState([]);
    const [targetSharl,setTargetSharl] = useState("");
    const [gameStage,setGameStage] = useState(0);
    const [questions,setQuestions] = useState([]);
    const [targetPosition,setTargetPosition] = useState(999);
    const [selectedQ1,setSelectedQ1] = useState("");
    const [selectedQ2,setSelectedQ2] = useState("");
    const [selectedQ3,setSelectedQ3] = useState("");
    const [door1Image,setDoor1Image] = useState("match_door")
    const [door2Image,setDoor2Image] = useState("match_door")
    const [door3Image,setDoor3Image] = useState("match_door")
    const [finalText, setFinalText] = useState("")
    const [success, setSuccess] = useState("")

    useEffect(()=>{
        },[])

    const startMatchHandler = () => {
        const sharls = ['Barl','Wharl','Narl','Round Sharl','Cheeky Sharl','Pancake Sharl','Angry Sharl','Sleepy Sharl','Cute Sharl']
        const randomSharl = Math.floor(Math.random() * sharls.length);
        const selectedSharl = sharls[randomSharl]
        setTargetSharl(selectedSharl);
        const newContestants = getMatchSharls(selectedSharl);
        setContestants(newContestants.sharls);
        setTargetPosition(newContestants.target)
        setQuestions(newContestants.questions)
        setGameStage(1);

        //need to reduce balance by 100 coins
        const userBal = calculateBalanceFunction(props,"-", 100)
        props.dispatch(updateBalanceFunction(props,userBal))
    }
    const resetMatchHandler = () => {
        setTargetSharl("");
        setSelectedQ1(false)
        setSelectedQ2(false)
        setSelectedQ3(false)
        setContestants([]);
        setTargetPosition(999)
        setQuestions([])
        setGameStage(0);
        setDoor1Image("match_door")
        setDoor2Image("match_door")
        setDoor3Image("match_door")
        setSuccess("")
    }

    const claimPrizeHandler = () => {
        const userBal = calculateBalanceFunction(props,"+", 250)
        props.dispatch(updateBalanceFunction(props,userBal))
        resetMatchHandler()
    }

    const selectedDoorHandler = (doorNumber) => {
        if (gameStage===7) {
            if (doorNumber===0){
                setDoor1Image(contestants[0].name)
                if (targetPosition===0){
                    const text = "Congratulations! You correctly selected " + contestants[0].name + "! It's a match!"
                    setFinalText(text)
                    setTimeout(function(){
                        setSuccess("true")
                        setDoor2Image(contestants[1].name);
                        setDoor3Image(contestants[2].name);
                        }, 1000);
                } else {
                    const targetDoor = (targetPosition + 1).toString()
                    const text = "Oh no! You incorrectly selected " + contestants[0].name + ". " + targetSharl +" was behind door "+targetDoor+"!"
                    setFinalText(text)
                    setTimeout(function(){
                        setSuccess("false")
                        setDoor2Image(contestants[1].name);
                        setDoor3Image(contestants[2].name);
                        }, 1000);
                }
            } else if (doorNumber===1) {
                setDoor2Image(contestants[1].name)
                if (targetPosition===1){
                    const text = "Congratulations! You correctly selected " + contestants[1].name + "! It's a match!"
                    setFinalText(text)
                    setTimeout(function(){
                        setSuccess("true")
                        setDoor1Image(contestants[0].name);
                        setDoor3Image(contestants[2].name);
                        }, 1000);
                } else {
                    const targetDoor = (targetPosition + 1).toString()
                    const text = "Oh no! You incorrectly selected " + contestants[1].name + ". " + targetSharl +" was behind door "+targetDoor+"!"
                    setFinalText(text)
                    setTimeout(function(){
                        setSuccess("false")
                        setDoor1Image(contestants[0].name);
                        setDoor3Image(contestants[2].name);
                        }, 1000);
                }
            } else {
                setDoor3Image(contestants[2].name)
                if (targetPosition===2){
                    const text = "Congratulations! You correctly selected " + contestants[2].name + "! It's a match!"
                    setFinalText(text)
                    setTimeout(function(){
                        setSuccess("true")
                        setDoor1Image(contestants[0].name);
                        setDoor2Image(contestants[1].name);
                        }, 1000);
                } else {
                    const targetDoor = (targetPosition + 1).toString()
                    const text = "Oh no! You incorrectly selected " + contestants[2].name + ". " + targetSharl +" was behind door "+targetDoor+"!"
                    setFinalText(text)
                    setTimeout(function(){
                        setSuccess("false")
                        setDoor1Image(contestants[0].name);
                        setDoor2Image(contestants[1].name);
                        }, 1000);
                }
            }
            setGameStage(8)
        }

    }

    const goToQ1Handler = () => {
            setGameStage(2)
    }

    const goToQ2Handler = () => {
            setGameStage(4)
    }

    const question1Handler = (question) => {
        setGameStage(3)
        if (question===1) {
            setSelectedQ1(questions["q1"])
            const otherQ = questions["q2"]
            otherQ["qValue"] = "q5"
            const newQ = {"q5": otherQ}
            const currentQuestions = questions
            const newQuestions = Object.assign(currentQuestions,newQ);
            setQuestions(newQuestions);
            const newContestants = []
            for (let i=0; i < contestants.length; i++) {
                const sharl = contestants[i]
                sharl["q5"] = sharl.q2
                newContestants.push(sharl)
            } setContestants(newContestants)
        } else if (question===2) {
            setSelectedQ1(questions["q2"])
            const otherQ = questions["q1"]
            otherQ["qValue"] = "q5"
            const newQ = {"q5": otherQ}
            const currentQuestions = questions
            const newQuestions = Object.assign(currentQuestions,newQ);
            setQuestions(newQuestions);
            const newContestants = []
            for (let i=0; i < contestants.length; i++) {
                const sharl = contestants[i]
                sharl["q5"] = sharl.q1
                newContestants.push(sharl)
            }
            setContestants(newContestants)
        }
    }

    const question2Handler = (question) => {
        setGameStage(5)
        if (question===3) {
            setSelectedQ2(questions["q3"])
            const otherQ = questions["q4"]
            otherQ["qValue"] = "q6"
            const newQ = {"q6": otherQ}
            const currentQuestions = questions
            const newQuestions = Object.assign(currentQuestions,newQ);
            setQuestions(newQuestions);
              const newContestants = []
            for (let i=0; i < contestants.length; i++) {
                const sharl = contestants[i]
                sharl["q6"] = sharl.q4
                newContestants.push(sharl)
            }
            setContestants(newContestants);
        } else if (question===4) {
            setSelectedQ2(questions["q4"])
            const otherQ = questions["q3"]
            otherQ["qValue"] = "q6"
            const newQ = {"q6": otherQ}
            const currentQuestions = questions
            const newQuestions = Object.assign(currentQuestions,newQ);
            setQuestions(newQuestions);
            const newContestants = []
            for (let i=0; i < contestants.length; i++) {
                const sharl = contestants[i]
                sharl["q6"] = sharl.q3
                newContestants.push(sharl)
            }
            setContestants(newContestants)
        }
    }


    const goToQ3Handler = () => {
            setGameStage(6)
    }

    const question3Handler = (question) => {
        setGameStage(7)
        if (question===5) {
          setSelectedQ3(questions["q5"])
          } else if (question===6) {
          setSelectedQ3(questions["q6"])
        }
    }

    const bgColorHandler = (id) => {
        if (id===0 || id===2) {
          return "#b7cceb"
          } else  {
          return "#a2b8eb"
        }
    }

    const bRadiusHandler = (id) => {
        if (id===2) {
          return "0px 24px 0px 0px"
          } else  {
          return "0px"
        }
    }


    return (
        <StyleGamesContainer>
           <StyleGamesBody>
               <StyleGamesMatchTop>
                   <StyleGamesMatchTopSharl>
                       { targetSharl ?
                       <>
                       <img src={require("../../../assets/images/games/sharls/"+targetSharl+".png")} alt={targetSharl} />
                       <p>{targetSharl}</p>
                       </>
                           :""}
                   </StyleGamesMatchTopSharl>
                   <StyleGamesMatchTopContestants>
                       {selectedQ1 && (gameStage===3 || gameStage===4) ?
                           <StyleGamesMatchTopQ1Box>
                               <p>{selectedQ1.question}</p>
                           </StyleGamesMatchTopQ1Box>
                       :""}
                       {selectedQ2 && (gameStage===5 || gameStage===6) ?
                       <StyleGamesMatchTopQ1Box>
                           <p>{selectedQ2.question}</p>
                       </StyleGamesMatchTopQ1Box>
                        :""}
                       {selectedQ3 && (gameStage===7)?
                           <StyleGamesMatchTopQ1Box>
                               <p>{selectedQ3.question}</p>
                           </StyleGamesMatchTopQ1Box>
                       :""}
                        {contestants.length===0 ?
                            <div>
                                <img src={require("../../../assets/images/games/sharls/match_sharl.png")} alt={"matchmaker"} />
                            </div>
                        :""}
                           {contestants.map(sharl =>
                             <StyleGamesMatchTopCItems bgColor={bgColorHandler(sharl.id)} bRadius={bRadiusHandler(sharl.id)} onClick={()=>selectedDoorHandler(sharl.id)} id={sharl.id} key={sharl.id}>
                                 <StyleGamesMatchTopCIImg>
                                     {sharl.id===0 ?
                                        <img src={require("../../../assets/images/games/sharls/"+door1Image+".png")} alt={sharl.id} />
                                         :""}
                                    {sharl.id===1 ?
                                        <img src={require("../../../assets/images/games/sharls/"+door2Image+".png")} alt={sharl.id} />
                                         :""}
                                     {sharl.id===2 ?
                                        <img src={require("../../../assets/images/games/sharls/"+door3Image+".png")} alt={sharl.id} />
                                         :""}
                                 </StyleGamesMatchTopCIImg>
                                 <StyleGamesMatchTopCIAnswers>
                                     { door1Image!=="match_door" && door2Image!=="match_door" && door3Image!=="match_door" ?
                                         <StyleGamesMatchSharlAnswers1>
                                             <p><b>{sharl.name}</b></p>
                                        </StyleGamesMatchSharlAnswers1>
                                     :""}
                                     { selectedQ1 && (gameStage===3 || gameStage===4)?
                                         <StyleGamesMatchSharlAnswers1>
                                             {selectedQ1.qValue==="q1" ?
                                                 <p>{sharl.q1}</p> : <p>{sharl.q2}</p>}
                                        </StyleGamesMatchSharlAnswers1>
                                     :""}
                                     { selectedQ2 && (gameStage===5 || gameStage===6) ?
                                         <StyleGamesMatchSharlAnswers1>
                                             {selectedQ2.qValue==="q3" ?
                                                 <p>{sharl.q3}</p> : <p>{sharl.q4}</p>}
                                        </StyleGamesMatchSharlAnswers1>
                                     :""}
                                     { selectedQ3 && (gameStage===7) ?
                                         <StyleGamesMatchSharlAnswers1>
                                             {selectedQ3.qValue==="q5" ?
                                                 <p>{sharl.q5}</p> : <p>{sharl.q6}</p>}
                                        </StyleGamesMatchSharlAnswers1>
                                     :""}
                                 </StyleGamesMatchTopCIAnswers>
                        </StyleGamesMatchTopCItems> )}
                   </StyleGamesMatchTopContestants>
                   </StyleGamesMatchTop>


                   {gameStage === 0 ?
                       <StyleGamesMatchBottom>
                           <StyleGamesMatchBLeft>
                           </StyleGamesMatchBLeft>
                           <StyleGamesMatchBMid>
                                <p>Welcome to the match game!</p>
                                <p>Lets reveal the contestants and start the game.</p>
                           </StyleGamesMatchBMid>
                           <StyleGamesMatchBRight>
                               <StyleGamesMatchContinueButton onClick={() => startMatchHandler()}>Start 100<img src={require("../../../assets/images/misc/shadollar.png")} alt={"shadollar"}/></StyleGamesMatchContinueButton>
                           </StyleGamesMatchBRight>
                       </StyleGamesMatchBottom>
                       : ""
                   }
                   {gameStage === 1 ?
                       <StyleGamesMatchBottom>
                            <StyleGamesMatchBLeft>
                                <img src={require("../../../assets/images/games/sharls/match_sharl.png")} alt={"matchmaker"} />
                            </StyleGamesMatchBLeft>
                         <StyleGamesMatchBMid>
                            <p>Our match today is hoping to find a <b>{targetSharl}</b></p>
                            <p>Let's ask some questions and see which door <b>{targetSharl}</b> is hiding behind</p>
                         </StyleGamesMatchBMid>
                           <StyleGamesMatchBRight>
                           <StyleGamesMatchContinueButton onClick={() => goToQ1Handler()}>Continue</StyleGamesMatchContinueButton>
                           </StyleGamesMatchBRight>
                         </StyleGamesMatchBottom>
                       : ""
                   }
                   {gameStage === 2 ?
                       <StyleGamesMatchBottom>
                             <StyleGamesMatchBLeft>
                                <img src={require("../../../assets/images/games/sharls/match_sharl.png")} alt={"matchmaker"} />
                             </StyleGamesMatchBLeft>
                             <StyleGamesMatchBMid>
                                <p>Please select one of the following two questions for the sharls</p>
                             </StyleGamesMatchBMid>
                            <StyleGamesMatchBRight>
                                <StyleGamesQuizQuestionButton onClick={()=>question1Handler(1)}>{questions["q1"].question}</StyleGamesQuizQuestionButton>
                                <StyleGamesQuizQuestionButton onClick={()=>question1Handler(2)}>{questions["q2"].question}</StyleGamesQuizQuestionButton>
                            </StyleGamesMatchBRight>
                           </StyleGamesMatchBottom>
                       : ""
                   }
                   {gameStage === 3 ?
                       <StyleGamesMatchBottom>
                             <StyleGamesMatchBLeft>
                                <img src={require("../../../assets/images/games/sharls/match_sharl.png")} alt={"matchmaker"} />
                             </StyleGamesMatchBLeft>
                             <StyleGamesMatchBMid>
                                <p>Good question! Let's move on to question 2..</p>
                             </StyleGamesMatchBMid>
                            <StyleGamesMatchBRight>
                                <StyleGamesMatchContinueButton onClick={()=>goToQ2Handler()}>Continue</StyleGamesMatchContinueButton>
                            </StyleGamesMatchBRight>
                        </StyleGamesMatchBottom>
                       : ""
                   }
                    {gameStage === 4 ?
                       <StyleGamesMatchBottom>
                             <StyleGamesMatchBLeft>
                                <img src={require("../../../assets/images/games/sharls/match_sharl.png")} alt={"matchmaker"} />
                             </StyleGamesMatchBLeft>
                             <StyleGamesMatchBMid>
                                <p>Please select one of the following two questions for the sharls</p>
                             </StyleGamesMatchBMid>
                             <StyleGamesMatchBRight>
                                 <StyleGamesQuizQuestionButton onClick={()=>question2Handler(3)}>{questions["q3"].question}</StyleGamesQuizQuestionButton>
                                 <StyleGamesQuizQuestionButton onClick={()=>question2Handler(4)}>{questions["q4"].question}</StyleGamesQuizQuestionButton>
                            </StyleGamesMatchBRight>
                       </StyleGamesMatchBottom>
                       : ""
                   }
                   {gameStage === 5 ?
                       <StyleGamesMatchBottom>
                           <StyleGamesMatchBLeft>
                                <img src={require("../../../assets/images/games/sharls/match_sharl.png")} alt={"matchmaker"} />
                             </StyleGamesMatchBLeft>
                             <StyleGamesMatchBMid>
                                <p>Great question! Let's move on to the final question</p>
                             </StyleGamesMatchBMid>
                            <StyleGamesMatchBRight>
                                <StyleGamesMatchContinueButton onClick={()=>goToQ3Handler()}>Continue</StyleGamesMatchContinueButton>
                            </StyleGamesMatchBRight>
                        </StyleGamesMatchBottom>
                       : ""
                   }
                    {gameStage === 6 ?
                       <StyleGamesMatchBottom>
                            <StyleGamesMatchBLeft>
                                <img src={require("../../../assets/images/games/sharls/match_sharl.png")} alt={"matchmaker"} />
                             </StyleGamesMatchBLeft>
                             <StyleGamesMatchBMid>
                                 <p>Please select one of the following two questions for the sharls</p>
                             </StyleGamesMatchBMid>
                            <StyleGamesMatchBRight>
                                <StyleGamesQuizQuestionButton onClick={()=>question3Handler(5)}>{questions["q5"].question}</StyleGamesQuizQuestionButton>
                                <StyleGamesQuizQuestionButton onClick={()=>question3Handler(6)}>{questions["q6"].question}</StyleGamesQuizQuestionButton>
                            </StyleGamesMatchBRight>
                        </StyleGamesMatchBottom>
                       : ""
                   }
                   {gameStage === 7 ?
                       <StyleGamesMatchBottom>
                            <StyleGamesMatchBLeft>
                                <img src={require("../../../assets/images/games/sharls/match_sharl.png")} alt={"matchmaker"} />
                            </StyleGamesMatchBLeft>
                           <StyleGamesMatchBMid>
                             <p>Now you have their answers, please select the door you think {targetSharl} is behind!</p>
                           </StyleGamesMatchBMid>
                           <StyleGamesMatchBRight>
                           </StyleGamesMatchBRight>
                       </StyleGamesMatchBottom>
                       : ""
                   }
                   {gameStage === 8 ?
                       <StyleGamesMatchBottom>
                            <StyleGamesMatchBLeft>
                                <img src={require("../../../assets/images/games/sharls/match_sharl.png")} alt={"matchmaker"} />
                             </StyleGamesMatchBLeft>
                             <StyleGamesMatchBMid>
                                <p>{finalText}</p>
                             </StyleGamesMatchBMid>
                            <StyleGamesMatchBRight>
                                {success==="true" ?
                                    <StyleGamesMatchContinueButton onClick={()=>claimPrizeHandler()}>Claim Prize 250<img src={require("../../../assets/images/misc/shadollar.png")} alt={"shadollar"}/></StyleGamesMatchContinueButton>
                                    : "" }
                                {success==="false" ?
                                    <StyleGamesMatchContinueButton onClick={() => resetMatchHandler()}>Start Over</StyleGamesMatchContinueButton>
                                    : ""
                                }
                          </StyleGamesMatchBRight>
                        </StyleGamesMatchBottom>
                       : ""
                   }

            </StyleGamesBody>
            <StyleGamesFooter>
                <StyleGamesButtonContainer>
                    <Link to="/games">
                        <StyleGamesButton>Back to Games</StyleGamesButton>
                    </Link>
                </StyleGamesButtonContainer>
            </StyleGamesFooter>
        </StyleGamesContainer>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(SharlMatch);
