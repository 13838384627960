import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    StyleGamesButton,
    StyleGamesButtonContainer,
    StyleGamesContainer,
    StyleGamesFooter,
    StyleGamesInvest,
    StyleGamesInvestButton,
    StyleGamesInvestItem,
    StyleInvestBottom,
    StyleInvestMid, StyleInvestMidBlank,
    StyleInvestTop, StyleInvestTopName, StyleInvestTopSharl
} from "../../../styles/games";
import {Link} from "react-router-dom";
import getSharlInvestFunction from "../../../store/actions/Games/getSharlInvestAction";
import confirmSharlInvestFunction from "../../../store/actions/Games/confirmSharlInvestAction";


function SharlInvest(props) {
    const [investments,setInvestments] = useState()
    const [prize1, setPrize1] = useState()
    const [prize2, setPrize2] = useState()
    const [prize3, setPrize3] = useState()

    useEffect(()=>{
        props.dispatch(getSharlInvestFunction(props))
        setPrize1([])
        setPrize2([])
        setPrize3([])
        // eslint-disable-next-line
    },[])


    useEffect(()=>{
        const invest = props.GameReducer.investments
        if (invest) {
            setInvestments(invest[0])
        }
        const invest_prize = props.GameReducer.invest_prize
        if (invest_prize) {
            const sharl_number = invest_prize.sharl_number
            if (sharl_number===1){
                setPrize1(invest_prize)
            } else if (sharl_number===2){
                setPrize2(invest_prize)
            } else if (sharl_number===3){
                setPrize3(invest_prize)
            }
        }
        // eslint-disable-next-line
        },[props.GameReducer])

    const selectInvestmentHandler = (investment) => {
        const sharl_number = {"sharl_number": investment}
        props.dispatch(confirmSharlInvestFunction(props,sharl_number))
    }

    return (
        <StyleGamesContainer>
            {investments ?
           <StyleGamesInvest>
                   {investments.location_1 ?
                       <StyleGamesInvestItem>
                            <StyleInvestTop>
                                <img src={require("../../../assets/images/invest/locations/"+investments.location_1+".png")} alt={investments.location_1} />
                                <StyleInvestTopSharl>
                                    <img src={require("../../../assets/images/games/sharls/"+investments.sharl_1+".png")} alt={investments.sharl_1} />
                                </StyleInvestTopSharl>
                                <StyleInvestTopName>
                                    <img src={require("../../../assets/images/invest/names/"+investments.name_1+".png")} alt={investments.name_1} />
                                </StyleInvestTopName>
                            </StyleInvestTop>
                           {investments.completed_1===false && prize1.length===0 ?
                           <StyleInvestMid>
                               <p>{investments.message_1}</p>
                           </StyleInvestMid>
                               :""}
                           {investments.completed_1===false && prize1.length!==0 ?
                                <StyleInvestMidBlank>
                                    {prize1.success===true ?
                                        <>
                                            <p>Investment Complete!</p>
                                            <h1>The {investments.name_1} {investments.location_1} was a great
                                                success!</h1>
                                            <h1>{investments.sharl_1} gives you {prize1.prize}<img src={require("../../../assets/images/misc/shadollar.png")} alt={"shadollar"}/> in return!</h1>
                                        </>
                                        :
                                        <>
                                            <p>Investment Complete!</p>
                                            <h1>The {investments.name_1} {investments.location_1} was unsuccessful..</h1>
                                            <h1>{investments.sharl_1} is a bit sad</h1>
                                        </>
                                    }
                                 </StyleInvestMidBlank> :""}
                           {investments.completed_1===true ?
                                <StyleInvestMidBlank>
                                   <p>Investment Complete!</p>
                                   <h1>The Shammunity thanks you for supporting the {investments.name_1} {investments.location_1} project!</h1>
                                 </StyleInvestMidBlank>
                          : ""}
                           <StyleInvestBottom>
                                {investments.completed_1===false && prize1.length===0 ?
                                    <StyleGamesInvestButton onClick={()=>selectInvestmentHandler(1)}>{investments.cost_1}<img src={require("../../../assets/images/misc/shadollar.png")} alt={"shadollar"}/></StyleGamesInvestButton>
                                    :""
                                }
                           </StyleInvestBottom>
                       </StyleGamesInvestItem>
                       : "" }
                   {investments.location_2 ?
                       <StyleGamesInvestItem>
                            <StyleInvestTop>
                                <img src={require("../../../assets/images/invest/locations/"+investments.location_2+".png")} alt={investments.location_2} />
                                <StyleInvestTopSharl>
                                    <img src={require("../../../assets/images/games/sharls/"+investments.sharl_2+".png")} alt={investments.sharl_2} />
                                </StyleInvestTopSharl>
                                <StyleInvestTopName>
                                    <img src={require("../../../assets/images/invest/names/"+investments.name_2+".png")} alt={investments.name_2} />
                                </StyleInvestTopName>
                            </StyleInvestTop>
                           {investments.completed_2===false && prize2.length===0 ?
                           <StyleInvestMid>
                             <p>{investments.message_2}</p>
                           </StyleInvestMid>
                               :""}
                           {investments.completed_2===false && prize2.length!==0 ?
                                <StyleInvestMidBlank>
                                    {prize2.success===true ?
                                        <>
                                            <p>Investment Complete!</p>
                                            <h1>The {investments.name_2} {investments.location_2} was a great
                                                success!</h1>
                                            <h1>{investments.sharl_2} gives you {prize2.prize}<img src={require("../../../assets/images/misc/shadollar.png")} alt={"shadollar"}/> in return</h1>
                                        </>
                                        :
                                        <>
                                            <p>Investment Complete!</p>
                                            <h1>The {investments.name_2} {investments.location_2} was unsuccessful..</h1>
                                            <h1>{investments.sharl_2} is a bit sad</h1>
                                        </>
                                    }
                                 </StyleInvestMidBlank> :""}
                           {investments.completed_2===true ?
                                <StyleInvestMidBlank>
                                   <p>Investment Complete!</p>
                                   <h1>The Shammunity thanks you for supporting the {investments.name_2} {investments.location_2} project!</h1>
                                 </StyleInvestMidBlank>
                          : ""}
                           <StyleInvestBottom>
                                {investments.completed_2===false && prize2.length===0 ?
                                    <StyleGamesInvestButton onClick={()=>selectInvestmentHandler(2)}>{investments.cost_2}<img src={require("../../../assets/images/misc/shadollar.png")} alt={"shadollar"}/></StyleGamesInvestButton>
                                    :""
                                }
                           </StyleInvestBottom>
                       </StyleGamesInvestItem>
                       : "" }

                   {investments.location_3 ?
                       <StyleGamesInvestItem>
                            <StyleInvestTop>
                                <img src={require("../../../assets/images/invest/locations/"+investments.location_3+".png")} alt={investments.location_3} />
                                <StyleInvestTopSharl>
                                    <img src={require("../../../assets/images/games/sharls/"+investments.sharl_3+".png")} alt={investments.sharl_3} />
                                </StyleInvestTopSharl>
                                <StyleInvestTopName>
                                    <img src={require("../../../assets/images/invest/names/"+investments.name_3+".png")} alt={investments.name_3} />
                                </StyleInvestTopName>
                            </StyleInvestTop>
                           {investments.completed_3===false && prize3.length===0 ?
                           <StyleInvestMid>
                               <p>{investments.message_3}</p>
                           </StyleInvestMid>
                               :""}
                           {investments.completed_3===false && prize3.length!==0 ?
                                <StyleInvestMidBlank>
                                    {prize3.success===true ?
                                        <>
                                            <p>Investment Complete!</p>
                                            <h1>The {investments.name_3} {investments.location_3} was a great
                                                success!</h1>
                                            <h1>{investments.sharl_3} gives you {prize3.prize}<img src={require("../../../assets/images/misc/shadollar.png")} alt={"shadollar"}/> in return!</h1>
                                        </>
                                        :
                                        <>
                                            <p>Investment Complete!</p>
                                            <h1>The {investments.name_3} {investments.location_3} was unsuccessful..</h1>
                                            <h1>{investments.sharl_3} is a bit sad</h1>
                                        </>
                                    }
                                 </StyleInvestMidBlank> :""}
                           {investments.completed_3===true ?
                                <StyleInvestMidBlank>
                                   <p>Investment Complete!</p>
                                   <h1>The Shammunity thanks you for supporting the {investments.name_3} {investments.location_3} project!</h1>
                                 </StyleInvestMidBlank>
                          : ""}
                           <StyleInvestBottom>
                                {investments.completed_3===false && prize3.length===0 ?
                                    <StyleGamesInvestButton onClick={()=>selectInvestmentHandler(3)}>{investments.cost_3}<img src={require("../../../assets/images/misc/shadollar.png")} alt={"shadollar"}/></StyleGamesInvestButton>
                                    :""
                                }
                           </StyleInvestBottom>
                       </StyleGamesInvestItem>
                       : "" }
           </StyleGamesInvest>
            : ""}
            <StyleGamesFooter>
                <StyleGamesButtonContainer>
                    <Link to="/games">
                        <StyleGamesButton>Back to Games</StyleGamesButton>
                    </Link>
                </StyleGamesButtonContainer>
            </StyleGamesFooter>
        </StyleGamesContainer>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(SharlInvest);
