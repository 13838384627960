const hungerLevelCalculator = (hungerLevel,daysSinceFeed) => {
       const hunger_levels = [
            {"level": 1, "name": "starving"},
            {"level": 2, "name": "hungry"},
            {"level": 3, "name": "content"},
            {"level": 4, "name": "stuffed"}
            ]
        const currentHungerLevel = hunger_levels.filter(item =>
            item.name.toLowerCase() === hungerLevel
        )

        let updatedHungerLevel = currentHungerLevel[0].level - daysSinceFeed;
        if (updatedHungerLevel < 1) {
            updatedHungerLevel = 1
        }
        const finalHungerLevel = hunger_levels.filter(item =>
            item.level === updatedHungerLevel
        )
        if (finalHungerLevel[0]) {
            return finalHungerLevel[0].name;
        } else {
            return "error";
        }
    }
export default hungerLevelCalculator;