import {getprize} from "../../types";
import {updateBalanceAction} from "../MainApp/updateBalanceAction";


export const claimBNWPrizeAction = (data) => ({
    type: getprize,
    payload: data,
});


const claimBNWPrizeFunction = (props,userBal,highScore) => (dispatch) => {

    const balance = userBal;
    const bnw_high_score = {"bnw_high_score": highScore};
    const prize_data = Object.assign(balance,bnw_high_score);

    const URL = "https://www.sharl.info/backend/api/users/me/";
    const token = props.SignInReducer.token;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'PATCH',
        body: JSON.stringify(prize_data),
        headers,
    };

     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        dispatch(updateBalanceAction(data));
    })
};

export default claimBNWPrizeFunction;;