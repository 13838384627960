import Header from '../Header'
import Footer from '../Footer'
import React from 'react'
import {AppStyleContainer, StyleBody} from "../../../styles/app";
import {connect} from "react-redux";

function Layout(props) {
    const currentPage = props.location.pathname;
    const username = props.UserReducer.user.username;

    if (currentPage==="/reset-password" || currentPage==="/signin" || currentPage==="/signup" || currentPage==="/" ) {
        return (
            <AppStyleContainer>
                <Header />
                <StyleBody>
                    {props.children}
                </StyleBody>
                <Footer />
            </AppStyleContainer>
        )
    } else {
        if (username) {
            return (
                <AppStyleContainer>
                    <Header />
                    <StyleBody>
                        {props.children}
                    </StyleBody>
                    <Footer />
                </AppStyleContainer>
            )
        } else {
            return (
            <AppStyleContainer>
                <Header />
                <StyleBody>
                    <p>Something about you should log in on whatever</p>
                    <p>Have a cute sharl pic here</p>
                </StyleBody>
                <Footer />
            </AppStyleContainer>
            )
        }
    }
}


const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(Layout);
