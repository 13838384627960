import {researchprize} from "../../types";
import selectResearchCatFunction from "./selectResearchAction";

export const setResearchPrizeAction = (data) => ({
    type: researchprize,
    payload: data,
});


const setResearchPrizeFunction = (prize, research_data) => (dispatch) => {
   dispatch(setResearchPrizeAction(prize));
   dispatch(selectResearchCatFunction(research_data))
};

export default setResearchPrizeFunction;;