import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';

import getNarlItemsFunction from "../../../store/actions/Market/getNarlItemsAction";
import selectNarlFunction from "../../../store/actions/Market/selectNarlAction";
import {
    StyleNarlContainer,
    StyleNarlItem,
    StyleNarlItemContainer,
    StyleNarlItemQuantity,
    StyleNarlItemSoldOut
} from "../../../styles/market";


function NarlAndFriendsItems(props) {
    const [narlItems, setNarlItems] = useState([]);

    const selectItemHandler = (e) => {
        let selectedItemId = e.currentTarget.id;
        const selectedItem = narlItems.filter(item =>
        // eslint-disable-next-line
        item.id == selectedItemId);
        if (selectedItem[0].quantity > 0) {
            props.dispatch(selectNarlFunction(selectedItem));
        }
    }

    useEffect(() => {
       const narlList = []
       const narlData = props.MarketReducer.narl_items.narl_shop;

       if (narlData) {
           const shopOwner = props.MarketReducer.narlOrBarl
           if (shopOwner === "narl") {
               narlData[0].item_1['quantity'] = narlData[0].item_1_quantity;
               narlData[0].item_1['narlNumber'] = "n1";
               narlList.push(narlData[0].item_1);
               narlData[0].item_2['quantity'] = narlData[0].item_2_quantity;
               narlData[0].item_2['narlNumber'] = "n2";
               narlList.push(narlData[0].item_2);
               narlData[0].item_3['quantity'] = narlData[0].item_3_quantity;
               narlData[0].item_3['narlNumber'] = "n3";
               narlList.push(narlData[0].item_3);
               narlData[0].item_4['quantity'] = narlData[0].item_4_quantity;
               narlData[0].item_4['narlNumber'] = "n4";
               narlList.push(narlData[0].item_4);
               narlData[0].item_5['quantity'] = narlData[0].item_5_quantity;
               narlData[0].item_5['narlNumber'] = "n5";
               narlList.push(narlData[0].item_5);
           } else if (shopOwner === "barl") {
               narlData[0].barl_item_1['quantity'] = narlData[0].barl_item_1_quantity;
               narlData[0].barl_item_1['narlNumber'] = "b1";
               narlList.push(narlData[0].barl_item_1);
               narlData[0].barl_item_2['quantity'] = narlData[0].barl_item_2_quantity;
               narlData[0].barl_item_2['narlNumber'] = "b2";
               narlList.push(narlData[0].barl_item_2);
               narlData[0].barl_item_3['quantity'] = narlData[0].barl_item_3_quantity;
               narlData[0].barl_item_3['narlNumber'] = "b3";
               narlList.push(narlData[0].barl_item_3);
               narlData[0].barl_item_4['quantity'] = narlData[0].barl_item_4_quantity;
               narlData[0].barl_item_4['narlNumber'] = "b4";
               narlList.push(narlData[0].barl_item_4);
               narlData[0].barl_item_5['quantity'] = narlData[0].barl_item_5_quantity;
               narlData[0].barl_item_5['narlNumber'] = "b5";
               narlList.push(narlData[0].barl_item_5);
           }
           setNarlItems(narlList);

                }
    },[props.MarketReducer])

    useEffect(() => {
        props.dispatch(getNarlItemsFunction(props));
        // eslint-disable-next-line
        },[])


    return (
        <StyleNarlContainer>
        <StyleNarlItemContainer>
        {narlItems.map(item =>

            <StyleNarlItem id={item.id} key={item.id} onClick={(e) => selectItemHandler(e)} >
                        <>
                        <img src={`https://www.sharl.info/${item.image}`} alt={item.name} />
                        <h1>  {item.name} </h1>
                        </>


            {  item.quantity > 0 ?
                <StyleNarlItemQuantity>
                    <p>{item.quantity} </p>
                </StyleNarlItemQuantity>
                :
                <StyleNarlItemSoldOut>
                    <p>Sold Out!</p>
                </StyleNarlItemSoldOut>
            }

            </StyleNarlItem>
)}
            </StyleNarlItemContainer>
      </StyleNarlContainer>
)
}

const mapStateToProps = state => {
    return {
      ...state,
      };
    };

export default connect(mapStateToProps)(NarlAndFriendsItems);
