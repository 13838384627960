import React from 'react';
import SharlSackBody from "./SackBody";

function Sack() {
  return (
        <SharlSackBody/>
  );
}

export default Sack;
