import React from 'react'
import ActiveSquare from './ActiveSquare'
import { ItemTypes } from '../Constants'
import { useDrop } from 'react-dnd'
import {
    StyleActiveTankSquareMain,
    StyleTankOverDrop,
} from "../../../../styles/tank";
import selectTunaCanFunction from "../../../../store/actions/TunaCan/selectTunaCanAction";

function DropCanSquare({ x, y, props, children }) {

  const dropHandler = (props, monitor) => {
        props.dispatch(selectTunaCanFunction(monitor.tunaCan))
    }

  const [{ isOver }, drop] = useDrop({
        accept: ItemTypes.SELECTEDCAN,
        drop: monitor => dropHandler(props, monitor),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
    })

  })

    return (
        <StyleActiveTankSquareMain ref={drop}>
            <ActiveSquare>{children}</ActiveSquare>
                {isOver ?
                    <StyleTankOverDrop />
                : ""}
        </StyleActiveTankSquareMain>
  )
}

export default DropCanSquare;