import React from 'react';
import PasswordResetBody from "./PasswordResetBody";

function PasswordReset() {
  return (
        <PasswordResetBody/>
  );
}

export default PasswordReset;;
