import React, {useEffect, useState} from 'react'
import { ItemTypes } from '../TankBody/DragTank/Constants'
import { useDrop } from 'react-dnd'
import {
    StyleTankActiveDropOverlayNo,
    StyleTankActiveDropOverlayYes,
    StyleTankActiveDropSharl, StyleTankFeedSharl, StyleTankFeedSharlText,
} from "../../../styles/tanktest";
import {StyleResearchAvatarImg, StyleResearchHat} from "../../../styles/research";
import hungerLevelCalculator from "../../Sharls/hungerLevelCalculator";
import {canDropFish} from "../TankBody/ActiveTank/Movement";
import feedSharlFunction from "../../../store/actions/Tank/feedSharlAction";

function DropSharl({props,sharl, children }) {
    const [hungerLevel,setHungerLevel] = useState("")
    const [spinning, setSpinning] = useState(false)


    useEffect(()=>{
        const hunger = hungerLevelCalculator(sharl.hunger_level, sharl.last_fed)
        setHungerLevel(hunger)
    },[sharl.hunger_level,sharl.last_fed])


    const dropHandler = (props,monitor) => {
      setSpinning(true);
      //also need to know what the fish is and to add it in front of sharl for a second

      const fish_id = monitor.itemId;
      let level = monitor.level;
      const fedSharls = []
      if (level > 3) {
          const allSharls = props.UserReducer.sharls.filter(sharl=>
              sharl.is_active===true)
          for (let i=0; i<allSharls.length;i++){
              const sharlHunger = hungerLevelCalculator(sharl.hunger_level, sharl.last_fed)
              fedSharls.push({"id": allSharls[i].id, "hunger_level":sharlHunger})
          }
          level = level - 2
      } else {
           fedSharls.push({"id": sharl.id, "hunger_level":hungerLevel})
      }
      const shaData = {"fish_id": fish_id, "levels": level, "sharls": fedSharls}
        props.dispatch(feedSharlFunction(props,shaData))
      }

  const [{ isOver }, drop] = useDrop({
        accept: ItemTypes.SELECTEDFISH,
        drop: monitor => dropHandler(props, monitor),
        canDrop: (monitor) => canDropFish(props, hungerLevel),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            item: monitor.getItem(),
            canDrop: !!monitor.canDrop()
        })

  })
    return (
        <StyleTankActiveDropSharl
            ref={drop}
            style={{
              cursor: 'cell',
          }}>
                <StyleTankFeedSharl
                    width={"60%"}
                    spinning={spinning}
                    onAnimationEnd={() => setSpinning(false)}
                >
                    <StyleResearchAvatarImg>
                       <img src={sharl.sharl_avatar} alt={sharl.sharl_avatar} />
                   </StyleResearchAvatarImg>
                   { sharl.headgear ?
                       <StyleResearchHat>
                           { sharl.headgear.image.includes("https") ?
                               <img src={sharl.headgear.image} alt={sharl.headgear.name} />
                               :
                               <img src={"https://www.sharl.info/" + sharl.headgear.image} alt={sharl.headgear.name} />
                           }
                       </StyleResearchHat>
                       : ""
                   }
               </StyleTankFeedSharl>
            <StyleTankFeedSharlText width={"40%"}>
                <p>{sharl.name}</p>
                <h1>{hungerLevel}</h1>
            </StyleTankFeedSharlText>
            {isOver && hungerLevel!=="stuffed" ?
                <StyleTankActiveDropOverlayYes>

                 </StyleTankActiveDropOverlayYes>
                : ""}
            {isOver && hungerLevel==="stuffed"  ?
                <StyleTankActiveDropOverlayNo>

                 </StyleTankActiveDropOverlayNo>
                : ""}
        </StyleTankActiveDropSharl>
  )
}

export default DropSharl;