import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    StyleShopCheckoutBackButton,
    StyleShopCheckoutContainer,
    StyleShopCheckoutItem, StyleShopCheckoutItemBodyContainer, StyleShopCheckoutItemFooter,
    StyleShopCheckoutItemHeader,
    StyleShopCheckoutPayment
} from "../../styles/shop";
import {PayPalButtons} from "@paypal/react-paypal-js";
import ShopCheckoutItem from "./ShopCheckoutItem";
import updateShopStageFunction from "../../store/actions/Shop/updateShopStageAction";
import purchaseItemsFunction from "../../store/actions/Shop/purchaseItemsAction";

function ShopCheckout(props) {
    const [cart, setCart] = useState([])
    const [cartTotal,setCartTotal] = useState(0)
    const [errorMessage, setErrorMessage] = useState("");
    const [orderStatus, setOrderStatus] = useState("open");


    useEffect(()=>{
        const newCart = props.ShopReducer.cart
        if (newCart.length > 0){
            let totalCost = 0;
            setCart(newCart)
            for (let i=0; i <newCart.length; i++){
                const cost = parseInt(newCart[i].quantity)*parseInt(newCart[i].item.cash_shop_price)
                totalCost = totalCost + cost
            }
            setCartTotal(totalCost)
        }
    },[props.ShopReducer])

     const createOrder = (data, actions) => {
         const finalCart = props.ShopReducer.cart
         let orderTotal = 0
         for (let i=0; i <finalCart.length; i++){
             const cost = parseInt(finalCart[i].quantity)*parseInt(finalCart[i].item.cash_shop_price)
             orderTotal = orderTotal + cost
            }
         //while doing the total, also need to make a list of the items
         //to pass through paypal so there are line items for each purchase

        return actions.order
          .create({
            purchase_units: [
              {
                amount: {
                    currency: 'USD',
                    value: orderTotal,
                },
              },
            ],
            // remove the application object if you need your users to add a shipping address
            application_context: {
              shipping_preference: "NO_SHIPPING",
            },
          })
          .then((orderID) => {
              return orderID;
          });
      };

      const errorHandler = (err) => {
          setErrorMessage("Something went wrong.");
          setOrderStatus("error")
      }
      const cartHandler = () => {
           props.dispatch(updateShopStageFunction("cart"))
      }

      const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const {payer} = details;
            const payerID = payer["payer_id"]
            const finalCart = props.ShopReducer.cart
            const cartData = []
            let orderTotal = 0
             for (let i=0; i <finalCart.length; i++){
                const cost = parseInt(finalCart[i].quantity)*parseInt(finalCart[i].item.cash_shop_price)
                orderTotal = orderTotal + cost
                let newArray = {"id": finalCart[i].item.id, "name":finalCart[i].item.name, "quantity":finalCart[i].quantity, "price":finalCart[i].item.cash_shop_price}
                 cartData.push(newArray)
             }
            const userID = props.UserReducer.user.id;
            const purchaseData = {"cart": cartData, "invoice_total_cost": orderTotal, "invoice_payer_id": payerID, "paypal_order_id":details.id};
            props.dispatch(purchaseItemsFunction(props,userID,purchaseData));
        }).catch(err=> errorHandler(err));
      };

    return (
        <StyleShopCheckoutContainer>
            <StyleShopCheckoutItem>
                <StyleShopCheckoutItemHeader>
                    <p>Item</p>
                    <p>Quantity</p>
                    <p>Total Price</p>
                </StyleShopCheckoutItemHeader>
                <StyleShopCheckoutItemBodyContainer>
                    {cart.map(item =>
                        <ShopCheckoutItem props={props} item={item} key={item.code} />
                    )}
                </StyleShopCheckoutItemBodyContainer>
                <StyleShopCheckoutItemFooter>
                <StyleShopCheckoutBackButton onClick={()=>cartHandler()}>Back to Cart</StyleShopCheckoutBackButton>
                <p>Total: ${cartTotal}.00 (USD)</p>
                </StyleShopCheckoutItemFooter>
            </StyleShopCheckoutItem>
            <StyleShopCheckoutPayment>
                {orderStatus === "open" ?
                    <PayPalButtons
                        createOrder={createOrder}
                        onApprove={onApprove}
                        style={{size: "responsive", color: "blue", shape: "pill", label: "pay", tagline: false}}/>
                    :
                        <div>
                            <p>{errorMessage}</p>
                        </div>
                }
                <>
                    <p>*Do not refresh/leave page while payment is processing</p>
                </>
            </StyleShopCheckoutPayment>
        </StyleShopCheckoutContainer>
    );
}


const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(ShopCheckout);
