import { updatemarket} from "../../types";

export const narlOrBarlAction = (shop) => (
    {
    type: updatemarket,
    payload: shop
});


const narlOrBarlFunction = (shop) => (dispatch) => {
    dispatch(narlOrBarlAction(shop))
}

export default narlOrBarlFunction;