import React from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {StyleBodyItem} from "../../../styles/app";
import {StyleBodyButton, StyleBodyEmail, StyleBodyEmailItem, StyleBodySignInItem} from "../../../styles/signup";

function PasswordFinal(props) {

  return (
      <>
       <StyleBodyItem>
       <img src={require("../../../assets/images/misc/sharl_swim.png")} alt={"sharl"} />
        </StyleBodyItem>
      <StyleBodyItem>
        <StyleBodyEmail>
        <StyleBodyEmailItem>
            <h2>Complete!</h2>
        </StyleBodyEmailItem>
        <StyleBodyEmailItem>
            <img src={require("../../../assets/images/misc/hawaiiansharl-front.png")} alt="confirm"/>
        </StyleBodyEmailItem>
        <StyleBodySignInItem>
            <StyleBodyButton><Link to="/signin">SIGN IN</Link></StyleBodyButton>
        </StyleBodySignInItem>
         <StyleBodyEmailItem>
        </StyleBodyEmailItem>
      </StyleBodyEmail>
    </StyleBodyItem>
    </>
);
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(PasswordFinal);
