import {getmuseum} from "../../types";

export const getMuseumResearchAction = (data) => ({
    type: getmuseum,
    payload: data,
});

const URL = `https://www.sharl.info/backend/api/facts/museum/`;


const getMuseumResearchFunction = (props) => (dispatch) => {
    const token = props.SignInReducer.token
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'GET',
        headers
    };
 
     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        const sharlFacts = data.sharl_facts;
        dispatch(getMuseumResearchAction(sharlFacts));
       })
};
export default getMuseumResearchFunction;