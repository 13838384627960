import React from "react";
import {FishRatingContainer} from "../../styles/adopt";

import fish from "../../assets/images/misc/fish_rating.png";

const renderRating = value => {
    switch (value) {
        case 0: {
            return (
            <FishRatingContainer>
                <img src={fish} alt="fish"/>
            </FishRatingContainer>
        )}
        case 1: {
            return (
            <FishRatingContainer>
            <img src={fish} alt="fish"/>
            </FishRatingContainer>
        )}
        case 2: {
            return (
            <FishRatingContainer>
            <img src={fish} alt="fish"/>
            <img src={fish} alt="fish"/>
            </FishRatingContainer>
        )}
        case 3: {
            return (
            <FishRatingContainer>
            <img src={fish} alt="fish"/>
            <img src={fish} alt="fish"/>
            <img src={fish} alt="fish"/>
            </FishRatingContainer>
        )}
        case 4: {
            return (
            <FishRatingContainer>
            <img src={fish} alt="fish" />
            <img src={fish} alt="fish"/>
            <img src={fish} alt="fish"/>
            <img src={fish} alt="fish"/>
            </FishRatingContainer>
        )}
        case 5: {
            return (
            <FishRatingContainer>
            <img src={fish} alt="fish"/>
            <img src={fish} alt="fish"/>
            <img src={fish} alt="fish"/>
            <img src={fish} alt="fish"/>
            <img src={fish} alt="fish"/>
            </FishRatingContainer>
        )}
        default: {
            return (
            <FishRatingContainer>
            </FishRatingContainer>
        )}

     }
}
export default renderRating;