import {tunaprize} from "../../types";
import generateTunaCanPrize from "../../../containers/TunaCan/generateTunaCanPrize";
import {getUserItemsAction} from "../MainApp/getUserItemsAction";


export const getTunaCanPrizeAction = (data) => ({
    type: tunaprize,
    payload: data,
});

const getTunaCanPrizeFunction = (props, selectedTuna) => (dispatch) => {
    const prize = generateTunaCanPrize(selectedTuna.name);
    const tuna_can_id = {"tuna_can_id": selectedTuna.id};
    const tuna_can_quantity = {"tuna_can_quantity": selectedTuna.quantity};
    const prize_item = {"prize_id": prize[0].item};
    const prize_quantity = {"prize_quantity": prize[0].quantity}
    const prize_data = Object.assign(tuna_can_id, tuna_can_quantity, prize_item, prize_quantity);

    const URL = "https://www.sharl.info/backend/api/users/tuna/";
    const token = props.SignInReducer.token;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'PATCH',
        body: JSON.stringify(prize_data),
        headers,
    };

     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        const userItems = data.items;

        const removeProperties = (object, ...keys) => Object.entries(object).reduce((prev, [key, value]) => ({...prev, ...(!keys.includes(key) && { [key]: value }) }), {})
        const allData = removeProperties(data, 'items', 'sharls')
        const concatData = {"user":allData, "sharls":data.sharls, "items":data.items }
        dispatch(getUserItemsAction(concatData));
        const prizeData = userItems.filter(item =>
        item.item_code === prize[0].item);
        prizeData["prize_quantity"] = prize[0].quantity
        dispatch(getTunaCanPrizeAction(prizeData[0]));
    })

};

export default getTunaCanPrizeFunction;;