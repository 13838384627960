import styled from "styled-components";


export const StyleMarketContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    `;


export const StyleMarketBody = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    background: linear-gradient(to top, rgba(150, 255, 255, 0.7) 0%, rgba(51, 102, 153, 0.6) 100%), url('../images/backgrounds/seabg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    img {
    height: 100%;
    width: 100%;
    }
    `;


export const StyleMarketSides = styled.div`
    height: 100%;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        height: 80%;
        width: 90%;
    }`

export const StyleMarketCenter = styled.div`
    height: 100%;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    `




export const StyleMarketOverlay = styled.div`
    height: 75%;
    width: 98%;
    z-index: 2;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-around;
    
    `;


export const StyleMarketOverlayItem = styled.div`
    height: 100%;
    width: 38%;
    
   a:-webkit-any-link {
    text-decoration: none;
    margin-left: 0%;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    }
     `;

export const StyleBazarContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
   
    `;

export const StyleBazarBody = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    `;

export const StyleBazarBodyLeft = styled.div`
    height: 100%;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
    height: 80%;
    width: auto;
    }
    `;


export const StyleBazarBodyRightTop = styled.div`
    height: 60%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    `;


export const StyleBazarBodyRightBottom = styled.div`
    height: 30%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
   background-color: white;
    border: 1px solid black;
    border-radius: 24px;
    `;


export const StyleBazarBodyRightItem = styled.div`
    height: 60%;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;    
    padding: 2%;
    `;


export const StyleBazarBodyRight = styled.div`
    height: 100%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    `;


export const StyleBuyNarlButton = styled.button`
    height: 30%;
    width: 100%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 2vh;
    border: none;
    margin: 2%;
 `;

export const StyleNarlContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

        `;


export const StyleNarlItemContainer = styled.div`
    height: 100%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;   

`;


export const StyleNarlItem = styled.div`
    border-radius: 24px;
    position: relative;
    height: 45%;
    width: 17.8%;
    margin: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    img {
    height: 100%;
    width: auto%;
    }
       h1 {
        font-size: 0;
    }   
    :hover {
    h1 {
        font-size: 1.4vw;
        color: white;
        width: 100%;
        text-align: center;
        position: absolute;
        background-color: rgba(51,102,153,0.9);
        z-index: 10;
        font-weight: bold;   
    }    
    }   
    `;


export const StyleNarlItemQuantity = styled.div`
    position: absolute;
    right: 2%;
    bottom: 5%;
    height: 20%;
    width: 30%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
     p {
     font-weight: bold;
     font-size: 1.2vw;
     }
    `;

export const StyleNarlItemSoldOut = styled.div`
    position: absolute;
    right: 35%;
    bottom: 5%;
    height: 20%;
    width: 30%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
     p {
     font-weight: bold;
     font-size: 1.2vw;
     color: red;
     }
    `;





export const StyleNarlConfirm = styled.div`
    height: 33%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
    height: 100%;
    width: auto;
    padding-left: 5%; 
    }
    p {
    font-size: 1.8vw;
    font-weight: bold;
    }
    h1 {
    font-size: 1.8vw;
    font-weight: bold;
    color: red;
    }
`;


export const StyleSharlSelectedItemTotal = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    
    h2 {
    font-size: 1vw;
    font-weight: bold;
    color: red;
    line-height: 2;
    }
    
   
`;


export const StyleSharlSelectedItemInfo = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
    height: 100%;
    width: auto;
     }
     p {
     font-size: 1vw;
     }
    
`;


export const StyleSharlSelectedItemSlider = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10%;
    flex-direction: column;
     p {
     font-size: 1.8vw;
     font-weight: bold;
     line-height: 2;
     }
    
`;


export const StyleNarlQuantityInput = styled.input`
     width: 60%;
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
     background: transparent; /* Otherwise white in Chrome */
    ::-webkit-slider-thumb {
        -webkit-appearance: none;
        }
    :focus {
        outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
        }
    ::-ms-track {
        width: 100%;
        cursor: pointer;

         /* Hides the slider so custom styles can be added */
        background: transparent; 
        border-color: transparent;
        color: transparent;
    }
    ::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: 1px solid #000000;
        height: 36px;
        width: 16px;
        border-radius: 3px;
        background: #ffffff;
        cursor: pointer;
        margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
    }
    ::-moz-range-thumb {
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        border: 1px solid #000000;
        height: 36px;
        width: 16px;
        border-radius: 3px;
        background: #ffffff;
        cursor: pointer;
    }

    ::-ms-thumb {
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
    }
   ::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #336699;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

:focus::-webkit-slider-runnable-track {
  background: #336699;
}

::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #336699;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
::-ms-fill-lower {
  background: #336699;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
:focus::-ms-fill-lower {
  background: #336699;
}
::-ms-fill-upper {
  background: #336699;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
:focus::-ms-fill-upper {
  background: #336699;
} 
   
  `;