import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    StyleShopBodyContent,
    StyleShopCartBody,
    StyleShopCartBodyFooter, StyleShopCartBodyFooterButtons,
    StyleShopCartBodyFooterTotal,
    StyleShopCartBodyHeader,
    StyleShopCartBodyItems, StyleShopCheckoutButton,
} from "../../styles/shop";
import ShopCartItem from "./ShopCartItem";
import updateShopStageFunction from "../../store/actions/Shop/updateShopStageAction";
import addToCartFunction from "../../store/actions/Shop/addToCartAction";

function ShopCart(props) {
    const [cart, setCart] = useState([])
    const [cartTotal,setCartTotal] = useState(0)

    useEffect(()=>{
        const newCart = props.ShopReducer.cart
        if (newCart.length > 0){
            setCart(newCart)
            let totalCost = 0;
            for (let i=0; i <newCart.length; i++){
                const cost = parseInt(newCart[i].quantity)*parseInt(newCart[i].item.cash_shop_price)
                totalCost = totalCost + cost
            }
            setCartTotal(totalCost)
        } else {
            setCart([])
            setCartTotal(0)
        }
    },[props.ShopReducer])

    const checkOutHandler = () => {
        props.dispatch(updateShopStageFunction("checkout"))
    }

    const clearCartHandler = () => {
        props.dispatch(addToCartFunction([]))
    }

    return (
        <StyleShopBodyContent>
            {cart.length === 0 ?
                <p>Oh no.. its empty!</p>
                :
                <StyleShopCartBody>
                    <StyleShopCartBodyHeader>
                        <p>Shopping Cart</p>
                    </StyleShopCartBodyHeader>
                    <StyleShopCartBodyItems>
                        {cart.map(item =>
                            <ShopCartItem props={props} item={item} key={item.code} />
                        )}
                    </StyleShopCartBodyItems>
                    <StyleShopCartBodyFooter>
                        <StyleShopCartBodyFooterTotal>
                            <p>Total: <b>${cartTotal}.00</b></p>
                        </StyleShopCartBodyFooterTotal>
                        <StyleShopCartBodyFooterButtons>
                            <StyleShopCheckoutButton onClick={()=>clearCartHandler()}>Clear Cart</StyleShopCheckoutButton>
                            <StyleShopCheckoutButton onClick={()=>checkOutHandler()}>Checkout</StyleShopCheckoutButton>
                        </StyleShopCartBodyFooterButtons>
                    </StyleShopCartBodyFooter>
                </StyleShopCartBody>
            }
        </StyleShopBodyContent>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(ShopCart);
