import calculateBalanceFunction from "../../../containers/MainApp/updateBalance";
import {updateBalanceAction} from "../MainApp/updateBalanceAction";


const upgradeBagFunction = (props,new_bag_level) => (dispatch) => {
    let bag_data = "";

    if (new_bag_level===2){
        const balance = calculateBalanceFunction(props,"-", 20000);
        const max_items = {"max_items": 30};
        const bag_level = {"bag_level": 2};
        bag_data = Object.assign(balance,max_items,bag_level);
    } else {
        const balance = calculateBalanceFunction(props,"-", 50000);
        const max_items = {"max_items": 45};
        const bag_level = {"bag_level": 3};
        bag_data = Object.assign(balance,max_items,bag_level);
    }

    const URL = "https://www.sharl.info/backend/api/users/me/";
    const token = props.SignInReducer.token;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'PATCH',
        body: JSON.stringify(bag_data),
        headers,
    };

     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        dispatch(updateBalanceAction(data));
    })

};

export default upgradeBagFunction;;