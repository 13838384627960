import React, {useEffect} from 'react';
import { connect } from 'react-redux'
import {
    StyleGamesBody,
    StyleGamesButton,
    StyleGamesButtonContainer,
    StyleGamesContainer,
    StyleGamesFooter
} from "../../../styles/games";
import {Link} from "react-router-dom";
import SharlPrizeBody from "./SharlPrizeBody";
import resetPrizeFunction from "../../../store/actions/Games/resetPrizeAction";

function SharlPrize(props) {

    useEffect(() => {
        if (!props.UserReducer.user.username) {
            props.dispatch(resetPrizeFunction());
        }
        // eslint-disable-next-line
        },[]);

    return (
        <StyleGamesContainer>
        <StyleGamesBody>
        <SharlPrizeBody />
           </StyleGamesBody>
            <StyleGamesFooter>
                <StyleGamesButtonContainer>
                    <Link to="/games">
                        <StyleGamesButton>Back to Games</StyleGamesButton>
                    </Link>
                </StyleGamesButtonContainer>
            </StyleGamesFooter>
        </StyleGamesContainer>
)
}

const mapStateToProps = state => {
    return {
      ...state,
      };
    };

export default connect(mapStateToProps)(SharlPrize);
