import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";
import {
    StyleMarketBody, StyleMarketCenter,
    StyleMarketContainer,
     StyleMarketSides
} from "../../styles/market";
import clearSelectedItemFunction from "../../store/actions/Market/clearSelectedItemAction";
import NarlAndFriends from "./NarlAndFriends";
import narlOrBarlFunction from "../../store/actions/Market/narlOrBarlAction";
import clearNarlOrBarlFunction from "../../store/actions/Market/clearNarlOrBarlAction";

function MarketBody(props) {
    const [stage, setStage] = useState("market")


    useEffect(() => {
        props.dispatch(clearSelectedItemFunction());
        props.dispatch(clearNarlOrBarlFunction());
        // eslint-disable-next-line
        },[]);


    useEffect(() => {
        setStage(props.MarketReducer.narlOrBarl)
        // eslint-disable-next-line
        },[props.MarketReducer]);

    const shopHandler = shop => {
        props.dispatch(narlOrBarlFunction(shop))
    }

    return (
        <StyleMarketContainer>
            <StyleMarketBody>
            <StyleMarketSides>
                <img src={require("../../assets/images/market/narl_and_friends.png")} alt={"Narl"} onClick={()=>shopHandler("narl")}/>
            </StyleMarketSides>
            <StyleMarketCenter>
                {stage === "market" ?
                    <>
                    <p>Welcome to the Market</p>
                    <p>Click on Narl or Barl to see their wares</p>
                    </> :
                    <NarlAndFriends/>
                }
            </StyleMarketCenter>
            <StyleMarketSides>
                <img src={require("../../assets/images/market/barl_shop.png")} alt={"Barl"} onClick={()=>shopHandler("barl")} />
            </StyleMarketSides>
            </StyleMarketBody>
        </StyleMarketContainer>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(MarketBody);
