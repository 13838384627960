import {passworderror, passwordtoken} from "../../types";

export const verifyPasswordAction = () => ({
    type: passwordtoken,
});

export const verifyPasswordError = (response) => ({
    type: passworderror,
    payload: response,
})

const URL = `https://www.sharl.info/backend/api/auth/password-reset/validation/`;
//const URL = `http://0.0.0.0:8000/backend/api/auth/password-reset/validation/`;

export const verifyPasswordFunction = (data,props) => (dispatch) => {
    const config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {'Content-Type': 'application/json'}
    };

 fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        const response = data;
        if (response === "Success! Password was reset") {
            dispatch(verifyPasswordAction());
        } else {
            dispatch(verifyPasswordError(response));
        }
    })
    }
