import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";
import {
    StyleResearchButton,
    StyleResearchRight,
    StyleResearchRightBottom,
    StyleResearchRightTop,
    StyleResearchTotalHeader,
    StyleResearchTotalBody,
    StyleResearchTotalCost,
    StyleResearchTotalDuration,
} from "../../../styles/research";
import startResearchFunction from "../../../store/actions/Research/startResearchAction";


function ResearchStart(props) {
    const [researchSharl, setResearchSharl] = useState("");
    const [researchCat, setResearchCat] = useState("");
    const [showStartButton, setShowStartButton] = useState(false);

    useEffect(() => {
        if (props.ResearchReducer.selectedResearch) {
            setResearchCat(props.ResearchReducer.selectedResearch.category);
        }
        setResearchSharl(props.ResearchReducer.selectedSharl);
        // eslint-disable-next-line
        },[props.ResearchReducer]);


    useEffect(() => {
        let canStart = true;
        const user = props.UserReducer.user
  //      const prizeSharl = props.UserReducer.user.prize_top_sharl
   //     const sharlGenus = props.ResearchReducer.selectedSharl.genus

        //in here you could add bonus for having sharls sharl

        let researchCost = 0;
        if (researchSharl.bonus_type==="cost") {
            researchCost = researchCat.cost / researchSharl.bonus_value;
        } else {
            researchCost = researchCat.cost
        }
        if (user.balance < researchCost) {
            canStart = false;
        }
        const sharl = props.ResearchReducer.selectedSharl
        if (!sharl.name) {
            canStart = false;
        }
        const research = props.ResearchReducer.selectedResearch
        if (sharl && research) {
            setShowStartButton(canStart);
        }
            // eslint-disable-next-line
          },[props.ResearchReducer]);


    const costColorHandler = () => {
        const user = props.UserReducer.user
        let researchCost = 0
        if (researchSharl.bonus_type==="cost") {
            researchCost = researchCat.cost / researchSharl.bonus_value;
        } else {
            researchCost = researchCat.cost
        }
        if ( user.balance >= researchCost ) {
                return "black"
        } else {
            return "red"
        }
    }

    const startResearchHandler = () => {
        const sharlId = {"sharl_id": researchSharl.id};
        const researchId = {"research_code": researchCat.researchCode};

        let researchCost = researchCat.cost;
        if (researchSharl.bonus_type==="cost") {
            researchCost = researchCat.cost / researchSharl.bonus_value;
        }
        const balance = {"balance": props.UserReducer.user.balance - researchCost};

        let researchDuration = researchCat.duration * 60;
        if (researchSharl.bonus_type==="duration") {
            researchDuration = parseInt(researchCat.duration * 60 / researchSharl.bonus_value);
        }

        const startTime = new Date(Date.now());
        const endTime = new Date(startTime);
        endTime.setMinutes(endTime.getMinutes() + researchDuration);
        const researchEnd = new Date(endTime).toISOString();
        const researchEndTime = {"research_end_time": researchEnd};

        const research_data = Object.assign(sharlId, researchId, balance, researchEndTime);
        props.dispatch(startResearchFunction(props, researchSharl.id, research_data));
        //in here need to update the user reducer so that the selected sharl is changed from selected to in progress
    }

    return (
        <StyleResearchRight>
            <StyleResearchRightTop>
                <StyleResearchTotalHeader>
                    {researchCat.name && researchSharl.name && researchSharl.is_researching === false ?
                        <>
                            <p>Research {researchCat.name} with {researchSharl.name}</p>
                            {researchSharl.bonus_type !== "blank" ? <h1>bonus: reduce {researchSharl.bonus_type}</h1> : ""}
                        </>
                        : ""
                    }
                </StyleResearchTotalHeader>
                {researchCat.name && researchSharl.name && researchSharl.is_researching === false ?
                    <StyleResearchTotalBody>
                        <StyleResearchTotalCost pColor={costColorHandler()}>
                            {researchSharl.bonus_type === "cost" ?
                                <p>{researchCat.cost / researchSharl.bonus_value} <img src={require("../../../assets/images/misc/shadollar.png")} alt="gold" /> </p>
                                :
                                <p>{researchCat.cost} <img src={require("../../../assets/images/misc/shadollar.png")} alt="gold" /> </p>
                                   }
                            </StyleResearchTotalCost>
                            <StyleResearchTotalDuration>
                                {researchSharl.bonus_type === "duration" ?
                                    <p>Duration {researchCat.duration / researchSharl.bonus_value} {researchCat.duration / researchSharl.bonus_value === "1" ? "hour" : "hours"}</p>
                                    :
                                    <p>Duration {researchCat.duration} {researchCat.duration === 1 ? "hour" : "hours"}</p>
                                }
                            </StyleResearchTotalDuration>
                        </StyleResearchTotalBody>
                        : ""}
                        </StyleResearchRightTop>
                      <StyleResearchRightBottom>
                          {showStartButton ?
                            <StyleResearchButton onClick={() => startResearchHandler()}>Start Research</StyleResearchButton>
                            : ""
                              }
                      </StyleResearchRightBottom>
                    </StyleResearchRight>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(ResearchStart);
