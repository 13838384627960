import {getnarl} from "../../types";

export const getNarlItemsAction = (data) => ({
    type: getnarl,
    payload: data,
});


const URL = `https://www.sharl.info/backend/api/narl/`;

const getNarlItemsFunction = (props) => (dispatch) => {
    const token = props.SignInReducer.token
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'GET',
        headers
    };
 
     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        dispatch(getNarlItemsAction(data));
    })
};
export default getNarlItemsFunction;