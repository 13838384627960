const calculateResults = (results) => {
    const quizResponses = results;

    let roundSharl = 0;
    let cheekySharl = 0;
    let sleepySharl = 0;
    let pancakeSharl = 0;

    const q1 = quizResponses[0].answer
    const q2 = quizResponses[1].answer
    const q3 = quizResponses[2].answer
    const q4 = quizResponses[3].answer
    const q5 = quizResponses[4].answer

    switch (q1) {
        case "a1":
            roundSharl++;
            break;
        case "a2":
            pancakeSharl++;
            break;
        case "a3":
            cheekySharl++;
            break;
        case "a4":
            sleepySharl++;
            break;
        default:
            sleepySharl++;
            break;
    }
    switch (q2) {
        case "a1":
            pancakeSharl++;
            break;
        case "a2":
            roundSharl++;
            break;
        case "a3":
            cheekySharl++;
            break;
        case "a4":
            sleepySharl++;
            break;
        default:
            sleepySharl++;
            break;
    }
    switch (q3) {
        case "a1":
            pancakeSharl++;
            break;
        case "a2":
            sleepySharl++;
            break;
        case "a3":
            cheekySharl++;
            break;
        case "a4":
            roundSharl++;
            break;
        default:
            sleepySharl++;
            break;
    }
    switch (q4) {
        case "a1":
            sleepySharl++;
            break;
        case "a2":
            cheekySharl++;
            break;
        case "a3":
            pancakeSharl++;
            break;
        case "a4":
            roundSharl++;
            break;
        default:
            sleepySharl++;
            break;
    }
    switch (q5) {
        case "a1":
            cheekySharl++;
            break;
        case "a2":
            roundSharl++;
            break;
        case "a3":
            sleepySharl++;
            break;
        case "a4":
            pancakeSharl++;
            break;
        default:
            sleepySharl++;
            break;
    }
    let finalSharl = "Round Sharl";
    if (roundSharl < cheekySharl) {
        finalSharl = "Cheeky Sharl";
    }
    if (cheekySharl < pancakeSharl) {
        finalSharl = "Pancake Sharl";
    }
    if (pancakeSharl < sleepySharl) {
        finalSharl = "Sleepy Sharl";
    }

    return finalSharl
}

export default calculateResults;