import {signout} from "../../types";

export const signOutAction = token => (
    {
    type: signout,
});


const signOutFunction = () => (dispatch) => {
    dispatch(signOutAction());
    localStorage.removeItem('token');
}

export default signOutFunction 