import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";

import { DndProvider } from 'react-dnd'
import DropSharl from "./DropSharl";
import {HTML5Backend} from "react-dnd-html5-backend";
import {StyleTankActiveDropContainer, StyleTankActiveDropItemContainer} from "../../../styles/tanktest";


function DropActiveContainer(props) {
    const [squares,setSquares] = useState([])

    function sortSharls( a, b ) {
        if ( a.id < b.id ){
            return -1;
        }
        if ( a.id > b.id ){
            return 1;
        }
        return 0;
    }

   useEffect(() => {
       //get list of sharls and then make the squares for each sharl
       const allSharls = props.UserReducer.sharls
       if (allSharls) {
           const activeSharls = allSharls.filter(sharl =>
               sharl.is_active === true)
           activeSharls.sort(sortSharls);
           const newSquares = [];
           for (let i = 0; i < activeSharls.length; i++) {
               newSquares.push(renderSquare(props, activeSharls[i], i))
           }
           setSquares(newSquares)
       }
       // eslint-disable-next-line
        },[props.UserReducer]);


    function renderSquare(props, sharl, i) {
            return (
                <StyleTankActiveDropItemContainer height={"85%"} key={sharl.id} id={sharl.id} bgColor={sharl.color}>
                    <DropSharl props={props} sharl={sharl}/>
                </StyleTankActiveDropItemContainer>
            )}

  return (
        <DndProvider backend={HTML5Backend}>
            <StyleTankActiveDropContainer bRadius={"24px 24px 0px 0px"} borderBottom={"1px solid black"}>
                {squares}
            </StyleTankActiveDropContainer>
        </DndProvider>
  )
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(DropActiveContainer);