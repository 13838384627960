import {helppolicy} from "../../types";

export const updateHelpPolicyAction = (data) => ({
    type: helppolicy,
    payload: data,
});


const updateHelpPolicyFunction = (data) => (dispatch) => {
    dispatch(updateHelpPolicyAction(data));
};

export default updateHelpPolicyFunction;