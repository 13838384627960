import {buynarl, clearnarl, getnarl, selectnarl, updatemarket} from "../types";

const initialState = {
    narl_items: [],
    selected_narl_item: "",
    narlOrBarl: "market",
    };

const MarketReducer = (state = initialState, action) => {
  switch (action.type) {
        case updatemarket:
            return {
            ...state,
            narlOrBarl: action.payload,
            }
        case getnarl:
            return {
            ...state,
            narl_items: action.payload,
            }
        case selectnarl:
           return {
           ...state,
           selected_narl_item: action.payload,
            }
        case clearnarl:
           return {
           ...state,
           selected_narl_item: "",
            }
        case buynarl:
            return {
            ...state,
            narl_items: action.payload,
            }
        default:
            return state;
  }
}

export default MarketReducer;