import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import MainApp from './containers/MainApp';

import store from './store/index.js';

import {signInAction} from "./store/actions/Auth/signInAction";

import HOCWrapper from './HOC/HOCWrapper';
import HOCSignIn from "./HOC/HOCSignIn";

//import SignUp from "./containers/SignUp";
import SignIn from "./containers/Auth/SignIn";
import { GlobalStyle} from "./styles/app";
import PasswordReset from "./containers/Auth/PasswordReset";
import Profile from "./containers/Profile/Profile";
import Adopt from "./containers/Adopt";
import Games from "./containers/Games";
import SharlPrize from "./containers/Games/SharlPrize";
import SharlFish from "./containers/Games/SharlFish";
import Sharls from "./containers/Sharls";
import SingleSharlBody from "./containers/Sharls/SingleSharlBody";
import Sack from "./containers/Sack";
import Market from "./containers/Market";
import Town from "./containers/Town";
import Tank from "./containers/Tank";
import SharlMuseum from "./containers/Museum";
import Help from "./containers/Help";
import TunaCan from "./containers/TunaCan";
import Research from "./containers/Research";
import Shop from "./containers/Shop/Shop";
import SharlQuiz from "./containers/Games/SharlQuiz/SharlQuiz";
import SharlInvest from "./containers/Games/SharlInvest";
import SharlMatch from "./containers/Games/SharlMatch";
import BarlWharlNarl from "./containers/Games/BarlWharlNarl/BarlWharlNarl";
import TankHome from "./containers/Tank/TankHome";
import TankEdit from "./containers/Tank/TankEdit";
import Studio from "./containers/Studio/Studio";
import CookiePolicy from "./containers/Help/Terms/CookiePolicy";
import PrivacyPolicy from "./containers/Help/Terms/PrivacyPolicy";
import TAndCPolicy from "./containers/Help/Terms/TAndCPolicy";
import ReturnPolicy from "./containers/Help/Terms/ReturnPolicy";
import EULAPolicy from "./containers/Help/Terms/EULA";
import FeedSharls from "./containers/Tank/FeedSharls";
import TrashExchange from "./containers/Games/TrashExchange/TrashExchange";
import ShareMySharls from "./containers/ShareMySharls";
import ShareMySharlsHome from "./containers/ShareMySharls/Home";
import Layout from "./containers/MainApp/Layout/Layout";

const token = localStorage.getItem('token');
if (token) {
    store.dispatch(signInAction(token))
}


function App() {
    // <Route exact path="/signup" component={SignUp} />
    //
  return (
        <BrowserRouter>
        <div>
        <GlobalStyle />
            <Switch>
                  <Route exact path="/sharemysharls/:username" component={ShareMySharls} />
                  <Route exact path="/sharemysharls" component={ShareMySharlsHome} />
                  <Route exact path="/help" component={Help} />
                  <Route exact path="/cookies" component={CookiePolicy} />
                  <Route exact path="/privacy" component={PrivacyPolicy} />
                  <Route exact path="/terms" component={TAndCPolicy} />
                  <Route exact path="/returns" component={ReturnPolicy} />
                  <Route exact path="/eula" component={EULAPolicy} />
                  <Layout>
                      <Route exact path="/shop" component={Shop} />
                      <Route exact path="/profile" component={Profile} />
                      <Route exact path="/adopt" component={Adopt} />
                      <Route exact path="/games" component={Games} />
                      <Route exact path="/prize" component={SharlPrize} />
                      <Route exact path="/quiz" component={SharlQuiz} />
                      <Route exact path="/invest" component={SharlInvest} />
                      <Route exact path="/match" component={SharlMatch} />
                      <Route exact path="/fish" component={SharlFish} />
                      <Route exact path="/trash" component={TrashExchange} />
                      <Route exact path="/bwn" component={BarlWharlNarl} />
                      <Route exact path="/sharls/active" component={SingleSharlBody} />
                      <Route exact path="/sharls" component={Sharls} />
                      <Route exact path="/sack" component={Sack} />
                      <Route exact path="/market" component={Market} />
                      <Route exact path="/tank/edit" component={TankEdit} />
                      <Route exact path="/tank/sharls" component={Tank} />
                      <Route exact path="/tank/feed" component={FeedSharls} />
                      <Route exact path="/tank" component={TankHome} />
                      <Route exact path="/museum" component={SharlMuseum} />
                      <Route exact path="/research" component={Research} />
                      <Route exact path="/tuna" component={TunaCan} />
                      <Route exact path="/studio" component={Studio} />
                      <Route exact path="/town" component={Town} />
                      <Route exact path="/reset-password" component={PasswordReset} />
                      <Route exact path="/signin" component={HOCSignIn(SignIn)} />
                      <Route exact path="/" component={HOCWrapper(MainApp)} />
                  </Layout>
            </Switch>
        </div>
      </BrowserRouter>
  );
}

export default App;