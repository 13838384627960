import styled, {createGlobalStyle} from "styled-components";
import Quicksand from "../assets/fonts/Quicksand.ttf";


export const AppStyleContainer = styled.div`
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    `;


export const StyleBody = styled.div`
    height: 85%;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

export const StyleFooter = styled.div`
    height: 5%;
    width: 100%;
    background-color: #afbed1;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
    color: white;
    font-size: 2vh;
    };
    `;


export const StyleBodyItem = styled.div`
    height: 100%;
    width: 50%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
     line-height: 3; 
     font-size: 3.5vh;
    
    }
     ul {
     display: flex;
     flex-direction: column;
     align-items: center;
    list-style-type: none;
    text-align: center;
    }
    li {
    height: 40%;
    width: 80%;
    line-height: 2; 
    font-size: 2.5vh;
    margin: 2% 10%;

    }
    img {
    height: 20%;
    }
  
    `;

export const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    height: 100vh;
    width: 100%;
  }
  * {
    font-family: Quicksand;
    margin: 0;
    padding: 0;
  }
  a:-webkit-any-link {
    color: white;
    text-decoration: none;
}
 @font-face {
    font-family: Quicksand;
    src: url(${Quicksand}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;
