import styled, {keyframes} from "styled-components";


export const StyleTankTestContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    background: linear-gradient(to top, rgba(150, 255, 255, 0.7) 0%, rgba(51, 102, 153, 0.6) 100%), url('../images/backgrounds/seabg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    `;


export const StyleTankTestButtonContainer = styled.div`
    height: 50%;
    width: 10%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 3%;
    position: relative;
    `;

export const StyleTankShareContainer = styled.div`
    height: 40%;
    width: 250%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    border-radius: 24px;
    position: absolute;
    z-index: 10;
    left: 20%;
    top: 100%;
    background-color: white;
    color: #336699;
    `;

export const StyleTankShareInput = styled.input`
    height: 25%;
    width: 85%;
    display: flex;
    align-items: center;
    margin-left: 1%;
    margin-top: 2%;
    background-color: white;
      font-size: 0.9vw;
    -webkit-appearance: none;
    -moz-appearance: none;
    `;

export const StyleTankShareCopy = styled.div`
    height: 25%;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
    img {
        height: 90%;
        width: auto;
        }
    `;

export const StyleTankEditPanel = styled.div`
    height: 80%;
    width: 20%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 3%;
    border-radius: 24px;
    background-color: white;    
    `;

export const StyleTankEditPanelTop = styled.div`
    height: 75%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    background-color: #336699;
    border-radius: 24px 24px 0px 0px;    
    `;

export const StyleTankEditPanelBottom = styled.div`
    height: 25%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    background-color: white;
    border-radius: 0px 0px 24px 24px;
    `;


export const StyleTankUpgradeContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: ${props => props.justify || "space-around"};
    p {
        font-size: 1.5vw;
        color: white;
    }
    `;

export const StyleTankItemsContainer = styled.div`
    height: 25%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid white;
    img {
        height: 80%;
        width: auto;
    }
    `;

export const StyleTankUpgradeComplete = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    p {
        font-size: 1.5vw;
        color: white;
        text-align: center;
    }
    `;

export const StyleTankUpgradeItem = styled.div`
    height: ${props => props.height || "22%"};
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    p {
        font-size: 1.1vw;
        color: white;
        
    }
    `;
export const StyleTankUpgradeImg = styled.div`
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    p {
        color: ${props => props.fontColor || "white"};
    }
    img {
           height: 20%;
           width: 10%;
        }
    `;

export const StyleTankEditButtons = styled.button`
    height: 25%;
    width: 60%;
    background-color: #336699;
    border-radius: 24px;
    color: white;    
    border: none;
    font-size: 1vw;
    `;

export const StyleTankEditItemsButton = styled.button`
    height: 45%;
    width: 40%;
    color: #336699;
    border-radius: 24px;
    background-color: white;    
    border: none;
    font-size: 1vw;
    margin-left: 2%;
    margin-right: 2%;
    `;

export const StyleTankEditItemsDropDown = styled.select`
    height: 45%;
    width: 80%;
    color: #336699;
    border-radius: 24px;
    background-color: white;    
    border: none;
    font-size: 1vw;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-bottom: 10%;
    margin-left: 5%;
    `;

export const StyleTankUpgradeButton = styled.button`
    height: 35%;
    width: 45%;
    background-color: white;
    border-radius: 24px;
    color: #336699;    
    border: 1px solid #336699;
    font-size: 1.1vw;
    `;

export const StyleTankTestContainerButtons = styled.button`
    height: 15%;
    width: 99%;
    background-color: #336699;
    border-radius: 24px;
    color: white;    
    border: none;
    font-size: 1vw;
    `;

export const StyleTankTestBackground = styled.div`
    height: 80%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
 
    background-image: url(${props => props.bgImage});
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    `;

export const StyleTankItemContainer = styled.div`
    height: 15%;
    width: 15%;
    z-index: ${props => props.zIndex || 10};
    position: absolute;
    
    left: ${props => props.left || "20%"};
    bottom: ${props => props.bottom || "20%"};
    
    img {
        height: 100%;
        width: 100%;
    }
    `;

export const StyleTankTestBackgroundOverlay = styled.div`
    height: 100%;
    width: 100%;
    z-index: 10;
    background-color: rgba(100,100,100,0.5);
    position: relative;
    `;

export const StyleTankTestBackgroundOverlayItem = styled.div`
    height: 10%;
    width: 10%;
    z-index: 100;
    background-color: rgba(100,100,100,0.8);
    position: absolute;
    border: 2px solid black;
    left: 40%;
    bottom: 20%;
    `;


const sharlSwimLeft = keyframes`
  from {   
    background: url('../images/tank/left1.png');
    background-size: 60%;
    background-repeat: no-repeat;
    left: 0;
    transform: rotateY(0deg);
  }
  25% {
    background: url('../images/tank/left2.png');
    background-size: 60%;
    background-repeat: no-repeat;
    transform: rotateY(0deg);
  }
  45% {
     transform: rotateY(0deg);
  }
  50% {
    background: url('../images/tank/left1.png');
    background-size: 60%;
    background-repeat: no-repeat;
    left: 80%;
    transform: rotateY(180deg);
  }
  75% {
    background: url('../images/tank/left2.png');
    background-size: 60%;
    background-repeat: no-repeat;
    transform: rotateY(180deg);
  }
  95% {
    transform: rotateY(180deg);
    }
  to {
    background: url('../images/tank/left1.png');
    background-size: 60%;
    background-repeat: no-repeat;
    left: 0;
    transform: rotateY(180deg);  
  }  `


const sharlSwimRight = keyframes`
  from {
    left: 80%;
    background: url('../images/tank/right1.png');
    background-size: 60%;
    background-repeat: no-repeat;
    transform: rotateY(0deg);
  }
  
  25% {
    background: url('../images/tank/right2.png');
    background-size: 60%;
    background-repeat: no-repeat;
    transform: rotateY(0deg);
  }
  45% {
      transform: rotateY(0deg);
  }
  50% {
    background: url('../images/tank/right1.png');
    background-size: 60%;
    background-repeat: no-repeat;
    left: 0;
    transform: rotateY(180deg);
    }
  
  75% {
    background: url('../images/tank/right2.png');
    background-size: 60%;
    background-repeat: no-repeat;
    transform: rotateY(180deg);
  }
  95% {
      transform: rotateY(180deg);
  }
  to {
    background: url('../images/tank/right1.png');
    background-size: 60%;
    background-repeat: no-repeat;
    left: 80%;
    transform: rotateY(180deg);
  }  `

export const StyleTankTestSharlContainer = styled.div`
    height: 20%;
    width: 20%;
    position: absolute;
    top: ${props => props.topPos || "20%"};
    z-index: 20; 
    
    background-image: url(${props => props.bgImage});
    background-size: 60%;
    background-repeat: no-repeat;
    :nth-child(odd) {
        animation-name: ${sharlSwimLeft};
        animation-duration: ${props => props.swimSpeed || "10s"};
        animation-iteration-count: infinite;
        &:hover {
            animation-play-state: paused;
            z-index: 100;
        }
    }
    :nth-child(even) {
        animation-name: ${sharlSwimRight};
        animation-duration: ${props => props.swimSpeed || "10s"};
        animation-iteration-count: infinite;
        &:hover {
           animation-play-state: paused;
           z-index: 100;
        }
    }
    `;




export const StyleTankTestSharlBackground = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
    height: 100%;
    width: auto;
    }
    `;


export const StyleTankTestSharlHat = styled.div`
    height: 50%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 48%;
    left: ${props => props.pos || "12%"};
    transform: ${props => props.hatFlip || "rotateY(0deg)"};
    z-index: 10;
    `;

export const StyleTankFeedBackground = styled.div`
    height: 100%;
    width: 100%;
    background: linear-gradient(to top, rgba(150, 255, 255, 0.7) 0%, rgba(51, 102, 153, 0.6) 100%), url('../images/backgrounds/seabg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

export const StyleTankFeedSharls = styled.div`
    height: 90%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
`;

export const StyleTankFeedFood = styled.div`
    height: 90%;
    width: 30%;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const StyleTankFeedFoodContent = styled.div`
    height: 85%;
    width: 100%;
    background-color: #336699;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    border-radius: 24px 24px 0px 0px;
`;

export const StyleTankFeedFoodBack = styled.div`
    height: 15%;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 24px 24px;
`;

export const StyleTankFeedButton = styled.button`
    height: 70%;
    width: 30%;
    background-color: #336699;
    border-radius: 24px;
    border: none;
    a:-webkit-any-link {
        text-decoration: none;
        color: white;
        font-size: 1vw;
    }
`;


export const StyleSharlTankFeedItem = styled.div`
    height: 15%;
    width: 46%;
    margin-left: 2%;
    margin-right: 2%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    border-radius: 24px;    
`;


export const StyleSharlTankFeedItemContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background-color: white;
`;

export const StyleSharlTankFeedItemDrag = styled.div`
    height: 100%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    img {
        height: 100%;
        width: auto;
        transform: translate(0, 0);
        background: none;
    }
`;

export const StyleSharlTankFeedItemStay = styled.div`
    height: 100%;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: none;
    border-radius: 24px;
    
    p {
    font-size: 1.2vw;
    }
`;
export const StyleTankFeedActiveSharls = styled.div`
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px 24px 0px 0px;
`;

export const StyleTankFeedInactiveSharls = styled.div`
    height: 80%;
    width: 100%;
    background-color: pink;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 24px 24px;
`;

export const StyleTankActiveDropContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
   
    align-items: ${props => props.pos || "center"};
    justify-content: flex-start;
    background-color: white;
    border-radius: ${props => props.bRadius || "24px"};    
    border-bottom: ${props => props.borderBottom || "none"};    
`;

export const StyleTankActiveDropItemContainer = styled.div`
    height: ${props => props.height || "90%"};
    width: 21%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.bgColor || "white"};
    border-radius: 24px;    
    margin: 2%;
`;

export const StyleTankActiveDropSharl = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    position: relative; 
   `;


export const StyleTankActiveDropOverlayYes = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    z-index: 10;
    position: absolute;
    background-color: green;
    opacity: 0.7;
   `;

export const StyleTankActiveDropOverlayNo = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    z-index: 10;
    position: absolute;
    background-color: red;
    opacity: 0.7;
       
   `;


const sharlEat = keyframes`
  from {   
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(45deg);
  }
  20% {
    transform: rotate(-45deg);
  }
  30% {
    transform: rotate(60deg);
  }
  40% {
    transform: rotate(-60deg);
  }
  50% {
     transform: rotate(45deg);
  }
  60% {
     transform: rotate(-45deg);
  }
  
  to {
    transform: rotate(720deg);  
  }  `

export const StyleTankFeedSharl = styled.div`
    height: 100%;
    width: ${props => props.width ||"50%"};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
   
    animation-name: ${props => props.spinning===true ? sharlEat : ""};
    animation-duration: 2s;
    animation-iteration-count: 1;
    `;

export const StyleTankFeedSharlText = styled.div`
    height: 100%;
    width: ${props => props.width ||"50%"};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    p {
        font-size: 1vw;
        font-weight: bold;
    }
    h1 {
        font-size: 1vw;
        font-style: italic;
        font-weight: normal;
    }
   
    `;
