import React, {useState} from 'react';

import {connect} from "react-redux";
import {StyleWheelContainer} from "../../../styles/games";
import setSharlPrizeFunction from "../../../store/actions/Games/setSharlPrizeAction";
import {WheelContainer, WheelItem, WheelMain, WheelMainContainer} from "../../../styles/wheel";

function WheelBody(props) {
    const [selectedItem, setSelectedItem] = useState(null);
    const [timeVar, setTimeVar] = useState(null);
    const [clicks, setClicks] = useState(1);

    const items  = ['Round Sharl', 'Cheeky Sharl', 'Pancake Sharl', 'Angry Sharl', 'Sleepy Sharl', 'Cute Sharl'];
    const wheelVars = {
          '--nb-item': items.length,
          '--selected-item': selectedItem,
          '--time-var': timeVar,
    };

    let spinning = selectedItem !== null ? 'spinning' : '';


    const selectItem = (e) => {
        if (clicks===1){
            if (selectedItem === null) {
                const selectedItemD = Math.floor(Math.random() * items.length);
                setSelectedItem(selectedItemD)
                const calcTimeVar = 360 + Math.floor(Math.random() * 60);
                setTimeVar(calcTimeVar.toString()+"deg")
                if (calcTimeVar >= 359  && calcTimeVar <=365){
                    props.dispatch(setSharlPrizeFunction("Round Sharl"))
                } else if (calcTimeVar >=366 && calcTimeVar <=375){
                    props.dispatch(setSharlPrizeFunction("Cute Sharl"))
                } else if (calcTimeVar >=376 && calcTimeVar <=385){
                    props.dispatch(setSharlPrizeFunction("Sleepy Sharl"))
                } else if (calcTimeVar >=386 && calcTimeVar <=395){
                    props.dispatch(setSharlPrizeFunction("Angry Sharl"))
                } else if (calcTimeVar >=396 && calcTimeVar <=405){
                    props.dispatch(setSharlPrizeFunction("Pancake Sharl"))
                } else if (calcTimeVar >= 406 && calcTimeVar <=415){
                    props.dispatch(setSharlPrizeFunction("Cheeky Sharl"))
                } else if (calcTimeVar >= 416 && calcTimeVar <=420){
                    props.dispatch(setSharlPrizeFunction("Round Sharl"))
                }
            } else {
                setSelectedItem(null);
                setTimeout(selectItem, 500);
            }
            setClicks(0)
        }
  }

    return (
            <StyleWheelContainer>
                <WheelMainContainer>
                    <WheelContainer>
                    <WheelMain
                        spinning={spinning}
                        style={wheelVars}
                        onClick={(e) => selectItem(e)}>
                        {items.map((item, index) => (
                            <WheelItem key={index} style={{ '--item-nb': index }}>
                                <img height="70%" width="70%" src={require("../../../assets/images/games/sharls/"+item+".png")} alt={item}/>
                            </WheelItem>
                        ))}
                    </WheelMain>
                </WheelContainer>
                </WheelMainContainer>
                </StyleWheelContainer>
            )
  }

const mapStateToProps = state => {
    return {
      ...state,
      };
    };

export default connect(mapStateToProps)(WheelBody);
