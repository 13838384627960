import {addtocart, getshop, orderid, shopstage} from "../types";

const initialState = {
    stage: "home",
    cart: [],
    inventory: [],
    order: "",
    };

const ShopReducer = (state = initialState, action) => {
  switch (action.type) {
        case shopstage:
            return {
            ...state,
            stage: action.payload,
            }
        case getshop:
            return {
            ...state,
            inventory: action.payload,
            }
        case addtocart:
            return {
            ...state,
            cart: action.payload,
            }
        case orderid:
            return {
            ...state,
            order: action.payload,
            cart: [],
            stage: "completed",
            }
        default:
            return state;
  }
}

export default ShopReducer;