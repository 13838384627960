import styled from "styled-components";


export const StyleProfileItem = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to top, rgba(150, 255, 255, 0.7) 0%, rgba(51, 102, 153, 0.6) 100%), url('../images/backgrounds/seabg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    h2 {
     line-height: 3; 
     font-size: 3.5vh; 
     overflow: hidden;
    }
    li {
    line-height: 2; 
    font-size: 2.5vh;
    }
      
    `;


export const StyleProfileContainer = styled.div`
    height: 80%;
    width: 60%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    background-color: white;
    border-radius: 24px;
    `;

export const StyleUserProfileContainer = styled.div`
    height: 100%;
    width: 100%;
    min-width: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  
    p {
    font-size: 3vh;
    line-height: 2;
    overflow: hidden;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
    }
    img {
    height: 50%;
    }
    
    `;

export const StyleSharlProfileItem = styled.div`
    height: 90%;
    width: 23%;
    margin: 1%;
    display: flex;
    background-color: white;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    a:-webkit-any-link {
        text-decoration: none;
        color: black;
        height: 100%;
        width: 100%;
    }
    `;

export const StyleSharlProfileItemDouble = styled.div`
    height: 90%;
    width: 46%;
    margin: 1%;
    display: flex;
    background-color: white;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    img {
    height: 20%
    }
    `;

export const StyleSharlItem = styled.div`
    height: 45%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    p {
    line-height:1;
    font-size: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    }
    
    `;


export const StyleSharlItemSingle = styled.div`
    height: 6%;
    width: 100%;
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    p {
    line-height: 1.5;
    font-size: 1.1vw;
    overflow: hidden;
    }
    
    
    h2 {
    line-height:1.5;
    font-size: 1.5vw;
    font-weight: bold;
    }
    `;

export const StyleUserProfileText = styled.div`
    height: ${props => props.height || "100%"};
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${props => props.justify || "center"};
    border-radius: 24px 0px 0px 24px;
    p {
        font-size: 1.5vw;
        line-height: 2;
        text-align: center;
    }
    background-color: ${props => props.bgColor || "blank"};
    color: ${props => props.fontColor || "#336699"};
    a:-webkit-any-link {
        color: #336699;
        text-decoration: none;
        align-items: center;
        font-weight: bold;
        text-align: center;
    }   
    `;

export const StyleUserProfileBody = styled.div`
    height: ${props => props.divHeight || "100%"};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  
    `;

export const StyleUserProfileImg = styled.div`
    height: 12%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        padding-right: 2%;
        height: 70%;
    }
    p {
        line-height: 1;
    }
    
    `;
export const StyleSettingsProfileBody = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    `;

export const StyleUserProfileFooter = styled.div`
    height: 100%;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    `;


export const StyleProfileButton = styled.button`
    height: ${props => props.adoptHeight || "10%"};
    width: ${props => props.adoptWidth || "80%"};
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 2.5vh;
    border: none;
    margin: 2%;
    a:-webkit-any-link {
    color: white;
    text-decoration: none;
    }   
    a {
    color: white;
    overflow: hidden;
    }
 `;

export const StyleProfileDeactivateButton = styled.button`
    height: 20%;
    width: 50%;
    background-color: ${props => props.buttonColor || "#336699"};
    border-radius: 24px;
    color: white;
    font-size: 2.5vh;
    border: none;
    margin: 2%;
    a:-webkit-any-link {
    color: white;
    text-decoration: none;
    }   
    a {
    color: white;
    overflow: hidden;
    }
    
 `;

export const StyleProfilePassButton = styled.button`
    height: 15%;
    width: 50%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 2.5vh;
    border: none;
    margin-top: 5%;
    margin-left: 25%;
    a:-webkit-any-link {
        color: white;
        text-decoration: none;
    }   
    a {
        color: white;
        overflow: hidden;
    }
 `;


export const DotRatingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 70%;
    margin: 2%
  } 
`;


export const StyleUserPasswordForm = styled.form`
    height: 55%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    `;

export const StyleUserPasswordInput = styled.input`
    height: 15%;
    width: 80%;
    margin-top: 4%;
    font-size: 1.2vw;
    margin-left: 10%;
    `;