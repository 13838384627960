import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";


import {
    StyleBazarBody,
    StyleBazarBodyRight,
    StyleBazarBodyRightBottom,
    StyleBazarBodyRightItem,
    StyleBazarBodyRightTop,
    StyleBazarContainer,
    StyleBuyNarlButton, StyleNarlConfirm, StyleNarlQuantityInput,
    StyleSharlSelectedItemInfo,
    StyleSharlSelectedItemSlider, StyleSharlSelectedItemTotal
} from "../../../styles/market";
import NarlAndFriendsItems from './NarlAndFriendsItems';
import clearNarlItemFunction from "../../../store/actions/Market/clearNarlItemAction";
import buyNarlItemFunction from "../../../store/actions/Market/buyNarlItemAction";

function NarlAndFriends(props) {
    const [selectedItem, setSelectedItem] = useState("");
    const [buyQuantity, setBuyQuantity] = useState('');
    const [userBalance, setUserBalance] = useState("")
    const [buyError, setBuyError] = useState("");

    useEffect(() => {
        props.dispatch(clearNarlItemFunction());
        // eslint-disable-next-line
        },[]);

    useEffect(() => {
        setBuyError("")
        // eslint-disable-next-line
        },[selectedItem]);

    useEffect(() => {
        setSelectedItem(props.MarketReducer.selected_narl_item[0]);
        setBuyQuantity(1);
        setUserBalance(props.UserReducer.user.balance);
        setBuyError("");
        // eslint-disable-next-line
        },[props.MarketReducer]);

    const confirmBuyHandler = () => {
        const itemId = selectedItem.id;
        const totalCost = selectedItem.value * buyQuantity;
        const itemQuantity = buyQuantity;
        const max_items = props.UserReducer.user.max_items;
        const narlNumber = selectedItem.narlNumber;

        const user_items = props.UserReducer.items;
        const filteredUserItems = user_items.filter(
               item => item.id === itemId
           );

        if (filteredUserItems.length === 0) {
            const bag_space = max_items - user_items.length;
            if (bag_space < 1 ) {
                setBuyError("No room in sack.. Manage sack?")
            } else {
              props.dispatch(buyNarlItemFunction(props, itemId, totalCost, itemQuantity, narlNumber));
                selectedItem.quantity = selectedItem.quantity - buyQuantity;
            }
        } else {
            props.dispatch(buyNarlItemFunction(props, itemId, totalCost, itemQuantity, narlNumber));
            selectedItem.quantity = selectedItem.quantity - buyQuantity;
        }
    }

    return (
            <StyleBazarContainer>
                <StyleBazarBody>
                    <StyleBazarBodyRight>
                        <StyleBazarBodyRightTop>
                            <NarlAndFriendsItems props={props} />
                        </StyleBazarBodyRightTop>
                                {buyError ? <p>{buyError}</p> : "" }
                        {selectedItem ?
                        <StyleBazarBodyRightBottom>

                            {selectedItem.quantity > 0 ?
                            <>
                                <StyleBazarBodyRightItem>
                                {selectedItem ?
                                        <StyleSharlSelectedItemInfo>
                                            <img src={`https://www.sharl.info/${selectedItem.image}`} alt={selectedItem.name} />
                                        </StyleSharlSelectedItemInfo>
                                    : ""
                                }
                            </StyleBazarBodyRightItem>

                            <StyleBazarBodyRightItem>
                            {selectedItem ?
                               <StyleSharlSelectedItemSlider>
                               <p> {buyQuantity ? buyQuantity : 1} </p>
                                <StyleNarlQuantityInput
                                    type="range"
                                    onChange={(e) => setBuyQuantity(e.currentTarget.value)}
                                    value={buyQuantity}
                                    min="1"
                                    max= {selectedItem.quantity}
                                />
                                </StyleSharlSelectedItemSlider>
                                : ""
                                }
                            </StyleBazarBodyRightItem>

                            { selectedItem ?
                                <StyleBazarBodyRightItem>
                                { buyQuantity * selectedItem.value <= userBalance ?
                                    <StyleSharlSelectedItemTotal>
                                    <StyleNarlConfirm>
                                    <p>{buyQuantity * selectedItem.value}</p>
                                    <img src={require("../../../assets/images/misc/shadollar.png")} alt={"Shadollar"} />
                                    </StyleNarlConfirm>
                                    <StyleBuyNarlButton onClick={() => confirmBuyHandler()}>Confirm Buy</StyleBuyNarlButton>
                                    </StyleSharlSelectedItemTotal>
                                    :
                                    <StyleSharlSelectedItemTotal>
                                    <StyleNarlConfirm>
                                    <h1>{buyQuantity * selectedItem.value}</h1>
                                    <img src={require("../../../assets/images/misc/shadollar.png")} alt={"Shadollar"} />
                                    </StyleNarlConfirm>
                                    <h2>Too Expensive..</h2>
                                    </StyleSharlSelectedItemTotal>
                                }

                             </StyleBazarBodyRightItem>
                            : ""
                            }
                            </> :
                                ""}

                        </StyleBazarBodyRightBottom> : ""
                        }
                     </StyleBazarBodyRight>
                </StyleBazarBody>
            </StyleBazarContainer>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(NarlAndFriends);
