import React from 'react';


function ReturnPolicy() {
  return (
        <>
          <p>Return Policy</p>
        </>
  );
}

export default ReturnPolicy;;
