import {userdata, useritems} from "../../types";

export const getUserItemsAction = (data) => ({
    type: userdata,
    payload: data,
});


export const setUserItemsAction = (data) => ({
    type: useritems,
    payload: data,
});

const URL = `https://www.sharl.info/backend/api/users/items/`;

const getUserItemsFunction = (props) => (dispatch) => {
    const token = props.SignInReducer.token
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'GET',
        headers
    };
 
     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        dispatch(setUserItemsAction(data.items));
        const removeProperties = (object, ...keys) => Object.entries(object).reduce((prev, [key, value]) => ({...prev, ...(!keys.includes(key) && { [key]: value }) }), {})
        const allData = removeProperties(data, 'items', 'sharls')
        const concatData = {"user":allData, "sharls":data.sharls, "items":data.items}
        dispatch(getUserItemsAction(concatData));
    })
};
export default getUserItemsFunction;