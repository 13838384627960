import React from 'react'
import {
    StyleDragMain,
    StyleDragSharlContainer,
    StyleInactiveSharl,
} from "../../../../styles/tank";
import selectTankSharlFunction from "../../../../store/actions/Tank/selectTankSharlAction";


export const BlankSharl = (props) => {

    const selectSharlHandler = (e) => {
        props.props.dispatch(selectTankSharlFunction(""));
    }



  return (
        <StyleInactiveSharl onClick={()=>selectSharlHandler()}>
            <StyleDragSharlContainer>
                <StyleDragMain>
                </StyleDragMain>
            </StyleDragSharlContainer>
        </StyleInactiveSharl>


  )
}

export default BlankSharl;