import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {StyleTunaContents, StyleTunaItemQuantity} from "../../../styles/tuna";
import clearTunaCanPrizeFunction from "../../../store/actions/TunaCan/clearTunaCanPrizeAction";


function TunaCanContent(props) {
    const [tunaPrize, setTunaPrize] = useState("")

    useEffect(()=>{
        props.dispatch(clearTunaCanPrizeFunction());
        // eslint-disable-next-line
        },[])


    useEffect(() => {
        const prize = props.TunaCanReducer.tunaCanPrize;
        setTunaPrize(prize);
        // eslint-disable-next-line
        },[props.TunaCanReducer]);

    if (tunaPrize) {
        return (
            <StyleTunaContents>
                <img src={"https://www.sharl.info" + tunaPrize.image} alt={"tuna sharls"}/>
                <h1>{tunaPrize.name}</h1>
                <StyleTunaItemQuantity>
                    <p>{tunaPrize.prize_quantity}</p>
                </StyleTunaItemQuantity>
            </StyleTunaContents>
        );
    } else {
        return (
            <></>
        )
    }
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(TunaCanContent);
