import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {StyleTownContainer, StyleTownImageContainer, StyleTownLinkContainer} from "../../../styles/town";


function TownBody(props) {
    return (
            <>
                <StyleTownContainer>
                    <StyleTownImageContainer>
                        <img src={require("../../../assets/images/town/shatlantis-bg.png")} alt={"shatlantis"} />
                    </StyleTownImageContainer>
                    <StyleTownLinkContainer top={"58%"} left={"58.3%"} width={"6%"} height={"4%"}>
                        <Link to="/sharls">
                            <p>SHARLS</p>
                        </Link>
                    </StyleTownLinkContainer>
                  <StyleTownLinkContainer top={"56.2%"} left={"35.9%"} width={"6%"} height={"8%"}>
                        <Link to="/studio">
                            <p>HAPPY SHARL'S STUDIO</p>
                        </Link>
                    </StyleTownLinkContainer>
                  <StyleTownLinkContainer top={"50%"} left={"47.2%"} width={"6%"} height={"4%"}>
                        <Link to="/adopt">
                            <p>ADOPTION</p>
                        </Link>
                    </StyleTownLinkContainer>
                  <StyleTownLinkContainer top={"82%"} left={"47.5%"} width={"6%"} height={"4%"}>
                        <Link to="/market">
                            <p>MARKET</p>
                        </Link>
                    </StyleTownLinkContainer>
                  <StyleTownLinkContainer top={"82%"} left={"38.5%"} width={"6%"} height={"4%"}>
                        <Link to="/shop">
                            <p>CASH SHOP</p>
                        </Link>
                    </StyleTownLinkContainer>
                  <StyleTownLinkContainer top={"82%"} left={"56.5%"} width={"6%"} height={"4%"}>
                        <Link to="/tuna">
                            <p>TUNA CANS</p>
                        </Link>
                    </StyleTownLinkContainer>
                  <StyleTownLinkContainer top={"69.5%"} left={"26.5%"} width={"6%"} height={"4%"}>
                        <Link to="/games">
                            <p>ARCADE</p>
                        </Link>
                    </StyleTownLinkContainer>
                  <StyleTownLinkContainer top={"69.5%"} left={"68.2%"} width={"6%"} height={"4%"}>
                        <Link to="/museum">
                            <p>MUSEUM</p>
                        </Link>
                    </StyleTownLinkContainer>
                  <StyleTownLinkContainer top={"29%"} left={"47.2%"} width={"6%"} height={"4%"}>
                        <Link to="/research">
                            <p>RESEARCH</p>
                        </Link>
                    </StyleTownLinkContainer>
                  <StyleTownLinkContainer top={"43%"} left={"39.2%"} width={"6%"} height={"4%"}>
                        <Link to="/tank">
                            <p>TANK</p>
                        </Link>
                    </StyleTownLinkContainer>
                  <StyleTownLinkContainer top={"43%"} left={"55%"} width={"6%"} height={"4%"}>
                        <Link to="/sack">
                            <p>SACK</p>
                        </Link>
                    </StyleTownLinkContainer>
                  <StyleTownLinkContainer top={"37%"} left={"65%"} width={"6%"} height={"4%"}>
                        <Link to="/help">
                            <p>HELP</p>
                        </Link>
                    </StyleTownLinkContainer>
                  <StyleTownLinkContainer top={"37%"} left={"28%"} width={"6%"} height={"4%"}>
                        <Link to="/profile">
                            <p>PROFILE</p>
                        </Link>
                    </StyleTownLinkContainer>
                </StyleTownContainer>
            </>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(TownBody);
