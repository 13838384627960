import React from 'react'
import ActiveSquare from './ActiveSquare'
import { canMoveSharl } from './Movement'
import { ItemTypes } from '../DragTank/Constants'
import { useDrop } from 'react-dnd'
import {
    StyleActiveTankSquareMain,
    StyleTankNoDrop,
    StyleTankOverDrop,
} from "../../../../styles/tank";
import moveSharlFunction from "../DragTank/moveSharlAction";

function ActiveTankSquare({ x, y, props, sharl, children }) {

  const dropHandler = (props, monitor, [x,y]) => {
        props.dispatch(moveSharlFunction(props,monitor.itemId,[99, 99], true))
    }

  const [{ isOver, canDrop }, drop] = useDrop({
        accept: ItemTypes.SELECTEDSHARL,
        drop: monitor => dropHandler(props, monitor, [x,y]),
        canDrop: () => canMoveSharl(props, x, y),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop()
    })

  })

    return (
        <StyleActiveTankSquareMain ref={drop}>
            <ActiveSquare>{children}</ActiveSquare>
                {isOver && canDrop ?
                    <StyleTankOverDrop />
                : ""}

                {isOver && !canDrop ?
                    <StyleTankNoDrop />
                : ""}
        </StyleActiveTankSquareMain>
  )
}

export default ActiveTankSquare;