import React from 'react'
import { ItemTypes } from '../TankBody/DragTank/Constants'
import { useDrag } from 'react-dnd'
import {
    StyleSharlTankFeedItemContainer,
    StyleSharlTankFeedItemDrag,
    StyleSharlTankFeedItemStay
} from "../../../styles/tanktest";


export const DragFish = ({ item, props }) => {
    const itemId = item.id;
    let level = 0;
    if (item.name === "Anchovies" || item.name === "Herring"){
        level = 1;
    } else if (item.name === "Sardines" || item.name === "Crab"){
        level = 2;
    } else if (item.name === "Tuna" || item.name === "Rainbow Trout" || item.name === "Salmon" || item.name === "Flathead") {
        level = 3;
    }

    const [{isDragging}, drag] = useDrag({
        item: {type: ItemTypes.SELECTEDFISH, itemId: itemId, item: item, level: level},
        collect: (monitor) => ({
            item: monitor.getItem(),
            isDragging: !!monitor.isDragging(),
            }),
        })


  return (
      <StyleSharlTankFeedItemContainer
        style={{
            background: isDragging ? "#336699" : "white",
            color: isDragging ? "white" : "#336699",
            border: isDragging ? "1px solid white" : "#336699",
        }}
      >
         <StyleSharlTankFeedItemDrag
             id={item.id}
             key={item.id}
             ref={drag}
             style={{
                cursor: 'grab',
                transform: 'translate(0, 0)',
                background: 'none',
             }}>
             <img src={`https://www.sharl.info/${item.image}`} alt={item.name} />
         </StyleSharlTankFeedItemDrag>
          <StyleSharlTankFeedItemStay>
            <p>{item.name} ({item.quantity - item.quantity_in_use}) </p>
          </StyleSharlTankFeedItemStay>
      </StyleSharlTankFeedItemContainer>
  )
}

