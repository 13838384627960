import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";

import {
    StyleResearchMidTextItem,
    StyleResearchRight,
    StyleResearchMidBackground,
    StyleResearchMid,
    StyleResearchMidImg,
    StyleResearchMidHat,
    StyleResearchMidText,
    StyleResearchMidButton
} from "../../../styles/research";
import {getSharlTime} from "./getSharlTime";
import finishResearchFunction from "../../../store/actions/Research/finishResearchAction";


function ResearchMid(props) {
    const [sharl, setSharl] = useState("");
    const [research, setResearch] = useState("");
    const [researchDuration, setResearchDuration] = useState("");

    useEffect(() => {
        const selectedSharl = props.ResearchReducer.selectedSharl.research_code;
        const selectedResearch = props.ResearchReducer.selectedResearch.fact_code;
        if (selectedSharl) {
            setSharl(props.ResearchReducer.selectedSharl);
             if (selectedSharl === "F999" && selectedResearch){
                  const factCode = props.ResearchReducer.selectedResearch.fact_code;
                  const factNum = parseInt(factCode.slice(2, 4));
                  const researchCategory = props.ResearchReducer.selectedResearch.category.name;
                  const selectedResearch = {"code": factCode, "category": researchCategory, "fact_number": factNum }
                  setResearch(selectedResearch);
                  let duration = props.ResearchReducer.selectedResearch.category.duration * 60
                  if (props.ResearchReducer.selectedSharl.bonus_type === "duration") {
                      duration = duration / props.ResearchReducer.selectedSharl.bonus_value;
                  }
                  const hours = Math.floor(duration / 60)
                  const min = duration - (hours * 60)
                  if (hours < 1) {
                      setResearchDuration(min + "m");
                  } else {
                      setResearchDuration(hours + "h " + min + "m");
                  }
             } else {
                 const factCode = props.ResearchReducer.selectedSharl.research_code;
                 const factNum = parseInt(factCode.slice(2, 4));
                 const researchCode = factCode.slice(0, 2);
                 let researchCategory = "";
                 if (researchCode === "F1") {
                     researchCategory = "Sharl Facts";
                 } else if (researchCode === "F2") {
                     researchCategory = "Shark Facts";
                 } else if (researchCode === "F3") {
                     researchCategory = "Friend Book";
                 } else if (researchCode === "F4") {
                     researchCategory = "Sharl History";
                 }
                 const selectedResearch = {"code": factCode, "category": researchCategory, "fact_number": factNum}
                 setResearch(selectedResearch);
             }
        }
        // eslint-disable-next-line
        },[props.ResearchReducer]);

    const finishResearchHandler = (e) => {
        const sharlId = {"sharl_id": sharl.id};
        const researchCode = {"research_code": research.code};
        const research_data = Object.assign(sharlId, researchCode);
        props.dispatch(finishResearchFunction(props, research_data));

    }

    return (
                <StyleResearchRight>
                    <StyleResearchMidBackground bgColor={sharl.color}>
                        <StyleResearchMid>
                            <StyleResearchMidImg>
                                <img src={sharl.sharl_avatar} alt={sharl.sharl_avatar} />
                            </StyleResearchMidImg>
                            { sharl.headgear ?
                            <StyleResearchMidHat>
                            { sharl.headgear.image.includes("https") ?
                                <img src={sharl.headgear.image} alt={sharl.headgear.name} />
                                :
                                <img src={"https://www.sharl.info/" + sharl.headgear.image} alt={sharl.headgear.name} />
                            }
                            </StyleResearchMidHat>
                                : ""
                        }
                        </StyleResearchMid>
                        </StyleResearchMidBackground>

                        <StyleResearchMidText>
                            <StyleResearchMidTextItem>
                                {getSharlTime(sharl.research_end_time) === "completed" && sharl.is_researching ?
                                    <p>{sharl.name} has completed researching:</p>
                                    :
                                    <p>{sharl.name} is currently researching:</p>
                                }
                                <p><b>{research.category} {research.fact_number}</b></p>
                            </StyleResearchMidTextItem>
                            <StyleResearchMidTextItem>
                                {getSharlTime(sharl.research_end_time) === "completed" && sharl.is_researching ?
                                    <>
                                        <StyleResearchMidButton onClick={(e) => finishResearchHandler(e)}>Finish
                                        </StyleResearchMidButton>
                                    </>
                                    :
                                    <>
                                        {researchDuration ?
                                            <p>Completed in {researchDuration}</p>
                                            :
                                            <p>Completed in: {getSharlTime(sharl.research_end_time)}</p>
                                        }
                                        </>
                                            }
                            </StyleResearchMidTextItem>
                        </StyleResearchMidText>
                     </StyleResearchRight>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(ResearchMid);
