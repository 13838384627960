import styled from "styled-components";

export const StyleHelpButton = styled.button`
    height: 90%;
    width: 15%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 2.5vh;
    border: none;
    a:-webkit-any-link {
    color: white;
    text-decoration: none;
    }   
    a {
    color: white;
    }
 `;

export const StyleHelpContainer = styled.div`
    height: 90%;
    width: 80%;
    display: flex;
    background-color: white;
    border-radius: 24px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;


export const StyleHelpNavigation = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid black;
    `;

export const StyleHelpNavigationItem = styled.div`
    height: 100%;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.bgColor || "white"}; 
    border-radius: ${props => props.borderRadius || "0px"}; 
    
    p {
        font-size: 1.5vw;
        font-weight: ${props => props.fontWeight || "300"}; 
        color: ${props => props.fontColor || "black"}; 
    }
  
    `;

export const StyleHelpContent = styled.div`
    height: 90%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

export const StyleHelpPageContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0px 0px 24px 24px;
    overflow: auto;
    `;

export const StyleHelpPageItemBlock = styled.div`
    min-height: ${props => props.minHeight || "10%"};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid black;
    &:nth-child(1){
        border-top: 1px solid black;
    }
    `;

export const StyleHelpPageItem = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    display: ${props => props.display || "auto"}; 
    background-color: ${props => props.bgColor || "white"};
    color: ${props => props.color || "black"};
    p{
    margin-left: ${props => props.marginLeft || "3%"};
    }
    h1{
    margin-left: 2%;
    }
    `;


export const StyleHelpContactHeader = styled.div`
    height: 15%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;
export const StyleHelpContactFooter = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;

export const StyleHelpContactBody = styled.div`
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    `;
export const StyleHelpContactBodyContainer = styled.div`
    height: 85%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    `;


export const StyleHelpContactForm = styled.form`
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: salmon;
    p{
        color: white;
        font-size: 1.2vw;
        line-height: 1.5;
    }
    `;

export const StyleHelpContactInput = styled.input`
    height: 10%;
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.bgColor || "#336699"};
    border: none;
    border-radius: 24px;
    color: white;
    font-size: 1vw;
    padding-left: 5%;
    `;

export const StyleHelpContactSelect = styled.select`
    height: 10%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.bgColor || "#336699"};
    border: none;
    border-radius: 24px;
    color: white;
    font-size: 1vw;
    `;

export const StyleHelpContactText = styled.textarea`
    height: 25%;
    width: 78%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: ${props => props.bgColor || "#336699"};
    border: none;
    color: white;
    font-size: 1vw;
    padding-left: 1%;
    padding-right: 1%;
    `;

export const StyleHelpContactBox = styled.input`
    height: 80%;
    width: 10%;

    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding-left: 1%;
    padding-right: 1%;
    margin-top: 2%;
    `;


export const StyleHelpContactBoxContainer = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p{
    font-size: 1vw;
    margin-top: 2%;
    color: ${props => props.checkColor || "white"}
    }
    `;


export const StyleHelpTermsContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 0px 0px 24px 24px;
    `;

export const StyleHelpTermsBody = styled.div`
    height: 90%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    `;

export const StyleHelpTermsContent = styled.div`
    height: 90%;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    `;

export const StyleHelpTermsItem = styled.div`
    height: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    background-color: ${props => props.bgColor || "white"};
    color: ${props => props.fontColor || "black"};
    border-top: 1px solid black;
    &:nth-child(1){
        border-top: none;
    }
    `;