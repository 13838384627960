import React from 'react';
import SharlMuseumBody from "./MuseumBody";

function SharlMuseum() {
  return (
        <SharlMuseumBody/>
  );
}

export default SharlMuseum;;
