import React from 'react';
import SharlsBody from "./SharlsBody";

function Sharls() {
  return (
        <SharlsBody/>
  );
}

export default Sharls;
