import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {
    StyleSharlButton, StyleSharlRemoveButton,
    StyleSharlUpdateBody,
    StyleSharlUpdateContainer,
    StyleSharlUpdateFooter,
    StyleSharlSackItem, StyleSharlSackItemQuantity
} from "../../../styles/sharls";
import hideSharlItemsFunction from "../../../store/actions/Sharls/hideSharlItemsAction";
import updateSharlHatFunction from "../../../store/actions/Sharls/updateSharlHatAction";
import saveSharlHatFunction from "../../../store/actions/Sharls/saveSharlHatAction";
import removeSharlHatFunction from "../../../store/actions/Sharls/removeSharlHatAction";



function SharlItems(props){
    const [selectedHat, setSelectedHat] = useState("");
    const items = props.UserReducer.items;
    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => {
       const filteredItemsCat = items.filter(
            item => item.category === "headgear"
        );
       const filteredItemsQuantity = filteredItemsCat.filter(
            item => item.quantity - item.quantity_in_use >= 1
        );
       const filteredItemsFinal = filteredItemsQuantity.filter(
            item => item.name !== "Blank Hat"
        );
       setFilteredItems(filteredItemsFinal);
        // eslint-disable-next-line
        },[props.SharlReducer.headgear]);



    const closeItemHandler = (e) => {
        props.dispatch(hideSharlItemsFunction());
        let newImage = props.SharlReducer.selected_sharl.headgear.image
            props.dispatch(updateSharlHatFunction(newImage));
    }

    const saveHatHandler = () => {
        const sharlID = props.SharlReducer.selected_sharl.id;
        const currentHeadgear = props.SharlReducer.selected_sharl.headgear.id
        if (selectedHat.id){
           if (currentHeadgear !== selectedHat.id) {
                const headgear = {"headgear": selectedHat.id}
                props.dispatch(saveSharlHatFunction(props, headgear, sharlID));
                props.dispatch(hideSharlItemsFunction());
                const elementPos = items.map(function (x) {
                    return x.id;
                }).indexOf(selectedHat.id);
                const hatItem = items[elementPos];
                hatItem.quantity_in_use = hatItem.quantity_in_use + 1;
                const oldElementPos = items.map(function (x) {
                    return x.id;
                }).indexOf(props.SharlReducer.selected_sharl.headgear.id)
                const oldHatItem = items[oldElementPos];
                oldHatItem.quantity_in_use = oldHatItem.quantity_in_use - 1;
            }
            }

    }

       const removeHatHandler = () => {
            const sharlID = props.SharlReducer.selected_sharl.id;
            props.dispatch(removeSharlHatFunction(props,sharlID));

            const oldElementPos = items.map(function(x) {return x.id; }).indexOf(props.SharlReducer.selected_sharl.headgear.id)
            const oldHatItem = items[oldElementPos];
            oldHatItem.quantity_in_use = oldHatItem.quantity_in_use -1;
            props.dispatch(hideSharlItemsFunction());
    }
        const selectItemHandler = (e) => {
            let selectedItemId = e.currentTarget.id;
            const selectedItem = items.filter(item =>
             // eslint-disable-next-line
                item.id == selectedItemId);
            const selectedItemCat = selectedItem[0].category;
            const selectedItemImage = selectedItem[0].image;
            if (selectedItemCat === "headgear") {
                 setSelectedHat(selectedItem[0]);
            const hatURL = `https://www.sharl.info/${selectedItemImage}`;
            props.dispatch(updateSharlHatFunction(hatURL));
        }
    }

    return (
        <StyleSharlUpdateContainer>
            <StyleSharlUpdateBody>

                {filteredItems.map(item =>
                       <StyleSharlSackItem id={item.id} key={item.id} onClick={(e) => selectItemHandler(e)}>
                        <>
                        <img src={`https://www.sharl.info/${item.image}`} alt={item.name} />
                        <h1>  {item.name} </h1>
                        </>
                 <StyleSharlSackItemQuantity>
                        <p>{item.quantity - item.quantity_in_use} </p>
                    </StyleSharlSackItemQuantity>
                </StyleSharlSackItem>
)}

            </StyleSharlUpdateBody>
            <StyleSharlUpdateFooter>
                <StyleSharlRemoveButton onClick={() => removeHatHandler()}>Remove Hat</StyleSharlRemoveButton>
            </StyleSharlUpdateFooter>
            <StyleSharlUpdateFooter>
                <StyleSharlButton onClick={() => closeItemHandler()}>Close</StyleSharlButton>
                <StyleSharlButton onClick={() => saveHatHandler()}>Save</StyleSharlButton>
            </StyleSharlUpdateFooter>
        </StyleSharlUpdateContainer>
    )
}

const mapStateToProps = state => {
    return {
      ...state,
      };
    };

export default connect(mapStateToProps)(SharlItems);