function calculateBalanceFunction(props,updateType, amount)  {
        const oldBalance = props.UserReducer.user.balance;
        let newBalance = 0;

        if (updateType==="-") {
            newBalance = oldBalance - amount;
        } else if (updateType==="+") {
            newBalance = oldBalance + amount;
        }
    const balance = {"balance": newBalance};
        return balance;
}

export default calculateBalanceFunction;