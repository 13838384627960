import {getsinglesharl} from "../../types";

export const getSingleSharlAction = (selectedSharl,headgear) => ({
    type: getsinglesharl,
    payload: selectedSharl,
    headgear: headgear
});


const getSingleSharlFunction = (selectedSharl) => (dispatch) => {
    if (selectedSharl) {
        if (selectedSharl.headgear) {
            dispatch(getSingleSharlAction(selectedSharl, selectedSharl.headgear.image))
        }

    }

};


export default getSingleSharlFunction;