import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    StyleHelpTermsBody,
    StyleHelpTermsContainer,
    StyleHelpTermsContent,
    StyleHelpTermsItem
} from "../../../styles/help";
import updateHelpPolicyFunction from "../../../store/actions/Help/updateHelpPolicyAction";
import TermsContent from "./TermsContent";


function Terms(props) {
    const [selectedPolicy, setSelectedPolicy] = useState("");

    useEffect(()=>{
        const currentPolicy = props.HelpReducer.policy
        setSelectedPolicy(currentPolicy);
    },[props.HelpReducer])

    const selectPolicyHandler = (policy) => {
        props.dispatch(updateHelpPolicyFunction(policy))
    }

    const bgColorHandler = (policy) => {
        if (policy===selectedPolicy) {
            return "#336699"
        } else {
            return "white"
        }
    }

    const fontColorHandler = (policy) => {
        if (policy===selectedPolicy) {
            return "white"
        } else {
            return "black"
        }
    }
    return (
        <StyleHelpTermsContainer>
            <StyleHelpTermsContent>
                <TermsContent/>
            </StyleHelpTermsContent>
            <StyleHelpTermsBody>
                <StyleHelpTermsItem onClick={()=>selectPolicyHandler("privacy")} bgColor={bgColorHandler("privacy")} fontColor={fontColorHandler("privacy")}>
                    <p>Privacy Policy</p>
                </StyleHelpTermsItem>
                <StyleHelpTermsItem onClick={()=>selectPolicyHandler("terms")} bgColor={bgColorHandler("terms")} fontColor={fontColorHandler("terms")}>
                    <p>Terms and Conditions</p>
                </StyleHelpTermsItem>
                <StyleHelpTermsItem onClick={()=>selectPolicyHandler("cookies")} bgColor={bgColorHandler("cookies")} fontColor={fontColorHandler("cookies")}>
                    <p>Cookie Policy</p>
                </StyleHelpTermsItem>
                <StyleHelpTermsItem onClick={()=>selectPolicyHandler("eula")} bgColor={bgColorHandler("eula")} fontColor={fontColorHandler("eula")}>
                    <p>End User License Agreement</p>
                </StyleHelpTermsItem>
                <StyleHelpTermsItem onClick={()=>selectPolicyHandler("returns")} bgColor={bgColorHandler("returns")} fontColor={fontColorHandler("returns")}>
                    <p>Return Policy</p>
                </StyleHelpTermsItem>
           </StyleHelpTermsBody>
        </StyleHelpTermsContainer>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(Terms);
