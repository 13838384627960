import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import verifyTokenFunction from "../store/actions/Auth/verifyTokenAction";

export default function HOCSignIn(WrappedComponent) {
    function AuthComponent(props) {

        useEffect(() => {
            if (props.SignInReducer.token || localStorage.length > 0) {
                verifyTokenFunction(props);}
             // eslint-disable-next-line
        }, [props.SignInReducer.token]);

        return <WrappedComponent {...props}/>
    }
    
    function mapStateToProps(state) {
        return {
            ...state,
        }
    }

    return connect(mapStateToProps)(AuthComponent)
}; 