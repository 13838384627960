import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import PrivacyPolicy from "./PrivacyPolicy";
import TAndCPolicy from "./TAndCPolicy";
import CookiePolicy from "./CookiePolicy";
import EULAPolicy from "./EULA";
import ReturnPolicy from "./ReturnPolicy";


function TermsContent(props) {
    const [policy, setPolicy] = useState(props.HelpReducer.policy);

    useEffect(()=>{
        const policyStage = props.HelpReducer.policy;
        setPolicy(policyStage);
    },[props.HelpReducer])

    switch (policy) {
        case "privacy":
            return(
               <PrivacyPolicy/>
            )
        case "terms":
            return(
               <TAndCPolicy/>
            )
        case "cookies":
            return(
                <CookiePolicy/>
                )
        case "eula":
            return(
                <EULAPolicy/>
                    )
        case "returns":
            return(
                <ReturnPolicy/>
                    )
        default:
            return(
                <>
                </>
                )
        }}


const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(TermsContent);