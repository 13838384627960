import React from 'react';

import HelpBody from "./HelpBody";
import {AppStyleContainer, StyleBody} from "../../styles/app";
import Header from "../MainApp/Header";
import Footer from "../MainApp/Footer";

function Help() {
  return (
        <AppStyleContainer>
            <Header />
            <StyleBody>
                <HelpBody/>
             </StyleBody>
             <Footer />
        </AppStyleContainer>
  );
}

export default Help;;
