import {clearitem} from "../../types";

export const clearSelectedItemAction = () => (
    {
    type: clearitem,
});


const clearSelectedItemFunction = () => (dispatch) => {
    dispatch(clearSelectedItemAction())
}

export default clearSelectedItemFunction;