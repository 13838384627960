import React from 'react';
import {connect} from "react-redux";
import {
     StyleShopCheckoutBodyItem,
} from "../../styles/shop";

function ShopCheckoutItem(props) {

    const totalHandler = (price,quantity) => {
        return parseInt(price) * parseInt(quantity)
    }

    return (
        <StyleShopCheckoutBodyItem id={props.item.code} key={props.item.code}>
            <h1><img src={props.item.item.image} alt={props.item.item.name} />  {props.item.item.name}</h1>
            <h1>{props.item.quantity}</h1>
            <p>${totalHandler(props.item.item.cash_shop_price,props.item.quantity)}.00</p>
        </StyleShopCheckoutBodyItem>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(ShopCheckoutItem);
