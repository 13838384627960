import styled from "styled-components";


export const StyleShopContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to top, rgba(150, 255, 255, 0.7) 0%, rgba(51, 102, 153, 0.6) 100%), url('../images/backgrounds/seabg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
   
    `;

export const StyleShopHeader = styled.div`
    height: 15%;
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #336699;
    border-radius: 24px 24px 0px 0px;
    p {
    font-size: 2.5vw;
    color: white;
    }
    `;

export const StyleShopHeaderLeft = styled.div`
    height: 100%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px 24px 0px 0px;
    p{
    margin-left: 10%;
    }
    `;

export const StyleShopHeaderRight = styled.div`
    height: 100%;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 24px 0px 0px;
    h1{
        height: 70%;
        font-weight: 300;
        color: white;
        font-size: 1.1vw;
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content: flex-end;
    }
    
    `;

export const StyleShopBody = styled.div`
    height: 75%;
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 0px 0px 24px 24px; 
    `;


export const StyleShopBodyMenu = styled.div`
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 0px 24px; 
    `;

export const StyleShopBodyMenuItem = styled.div`
    height: 33.33%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: ${props => props.bTop || "none"};
    border-bottom: ${props => props.bBot || "none"};
    background-color: #336699;    
    
    img {
        height: 80%;
        width: auto;
    }
        
    :hover {
        background-color: #336699;
        opacity: 0.5;
        }
    `;

export const StyleShopBodyContent = styled.div`
    height: 100%;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    border-radius: 0px 0px 24px 0px; 
    border-left: 1px solid black;
    `;

export const StyleShopComplete = styled.div`
    height: 50%;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 2;
    font-size: 1.2vw;
    `;

export const StyleShopCartBody = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    `;

export const StyleShopCartBodyHeader = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2%;
    p{
    font-size: 1.5vw;
    }
    `;

export const StyleShopCartBodyFooter = styled.div`
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;
export const StyleShopCartBodyFooterTotal = styled.div`
    height: 30%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    p {
    font-size: 1.2vw;  
    }
    `;
export const StyleShopCartBodyFooterButtons = styled.div`
    height: 70%;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    `;

export const StyleShopCheckoutBackButton = styled.button`
    height: 70%;
    width: 30%;
    border: none;
    border-radius: 24px;
    background-color: #336699;
    color: white;
    font-size: 1vw;
    margin-left: 2%;
    `;

export const StyleShopCheckoutButton = styled.button`
    height: 80%;
    width: 20%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 2.5vh;
    border: none;
 `;

export const StyleShopCartBodyItems = styled.div`
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    border-top: 0.5px solid black;
    border-bottom: 0.5px solid black;
      `;

export const StyleShopCheckoutContainer = styled.div`
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
      `;

export const StyleShopCartBodyItem = styled.div`
    height: 20%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid black;
    
    img {
        width: 7%;
        height: 85%;
    }
    h1 {
    width: 20%;
    height: 100%;
    font-size: 1.0vw;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    `;

export const StyleShopCheckoutBodyItem = styled.div`
    height: 20%;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid black;
    img {
        width: 30%;
        height: 80%;
        margin-right: 2%;
    }
    h1 {
    width: 40%;
    height: 100%;
    font-size: 1.0vw;
    font-weight: 300;
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: flex-start
    margin-left: 1%;
    }
    `;

export const StyleShopItemQuantity = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    input{
    width: 20%;
    height: 60%;
    font-size: 1vw;
    text-align: center;
    }
    `;


export const StyleShopBodyContentItemHeader = styled.div`
    height: 15%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #336699;
    p {
    font-size: 2vw;
    font-weight: 700;
    
    }
    h1 {
    font-size: 1vw;
    font-weight: 300;
    }
    ;`

export const StyleShopBodyContentItem = styled.div`
    height: 85%;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
    font-size: 1.6vw;
    font-weight: bold;
    text-align: center;
    }
    h1 {
    font-size: 1.2vw;
    font-weight: 300;
    line-height: 2;
    }
    img {
    height: 30%;
    width: auto;
    padding-bottom: 4%;
    }
    `;


export const StyleShopBuyButton = styled.button`
    height: 10%;
    width: 70%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 2.5vh;
    border: none;
    margin-top: 10%;
 `;



export const StyleShopCheckoutItem = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;

export const StyleShopCheckoutItemHeader = styled.div`
    height: 10%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid black;
    `;

export const StyleShopCheckoutItemBodyContainer = styled.div`
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    `;

export const StyleShopCheckoutItemFooter = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid black;
    
    p{
        font-weight: 700;
        font-size: 1.4vw;
        margin-right: 1%;
        }

    `;


export const StyleShopCheckoutPayment = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    border-left: 1px solid black;   
    
    > div {
       min-width: 80%;
       max-height: 80%;
       overflow: auto; 
  }
    
    `;

