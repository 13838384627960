import React, {useState} from 'react';
import { connect } from 'react-redux'
import {StyleBodyButton, StyleBodyEmail, StyleBodyEmailItem, StyleBodyForm, DonutSpinner, StyleBodyInput} from "../../../styles/signup";
import {StyleBodyItem} from "../../../styles/app";
import {signInFunction} from "../../../store/actions/Auth/signInAction";
//import {Link} from "react-router-dom";
//import passwordResetClearFunction from "../../../store/actions/Auth/passwordResetClearAction";


function SignInForm(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')

/*
forgot password link
                <StyleBodyEmailItem>
                            <Link to="reset-password"><p onClick={(e) => pwResetInHandler(e)}>Forgot your password?</p></Link>
                    </StyleBodyEmailItem>
            </StyleBodyEmail>

 */
    const signInHandler = (e) => {
        e.preventDefault();
        const data = {
            email,
            password
        };

        props.dispatch(signInFunction(data,props))
    };

/*
    const pwResetInHandler = (e) => {
        props.dispatch(passwordResetClearFunction())
    };
*/
    const onKeyPress = (e) => {
        if(e.which === 13) {
          signInHandler(e);
        }
      }

      return (
                <>
                <StyleBodyItem>
                    <img src={require("../../../assets/images/misc/sharl_swim.png")} alt={"sharl"} />
                </StyleBodyItem>
                <StyleBodyItem>
                    <StyleBodyEmail>
                        <StyleBodyEmailItem>
                            <h2>Sign In</h2>
                        </StyleBodyEmailItem>
                        <StyleBodyEmailItem>
                        { props.PageReducer.SignUpLoading ?
                            <DonutSpinner></DonutSpinner> :
                        <StyleBodyForm
                            onSubmit={signInHandler}>
                            <StyleBodyInput
                            name='email'
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            value={email}
                            placeholder='Email'
                            type='text'
                        />
                        <StyleBodyInput
                            name='password'
                            onChange={(e) => setPassword(e.currentTarget.value)}
                            value={password}
                            placeholder='Password'
                            type='password'
                            onKeyPress={(e) => onKeyPress(e)}
                        />
                    </StyleBodyForm>
                    }
                    {props.SignInReducer.error? <p>{props.SignInReducer.error}</p> : ""}
                </StyleBodyEmailItem>
                <StyleBodyEmailItem>
                    <StyleBodyButton id="sign_in_button" onClick={signInHandler} onKeyPress={(e) => onKeyPress(e)}>
                        CONTINUE
                     </StyleBodyButton>
                </StyleBodyEmailItem>
                <StyleBodyEmailItem>


                    </StyleBodyEmailItem>
            </StyleBodyEmail>
        </StyleBodyItem>
        </>
)
}

const mapStateToProps = state => {
    return {
      ...state,
      };
    };

export default connect(mapStateToProps)(SignInForm);
