import React from 'react';
import {connect} from "react-redux";
import DropCanContainer from "./DropCanContainer";

function DropTank(props) {

    return (
       <DropCanContainer props={props} />
)
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(DropTank);
