import {notverified} from "../../types";


export const verifyTokenAction = () => ({
    type: notverified
});

const URL = `https://www.sharl.info/backend/api/auth/token/verify/`;

function verifyTokenFunction(props) {
    let token = localStorage.token;

    if (!token) {
        token = props.SignInReducer.token;
    }
    if (token) {
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        })

        const config = {
            method: 'POST',
            headers,
            body: JSON.stringify({"token": token})
        };
        fetch(URL, config).then(res => res.json())
            .then(data => {
                const content = data;
                if (content.code === "token_not_valid") {
                    localStorage.removeItem('token');
                    props.dispatch(verifyTokenAction())
                } else {
                    props.history.push('/town')
                }
            })
    } else {
        props.dispatch(verifyTokenAction())
    }
}
export default verifyTokenFunction;