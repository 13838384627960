import {chosensharl} from "../../types";

export const selectSharlAdoptAction = (data) => ({
    type: chosensharl,
    payload: data,
});


const selectSharlAdoptFunction = (data) => (dispatch) => {
    dispatch(selectSharlAdoptAction(data));
}

export default selectSharlAdoptFunction;