import React from 'react'
import {StyleInactiveSharl,
} from "../../../../styles/tank";


export const BlankTunaCan = () => {
  return (
        <StyleInactiveSharl>
        </StyleInactiveSharl>

  )
}
export default BlankTunaCan;