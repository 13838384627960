import React, {useEffect, useState} from 'react';
import {AppStyleContainer} from "../../styles/app";
import Footer from "../MainApp/Footer";
import {StyleHeader, StyleHeaderItemMid, StyleShareHeader} from "../../styles/header";
import {Link, useParams} from "react-router-dom";
import {StyleShareBody, StyleTankShareBackground, StyleTankShareSharlContainer} from "../../styles/tank";
import {
    StyleTankItemContainer,
    StyleTankTestContainer,
    StyleTankTestSharlBackground,
    StyleTankTestSharlHat
} from "../../styles/tanktest";


function ShareMySharls() {
  //in here do the action call and return the data
    const [tankInfo, setTankInfo] = useState()
    const [tankSharls, setTankSharls] = useState()
    const [errorMsg, setErrorMsg] = useState()

    const [tankItem1, setTankItem1] = useState()
    const [tankItem2, setTankItem2] = useState()
    const [tankItem3, setTankItem3] = useState()

    let { username } = useParams();


  useEffect(()=>{
      getTankHandler()
  //eslint-disable-next-line
  },[])


  useEffect(()=>{
      //in here set up the tank or qhatever

  },[tankSharls])


  const getTankHandler = () => {
      const URL = `https://www.sharl.info/backend/api/tank/${username}`;
      const headers = new Headers({
        'Content-Type': 'application/json'
    })
      const config = {
        method: 'GET',
        headers
    };   
     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        const userInfo = data;
        if (!userInfo.tank){
            setErrorMsg("user not found")
        } else {
            const tank = userInfo.tank
            setTankInfo(tank)
            setTankSharls(userInfo.sharls)
            if (tank.tank_item_1){
               setTankItem1(tank.tank_item_1)
            }
            if (tank.tank_item_2){
                setTankItem2(tank.tank_item_2)
            }
            if (tank.tank_item_3){
                setTankItem3(tank.tank_item_3)
            }
            return userInfo;
        }
    }); 
  }

    const tankBgHandler = () => {
        if (tankInfo.tank_level===1){
            return   "../../images/tank/tank_1.png"
        } else if (tankInfo.tank_level===2){
            return   "../../images/tank/tank_2.png"
        } else if (tankInfo.tank_level===3){
            return   "../../images/tank/tank_3.png"
        } else if (tankInfo.tank_level===4){
            return   "../../images/tank/tank_4.png"
        }
    }

    const topPosHandler = () => {
        const randomLeft = Math.floor(Math.random() * 80);
        return randomLeft + "%"
    }

    const swimSpeedHandler = () => {
        const swimSpeed = (Math.floor(Math.random() * 3000) + 600) /100;
        return swimSpeed + "s"
    }

    const posHandler = (index) => {
        if (index % 2 === 0) {
            return "10%";
        }
        else {
            return "-10%";
        }
    }
    const hatFlipHandler = (index) => {
        if (index % 2 === 0) {
            return "rotateY(0deg)";
        }
        else {
            return "rotateY(180deg)";
        }
    }


  return (
      <AppStyleContainer>
          <StyleHeader>
            <StyleShareHeader>
              <StyleHeaderItemMid>
                  <p>SHARL</p>
                  <Link to="/">
                    <img src={require("../../assets/images/misc/header_sharl.png")} alt={"sharl"}/>
                  </Link>
                  <p>INFO</p>
             </StyleHeaderItemMid>
             </StyleShareHeader>
          </StyleHeader>
          <StyleShareBody>
        <StyleTankTestContainer>
        {tankSharls ?
          <StyleTankShareBackground bgImage={tankBgHandler()}>
              {tankSharls.map((sharl, index) =>
                <StyleTankShareSharlContainer key={index} topPos={topPosHandler()} swimSpeed={swimSpeedHandler()}>
                    <StyleTankTestSharlBackground>
                    <StyleTankTestSharlHat pos={posHandler(index)} hatFlip={hatFlipHandler(index)}>
                        {sharl.headgear ?
                            <img src={"https://www.sharl.info/" + sharl.headgear.image} alt={sharl.headgear.name}/>
                            : ""
                        }
                    </StyleTankTestSharlHat>
                </StyleTankTestSharlBackground>
                </StyleTankShareSharlContainer>
                 )}
               <>
                  {tankItem1 ?
                      <StyleTankItemContainer left={"5%"} bottom={"1%"} zIndex={30}>
                         <img src={tankItem1.image} alt={tankItem1.name}/>
                      </StyleTankItemContainer>
                  :""}
                  {tankItem2 ?
                      <StyleTankItemContainer left={"80%"} bottom={"1%"} zIndex={30}>
                         <img src={tankItem2.image} alt={tankItem2.name}/>
                      </StyleTankItemContainer>
                  :""}
                  {tankItem3 ?
                      <StyleTankItemContainer left={"43%"} bottom={"20%"} zIndex={10}>
                         <img src={tankItem3.image} alt={tankItem2.name}/>
                      </StyleTankItemContainer>
                  :""}
              </>
        </StyleTankShareBackground>
            : ""}
        </StyleTankTestContainer>

              <div>
                  {errorMsg ?
                  <p>{errorMsg}</p> : ""
                  }
              </div>
          </StyleShareBody>
          <Footer />
      </AppStyleContainer>
  );
}

export default ShareMySharls;
