import React from 'react';
import {connect} from "react-redux";
import Tank from './Tank';

function DragTank(props) {

    return (
       <Tank props={props} />
)
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(DragTank);
