import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    StyleShopBody,
    StyleShopBodyMenu,
    StyleShopBodyMenuItem,
    StyleShopContainer,
    StyleShopHeader, StyleShopHeaderLeft, StyleShopHeaderRight,
} from "../../styles/shop";
import ShopBodyContent from "./ShopBodyContent";
import updateShopStageFunction from "../../store/actions/Shop/updateShopStageAction";
import getShopFunction from "../../store/actions/CashShop/getShopAction";

function ShopBody(props) {
    const [cart, setCart] = useState([])

    const shopStageHandler = (stage) => {
        props.dispatch(updateShopStageFunction(stage))
    }

    const cartHandler = () => {
        props.dispatch(updateShopStageFunction("cart"))
    }

    useEffect(()=>{
        props.dispatch(getShopFunction(props))
        props.dispatch(updateShopStageFunction("home"))
    //eslint-disable-next-line
    },[])

    useEffect(()=>{
        const newCart = props.ShopReducer.cart
        if (newCart) {
            setCart(newCart)
        }
    },[props.ShopReducer.cart])

    return (
                <StyleShopContainer>
                    <StyleShopHeader>
                        <StyleShopHeaderLeft>
                            <p>Cash Shop</p>
                        </StyleShopHeaderLeft>
                        <StyleShopHeaderRight onClick={()=>cartHandler()}>
                            <p>Cart</p>
                            {cart.length > 0 ?
                                <h1>{cart.length}</h1>
                            :""}
                        </StyleShopHeaderRight>
                    </StyleShopHeader>
                    <StyleShopBody>
                        <StyleShopBodyMenu>
                            <StyleShopBodyMenuItem  bTop={"1px solid white"} bBot={"1px solid white"} onClick={()=>shopStageHandler("tokens")}>
                                <img src={require("../../assets/images/misc/wharl_token.png")} alt={"Tokens"} />
                            </StyleShopBodyMenuItem>
                            <StyleShopBodyMenuItem bTop={"none"} bBot={"none"} onClick={()=>shopStageHandler("hats")}>
                                <img src={require("../../assets/images/shop/hat.png")} alt={"Hats"} />
                            </StyleShopBodyMenuItem>
                            <StyleShopBodyMenuItem bTop={"1px solid white"} bBot={"none"} onClick={()=>shopStageHandler("coins")}>
                                <img src={require("../../assets/images/studio/heart_bg.png")} alt={"Backgrounds"} />
                            </StyleShopBodyMenuItem>
                        </StyleShopBodyMenu>
                        <ShopBodyContent/>
                    </StyleShopBody>
                 </StyleShopContainer>

    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(ShopBody);
