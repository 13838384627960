import {investprize} from "../../types";
import {updateBalanceAction} from "../MainApp/updateBalanceAction";

export const confirmSharlInvestAction = (data) => ({
    type: investprize,
    payload: data,
});

const URL = `https://www.sharl.info/backend/api/invest/update/`;

const confirmSharlInvestFunction = (props,sharl_number) => (dispatch) => {
    const token = props.SignInReducer.token
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'PATCH',
        body: JSON.stringify(sharl_number),
        headers
    };
 
     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        const currentUser = props.UserReducer.user
        const newBalance = data.balance
        currentUser.balance = newBalance
        props.dispatch(updateBalanceAction(currentUser))
        //make new object with parts from data
        const sharl_number = data.sharl_number
        const prize = data.prize
        const success = data.success
        const concatData = {"sharl_number": sharl_number, "prize": prize, "success": success}
        dispatch(confirmSharlInvestAction(concatData));
    })
};
export default confirmSharlInvestFunction;