import React, {useEffect, useState} from 'react';
import {StyleBody} from "../../../styles/app";
import PasswordEmail from "./PasswordEmail";
import PasswordConfirmEmail from "./PasswordConfirmEmail";

import PasswordFullForm from "./PasswordFullForm";
import PasswordFinal from "./PasswordFinal";
import {connect} from "react-redux";


function PasswordResetBody(props) {
      const [PasswordResetStage, setPasswordResetStage] = useState(1);

      useEffect(() => {
          if (props.PasswordResetReducer) {
            setPasswordResetStage(props.PasswordResetReducer.stage);
            }
          },[props.PasswordResetReducer]);

      if (PasswordResetStage === 1) {
        return (
          <StyleBody>
            <PasswordEmail/>
          </StyleBody>
      );
    } else if (PasswordResetStage === 2) {
        return (
          <StyleBody>
            <PasswordConfirmEmail/>
          </StyleBody>
      );
    } else if (PasswordResetStage === 3) {
        return (
          <StyleBody >
            <PasswordFullForm/>
          </StyleBody>
      );
    } else if (PasswordResetStage === 4) {
        return (
          <StyleBody>
            <PasswordFinal/>
          </StyleBody>
      );
    } else {
      return (
        <StyleBody>
          <PasswordEmail/>
        </StyleBody>
      );
    }
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(PasswordResetBody);