import {dragtunacan} from "../../types";

export const selectTunaCanAction = (data) => ({
    type: dragtunacan,
    payload: data,
});


const selectTunaCanFunction = (data) => (dispatch) => {
    dispatch(selectTunaCanAction(data));
};

export default selectTunaCanFunction;;