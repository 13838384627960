import {passwordreset, signuploaded, signuploading} from "../../types";

export const passwordResetAction = email => ({
    type: passwordreset,
    payload: email
});

export const signUpLoadingAction = () => ({
    type: signuploading,
});

export const signUpLoadedAction = () => ({
    type: signuploaded,
});

const URL = "https://www.sharl.info/backend/api/auth/password-reset/";

export const passwordResetFunction = (data) => (dispatch) => {

    dispatch(signUpLoadingAction())

    const headers = new Headers({
        'Content-Type': 'application/json'
    });

    const config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers,
    };

    const email = data.email;
     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        dispatch(passwordResetAction(email));
        dispatch(signUpLoadedAction())
    });
};