import React from 'react';
import StudioBody from "./StudioBody";

function Studio() {
  return (
        <StudioBody/>
  );
}

export default Studio;
