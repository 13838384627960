function generateSharls() {
    const sharl_choices = []
    for (let i=0; i < 4; i++) {
        let genus = "";
        let sharl_avatar = "";
        let roundness = 0;
        let sleepyness = 0;
        let cheekyness = 0;
        let pancakeness = 0;
        let rarity = "";
        let color = "";

        const genus_num = Math.floor(Math.random() * 100);

        if (genus_num <20) {
            genus = "Round Sharl"
            sharl_avatar = "/images/sharls/round_sharl.png"

            roundness = Math.floor(Math.random()  * (6 - 4) + 4);
            sleepyness = Math.floor(Math.random() * (4 - 1) + 1);
            cheekyness = Math.floor(Math.random() * (5 - 1) + 1);
            pancakeness = Math.floor(Math.random() * (3 - 1) + 1);

        } else if (genus_num >=20 && genus_num <50) {
            genus = "Sleepy Sharl"
            sharl_avatar = "/images/sharls/sleepy_sharl.png"

            roundness = Math.floor(Math.random()  * (4 - 1) + 1);
            sleepyness = Math.floor(Math.random() * (6 - 4) + 4);
            cheekyness = Math.floor(Math.random() * (5 - 1) + 1);
            pancakeness = Math.floor(Math.random() * (3 - 1) + 1);
        } else if (genus_num >=50 && genus_num <90) {
            genus = "Cheeky Sharl"
            sharl_avatar = "/images/sharls/cheeky_sharl.png"

            roundness = Math.floor(Math.random()  * (4 - 1) + 1);
            sleepyness = Math.floor(Math.random() * (4 - 1) + 1);
            cheekyness = Math.floor(Math.random() * (6 - 4) + 4);
            pancakeness = Math.floor(Math.random() * (3 - 1) + 1);
        } else if (genus_num >=90) {
            genus = "Pancake Sharl"
            sharl_avatar = "/images/sharls/pancake_sharl.png"

            roundness = Math.floor(Math.random()  * (3 - 1) + 1);
            sleepyness = Math.floor(Math.random() * (3 - 1) + 1);
            cheekyness = Math.floor(Math.random() * (5 - 1) + 1);
            pancakeness = Math.floor(Math.random() * (6 - 3) + 3);
        }  else {
            genus = "Cheeky Sharl"
            sharl_avatar = "/images/sharls/cheeky_sharl.png"
            roundness = Math.floor(Math.random()  * (4 - 1) + 1);
            sleepyness = Math.floor(Math.random() * (4 - 1) + 1);
            cheekyness = Math.floor(Math.random() * (6 - 4) + 4);
            pancakeness = Math.floor(Math.random() * (3 - 1) + 1);
        }
        const totalPoints = roundness + sleepyness + cheekyness + pancakeness;
        if (genus==="Pancake Sharl") {
            if (totalPoints<=11) {
                rarity = "common";
                color = "#79b4ed";
            } else if (totalPoints===12) {
                rarity = "rare";
                color = "#f5d442";
            } else if (totalPoints===13) {
                rarity = "legendary";
                color = "#b48ce6";
            }
        } else {
            if (totalPoints<=12) {
                rarity = "common";
                color = "#79b4ed";
            } else if (totalPoints===13) {
                rarity = "rare";
                color = "#f5d442";
            } else if (totalPoints===14) {
                rarity = "legendary";
                color = "#b48ce6";
            }
        }
        sharl_choices.push({"id": i, "genus": genus, "sharl_avatar": sharl_avatar, "rarity": rarity, "color": color, "roundness": roundness, "sleepyness": sleepyness, "cheekyness": cheekyness, "pancakeness": pancakeness})
        }
        return(sharl_choices);
}
export default generateSharls;