import {passwordupdate} from "../../types";

export const passwordUpdateAction = email => ({
    type: passwordupdate,
    payload: email
});

export const passwordUpdateFunction = (props,userData) => (dispatch) => {
    const userID = props.UserReducer.user.id
    const URL = `https://www.sharl.info/backend/api/auth/password-update/${userID}/`;

    const token = props.SignInReducer.token
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'PATCH',
        body: JSON.stringify(userData),
        headers
    };

     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        dispatch(passwordUpdateAction(data));
    })
};

export default passwordUpdateFunction;