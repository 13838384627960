
import React from 'react';

import SharlTankBody from "./TankBody";

function Tank() {
  return (
        <SharlTankBody/>
  );
}

export default Tank;;
