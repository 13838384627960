import React from 'react';
import {AppStyleContainer, } from "../../styles/app";
import Footer from "../MainApp/Footer";
import {StyleHeader, StyleHeaderItemMid, StyleShareHeader} from "../../styles/header";
import {Link} from "react-router-dom";
import {StyleShareBody} from "../../styles/tank";


function ShareMySharlsHome() {


  return (
      <AppStyleContainer>
          <StyleHeader>
            <StyleShareHeader>
              <StyleHeaderItemMid>
                  <p>SHARL</p>
                  <Link to="/">
                    <img src={require("../../assets/images/misc/header_sharl.png")} alt={"sharl"}/>
                  </Link>
                  <p>INFO</p>
             </StyleHeaderItemMid>
             </StyleShareHeader>
          </StyleHeader>
          <StyleShareBody>
              <div>
              <p>Share Yo Sharls</p>
              </div>
              <div>
               <p>Landing Page</p>
              </div>
          </StyleShareBody>
          <Footer />
      </AppStyleContainer>
  );
}

export default ShareMySharlsHome;
