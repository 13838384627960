import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux'
import {
    StyleGamesPrizeButton,
    StylePrizeContainer,
    StylePrizeItem, StylePrizeItemStartLeft
} from "../../../styles/games";
import getPrizeFunction from "../../../store/actions/Games/getPrizeAction";
import WheelBody from "./WheelBody"

function SharlPrizeStart(props) {
    const [showPrizeButton, setShowPrizeButton] = useState(props.GameReducer.showbutton)

    const prizeStageHandler = (e) => {
        props.dispatch(getPrizeFunction(props));
    }

    useEffect(() => {
        setShowPrizeButton(props.GameReducer.showbutton);
        // eslint-disable-next-line
        }, [props.GameReducer.showbutton]);

    return (
        <StylePrizeContainer>
           <StylePrizeItemStartLeft>
                <img src={require("../../../assets/images/sharls/pancake_sharl.png")} alt={"sharl"}/>
                <h2>Welcome to the Wheel of Sharls</h2>
                <p>Who will be Top Sharl today?</p>
            </StylePrizeItemStartLeft>
            <StylePrizeItem>
                <WheelBody/>
                    { showPrizeButton ?  <StyleGamesPrizeButton onClick={(e) => prizeStageHandler(e)}>Claim Prize</StyleGamesPrizeButton> : ""}
            </StylePrizeItem>
        </StylePrizeContainer>
        )
}

const mapStateToProps = state => {
    return {
      ...state,
      };
    };

export default connect(mapStateToProps)(SharlPrizeStart);
