import {gottoadoptsharls, gottoadopttoken} from "../../types";

export const goToAdoptSharlsAction = () => ({
    type: gottoadoptsharls,
});

export const goToAdoptTokenAction = () => ({
    type: gottoadopttoken,
});

export const goToAdoptSharlsFunction = (adoptToken) => (dispatch) => {
    if (adoptToken==="adopt"){
        dispatch(goToAdoptSharlsAction());
    } else {
        dispatch(goToAdoptTokenAction());
    }

};