import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";
import {
    StyleProfileContainer,
    StyleProfileItem,
} from "../../styles/profile";

import {
    StyleAdoptButton, StyleAdoptFullButton,
    StyleAdoptItemButtons,
    StyleAdoptItemMain,
    StyleAdoptItemMainBox, StyleAdoptItemText
} from "../../styles/adopt";
import {goToAdoptSharlsFunction} from "../../store/actions/Adopt/goToAdoptSharlsAction";
import updateBalanceFunction from "../../store/actions/MainApp/updateBalanceAction";
import calculateBalanceFunction from "../MainApp/updateBalance";
import {Link} from "react-router-dom";

function AdoptStart(props) {
    const [numSharls, setNumSharl] = useState()
    const [userBalance,setUserBalance] = useState(props.UserReducer.user.balance);


    useEffect(() => {
        const sharls = props.UserReducer.sharls.filter(sharl =>
        sharl.is_active === true)
        setNumSharl(sharls.length);
        const newBalance = props.UserReducer.user.balance;
        setUserBalance(newBalance);
        // eslint-disable-next-line
        },[props.UserReducer]);

    const adoptStageHandler = (e) => {
        props.dispatch(goToAdoptSharlsFunction("adopt"));
        const balance = calculateBalanceFunction(props,"-",50)
        props.dispatch(updateBalanceFunction(props,balance));
    }

    const tokenStageHandler = (e) => {
        props.dispatch(goToAdoptSharlsFunction("token"));
    }

    return (
            <>
                <StyleProfileItem>
                    <StyleProfileContainer>
                    <StyleAdoptItemMain>
                    <StyleAdoptItemMainBox>
                    {numSharls >= 4 || userBalance <= 50 ?
                    <StyleAdoptItemText>
                    <h2>Need to fix this</h2>
                    <h2>Sorry you're at sharlpacity..</h2>
    '               <h1>You'll have to move a Sharl to the tank before adopting a new one</h1>
                    <StyleAdoptFullButton><Link to="/tank/sharls">Edit Sharls</Link></StyleAdoptFullButton>
                    </StyleAdoptItemText>
                    :
                    <>
                    <h2>Welcome to the Shelter! </h2>
                    <h1>Ready for a new Sharl?</h1>
                    <StyleAdoptItemButtons>
                    <StyleAdoptButton onClick={(e) => adoptStageHandler(e)}>Adopt New Sharl 50<img src={require("../../assets/images/misc/shadollar.png")} alt={"shadollar"} /></StyleAdoptButton>
                    <StyleAdoptButton onClick={(e) => tokenStageHandler(e)}>Redeem Token</StyleAdoptButton>
                    </StyleAdoptItemButtons>
                    </>
                    }
                    </StyleAdoptItemMainBox>
                </StyleAdoptItemMain>
               </StyleProfileContainer>
                </StyleProfileItem>
            </>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(AdoptStart);
