import {
    clearmuseumstage,
    getmuseum, selectbook,

} from "../types";

const initialState = {
    museumStage: "home",
    research: [],
  };

const MuseumReducer = (state = initialState, action) => {
  switch (action.type) {
      case getmuseum:
            return {
            ...state,
            research: action.payload,
            museumStage: "home",
        }
        case selectbook:
            return {
             ...state,
            museumStage: action.payload,
        }
      case clearmuseumstage:
          return {
              ...state,
              museumStage: "home",
          }
        default:
            return {
                ...state,
            };
  }
}

export default MuseumReducer;