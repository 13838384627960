import React, {useState} from 'react';
import {connect} from "react-redux";
import {
    StyleTankShareContainer, StyleTankShareCopy, StyleTankShareInput,
    StyleTankTestButtonContainer,
    StyleTankTestContainerButtons,
} from "../../../styles/tanktest";
import {Link} from "react-router-dom";
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  ViberShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
} from "react-share";


function TankHomeButtons(props) {
    const [showShare, setShowShare] = useState(false)

    const username = props.UserReducer.user.username
    const shareUrl = `https://www.sharl.info/sharemysharls/${username}`;

    const title = 'See My Sharls';

       const sMSHandler = (e) => {
        e.preventDefault()
        setShowShare(!showShare)
    }

    const copyToClipboard = () => {
        const text = `https://www.sharl.info/sharemysharls/${username}`;
        const ta = document.createElement("textarea");
        ta.innerText = text;
        document.body.appendChild(ta);
        ta.select();
        document.execCommand("copy");
        ta.remove();
    };

    return(
          <StyleTankTestButtonContainer>
              <StyleTankTestContainerButtons><Link to="/tank/feed">Feed Sharls</Link></StyleTankTestContainerButtons>
              <StyleTankTestContainerButtons><Link to="/tank/sharls">Edit Active Sharls</Link></StyleTankTestContainerButtons>
              <StyleTankTestContainerButtons><Link to="/tank/edit">Edit Tank</Link></StyleTankTestContainerButtons>
              <StyleTankTestContainerButtons onClick={(e)=>sMSHandler(e)}>Share My Sharls</StyleTankTestContainerButtons>
              {showShare ?
                <StyleTankShareContainer>
                    <StyleTankShareInput
                        defaultValue={`https://www.sharl.info/ShareMySharls/${username}`}
                    />
                    <StyleTankShareCopy onClick={()=>copyToClipboard()}>
                         <img src={require("../../../assets/images/tank/copy.png")} alt={"copy"}/>
                    </StyleTankShareCopy>
                    <FacebookShareButton
                        url={shareUrl}
                        quote={title}
                    >
                        <FacebookIcon size={44} round />
                    </FacebookShareButton>
                    <FacebookMessengerShareButton
                        url={shareUrl}
                        appId="521270401588372"
                    >
                        <FacebookMessengerIcon size={44} round />
                    </FacebookMessengerShareButton>
                    <TwitterShareButton
                       url={shareUrl}
                        title={title}
                        >
                        <TwitterIcon size={44} round />
                    </TwitterShareButton>
                    <TelegramShareButton
                        url={shareUrl}
                        title={title}
                      >
                        <TelegramIcon size={44} round />
                    </TelegramShareButton>
                    <WhatsappShareButton
                        url={shareUrl}
                        title={title}
                        separator=":: "
                        >
                        <WhatsappIcon size={44} round />
                    </WhatsappShareButton>
                     <RedditShareButton
                         url={shareUrl}
                        title={title}
                        windowWidth={660}
                        windowHeight={460}
                        >
                        <RedditIcon size={44} round />
                    </RedditShareButton>
                    <TumblrShareButton
                        url={shareUrl}
                        title={title}
                        >
                        <TumblrIcon size={44} round />
                    </TumblrShareButton>
                    <LivejournalShareButton
                        url={shareUrl}
                        title={title}
                        description={shareUrl}
                        >
                        <LivejournalIcon size={44} round />
                    </LivejournalShareButton>
                    <EmailShareButton
                        url={shareUrl}
                        subject={title}
                        body="body"
                          >
                        <EmailIcon size={44} round />
                    </EmailShareButton>
                    <ViberShareButton
                        url={shareUrl}
                        title={title}
                        >
                        <ViberIcon size={44} round />
                     </ViberShareButton>
              </StyleTankShareContainer>
                  :""}
          </StyleTankTestButtonContainer>
)
}

const mapStateToProps = state => {
  return {

      ...state,
    };
  };

export default connect(mapStateToProps)(TankHomeButtons);
