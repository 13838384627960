 	import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    StyleTankItemContainer,
    StyleTankTestBackground,
    StyleTankTestContainer,
    StyleTankTestSharlBackground, StyleTankTestSharlContainer,
    StyleTankTestSharlHat
} from "../../../styles/tanktest";
import * as PropTypes from "prop-types";
import TankHomeButtons from "./TankHomeButtons";


StyleTankItemContainer.propTypes = {children: PropTypes.node};

function TankHomeBody(props) {
    const [sharls,setSharls] = useState()
    const [tankItem1, setTankItem1] = useState()
    const [tankItem2, setTankItem2] = useState()
    const [tankItem3, setTankItem3] = useState()

    useEffect(()=>{
        const allSharls = props.UserReducer.sharls;
        const filteredSharls = allSharls.filter(sharl =>
        sharl.is_active === false);
        setSharls(filteredSharls);
        const tank = props.UserReducer.user.tank;

        if (tank){
            const tankLevel = tank.tank_level
            if (tank.tank_item_1 && tankLevel > 1){
               setTankItem1(tank.tank_item_1)
            }
            if (tank.tank_item_2 && tankLevel > 2){
                setTankItem2(tank.tank_item_2)
            }
            if (tank.tank_item_3 && tankLevel > 3){
                setTankItem3(tank.tank_item_3)
            }
        }
    },[props.UserReducer])


    const topPosHandler = () => {
        const randomLeft = Math.floor(Math.random() * 80);
        return randomLeft + "%"
    }

    const swimSpeedHandler = () => {
        const swimSpeed = (Math.floor(Math.random() * 3000) + 600) /100;
        return swimSpeed + "s"
    }

    const posHandler = (index) => {
        if (index % 2 === 0) {
            return "10%";
        }
        else {
            return "-10%";
        }
    }
    const hatFlipHandler = (index) => {
        if (index % 2 === 0) {
            return "rotateY(0deg)";
        }
        else {
            return "rotateY(180deg)";
        }
    }

    const tankBGHandler = () => {
        const userTank = props.UserReducer.user.tank
        if (userTank){
            if (userTank.tank_level===1){
                return   "../../images/tank/tank_1.png"
            } else if (userTank.tank_level===2){
                return   "../../images/tank/tank_2.png"
            } else if (userTank.tank_level===3){
                return   "../../images/tank/tank_3.png"
            } else if (userTank.tank_level===4){
                return   "../../images/tank/tank_4.png"
            }
        }
    }

    return (
      <StyleTankTestContainer>
        {sharls ?
          <StyleTankTestBackground bgImage={tankBGHandler()}>
              {sharls.map((sharl, index) =>
                <StyleTankTestSharlContainer key={index} topPos={topPosHandler()} swimSpeed={swimSpeedHandler()} bgImage={sharl.sharl_avatar}>
                    <StyleTankTestSharlBackground>
                    {sharl.headgear ?
                        <StyleTankTestSharlHat pos={posHandler(index)} hatFlip={hatFlipHandler(index)}>
                            {sharl.headgear.image.includes("https") ?
                                <img src={sharl.headgear.image} alt={sharl.headgear.name}/>
                                :
                                <img src={"https://www.sharl.info/" + sharl.headgear.image} alt={sharl.headgear.name}/>
                            }
                        </StyleTankTestSharlHat>
                            : ""
                        }
                </StyleTankTestSharlBackground>
                </StyleTankTestSharlContainer>
                 )}
              <>
                  {tankItem1 ?
                      <StyleTankItemContainer left={"5%"} bottom={"1%"} zIndex={30}>
                         <img src={tankItem1.image} alt={tankItem1.name}/>
                      </StyleTankItemContainer>
                  :""}
                  {tankItem2 ?
                      <StyleTankItemContainer left={"80%"} bottom={"1%"} zIndex={30}>
                         <img src={tankItem2.image} alt={tankItem2.name}/>
                      </StyleTankItemContainer>
                  :""}
                  {tankItem3 ?
                      <StyleTankItemContainer left={"43%"} bottom={"20%"}>
                         <img src={tankItem3.image} alt={tankItem3.name}/>
                      </StyleTankItemContainer>
                  :""}
              </>
        </StyleTankTestBackground>
            : ""}

          <TankHomeButtons/>
      </StyleTankTestContainer>
                )

}

const mapStateToProps = state => {
  return {

      ...state,
    };
  };

export default connect(mapStateToProps)(TankHomeBody);
