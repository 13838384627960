import React from 'react';
import TownBody from "./TownBody";


function Town() {
  return (
        <TownBody/>
  );
}

export default Town;
