import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    StyleProfileButton,
    StyleProfileContainer, StyleProfileDeactivateButton,
    StyleProfileItem, StyleProfilePassButton, StyleUserPasswordForm, StyleUserPasswordInput, StyleUserProfileImg,
    StyleUserProfileText
} from "../../styles/profile";
import {Link} from "react-router-dom";
import passwordUpdateFunction from "../../store/actions/Auth/passwordUpdateAction";

function ProfileBody(props) {
    const [panel, setPanel] = useState("buttons");
    const [deactivatePanel, setDeactivatePanel] = useState(0);
    const [errorText, setErrorText] = useState();
    const [oldPw, setOldPw] = useState("")
    const [newPw, setNewPw] = useState("")
    const [confirmPw, setConfirmPw] = useState("")

    const panelHandler = (newPanel) => {
        setPanel(newPanel);
        setErrorText("");
    }

    useEffect(()=>{
        const msg = props.PasswordResetReducer.pw_update_msg;
        if (msg) {
            setErrorText(msg)
        }
    },[props.PasswordResetReducer])

    const submitHandler = (e) => {
        e.preventDefault()
        if (newPw==="" || oldPw==="" || confirmPw===""){
            setErrorText("Field cannot be blank")
        } else if (newPw !== confirmPw) {
            setErrorText("New passwords do not match")
        } else if (oldPw===newPw) {
            setErrorText("New password must be different from old")
        } else if (newPw.length < 10) {
            setErrorText("New password must be at least 10 characters")
        } else if (newPw.includes(" ")){
            setErrorText("Password cannot include blank spaces")
        } else {
            //otherwise send off request
            const userData = {"password": newPw, "old_password": oldPw}
            props.dispatch(passwordUpdateFunction(props, userData));
        }
    }

    return (
        <StyleProfileItem>
            <StyleProfileContainer>
                <StyleUserProfileText pad={"10%"} bgColor={"#336699"} fontColor={"white"}>
                    <p>Username: {props.UserReducer.user.username}</p>
                    <p>Email: {props.UserReducer.user.email}</p>
                    <StyleUserProfileImg>
                        <img src={require("../../assets/images/misc/shadollar.png")} alt={"shadollar"}/>
                        <p>{props.UserReducer.user.balance}</p>
                    </StyleUserProfileImg>
                    <StyleUserProfileImg>
                        <img src={require("../../assets/images/games/trash.png")} alt={"trash"}/>
                        <p>{props.UserReducer.user.trash_balance}</p>
                    </StyleUserProfileImg>
                </StyleUserProfileText>
                {panel === "buttons" ?
                <StyleUserProfileText>
                    <StyleProfileButton onClick={()=>panelHandler("pw")}>Change Password</StyleProfileButton>
                    <StyleProfileButton onClick={()=>panelHandler("delete")}>Deactivate Account</StyleProfileButton>
                </StyleUserProfileText>
                    :
                <StyleUserProfileText height={"90%"} justify={"space-around"}>
                    {panel === "pw" ?
                        <>
                            <p><b>Update Your Password</b></p>
                             <StyleUserPasswordForm>
                                 <StyleUserPasswordInput
                                     value={oldPw}
                                     onChange={(e) => setOldPw(e.currentTarget.value)}
                                     name={"Current Password"}
                                     type={"password"}
                                     placeholder={"Current Password"}/>
                                 <StyleUserPasswordInput
                                     value={newPw}
                                     onChange={(e) => setNewPw(e.currentTarget.value)}
                                     name={"New Password"}
                                     type={"password"}
                                     placeholder={"New Password"}/>
                                 <StyleUserPasswordInput
                                     value={confirmPw}
                                     onChange={(e) => setConfirmPw(e.currentTarget.value)}
                                     name={"Confirm New Password"}
                                     type={"password"}
                                     placeholder={"Confirm New Password"}/>
                                 <StyleProfilePassButton onClick={(e)=>submitHandler(e)}>Submit</StyleProfilePassButton>
                             </StyleUserPasswordForm>
                            <p>{errorText}</p>
                            <StyleProfileButton onClick={()=>panelHandler("buttons")}>Close</StyleProfileButton>
                        </>
                        :
                        <>
                            <p><b>Deactivate Your Account</b></p>
                            { deactivatePanel===0 ?
                                <StyleProfileDeactivateButton onClick={()=>setDeactivatePanel(1)}>I want to deactivate my account</StyleProfileDeactivateButton>
                                :""}
                            { deactivatePanel===1 ?
                                <StyleProfileDeactivateButton onClick={()=>setDeactivatePanel(2)}>Seriously</StyleProfileDeactivateButton>
                                :""}
                            { deactivatePanel===2 ?
                                <StyleProfileDeactivateButton onClick={()=>setDeactivatePanel(3)}>For real though</StyleProfileDeactivateButton>
                                :""}
                            { deactivatePanel===3 ?
                                <StyleProfileDeactivateButton onClick={()=>setDeactivatePanel(4)}>This isn't funny..</StyleProfileDeactivateButton>
                                :""}
                            { deactivatePanel===4 ?
                                <StyleProfileDeactivateButton buttonColor={"red"} onClick={()=>setDeactivatePanel(5)}>Confirm Deactivation</StyleProfileDeactivateButton>
                                :"" }
                            { deactivatePanel===5 ?
                                <>
                                    <p>We're sorry to see you go!</p>
                                    <p>Your account has been deactivated.</p>
                                    <p>To reactivate your account log back in.</p>
                                    <p>To completely delete your account please email info@sharl.info or <Link to={"/help"}>contact us</Link>.</p>
                                </> :"" }
                            <StyleProfileButton onClick={()=>panelHandler("buttons")}>Close</StyleProfileButton>
                        </>
                    }
                </StyleUserProfileText>
                    }
            </StyleProfileContainer>
        </StyleProfileItem>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(ProfileBody);
