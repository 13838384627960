const questions = [
    {"id":1,"question":"Fish are friends or food?"},
    {"id":2,"question":"Who is Top Sharl?"},
    {"id":3,"question":"What kind of music do you like to listen to?"},
    {"id":4,"question":"What is your most treasured item?"},
    {"id":5,"question":"What is your favourite movie genre?"},
    {"id":6,"question":"What is your favourite snack?"},
    {"id":7,"question":"How do you feel about the Shrimp Market?"},
    {"id":8,"question":"Do you enjoy travelling?"},
    {"id":9,"question":"What is your favourite dance?"},
    {"id":10,"question":"How old are you?"},
    {"id":11,"question":"Your hobbies include.."},
    {"id":12,"question":"Do you consider yourself a strong swimmer?"},
    {"id":13,"question":"Do you ever sleep?"},
    {"id":14,"question":"Do you have bones?"},
    {"id":15,"question":"True or False, Sharls are apex predators"}
]
const sharlAnswers = [
    {"id":1,"sharl":"Barl","q1":"Friends","q2":"I'm Top Sharl","q3":"Everything!","q4":"A rare token I found at sea","q5":"Indie","q6":"Sweet","q7":"It's not fair to the shrimpies","q8":"Yes!","q9":"The hula","q10":"200 years","q11":"Travelling","q12":"Yes","q13":"For many months at a time","q14":"Umm.. yeah, totally","q15":"True"},
    {"id":2,"sharl":"Narl","q1":"Food","q2":"I didn't check the wheel today","q3":"Everything!","q4":"My fishing gear","q5":"Action","q6":"Fish","q7":"It can be a solid investment","q8":"Yes!","q9":"The hula","q10":"20 years","q11":"Fishing","q12":"Yes","q13":"Yes","q14":"Of course!","q15":"False"},
    {"id":3,"sharl":"Wharl","q1":"Food","q2":"This again?","q3":"Everything!","q4":"My home","q5":"Romance","q6":"Fish","q7":"Tasty","q8":"Yes!","q9":"The hula","q10":"15 years","q11":"Playing pranks","q12":"Yes","q13":"Yes","q14":"Of course!","q15":"False"},
    {"id":4,"sharl":"Round Sharl","q1":"Food","q2":"I'm Top Sharl","q3":"Everything!","q4":"Food","q5":"Comedy","q6":"I love all food equally","q7":"Tasty","q8":"Yes!","q9":"The hula","q10":"Shamillion years","q11":"Cooking or baking","q12":"Yes","q13":"Kinda..","q14":"Nope","q15":"True"},
    {"id":5,"sharl":"Cheeky Sharl","q1":"Why not both?","q2":"I'm Top Sharl","q3":"Something to sing along with","q4":"A very special rock","q5":"Horror","q6":"Spicy","q7":"We're going to be rich!","q8":"Yes!","q9":"Ship-Hop","q10":"Shamillion years","q11":"Plotting to take over the world","q12":"Yes","q13":"Kinda..","q14":"Hehehe","q15":"True"},
    {"id":6,"sharl":"Pancake Sharl","q1":"Friends","q2":"I'm Top Sharl","q3":"Something I can dance to","q4":"My freinds!","q5":"Action","q6":"Sweet","q7":"I don't know","q8":"Yes!","q9":"The hula","q10":"Shamillion years","q11":"Playing with friends","q12":"Yes","q13":"Kinda..","q14":"Nope","q15":"True"},
    {"id":7,"sharl":"Angry Sharl","q1":"Food","q2":"I'm Top Sharl","q3":"The sounds of nature","q4":"Solitude","q5":"Romance","q6":"Savoury","q7":"I don't know","q8":"Not really..","q9":"Square dancing","q10":"Shamillion years","q11":"Sports","q12":"Yes","q13":"Kinda..","q14":"Nope","q15":"True"},
    {"id":8,"sharl":"Sleepy Sharl","q1":"Friends","q2":"I'm Top Sharl","q3":"Everything!","q4":"Sleep","q5":"Indie","q6":"Sweet","q7":"I don't know","q8":"Yes!","q9":"The hula","q10":"Shamillion years","q11":"Sleeping","q12":"Yes","q13":"Kinda..","q14":"Nope","q15":"True"},
    {"id":9,"sharl":"Cute Sharl","q1":"Friends","q2":"I'm Top Sharl","q3":"Everything!","q4":"My friends!","q5":"Comedy","q6":"Sweet","q7":"I don't know","q8":"Yes!","q9":"Ship-Hop","q10":"Shamillion years","q11":"Making new friends","q12":"Yes","q13":"Kinda..","q14":"Nope","q15":"True"},
]


const getMatchSharls = (selectedSharl) => {
    const targetSharl = selectedSharl;
    const targetSharlNum = Math.floor(Math.random() * 3)

    const allSharls = ['Barl','Wharl','Narl','Round Sharl','Cheeky Sharl','Pancake Sharl','Angry Sharl','Sleepy Sharl','Cute Sharl']
    const filteredSharls = allSharls.filter(sharl =>
        sharl!==targetSharl
    );

    //now that you have the filtered sharls, you need to get two of them
    const randomSharl1 = Math.floor(Math.random() * filteredSharls.length);
    const selectedSharl1 = filteredSharls[randomSharl1]
    const filteredSharls2 = filteredSharls.filter(sharl =>
        sharl!==selectedSharl1
    );
    const randomSharl2 = Math.floor(Math.random() * filteredSharls2.length);
    const selectedSharl2 = filteredSharls2[randomSharl2]

    //get the questions
    const randomQuestion1 = Math.floor(Math.random() * questions.length);
    const question1 = questions[randomQuestion1]
    question1["qValue"] = "q1"
    const filteredQuestions = questions.filter(question =>
        question.id!==question1.id
    );

    const randomQuestion2 = Math.floor(Math.random() * filteredQuestions.length);
    const question2 = filteredQuestions[randomQuestion2]
    question2["qValue"] = "q2"
    const filteredQuestions2 = filteredQuestions.filter(question =>
        question.id!==question2.id
    );

    const randomQuestion3 = Math.floor(Math.random() * filteredQuestions2.length);
    const question3 = filteredQuestions2[randomQuestion3]
    question3["qValue"] = "q3"
    const filteredQuestions3 = filteredQuestions2.filter(question =>
        question.id!==question3.id
    );

    const randomQuestion4 = Math.floor(Math.random() * filteredQuestions3.length);
    const question4 = filteredQuestions3[randomQuestion4]
    question4["qValue"] = "q4"
    const q1ID = "q" + question1.id
    const q2ID = "q" + question2.id
    const q3ID = "q" + question3.id
    const q4ID = "q" + question4.id

    const allQuestions = {"q1":question1, "q2":question2, "q3":question3, "q4":question4}
    const targetSharlAnswers = sharlAnswers.filter(sharl=>
        sharl.sharl===targetSharl
    )
    const targetSharlQ1 = targetSharlAnswers[0][q1ID]
    const targetSharlQ2 = targetSharlAnswers[0][q2ID]
    const targetSharlQ3 = targetSharlAnswers[0][q3ID]
    const targetSharlQ4 = targetSharlAnswers[0][q4ID]
    const selectedSharl1Answers = sharlAnswers.filter(sharl=>
        sharl.sharl===selectedSharl1
    )
    const selectedSharl1Q1 = selectedSharl1Answers[0][q1ID]
    const selectedSharl1Q2 = selectedSharl1Answers[0][q2ID]
    const selectedSharl1Q3 = selectedSharl1Answers[0][q3ID]
    const selectedSharl1Q4 = selectedSharl1Answers[0][q4ID]
    const selectedSharl2Answers = sharlAnswers.filter(sharl=>
        sharl.sharl===selectedSharl2
    )
    const selectedSharl2Q1 = selectedSharl2Answers[0][q1ID]
    const selectedSharl2Q2 = selectedSharl2Answers[0][q2ID]
    const selectedSharl2Q3 = selectedSharl2Answers[0][q3ID]
    const selectedSharl2Q4 = selectedSharl2Answers[0][q4ID]

    //get the answers for the sharls
    //need door number, sharl name, q1 and q1 answers
    //then turn into an array and return
    let matchResults = "";
    if (targetSharlNum===0) {
        const finalSharls = [
            {"id":0,"name":targetSharl,"q1":targetSharlQ1,"q2":targetSharlQ2,"q3":targetSharlQ3,"q4":targetSharlQ4},
            {"id":1,"name":selectedSharl1,"q1":selectedSharl1Q1,"q2":selectedSharl1Q2,"q3":selectedSharl1Q3,"q4":selectedSharl1Q4},
            {"id":2,"name":selectedSharl2,"q1":selectedSharl2Q1,"q2":selectedSharl2Q2,"q3":selectedSharl2Q3,"q4":selectedSharl2Q4}
        ]
        matchResults = {"questions":allQuestions, "target":0, "sharls":finalSharls}
    } else if (targetSharlNum===1) {
        const finalSharls = [
            {"id":0,"name":selectedSharl1,"q1":selectedSharl1Q1,"q2":selectedSharl1Q2,"q3":selectedSharl1Q3,"q4":selectedSharl1Q4},
            {"id":1,"name":targetSharl,"q1":targetSharlQ1,"q2":targetSharlQ2,"q3":targetSharlQ3,"q4":targetSharlQ4},
            {"id":2,"name":selectedSharl2,"q1":selectedSharl2Q1,"q2":selectedSharl2Q2,"q3":selectedSharl2Q3,"q4":selectedSharl2Q4}
        ]
        matchResults = {"questions":allQuestions, "target":1, "sharls":finalSharls}
    } else {
         const finalSharls = [
             {"id":0,"name":selectedSharl1,"q1":selectedSharl1Q1,"q2":selectedSharl1Q2,"q3":selectedSharl1Q3,"q4":selectedSharl1Q4},
             {"id":1,"name":selectedSharl2,"q1":selectedSharl2Q1,"q2":selectedSharl2Q2,"q3":selectedSharl2Q3,"q4":selectedSharl2Q4},
             {"id":2,"name":targetSharl,"q1":targetSharlQ1,"q2":targetSharlQ2,"q3":targetSharlQ3,"q4":targetSharlQ4},
         ]
        matchResults = {"questions":allQuestions, "target":2, "sharls":finalSharls}
    }
      return matchResults

}

export default getMatchSharls;