import React from 'react';

import {connect} from "react-redux";
import {
    StyleTunaCanContainer,
    StyleTunaCan,
    StyleTunaCanSides,
    StyleTunaCanMid,
    StyleTunaCanMidTop,
    StyleTunaCanMidBottom, StyleTunaButtonContainer, StyleTunaConfirmButton
} from "../../../styles/tuna";

import DragCan from "./DragCan";
import DropCan from "./DropCan";
import TunaCanContent from "./TunaCanContents";
import getTunaCanPrizeFunction from "../../../store/actions/TunaCan/getTunaCanPrizeAction";
import selectTunaCanFunction from "../../../store/actions/TunaCan/selectTunaCanAction";

function TunaCanBody(props) {

    const confirmTunaHandler = () => {
        const selectedTuna = props.TunaCanReducer.selectedTunaCan;
        props.dispatch(getTunaCanPrizeFunction(props, selectedTuna));
        //clear selected tuna can
        props.dispatch(selectTunaCanFunction())
    }

    return (
            <StyleTunaCan>
                <StyleTunaCanContainer>
                    <StyleTunaCanMid>

                        <StyleTunaCanMidTop>

                            <DropCan props={props} />
                            <StyleTunaButtonContainer>
                                { props.TunaCanReducer.selectedTunaCan ?
                                    <>
                                    <p>10</p>
                                    <img src={require("../../../assets/images/misc/shadollar.png")} alt="shadollar"/>
                                <StyleTunaConfirmButton onClick={()=>confirmTunaHandler()}>Confirm</StyleTunaConfirmButton>
                                    </>
                                : ""}

                            </StyleTunaButtonContainer>

                        </StyleTunaCanMidTop>
                        <StyleTunaCanMidBottom>
                            <DragCan props={props} />
                        </StyleTunaCanMidBottom>
                    </StyleTunaCanMid>
                    <StyleTunaCanSides>
                        <TunaCanContent props={props}/>

                    </StyleTunaCanSides>
                </StyleTunaCanContainer>
            </StyleTunaCan>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(TunaCanBody);
