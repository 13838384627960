import React from 'react';
import {connect} from "react-redux";

function StudioBody(props) {

    return (
        <>
        <p>Happy Sharls Studio!</p>
        </>

    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(StudioBody);
