import React from 'react';
import AdoptBody from "./AdoptBody";

function Adopt() {
  return (
        <AdoptBody/>
  );
}

export default Adopt;
