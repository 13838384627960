import React from 'react'
import {
    StyleDragSharlContainer,
    StyleInactiveSharl,
} from "../../../../styles/tank";
import { ItemTypes } from '../Constants'
import { useDrag } from 'react-dnd'
import {StyleDragUserCanMain} from "../../../../styles/tuna";


export const TunaCan = (props) => {

    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.SELECTEDCAN },
        collect: (monitor) => ({
            item: monitor.getItem(),
            isDragging: !!monitor.isDragging(),
        }),
    })

  return (
        <StyleInactiveSharl
          id={props.tunaCan.id}
          ref={drag}
          style={{
            opacity: isDragging ? 0.5 : 1,
            cursor: 'move',
          }}>
          <StyleDragSharlContainer>
            <StyleDragUserCanMain>
                <img src={"https://www.sharl.info/" + props.tunaCan.image} alt={props.tunaCan.image} />
                 <p>{props.tunaCan.name} ({props.tunaCan.quantity})</p>
            </StyleDragUserCanMain>
          </StyleDragSharlContainer>
    </StyleInactiveSharl>
  )
}


export default TunaCan;