import {
    clearsignup,
    confirmemail,
    signin,
    signinerror,
    signout,
    signup,
    takenemail,
    verify,
    verifyerror, notverified
} from "../types";

const initialState = {
    token: null,
    userAuth: null,
    error: false,
    email: null,
    verify: false,
    stage: 1,
    emailtaken: false,
    errormesage: "",
};

const SignInReducer = (state = initialState, action) => {
    switch (action.type) {
        case signin: {
            return {
                ...state,
                token: action.payload,
                userAuth: true,
                email: null,
                error: null,
            }
        }
        case notverified: {
            return {
                ...state,
                token: null,
                error: null,
            }
        }
        case signout: {
            return {
                ...state,
                token: null,
                userAuth: null,
                error: null,
                email: null,
            }
        }
        case signup: {
            return {
                ...state,
                token: null,
                userAuth: null,
                error: null,
                email: action.payload,
                stage: 2,
            }
        }
        case verify: {
            return{
            ...state, 
            error: false,
            verify: true,
            stage: 4,
        }
        }
        case verifyerror: {
            return {
                ...state,
                error: true,
                verify: false,                
                errormesage: action.payload,
            }
        }

        case clearsignup: {
            return {
                ...state,
                stage: 1,
                emailtaken: false,
                error: null,
            }
        }

        case confirmemail: {
            return {
                ...state,
                stage: 3,
                emailtaken: false,
            }
        }
        case takenemail: {
            return {
                ...state,
                emailtaken: true,
            }
        }
        case signinerror: {
            return {
                ...state,
                error: action.payload,
            }
        }

        default: {
            return state
        }
    }
}
export default SignInReducer;