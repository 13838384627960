import React from 'react'
import {StyleActiveSquareContainer} from "../../../styles/tank";

export default function ActiveSquare({ children }) {

    return (
        <StyleActiveSquareContainer>
            {children}
        </StyleActiveSquareContainer>
  )
}
