import {dragsharl} from "../../../../../store/types";

export const moveSharlAction = (data) => ({
    type: dragsharl,
    payload: data,
});


const moveSharlFunction = (props,sharlID,newPosition,active) => (dispatch) => {
    const x = newPosition[0];
    const y = newPosition[1];
    const tank_position = {tank_position: x + "/" + y};
    const is_active = {is_active: active};
    const data = Object.assign(tank_position, is_active);

    const URL = `https://www.sharl.info/backend/api/sharls/update/active/${sharlID}/`;

    const token = props.SignInReducer.token;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers,
    };

     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        const sharls = props.UserReducer.sharls;
        const newSharls = sharls.filter(sharl =>
        sharl.id !== sharlID);
        newSharls.push(data);
        const newData = {"sharls": newSharls, "selected": data}
        dispatch(moveSharlAction(newData));
    })
};

export default moveSharlFunction;