import React from 'react';

import {connect} from "react-redux";

import {
    StyleProfileItem,
} from "../../styles/profile";

import AllSharls from "./AllSharls";
import {StyleSharlProfileContainer, StyleSharlProfileItemMain} from "../../styles/sharls";


function SharlsBody(props) {
    return (
                <StyleProfileItem>
                    <StyleSharlProfileContainer>
                        <StyleSharlProfileItemMain>
                            <AllSharls props={props}/>
                        </StyleSharlProfileItemMain>
                    </StyleSharlProfileContainer>
                </StyleProfileItem>

    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(SharlsBody);
