import {adoptselect} from "../../types";

export const selectSharlAdoptFirstAction = (data) => ({
    type: adoptselect,
    payload: data,
});


const selectSharlAdoptFirstFunction = (data) => (dispatch) => {
    dispatch(selectSharlAdoptFirstAction(data));
}

export default selectSharlAdoptFirstFunction;