import styled from "styled-components";


export const StyleTunaCan = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: linear-gradient(to top, rgba(150, 255, 255, 0.7) 0%, rgba(51, 102, 153, 0.6) 100%), url('../images/backgrounds/seabg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    `;

export const StyleTunaCanContainer = styled.div`
    height: 90%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

export const StyleTunaCanMid = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    `;

export const StyleTunaCanMidTop = styled.div`
    height: 70%;
    width: 100%;
    background-color: grey;
    display: flex;
    border-radius: 24px 24px 0px 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;


export const StyleTunaMainContainer = styled.div`
    height: 80%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
 `;


export const StyleTunaButtonContainer = styled.div`
    height: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    p {
        font-size: 2vw;
        font-weight: bold;
    }
     
    vertical-align: center;   
    img {
        margin: 1%;
        height: 70%;
        width: auto;
     }
 `;


export const StyleTunaConfirmButton = styled.button`
    height: 70%;
    width: 18%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 1.5vw;
    border: none;
    margin-left: 10%;
 `;


export const StyleTunaCanMidBottom = styled.div`
    height: 30%;
    width: 100%;
    background-color: #336699;
    border-radius: 0px 0px 24px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    `;


export const StyleTunaContents = styled.div`
    position: relative;
    height: 40%;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        height: 80%;
        width: auto;
    }
    p{
    font-weight: bold;
    font-size: 1.2vw;
    }   
    
   background-color: white;
   border-radius: 24px;

       h1 {
        font-size: 0;
    }   
    :hover {
    h1 {
        font-size: 1.4vw;
        color: white;
        width: 100%;
        text-align: center;
        position: absolute;
        background-color: rgba(51,102,153,0.9);
        z-index: 10;
        font-weight: bold;   
    }    
    }   

    `;


export const StyleTunaItemQuantity = styled.div`
    position: absolute;
    right: 2%;
    bottom: 15%;
    height: 20%;
    width: 30%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
     p {
     font-weight: bold;
     font-size: 1.5vw;
     }
    `;

export const StyleTunaCanSides = styled.div`
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 4%;
    `;


export const StyleTunaDragMainContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
 `;


export const StyleTunaDragContainer = styled.div`
    height: 80%;
    width: 22%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1%;    
 `;


export const StyleDragUserCanMain = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;  
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    
    img {
        height: 85%;
        width: auto;
        overflow: hidden;
    }
  
    p {
        font-size: 1vw;
        color: white; 
    }
}   
    `;



export const StyleSelectedTunaContainer = styled.div`
    height: 45%;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        height: 100%;
        width: auto;
    }
    
 `;

