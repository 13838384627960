import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    StyleProfileButton, StyleProfileContainer, StyleProfileItem,
    StyleSharlProfileItem,
    StyleUserProfileBody,
    StyleUserProfileContainer
} from "../../../styles/profile";
import renderRating from "../renderRating";
import {
    StyleAdoptItemMain,
    StyleSharlItem,
    StyleSharlItemSingle,
    StyleSharlItemContainer,
    StyleSharlAdoptConfirm,
    StyleSelectTokenContainer,
    StyleSelectTokenHeader,
    StyleSelectTokenBody,
    StyleSelectToken,
    StyleTokenSingleContainer,
} from "../../../styles/adopt";
import selectSharlAdoptFunction from "../../../store/actions/Adopt/selectSharlAdoptAction";
import {clearAdoptStageFunction} from "../../../store/actions/Adopt/clearAdoptStageAction";
import generateTokenSharls from "./generateTokenSharls";
import selectSharlAdoptFirstFunction from "../../../store/actions/Adopt/selectSharlAdoptFirstAction";
import selectAdoptTokenFunction from "../../../store/actions/Adopt/selectAdoptTokenAction";


function TokenGenerator(props) {
    const [sharls, setSharls] = useState([]);
    const [filteredItems, setfilteredItems] = useState([]);
    const [selectedToken, setSelectedToken] = useState();


    useEffect(() => {
        const userItems = props.SharlReducer.user_items;
        if (userItems) {
            const tokens = userItems.filter(item =>
                item.category === "token" && item.quantity > 0
            );
            setfilteredItems(tokens);
        }
        // eslint-disable-next-line
        }, [props.SharlReducer]);

   const adoptSharlHandler = (e) => {
       const chosenSharl = sharls[0];
       props.dispatch(selectSharlAdoptFunction(chosenSharl));
       }

   const selectSharlHandler = (sharl) => {
       const chosenSharlId = sharl.id;
       props.dispatch(selectSharlAdoptFirstFunction(chosenSharlId));
       }

    const adoptClearHandler = (e) => {
        props.dispatch(clearAdoptStageFunction())
    }

    const selectToken = (tokenName, tokenID) => {
        const generatedSharl = generateTokenSharls(tokenName, tokenID);
        setSharls(generatedSharl);
        selectSharlHandler(generatedSharl[0])
        setSelectedToken(tokenID);
        props.dispatch(selectAdoptTokenFunction(tokenID))
    }

    const bgColorHandler = (tokenID) => {
        if (tokenID === parseInt(selectedToken)) {
                return "#336699"
        } else {
            return "white"
        }
    }
    const pColorHandler = (tokenID) => {
        if (tokenID === parseInt(selectedToken)) {
                return "white"
        } else {
            return "#336699"
        }
    }

    return (
             <StyleProfileItem>
                <StyleProfileContainer>
                    <StyleAdoptItemMain>
                        <StyleUserProfileContainer>
                            <StyleUserProfileBody>
                             <StyleSelectTokenContainer>
                               <StyleSelectTokenHeader>
                                 <p>Select a Token to Redeem</p>
                               </StyleSelectTokenHeader>
                               <StyleSelectTokenBody>
                                   {filteredItems.map(token =>
                                    <StyleSelectToken id={token.id} key={token.id} onClick={(e) => selectToken(token.name, token.id)} bgColor={bgColorHandler(token.id)} pColor={pColorHandler(token.id)}>
                                        <img src={"https://www.sharl.info" + token.image} alt={token.image} />
                                        <p>{token.name.split("Token")}</p>
                                    </StyleSelectToken>
                                )}
                                </StyleSelectTokenBody>
                             </StyleSelectTokenContainer>

                                {sharls.map(sharl =>
                                    <StyleSharlProfileItem id={sharl.id} key={sharl.id} onClick={(e) => selectSharlHandler(e)}>
                                        <StyleTokenSingleContainer>
                                        <img src={sharl.sharl_avatar} alt={sharl.sharl_avatar} />
                                        <StyleSharlItemSingle>
                                            <p>{sharl.genus}</p>
                                        </StyleSharlItemSingle>
`                                        <StyleSharlItemSingle>
                                        </StyleSharlItemSingle>
                                        </StyleTokenSingleContainer>
                                        <StyleSharlItemContainer>
                                        <StyleSharlItem>
                                            <p>Roundness:</p>
                                            {renderRating(sharl.roundness)}
                                        </StyleSharlItem>
                                        <StyleSharlItem>
                                            <p>Sleepyness:</p>
                                            {renderRating(sharl.sleepyness)}
                                        </StyleSharlItem>
                                        <StyleSharlItem>
                                            <p>Cheekyness:</p>
                                            {renderRating(sharl.cheekyness)}
                                        </StyleSharlItem>
                                        <StyleSharlItem>
                                            <p>Pancakeness:</p>
                                            {renderRating(sharl.pancakeness)}
                                        </StyleSharlItem>
                                        </StyleSharlItemContainer>
                                    </StyleSharlProfileItem>
                                )}
                            </StyleUserProfileBody>
                            <StyleSharlAdoptConfirm>
                                { selectedToken ?
                                <StyleProfileButton onClick={(e) => adoptSharlHandler(e)}>Adopt</StyleProfileButton>
                                    : "" }
                                <StyleProfileButton onClick={(e) => adoptClearHandler(e)}>Cancel</StyleProfileButton>
                            </StyleSharlAdoptConfirm>
                        </StyleUserProfileContainer>
                    </StyleAdoptItemMain>
               </StyleProfileContainer>
             </StyleProfileItem>
)
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(TokenGenerator);
