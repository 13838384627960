import {adopterror, adoptloaded, adoptloading, createsharl} from "../../types";

export const createSharlAction = email => ({
    type: createsharl,
});

export const adoptLoadingAction = () => ({
    type: adoptloading,
});

export const adoptLoadedAction = () => ({
    type: adoptloaded,
});

export const adoptErrorAction = (data) => ({
    type: adopterror,
    payload: data,
});

const URL = "https://www.sharl.info/backend/api/sharls/new/";

export const createSharlFunction = (data,props) => (dispatch) => {
    dispatch(adoptLoadingAction())
    const token = props.SignInReducer.token;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers
    };

     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        if (data.name[0] === "sharl with this name already exists.") {
            dispatch(adoptErrorAction(data));
            dispatch(adoptLoadedAction());
        } else {
            dispatch(createSharlAction());
            dispatch(adoptLoadedAction())
        }
    });
};