import React from 'react';
import {StyleFooter} from "../../styles/app";

function Footer() {
  return (
    <StyleFooter>
    <p>© sharl.info. All rights reserved.</p>
    </StyleFooter>
  );
}

export default Footer;
