import {helptopic} from "../../types";

export const updateHelpTopicAction = (data) => ({
    type: helptopic,
    payload: data,
});


const updateHelpTopicFunction = (data) => (dispatch) => {
    dispatch(updateHelpTopicAction(data));
};

export default updateHelpTopicFunction;