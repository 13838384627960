import React, {useEffect, useState} from 'react'
import ActiveSharl from './ActiveSharl'
import {connect} from "react-redux";

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ActiveTankSquare from "./ActiveTankSquare";
import {StyleActiveTankSharlContainer, StyleTankMainContainer} from "../../../../styles/tank";
import {BlankSharl} from "../DragTank/BlankSharl";

function ActiveContainer(props) {
  const [squares,setSquares] = useState([])

   useEffect(() => {
        const newSquares = [];
        for (let i = 0; i < 4; i++) {
          newSquares.push(renderSquare(i, props))
        }
        setSquares(newSquares)
           // eslint-disable-next-line
        },[props.UserReducer]);

   useEffect(() => {
        const newSquares = [];
        for (let i = 0; i < 4; i++) {
          newSquares.push(renderSquare(i, props))
        }
        setSquares(newSquares)
        // eslint-disable-next-line
        },[]);

    function renderSquare(i, props) {
        const x = 1;
        const y = Math.floor(i / 4);
      //  const sharlIndex = i;
      //  const tankPosition = x + "/" + y;
        const allSharls = props.UserReducer.sharls;
        const activeSharls = allSharls.filter(sharl =>
            sharl.tank_position === "99/99"
            );
        const activeSharl = activeSharls[i];

        if (activeSharl) {
            return (
                <StyleActiveTankSharlContainer key={i} id={i}>
                    <ActiveTankSquare x={x} y={y} props={props} sharl={activeSharl} >
                        <ActiveSharl props={props} sharl={activeSharl} id={activeSharl.id}/>
                    </ActiveTankSquare>
                </StyleActiveTankSharlContainer>
        )} else {
            return (
                <StyleActiveTankSharlContainer key={i} id={i} >
                  <ActiveTankSquare x={x} y={y} props={props} >
                    <BlankSharl props={props} />
                   </ActiveTankSquare>
                </StyleActiveTankSharlContainer>
        )}
    }

  return (
        <DndProvider backend={HTML5Backend}>
            <StyleTankMainContainer>
                {squares}
            </StyleTankMainContainer>
        </DndProvider>
  )}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(ActiveContainer);
