import {helppolicy, helptopic} from "../types";

const initialState = {
    topic: "help",
    policy: "",
    };

const HelpReducer = (state = initialState, action) => {
  switch (action.type) {
        case helptopic:
            return {
            ...state,
            topic: action.payload,
            }
        case helppolicy:
            return {
            ...state,
            policy: action.payload,
            }
        default:
            return state;
  }
}

export default HelpReducer;