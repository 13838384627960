import React from 'react';


function EULAPolicy() {
  return (
        <>
          <p>EULA</p>
        </>
          );
}

export default EULAPolicy;
