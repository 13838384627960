import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";

import {
    StyleSharlItem, StyleSharlItemSingle
} from "../../../styles/profile";
import {
    StyleProfileSharl, StyleSharlActiveContainer,
    StyleSharlContainer, StyleSharlHatContainer, StyleSharlImageContainer,
    StyleSharlItemSingleRating,
    StyleSharlLeft, StyleSharlLeftBlank, StyleSharlMiddle, StyleSharlOuter,
    StyleSharlRightItemShow
} from "../../../styles/sharls";
import getSingleSharlFunction from "../../../store/actions/Sharls/getSingleSharlAction";
import renderRating from "../../MainApp/renderRating";

import SharlItems from "./SharlItems";
import OtherSharls from "./OtherSharls";
import showSharlItemsFunction from "../../../store/actions/Sack/showSharlItemsAction";
import hungerLevelCalculator from "../hungerLevelCalculator";

function SingleSharlBody(props) {
    const [sharl, setSharl] = useState(props.SharlReducer.selected_sharl);
    const [showItems, setShowItems] = useState(props.SharlReducer.showSharlItems);
    const [sharlHat, setSharlHat]= useState(props.SharlReducer.headgear);

    const headgearHandler = (e) => {
        props.dispatch(showSharlItemsFunction());
    }

    useEffect(() => {
        const selectedSharl = props.SharlReducer.selected_sharl;
        if (selectedSharl) {
            const selectedSharlName = props.SharlReducer.selected_sharl.name
            if (selectedSharlName) {
                selectedSharl.calculated_hunger = hungerLevelCalculator(selectedSharl.hunger_level, selectedSharl.last_fed)
                setSharl(selectedSharl);
                setShowItems(props.SharlReducer.showSharlItems);
                setSharlHat(selectedSharl.headgear.image);}
            }
        // eslint-disable-next-line
        },[props.SharlReducer]);

        useEffect(() => {
           const newHat = props.SharlReducer.headgear
           setSharlHat(newHat)
        // eslint-disable-next-line
        },[props.SharlReducer.headgear]);

    useEffect(() => {
        if (!sharl.id) {
            const activeSharls = props.UserReducer.sharls.filter(sharl =>
            sharl.is_active===true);
            const selectedSharl = activeSharls[0]
            props.dispatch(getSingleSharlFunction(selectedSharl));
        }
        // eslint-disable-next-line
    }, [props.UserReducer]);

    const bgColorHandler = (color) => {
            return color;
     }

    if (sharl) {
        return (
                <>
                    <StyleProfileSharl>
                        <StyleSharlContainer>
                            <StyleSharlActiveContainer>
                                    <StyleSharlLeftBlank>
                                    </StyleSharlLeftBlank>
                                <StyleSharlLeft bgColor={bgColorHandler(sharl.color)}>
                                    <StyleSharlImageContainer>
                                        <img src={`https://www.sharl.info/${sharl.sharl_avatar}`} alt={sharl.sharl_avatar} />
                                    </StyleSharlImageContainer>
                                    { sharlHat ?
                                        <StyleSharlHatContainer>
                                            {sharlHat.includes("https") ?
                                                <img src={sharlHat} alt={sharlHat}/>
                                                :
                                                <img src={"http://www.sharl.info" + sharlHat} alt={sharlHat}/>
                                            }
                                        </StyleSharlHatContainer>
                                       : ""
                                    }
                                    <p onClick={(e) => headgearHandler(e)}>Change Hat?</p>
                                </StyleSharlLeft>
                                <StyleSharlMiddle>
                                    <StyleSharlItemSingle>
                                        <h1>{sharl.name}</h1>
                                    </StyleSharlItemSingle>
                                    <StyleSharlItemSingle>
                                        <p>Genus: {sharl.genus}</p>
                                    </StyleSharlItemSingle>
                                    <StyleSharlItemSingle>
                                        <p>Favorite Fish or Food: {sharl.fav_fish_or_food}</p>
                                    </StyleSharlItemSingle>
                                    <StyleSharlItemSingle>
                                        <p>Age: {sharl.age} days</p>
                                    </StyleSharlItemSingle>
                                    <StyleSharlItemSingle>
                                        <p>Hunger Level: {sharl.calculated_hunger} </p>
                                    </StyleSharlItemSingle>
                                    <StyleSharlItemSingle>
                                    </StyleSharlItemSingle>
                                    <StyleSharlItemSingleRating>
                                    <StyleSharlItem>
                                        <p>Roundness:</p>
                                    </StyleSharlItem>
                                    <StyleSharlItem>
                                        {renderRating(sharl.roundness)}
                                    </StyleSharlItem>
                                    </StyleSharlItemSingleRating>
                                    <StyleSharlItemSingleRating>
                                    <StyleSharlItem>
                                        <p>Sleepyness:</p>
                                    </StyleSharlItem>
                                    <StyleSharlItem>
                                        {renderRating(sharl.sleepyness)}
                                    </StyleSharlItem>
                                    </StyleSharlItemSingleRating>
                                    <StyleSharlItemSingleRating>
                                    <StyleSharlItem>
                                         <p>Cheekyness:</p>
                                    </StyleSharlItem>
                                    <StyleSharlItem>
                                        {renderRating(sharl.cheekyness)}
                                    </StyleSharlItem>
                                    </StyleSharlItemSingleRating>
                                    <StyleSharlItemSingleRating>
                                    <StyleSharlItem>
                                        <p>Pancakeness:</p>
                                    </StyleSharlItem>
                                    <StyleSharlItem>
                                        {renderRating(sharl.pancakeness)}
                                    </StyleSharlItem>
                                 </StyleSharlItemSingleRating>
                                </StyleSharlMiddle>
                                { showItems ?
                                <>
                                    <StyleSharlRightItemShow>
                                        <SharlItems props={props}/>
                                    </StyleSharlRightItemShow>
                                </>
                                 : ""
                                 }
                         </StyleSharlActiveContainer>
                        <StyleSharlOuter>
                            <OtherSharls props={props}/>
                        </StyleSharlOuter>
                    </StyleSharlContainer>
                </StyleProfileSharl>
            </>
        );
        } else {
            return (
                <StyleProfileSharl>
                <StyleSharlContainer>
             <StyleSharlLeft />
             <StyleSharlMiddle>
            <p>Loading..</p>
             </StyleSharlMiddle>
                </StyleSharlContainer>
                </StyleProfileSharl>
    )
    }
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(SingleSharlBody);
