import {quizresults} from "../../types";


export const quizResultsAction = (data) => ({
    type: quizresults,
    payload: data,
});


const quizResultsFunction = (data) => (dispatch) => {
    dispatch(quizResultsAction(data))

}

export default quizResultsFunction;;