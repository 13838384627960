import {getresearch} from "../../types";

export const getResearchAction = (data) => ({
    type: getresearch,
    payload: data,
});


const URL = `https://www.sharl.info/backend/api/facts/research/`;


const getResearchFunction = (props) => (dispatch) => {
    const token = props.SignInReducer.token
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'GET',
        headers
    };
 
     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        dispatch(getResearchAction(data.sharl_facts));
    })
};
export default getResearchFunction;