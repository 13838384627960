import {cleartuna, dragtunacan, tunaprize} from "../types";

const initialState = {
    selectedTunaCan: false,
    tunaCanPrize: "",
    };

const TunaCanReducer = (state = initialState, action) => {
  switch (action.type) {
      case dragtunacan:
        return {
            ...state,
                selectedTunaCan: action.payload,
        }
      case tunaprize:
        return {
            ...state,
                tunaCanPrize: action.payload,
        }
        case cleartuna:
            return {
            ...state,
                tunaCanPrize: "",
        }
        default:
            return state;
  }
}

export default TunaCanReducer;