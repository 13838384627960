export const helpQuestions = [
    {"id":1, "question":"How do I get a Sharl?", "answer":"Sharls can be adopted from the Adoption Center. Our regular Sharls are 50 coins for adoption. " +
            "Rare Sharls can be adopted through token redemption."},
    {"id":2, "question":"How do I earn shadollars?", "answer":"There are several Arcade games that you can play to earn shadollars"},
    {"id":3, "question":"How do I earn trash tokens?", "answer":"You can earn trash tokens by playing the sharl ball game in the arcade."},
    {"id":4, "question":"Where can I spend my trash tokens?", "answer":"Angry Sharl's Trash Center"},
    {"id":5, "question":"How do I add a hat to my Sharls?", "answer":"You can give hats to your Sharls on the Active Sharls Page."},
    {"id":6, "question":"Will my Sharl keep his hat in the tank?", "answer":"Yes! Sharls get quite attached to their hats and will continue wearing" +
            " them in the tank."},
    {"id":7, "question":"How can I share my Sharls with my friends?", "answer":"You can show off your tank with Share My Sharls. See the Tank page for sharing options."},
    {"id":8, "question":"Why can't I adopt any more Sharls?", "answer":"You can only adopt sharls if your active party is not yet full. If you have 4 active Sharls, you" +
            " will need to move at least one to the tank before adopting another. You can upgrade your tank to hold a maximum of 16 Sharls."},
    {"id":9, "question":"How often does the market change?", "answer":"Both Narl and Barl update their goods on a daily basis."},
    {"id":10, "question":"How do I change my password?", "answer":"You can change your password through your profile."},
    {"id":11, "question":"How do I delete my account?", "answer":"If you would like to take a break, you can deactivate your account in your profile. " +
            "If you are sure you want to delete your account please use the web form or send us an email at info@sharl.info."},
    {"id":12, "question":"I have a different question", "answer":"Please contact us via the web form or by email at info@sharl.info and one of our Sharls" +
            " will respond!"},
]
