import {updateresearch} from "../../types";

export const updateResearchAction = (data) => ({
    type: updateresearch,
    payload: data,
});


const updateResearchFunction = (researchStage) => (dispatch) => {
    dispatch(updateResearchAction(researchStage));
};

export default updateResearchFunction;;