import {selectnarl} from "../../types";

export const selectNarlAction = (data) => ({
    type: selectnarl,
    payload: data,
});


const selectNarlFunction = (selectedItem) => (dispatch) => {
        dispatch(selectNarlAction(selectedItem));
};

export default selectNarlFunction;