import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import HelpPage from "./HelpPage/HelpPage";
import FAQ from "./FAQ/FAQ";
import Contact from "./Contact/Contact";
import Terms from "./Terms/Terms";


function HelpContent(props) {
    const [topic, setTopic] = useState(props.HelpReducer.topic);

    useEffect(()=>{
        const topicStage = props.HelpReducer.topic;
        setTopic(topicStage);
    },[props.HelpReducer])

    switch (topic) {
        case "help":
            return(
               <HelpPage/>
            )
        case "faq":
            return(
                <FAQ/>
                )
        case "contact":
            return(
                <Contact/>
                    )
        case "terms":
            return(
                <Terms/>
                    )
        default:
            return(
                <HelpPage/>
                )
        }}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(HelpContent);