import {getUserItemsAction} from "../MainApp/getUserItemsAction";
import {getResearchAction} from "./getResearchAction";
import {selectResearchAction} from "./selectResearchAction";


const startResearchFunction = (props, sharlId, research_data) => (dispatch) => {
    const userID = props.UserReducer.user.id
    const URL = `https://www.sharl.info/backend/api/facts/research/${userID}/`;
    const token = props.SignInReducer.token;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'PATCH',
        body: JSON.stringify(research_data),
        headers,
    };

     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        const clearFields = {"research": [], "sharl": []}
        dispatch(selectResearchAction(clearFields));
        dispatch(getResearchAction(data.sharl_facts));
        const currentUser = props.UserReducer.user
        currentUser.balance = data.balance
        const concatData = {"user":currentUser, "sharls":data.sharls}
        dispatch(getUserItemsAction(concatData));
    })
};

export default startResearchFunction;;