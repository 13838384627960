import React from 'react'
import ActiveSquare from './ActiveSquare'
import { ItemTypes } from '../Constants'
import { useDrop } from 'react-dnd'
import {
    StyleTankOverDrop,
} from "../../../styles/tank";
import {StyleResearchTestDropContainer} from "../../../styles/test";
import selectResearchFunction from "../../../store/actions/Research/selectResearchAction";
import updateResearchFunction from "../../../store/actions/Research/updateResearchAction";


function DropSquare({ research, props, children }) {

  const dropHandler = (props,monitor) => {
        const selected = {"sharl":monitor.sharl, "research":research};
        props.dispatch(updateResearchFunction("start"));
        props.dispatch(selectResearchFunction(selected))
      }

  const [{ isOver }, drop] = useDrop({
        accept: ItemTypes.RESEARCH,
        drop: monitor => dropHandler(props, monitor),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
    })

  })
    return (
        <StyleResearchTestDropContainer
            ref={drop}
            style={{
              cursor: 'move',
          }}
        >
            <ActiveSquare>{children}</ActiveSquare>
                {isOver ?
                    <StyleTankOverDrop />
                : ""}
        </StyleResearchTestDropContainer>
  )
}

export default DropSquare;