import {clearnarl} from "../../types";

export const clearNarlItemAction = () => (
    {
    type: clearnarl,
});


const clearNarlItemFunction = () => (dispatch) => {
    dispatch(clearNarlItemAction())
}

export default clearNarlItemFunction;