import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    StyleGamesButton,
    StyleGamesButtonContainer,
    StyleGamesContainer,
    StyleGamesFooter, StyleTrashConfirmButton,
    StyleTrashExchange,
    StyleTrashExchangeContainer,
    StyleTrashExchangeImage,
    StyleTrashExchangeItems,
    StyleTrashExchangeItemsBottom,
    StyleTrashExchangeItemsTop, StyleTrashExchangeSale, StyleTrashItem
} from "../../../styles/games";
import {Link} from "react-router-dom";
import buyTrashItemFunction from "../../../store/actions/Games/buyTrashItemAction";

function TrashExchange(props) {
    const [trashItems, setTrashItems] = useState();
    const [selectedItem, setSelectedItem] = useState();
    const [bagFull, setBagFull] = useState();

    useEffect(()=>{
        //need to switch item ids to item codes
        const allItems = [
            {id:50, name:"Angry Sharl Token", image:"https://www.sharl.info/media-files/wharl_token_4T0TIYl.png", cost:500},
            {id:49, name:"Shrimpie Token", image:"https://www.sharl.info/media-files/wharl_token_4T0TIYl.png", cost:250},
            {id:48, name:"Trash Hat", image:"https://www.sharl.info/media-files/trash_gCmQ9RA.png", cost:100},
            {id:30, name:"Trash Statue", image:"https://www.sharl.info/media-files/trash_gCmQ9RA.png", cost:100},
            {id:31, name:"Colorful pebbles", image:"https://www.sharl.info/media-files/rocks.png", cost:20}
            ]
        setTrashItems(allItems)
        const userItems = props.UserReducer.items;
        const userBagLevel = props.UserReducer.user.max_items;
        const userItemsBlankHat = userItems.filter(item =>
            item.name!=="Blank Hat");
        const filteredItems = userItemsBlankHat.filter(item =>
            item.quantity!==0 && item.quantity > item.quantity_in_use
        )
        if (filteredItems.length >= userBagLevel){
            setBagFull(true);
        } else {
            setBagFull(false);
        }
    //eslint-disable-next-line
    },[])

    const buyItemHandler = (item) => {
        //do stuff
        const newTrashBal = props.UserReducer.user.trash_balance - item.cost
        props.dispatch(buyTrashItemFunction(props,item.id, newTrashBal))
    }

    const itemHandler = (item) => {
        setSelectedItem(item)
    }

    const bgColorHandler = (itemName) => {
        if (selectedItem && itemName===selectedItem.name){
            return "#336699";
        }
    }
    const textColorHandler = (itemName) => {
        if (selectedItem && itemName===selectedItem.name){
            return "white";
        }
    }

    return (
        <StyleGamesContainer>
            <StyleTrashExchangeContainer>
                <StyleTrashExchange>
                    <StyleTrashExchangeImage>
                        <p>sharl image</p>
                        <p>Some text about trash</p>
                    </StyleTrashExchangeImage>
                    { trashItems ?
                       <StyleTrashExchangeItems>
                            <StyleTrashExchangeItemsTop>
                            {trashItems.map(item =>
                                <StyleTrashItem
                                    id={item.id}
                                    key={item.id}
                                    onClick={()=>{itemHandler(item)}}
                                    bgColor={bgColorHandler(item.name)}
                                    textColor={textColorHandler(item.name)}
                                >
                                    <img src={item.image} alt={item.name} />
                                    <p>  {item.name} </p>
                                </StyleTrashItem>
                            )}
                            </StyleTrashExchangeItemsTop>
                                { selectedItem ?
                                    <StyleTrashExchangeItemsBottom>
                                        <StyleTrashExchangeSale>
                                            <img src={selectedItem.image} alt={selectedItem.name} />
                                        </StyleTrashExchangeSale>
                                        <StyleTrashExchangeSale>
                                            <p>{selectedItem.cost} <img src={require("../../../assets/images/trash/trash.png")} alt={"trash"} /></p>
                                        </StyleTrashExchangeSale>
                                            { props.UserReducer.user.trash_balance >= selectedItem.cost ?
                                                <StyleTrashExchangeSale>
                                                    { bagFull ?
                                                        <p>Your Bag is Full</p>
                                                        :
                                                        <StyleTrashConfirmButton onClick={()=>buyItemHandler(selectedItem)}>Buy</StyleTrashConfirmButton>
                                                    }

                                                </StyleTrashExchangeSale>
                                                :
                                                <StyleTrashExchangeSale/>
                                            }
                                    </StyleTrashExchangeItemsBottom>
                                    :
                                    <StyleTrashExchangeItemsBottom/>
                                }
                        </StyleTrashExchangeItems>
                        :
                        <StyleTrashExchangeItems/>
                    }
                </StyleTrashExchange>
            </StyleTrashExchangeContainer>
            <StyleGamesFooter>
                <StyleGamesButtonContainer>
                    <Link to="/games">
                        <StyleGamesButton>Back to Games</StyleGamesButton>
                    </Link>
                </StyleGamesButtonContainer>
            </StyleGamesFooter>
        </StyleGamesContainer>
 );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(TrashExchange);
