import React from 'react';

import TunaCanBody from "./TunaCanBody";


function TunaCan() {

  return (
        <TunaCanBody/>
  );
}

export default TunaCan;
