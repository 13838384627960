import {removehat} from "../../types";
import {updateSharlsAction} from "./saveSharlHatAction";

export const removeSharlHatAction = (data) => ({
    type: removehat,
    payload: data,
});


const removeSharlHatFunction = (props,sharlID) => (dispatch) => {

    const URL = `https://www.sharl.info/backend/api/sharls/update/${sharlID}/`;

    const token = props.SignInReducer.token;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'PATCH',
        headers,
    };

     fetch(URL, config)
        .then(res => res.json())
        .then(data => {
            dispatch(updateSharlsAction(data));
            const selected_sharl = data.filter(sharl =>
                sharl.id === sharlID);
             dispatch(removeSharlHatAction(selected_sharl[0]));
    })
};

export default removeSharlHatFunction;