import React, {useEffect, useState} from 'react'
import UserTunaCan from './UserTunaCan'
import {connect} from "react-redux";

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DragCanSquare from "./DragCanSquare";
import BlankTunaCan from "./BlankTunaCan";
import {StyleTunaDragContainer, StyleTunaDragMainContainer} from "../../../../styles/tuna";
import selectTunaCanFunction from "../../../../store/actions/TunaCan/selectTunaCanAction";


function DragCanContainer(props) {
  const [squares,setSquares] = useState([])

  const selectCanHandler = (activeTunaCan) => {
      props.dispatch(selectTunaCanFunction(activeTunaCan));
  }

   useEffect(() => {
        const newSquares = [];
        for (let i = 0; i < 4; i++) {
          newSquares.push(renderSquare(i, props))
        }
        setSquares(newSquares)
           // eslint-disable-next-line
        },[props.UserReducer]);


    function renderSquare(i, props) {
        const x = i % 1;
        const y = Math.floor(i / 4);

        const allItems = props.UserReducer.items;
        if (allItems) {
        const allTunaCans = allItems.filter(item =>
            item.category === "tuna"
            );


        const activeTunaCan = allTunaCans[i];
            if (activeTunaCan) {
            return (
                <StyleTunaDragContainer key={i} id={i} onClick={() => selectCanHandler(activeTunaCan) }>
                    <DragCanSquare x={x} y={y} props={props} tunaCan={activeTunaCan} >
                        <UserTunaCan props={props} tunaCan={activeTunaCan} id={activeTunaCan.id}/>
                    </DragCanSquare>
                </StyleTunaDragContainer>
        )} else {
            return (
                <StyleTunaDragContainer key={i} id={i}  onClick={() => selectCanHandler(false)}>
                    <DragCanSquare x={x} y={y} props={props} >
                        <BlankTunaCan props={props} />
                    </DragCanSquare>
                </StyleTunaDragContainer>
        )}
    }
    }

  return (
        <DndProvider backend={HTML5Backend}>
            <StyleTunaDragMainContainer>
                {squares}
            </StyleTunaDragMainContainer>
        </DndProvider>
  )}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(DragCanContainer);