import styled from "styled-components";


export const StyleBodyEmail = styled.div`
    height: 100%;
    width: 50%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
    height: 100%;
    }
    p {
    font-size: 2vh;
    }
    `;


export const StyleBodyButton = styled.button`
    height: 40%;
    width: 50%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 2.5vh;
    border: none;
    a:-webkit-any-link {
    color: white;
    text-decoration: none;
    }   
    a {
    color: white;
    }
 `;


export const StyleBodyInput = styled.input`
  height: 40%;
  width: 80%;
  font-size: 2.2vh;  
  border: solid 1px #ebebeb;
  margin: 5px;  
  ::placeholder{
    color: #4c4c4c;
    font-size: 1vw;
  }
  :focus {
    outline:none;
    }  
   `;

export const StyleBodyForm = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
   `;


export const StyleBodyEmailItem = styled.div`
    height: 15vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    h2 {
     line-height: 3; 
     font-size: 5vh;
    }
    h1 {
     line-height: 2; 
     font-size: 2vh;
    }
    p1 {
    font-size 2vh; 
    color: red;
    }
    
    p2 {
    font-size 2vh; 
    color: black;
    }
   p3 {
   margin: 5%;
   font-size: 2vh;
   }
      a:-webkit-any-link {
    color: black;
    text-decoration: none;
}  

    `;


export const StyleBodySignInItem = styled.div`
    height: 15vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    h2 {
     line-height: 3; 
     font-size: 5vh;
    }
    h1 {
     line-height: 2; 
     font-size: 2vh;
    }
    p1 {
    font-size 2vh; 
    color: red;
    }
    
    p2 {
    font-size 2vh; 
    color: black;
    }
   p3 {
   margin: 5%;
   font-size: 2vh;
   }
      a:-webkit-any-link {
    color: white;
    text-decoration: none;
}  

    `;



export const StyleBodyVerifylItem = styled.div`
    height: 30vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    h2 {
     line-height: 3; 
     font-size: 5vh;
    }
    h1 {
     line-height: 2; 
     font-size: 3vh;
    }
    p1 {
    color: red;
    }
    `;


export const StyleBodyFullForm = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
   `;


export const DonutSpinner = styled.div`
@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

  display: inline-block;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: rgb(188,189,220);
  border-radius: 50%;
  width: 80px;
  height: 80px;
   margin-left: 135px;
   margin-right: 135px;    
  animation: donut-spin 1.2s linear infinite;
`;