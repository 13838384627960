import React, {useEffect, useState} from 'react'
import TunaCan from './TunaCan'
import {connect} from "react-redux";

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DropCanSquare from "./DropCanSquare";
import {BlankTunaCan} from "../DragCan/BlankTunaCan";
import {StyleSelectedTunaContainer, StyleTunaMainContainer} from "../../../../styles/tuna";

function DropCanContainer(props) {
  const [squares,setSquares] = useState([])

   useEffect(() => {
        const newSquares = [];
        for (let i=0; i < 1; i++) {
            newSquares.push(renderSquare(props, i))
        }
          setSquares(newSquares)
           // eslint-disable-next-line
        },[props.TunaCanReducer]);

    function renderSquare(props, i) {
        const selectedTunaCan = props.TunaCanReducer.selectedTunaCan;
        if (selectedTunaCan) {
            const selectedTunaCanId = props.TunaCanReducer.selectedTunaCan.id
            if (selectedTunaCanId) {
                return (
                    <StyleSelectedTunaContainer key={i} id={i}>
                        <DropCanSquare props={props} tunaCan={selectedTunaCan} >
                            <TunaCan props={props} tunaCan={selectedTunaCan} id={selectedTunaCanId}/>
                        </DropCanSquare>
                    </StyleSelectedTunaContainer>
        )} } else {
            return (
                <StyleSelectedTunaContainer key={i} id={i}>
                  <DropCanSquare props={props} >
                    <BlankTunaCan props={props} />
                   </DropCanSquare>
                </StyleSelectedTunaContainer>
        )}
    }

  return (
        <DndProvider backend={HTML5Backend}>
            <StyleTunaMainContainer>
                {squares}
            </StyleTunaMainContainer>
        </DndProvider>
  )}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(DropCanContainer);
