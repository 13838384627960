import {getprize} from "../../types";
import generatePrize from "../../../containers/Games/SharlPrize/generatePrize";
import calculateBalanceFunction from "../../../containers/MainApp/updateBalance";
import {getUserItemsAction, setUserItemsAction} from "../MainApp/getUserItemsAction";


export const getPrizeAction = (data) => ({
    type: getprize,
    payload: data,
});


const getPrizeFunction = (props) => (dispatch) => {
    const prize = generatePrize();
    const balance = calculateBalanceFunction(props,"+", prize[0].amount);

    const item = {"item": prize[0].item};
    const prizeDateOG = new Date(Date.now());
    const prizeDateStr = new Date(prizeDateOG).toISOString();
    const prizeDate = prizeDateStr.split("T");
    const prizeSharl = props.GameReducer.prizesharl;
    const prize_top_sharl = {"prize_top_sharl": prizeSharl};
    const date_last_prize = {"date_last_prize": prizeDate[0]};
    const prize_data = Object.assign(balance,date_last_prize,prize_top_sharl,item);
    dispatch(getPrizeAction(prize));

    const userID = props.UserReducer.user.id

    const URL = `https://www.sharl.info/backend/api/users/items/${userID}/`;
    const token = props.SignInReducer.token;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'PATCH',
        body: JSON.stringify(prize_data),
        headers,
    };

     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        dispatch(setUserItemsAction(data.items));
        const removeProperties = (object, ...keys) => Object.entries(object).reduce((prev, [key, value]) => ({...prev, ...(!keys.includes(key) && { [key]: value }) }), {})
        const allData = removeProperties(data, 'items', 'sharls')
        const concatData = {"user":allData, "sharls":data.sharls, "items":data.items}
        dispatch(getUserItemsAction(concatData));
    })
};

export default getPrizeFunction;;