import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";

import { DndProvider } from 'react-dnd'
import {
    StyleResearchDragSharlContainer,
} from "../../../styles/test";
import {DragSharl} from "./DragSharl";
import {StyleTunaMainContainer} from "../../../styles/tuna";
import {HTML5Backend} from "react-dnd-html5-backend";


function DragContainer(props) {
  const [squares,setSquares] = useState([])

   useEffect(() => {
        const newSquares = [];
        for (let i = 0; i < 4; i++) {
          newSquares.push(renderSquare(i, props))
        }
        setSquares(newSquares)
           // eslint-disable-next-line
        },[props.UserReducer, props.ResearchReducer]);


    function renderSquare(i, props) {

        const allSharls = props.UserReducer.sharls;
        const selectedSharl = props.ResearchReducer.selectedSharl
        if (allSharls) {
            let allResearchSharls = []
            if (selectedSharl){
                allResearchSharls = allSharls.filter(sharl =>
            sharl.is_active===true && sharl.is_researching===false && sharl.id !==selectedSharl.id
            )} else {
                allResearchSharls = allSharls.filter(sharl =>
            sharl.is_active===true && sharl.is_researching===false
            )}

        const currentSharl = allResearchSharls[i];
            if (currentSharl) {
            return (
                <StyleResearchDragSharlContainer key={i} id={i} bgColor={currentSharl.color}>
                    <DragSharl sharl={currentSharl} props={props} id={currentSharl.id}/>
                </StyleResearchDragSharlContainer>
        )}
        }
    }

  return (
        <DndProvider backend={HTML5Backend}>
            <StyleTunaMainContainer>
                {squares}
            </StyleTunaMainContainer>
        </DndProvider>
  )}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(DragContainer);