import {getinvest} from "../../types";

export const getSharlInvestAction = (data) => ({
    type: getinvest,
    payload: data,
});


const URL = `https://www.sharl.info/backend/api/invest/`;

const getSharlInvestFunction = (props) => (dispatch) => {
    const token = props.SignInReducer.token
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'GET',
        headers
    };
 
     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        dispatch(getSharlInvestAction(data.invest));
    })
};
export default getSharlInvestFunction;