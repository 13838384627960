import {getshop} from "../../types";


export const getShopAction = (data) => ({
    type: getshop,
    payload: data,
});


const URL = `https://www.sharl.info/backend/api/shop/`;


const getShopFunction = (props) => (dispatch) => {
    const token = props.SignInReducer.token
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'GET',
        headers
    };
 
     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        dispatch(getShopAction(data[0]));
    })
};
export default getShopFunction;