import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {
    StyleSharlImageTankContainer,
    StyleTankInfoContainer,
    StyleTankInfoInner,
    StyleTankSharlHatContainer
} from "../../../../styles/tank";
import {
    StyleAllSharlItemDouble,
    StyleAllSharlItemImg, StyleAllSharlItemSingle,
} from "../../../../styles/sharls";
import hungerLevelCalculator from "../../../Sharls/hungerLevelCalculator";



function TankInfo(props) {
    const [sharl, setSharl] = useState("")

    useEffect(() => {
        const selectedSharl = props.UserReducer.selectedSharl;
        if (selectedSharl.name) {
            selectedSharl.calculated_hunger = hungerLevelCalculator(selectedSharl.hunger_level, selectedSharl.last_fed)
        }
        setSharl(props.UserReducer.selectedSharl)
        // eslint-disable-next-line
        },[props.UserReducer]);

    const bgColorHandler = (color) => {
        return color;
     }

    if (sharl.name) {

    return (
            <StyleTankInfoContainer>
                <StyleTankInfoInner>
                     <StyleAllSharlItemImg bgColor={bgColorHandler(sharl.color)}>
                        <StyleSharlImageTankContainer>
                            <img src={sharl.sharl_avatar} alt={sharl.sharl_avatar} />
                        </StyleSharlImageTankContainer>
                        { sharl.headgear ?
                            <StyleTankSharlHatContainer>
                                { sharl.headgear.image.includes("https") ?
                                    < img src={sharl.headgear.image} alt={sharl.headgear.name} />
                                 :
                                    <img src={"https://www.sharl.info"+sharl.headgear.image} alt={sharl.headgear.name} />
                                }
                            </StyleTankSharlHatContainer>
                        : ""
                        }

                    </StyleAllSharlItemImg>
                    <StyleAllSharlItemDouble>
                        <h2>{sharl.name}</h2>
                    </StyleAllSharlItemDouble>
                    { sharl.is_researching ?
                        <StyleAllSharlItemSingle>
                            <p><b><i>Researching</i></b></p>
                        </StyleAllSharlItemSingle>
                    :    <StyleAllSharlItemSingle>
                        </StyleAllSharlItemSingle>
                    }
                    <StyleAllSharlItemSingle>
                        <p>Genus: {sharl.genus}</p>
                    </StyleAllSharlItemSingle>
                    <StyleAllSharlItemSingle>
                        <p>Age: {sharl.age} days</p>
                    </StyleAllSharlItemSingle>
                    <StyleAllSharlItemSingle>
                        <p>Favorite Fish or Food: {sharl.fav_fish_or_food}</p>
                    </StyleAllSharlItemSingle>
                    <StyleAllSharlItemSingle>
                        <p>Hunger Level: {sharl.calculated_hunger}</p>
                    </StyleAllSharlItemSingle>
                </StyleTankInfoInner>
            </StyleTankInfoContainer>
    );

    } else {
        return (
            <StyleTankInfoContainer>
            <StyleTankInfoInner>
            </StyleTankInfoInner>
            </StyleTankInfoContainer>

        )
    }
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(TankInfo);
