import {researchcat} from "../../types";

export const selectResearchAction = (data) => ({
    type: researchcat,
    payload: data,
});


const selectResearchFunction = (research) => (dispatch) => {
        dispatch(selectResearchAction(research));
};

export default selectResearchFunction;