import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {
    StyleGamesBody,
    StyleGamesButton,
    StyleGamesButtonContainer,
    StyleGamesContainer,
    StyleGamesFooter,
} from "../../../styles/games";
import {Link} from "react-router-dom";
import SharlQuizContent from "./SharlQuizContent";
import updateQuizStageFunction from "../../../store/actions/Games/updateQuizStageAction";

function SharlQuiz(props) {
    useEffect(()=>{
        props.dispatch(updateQuizStageFunction(0))
    // eslint-disable-next-line
    },[])

    return (
        <StyleGamesContainer>
           <StyleGamesBody>
            <SharlQuizContent/>
           </StyleGamesBody>
            <StyleGamesFooter>
                <StyleGamesButtonContainer>
                    <Link to="/games">
                        <StyleGamesButton>Back to Games</StyleGamesButton>
                    </Link>
                </StyleGamesButtonContainer>
            </StyleGamesFooter>
        </StyleGamesContainer>

);
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(SharlQuiz);
