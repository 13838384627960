import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import verifyTokenFunction from "../store/actions/Auth/verifyTokenAction";

export default function HOCWrapper(WrappedComponent) {
    function AuthComponent(props) {
        useEffect(() => {
            redirectUser()
             // eslint-disable-next-line
        }, []);

        const redirectUser = () => {
            if (!props.token) {
                props.history.push('/')
            } else {
                verifyTokenFunction(props);
            }
        };
        return <WrappedComponent {...props}/>
    }

    function mapStateToProps(state) {
        return {
            ...state,
            token: state.SignInReducer.token
        }
    }

    return connect(mapStateToProps)(AuthComponent)
}; 