import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";

import { DndProvider } from 'react-dnd'
import {DragFish} from "./DragFish";
import {HTML5Backend} from "react-dnd-html5-backend";
import {StyleSharlTankFeedItem, StyleTankFeedFoodContent} from "../../../styles/tanktest";


function DragFishContainer(props) {
  const [squares,setSquares] = useState([])

   useEffect(() => {
        const items = props.UserReducer.items;
        if (items) {
            const filteredItems = items.filter(item =>
                item.category === "fish" && item.quantity > 0)
            filteredItems.sort(sortFish);
            const newSquares = [];
            for (let i = 0; i < filteredItems.length; i++) {
                newSquares.push(renderSquare(i, props, filteredItems[i]))
            }
            setSquares(newSquares)
        }
        // eslint-disable-next-line
        },[props.UserReducer]);

    function sortFish( a, b ) {
        if ( a.item_code < b.item_code ){
            return -1;
        }
        if ( a.item_code > b.item_code ){
            return 1;
        }
        return 0;
    }


    function renderSquare(i, props, item) {
            return (
                <StyleSharlTankFeedItem key={i} id={i}>
                    <DragFish item={item} props={props} id={item.id}/>
                </StyleSharlTankFeedItem>
        )}

  return (
        <DndProvider backend={HTML5Backend}>
            <StyleTankFeedFoodContent>
                {squares}
            </StyleTankFeedFoodContent>
        </DndProvider>
  )}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(DragFishContainer);