import React, {useState} from 'react';
import {connect} from "react-redux";
import {StyleHelpPageContainer, StyleHelpPageItem, StyleHelpPageItemBlock} from "../../../styles/help";
import {faqQuestions} from "../FAQ/faqQuestions";


function FAQ(props) {
    const [selectedQ, setSelectedQ] = useState(999)

    const selectQHandler = (itemId) => {
        if (itemId===selectedQ){
            setSelectedQ(999)
        } else {
            setSelectedQ(itemId)
        }
    }
    const displayHandler = (itemId) => {
        if (itemId===selectedQ) {
            return "auto"
        } else {
            return "none"
        }
    }
    const heightHandler = (itemId) => {
        if (itemId===selectedQ) {
            return "20%"
        } else {
            return "10%"
        }
    }

    return (
        <StyleHelpPageContainer>
            {faqQuestions.map(item =>
            <StyleHelpPageItemBlock id={item.id} key={item.id} onClick={()=>selectQHandler(item.id)} minHeight={heightHandler(item.id)}>
                <StyleHelpPageItem bgColor={"white"}>
                   <h1>+</h1> <p>{item.question}</p>
                </StyleHelpPageItem>
                <StyleHelpPageItem display={displayHandler(item.id)} color={"white"} bgColor={"#336699"} marginLeft={"7%"}>
                    <p>{item.answer}</p>
                </StyleHelpPageItem>
            </StyleHelpPageItemBlock>
               )}
        </StyleHelpPageContainer>
            );
    }


const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(FAQ);
