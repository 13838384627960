function generateTokenSharls(tokenName) {
    const sharl_choices = []
    for (let i=0; i < 1; i++) {
        let sharl_avatar = "";
        const roundness = Math.floor(Math.random()  * (6 - 1) + 1);;
        const sleepyness = Math.floor(Math.random()  * (6 - 1) + 1);;
        const cheekyness = Math.floor(Math.random()  * (6 - 1) + 1);;
        const pancakeness = Math.floor(Math.random()  * (6 - 1) + 1);;
        const genus = tokenName.split(" Token")[0];
        const rarity = "special";
        const color = "#f589d6";

        if (genus==="Wharl") {
            sharl_avatar = "/images/sharls/wharl.png"
        } else if (genus==="Narl") {
            sharl_avatar = "/images/sharls/narl.png"
        } else if (genus==="Pufferfish") {
            sharl_avatar = "/images/sharls/pufferfish.png"
        } else if (genus==="Barl") {
            sharl_avatar = "/images/sharls/barl.png"
        } else if (genus==="Cute&Round") {
            sharl_avatar = "/images/sharls/cute_and_round_sharl.png"
        } else if (genus==="Cute Sharl") {
            sharl_avatar = "/images/sharls/cute_sharl.png"
        } else if (genus==="Angry Sharl") {
            sharl_avatar = "/images/sharls/angry_sharl.png"
        }else if (genus==="Happy Sharl") {
            sharl_avatar = "/images/sharls/happy_sharl.png"
        }
        sharl_choices.push({"id": i, "genus": genus, "sharl_avatar": sharl_avatar, "rarity": rarity, "color": color, "roundness": roundness, "sleepyness": sleepyness, "cheekyness": cheekyness, "pancakeness": pancakeness})
        }
        return(sharl_choices);
}
export default generateTokenSharls;