import {shopstage} from "../../types";

export const updateShopStageAction = (data) => ({
    type: shopstage,
    payload: data,
});


const updateShopStageFunction = (data) => (dispatch) => {
    dispatch(updateShopStageAction(data));
};

export default updateShopStageFunction;