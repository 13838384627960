import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    StyleShopBodyContent, StyleShopBodyContentItemHeader,
} from "../../styles/shop";
import ShopItem from "./ShopItem";

function ShopBgs(props) {
      const [items, setItems] = useState();

    useEffect(()=>{
        const allItems = props.ShopReducer.inventory
        if (allItems) {
            const blankItems = []
            blankItems.push(allItems.background_item_1)
            blankItems.push(allItems.background_item_2)
            blankItems.push(allItems.background_item_3)
            blankItems.push(allItems.background_item_4)
            setItems(blankItems)
        }
    },[props.ShopReducer.inventory])


    return (
        <StyleShopBodyContent>
            <StyleShopBodyContentItemHeader>
                <p>Backgrounds</p>
                <h1>*Only for use in Happy Sharl's Studio</h1>
            </StyleShopBodyContentItemHeader>
            { items ?
                <>
                {items.map(item =>
                    <ShopItem id={item.id} key={item.id} props={props} item={item} />
                        )}
                </>
                : "" }
            </StyleShopBodyContent>
            );
};


const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(ShopBgs);
