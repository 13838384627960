import {updatebalance} from "../../types";

export const updateBalanceAction = (data) => ({
    type: updatebalance,
    payload: data
});

const updateBalanceFunction = (props,balance) => (dispatch) => {
    const URL = "https://www.sharl.info/backend/api/users/me/";
    const token = props.SignInReducer.token;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'PATCH',
        body: JSON.stringify(balance),
        headers,
    };

     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        dispatch(updateBalanceAction(data));
    })
};

export default updateBalanceFunction;