import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";

import SackItems from "./SackItems";
import {
    StyleSackBody,
    StyleSackBodyLeft,
    StyleSackBodyRight,
    StyleSackBodyRightMain,
    StyleSackContainer,
    StyleSackFooter,
    StyleSackFooterItem,
    StyleSackFooterButton,
    StyleTrashImageContainer,
    StyleSharlSelectedItem,
    StyleTrashImageDelete,
    StyleSackConfirmDeleteButton,
    StyleDeleteQuantityInput,
    StyleTrashImage,
    StyleUpgradeButton,
    StyleUpgradeCancelButton,
    StyleUpgradeContainer,
    StyleUpgradeContainerMain, StyleUpgradeContainerClose,
} from "../../styles/sack";
import deleteItemFunction from "../../store/actions/Sack/deleteItemAction";
import clearSelectedItemFunction from "../../store/actions/Market/clearSelectedItemAction";
import upgradeBagFunction from "../../store/actions/Sack/upgradeBagAction";

function SackBody(props) {
    const [maxItems, setMaxItems] = useState(props.UserReducer.user.max_items);
    const [bagLevel, setBagLevel] = useState(props.UserReducer.user.bag_level);
    const [numItems, setNumItems] = useState();
    const [selectedItem, setSelectedItem] = useState("");
    const [deleteQuantity, setDeleteQuantity] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [upgrade, setUpgrade] = useState("");
    const [errorMessage, setErrorMessage] = useState("");


    useEffect(() => {
        props.dispatch(clearSelectedItemFunction());
        // eslint-disable-next-line
        },[]);

    useEffect(() => {
        setMaxItems(props.UserReducer.user.max_items);
        setBagLevel(props.UserReducer.user.bag_level);
        const userItems = props.UserReducer.items;
        const userItemsBlankHat = userItems.filter(item =>
            item.name!=="Blank Hat");
        const filteredItems = userItemsBlankHat.filter(item =>
            item.quantity!==0 && item.quantity > item.quantity_in_use
        )
        setNumItems(filteredItems.length)
        // eslint-disable-next-line
        },[props.UserReducer]);

    useEffect(() => {
        setSelectedItem(props.UserReducer.selected_item[0]);
        setDeleteQuantity("1");
        setShowDelete(false);
        // eslint-disable-next-line
        },[props.UserReducer.selected_item]);


    const deleteItemHandler = (e) => {
           e.preventDefault();
            const data = {
            "item": selectedItem.id,
            "delete_quantity": deleteQuantity,
             "quantity": selectedItem.quantity,
             "quantity_in_use": selectedItem.quantity_in_use,
        };

        props.dispatch(deleteItemFunction(props, data));
        const newQuantity = selectedItem;
        newQuantity.quantity = selectedItem.quantity - deleteQuantity;
        setSelectedItem(newQuantity)
        if (newQuantity.quantity === 0) {
            clearSelectedItemFunction()
        }
        setShowDelete(false);
    };

     const showDeleteHandler = (e) => {
         setShowDelete(!showDelete)
     }
     const upgradeHandler = (e) => {
         setSelectedItem("")
         setUpgrade(true)
     }
     const closeUpgradeHandler = (e) => {
         if (props.UserReducer.selected_item) {
             setSelectedItem(props.UserReducer.selected_item[0])
         }
         setUpgrade(false)
     }

     const upgradeConfirmHandler = (new_bag_level) => {
         if (new_bag_level===2) {
             if (props.UserReducer.user.balance < 20000) {
                 setErrorMessage("Not Enough Shadollars")
             } else {
                 props.dispatch(upgradeBagFunction(props,new_bag_level))
                 setUpgrade(false)
             }
         }
         else if (new_bag_level===3) {
            if (props.UserReducer.user.balance < 50000) {
                 setErrorMessage("Not Enough Shadollars")
            } else {
                 props.dispatch(upgradeBagFunction(props,new_bag_level))
                setUpgrade(false)
            }
        }
     }

    return (
     <StyleSackContainer>
     <StyleSackBody>
        <StyleSackBodyLeft>
            <SackItems props={props}/>
        </StyleSackBodyLeft>

   <StyleSackBodyRight>
        <StyleSackBodyRightMain>


    {   selectedItem ?
         <>
        <StyleSharlSelectedItem>
        <img src={`https://www.sharl.info/${selectedItem.image}`} alt={selectedItem.name} />
        </StyleSharlSelectedItem>

        <StyleSharlSelectedItem>
        <h1>{selectedItem.name}</h1>
        <p>{selectedItem.description}</p>
        <p>Number Owned: {selectedItem.quantity - selectedItem.quantity_in_use} </p>
        <p>Value: {selectedItem.value}</p>
        </StyleSharlSelectedItem>
    { !showDelete ?
        <StyleSharlSelectedItem>
        <StyleTrashImageContainer>
            <StyleTrashImage src={require("../../assets/images/misc/trash_can.png")} alt="delete" onClick={(e) => showDeleteHandler(e)} />
        </StyleTrashImageContainer>
         </StyleSharlSelectedItem>
        :
         <StyleSharlSelectedItem>
         { selectedItem.quantity - selectedItem.quantity_in_use === 1 ?
            <StyleTrashImageContainer>
                <StyleSackConfirmDeleteButton onClick={(e) => deleteItemHandler(e)}>Confirm Delete</StyleSackConfirmDeleteButton>
                <StyleSackConfirmDeleteButton onClick={(e) => showDeleteHandler(e)}>Cancel</StyleSackConfirmDeleteButton>
            </StyleTrashImageContainer>
            :
        <>
            <StyleTrashImageContainer>
                <p> {deleteQuantity ? deleteQuantity : 1} </p>
                <StyleTrashImageDelete>
                   <StyleDeleteQuantityInput
                        type="range"
                        onChange={(e) => setDeleteQuantity(e.currentTarget.value)}
                        defaultValue="1"
                        min="1"
                        max= {selectedItem.quantity-selectedItem.quantity_in_use}
                    />
                </StyleTrashImageDelete>
            </StyleTrashImageContainer>
            <StyleTrashImageContainer>
                 <StyleSackConfirmDeleteButton onClick={(e) => deleteItemHandler(e)}>Confirm Delete</StyleSackConfirmDeleteButton>
                 <StyleSackConfirmDeleteButton onClick={(e) => showDeleteHandler(e)}>Cancel</StyleSackConfirmDeleteButton>
                </StyleTrashImageContainer>
         </>
         }
               </StyleSharlSelectedItem>
        }

        </>
        : ""
        }
        <>
            {upgrade ?
                <StyleUpgradeContainer>
                    {bagLevel === 1 ?
                        <StyleUpgradeContainerMain>
                            <p>Upgrade Sack to Level 2</p>
                            <p><b>30 items</b></p>
                            <StyleUpgradeButton onClick={(e) => upgradeConfirmHandler(2)}>Upgrade 20,000<img
                                src={require("../../assets/images/misc/shadollar.png")}
                                alt={"shadollar"}/></StyleUpgradeButton>
                            <p>{errorMessage}</p>
                        </StyleUpgradeContainerMain>
                        :
                        <StyleUpgradeContainerMain>
                            <p>Upgrade Sack to Level 3</p>
                            <p><b>45 items</b></p>
                            <StyleUpgradeButton onClick={(e) => upgradeConfirmHandler(3)}>Upgrade 50,000<img
                                src={require("../../assets/images/misc/shadollar.png")}
                                alt={"shadollar"}/></StyleUpgradeButton>
                            <p>{errorMessage}</p>
                        </StyleUpgradeContainerMain>

                    }
                    <StyleUpgradeContainerClose>
                        <StyleUpgradeCancelButton onClick={()=>closeUpgradeHandler()}>Close</StyleUpgradeCancelButton>
                    </StyleUpgradeContainerClose>

                </StyleUpgradeContainer>
            : ""}
        </>
        </StyleSackBodyRightMain>

    <StyleSackFooter>
      <StyleSackFooterItem>
            <p>Total Items: {numItems} / {maxItems}</p>
      </StyleSackFooterItem>
      <StyleSackFooterItem>
          {bagLevel < 3 ?
            <StyleSackFooterButton onClick={()=>upgradeHandler()}>UPGRADE</StyleSackFooterButton>
    : "" }
      </StyleSackFooterItem>
    </StyleSackFooter>

    </StyleSackBodyRight>

    </StyleSackBody>

    </StyleSackContainer>

    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(SackBody);
