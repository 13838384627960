import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';

import {
    StyleSharlImage,
    StyleSharlItemContainer, StyleSharlItemOuter,
    StyleSharlSackItem,     StyleSharlSackItemContainer, StyleSharlSackItemQuantity,
} from "../../styles/sack";
import selectItemFunction from "../../store/actions/Sack/selectItemAction";


function SackItems(props) {
    const [items,setItems] = useState(props.UserReducer.items);
    const [firstPage, setFirstPage] = useState([]);
    const [secondPage, setSecondPage] = useState([]);
    const [thirdPage, setThirdPage] = useState([]);
    const [itemPage, setItemPage] = useState(1);
    const [bagLevel, setBagLevel] = useState(props.UserReducer.user.bag_level);

    useEffect(() => {
       if (items) {
           setBagLevel(props.UserReducer.bag_level)
           const updatedItems = props.UserReducer.items
           setItems(updatedItems)
            const userItemsBlankHat = updatedItems.filter(item =>
                item.name!=="Blank Hat");
            const filteredItems = userItemsBlankHat.filter(item =>
                item.quantity!==0 && item.quantity > item.quantity_in_use);
           setBagLevel(props.UserReducer.user.bag_level)
           const numBlanks = props.UserReducer.user.max_items - filteredItems.length
           for (let i=0; i < numBlanks; i++) {
               filteredItems.push({"key":`b${i}`, "id":`b${i}` })
           }
           setFirstPage(filteredItems.slice(0,15))
           if (props.UserReducer.user.bag_level>1) {
               setSecondPage(filteredItems.slice(15,30))
           }
           if (props.UserReducer.user.bag_level>2) {
               setThirdPage(filteredItems.slice(30,45))
           }
       }
               // eslint-disable-next-line
    },[props.UserReducer])


    const selectItemHandler = (e) => {
        e.preventDefault();
        let selectedItemId = e.currentTarget.id;
        if (!selectedItemId.includes("b")) {
        const selectedItem = items.filter(item =>
        // eslint-disable-next-line
        item.id == selectedItemId);
        props.dispatch(selectItemFunction(selectedItem));
        }
    }

    const goToPageHandler = (page) => {
        setItemPage(page)
    }
    return (
        <StyleSharlSackItemContainer>
        <StyleSharlImage>
        <StyleSharlItemOuter>
            { itemPage > 1 ?
                <>
                    {itemPage === 2 ?
                        <h2 onClick={() => goToPageHandler(1)}>{"<"}</h2>
                        :
                        <h2 onClick={() => goToPageHandler(2)}>{"<"}</h2>
                    }
                </>
                :  <h2 id="hide">{"<"}</h2>
         }

         <StyleSharlItemContainer>
    {itemPage === 1 ?
        <>
           { firstPage.map(item =>
                <StyleSharlSackItem id={item.id} key={item.id} onClick={(e) => selectItemHandler(e)}>
                    {item.image?
                        <>
                        <img src={`https://www.sharl.info/${item.image}`} alt={item.name} />
                        <h1>  {item.name} </h1>
                        </>
                        : "" }
                    {item.quantity ?
                 <StyleSharlSackItemQuantity>
                        <p>{item.quantity - item.quantity_in_use} </p>
                    </StyleSharlSackItemQuantity>
                        :"" }
                </StyleSharlSackItem>
                )}
        </>
    :""}
    {itemPage === 2 ?
        <>
           { secondPage.map(item =>
                <StyleSharlSackItem id={item.id} key={item.id} onClick={(e) => selectItemHandler(e)}>
                    {item.image?
                        <>
                        <img src={`https://www.sharl.info/${item.image}`} alt={item.name} />
                        <h1>  {item.name} </h1>
                        </>
                        : "" }
                    {item.quantity ?
                 <StyleSharlSackItemQuantity>
                        <p>{item.quantity - item.quantity_in_use} </p>
                    </StyleSharlSackItemQuantity>
                        :"" }
                </StyleSharlSackItem>
                )}
        </>
    :""}
    {itemPage === 3 ?
        <>
           { thirdPage.map(item =>
                <StyleSharlSackItem id={item.id} key={item.id} onClick={(e) => selectItemHandler(e)}>
                    {item.image?
                        <>
                        <img src={`https://www.sharl.info/${item.image}`} alt={item.name} />
                        <h1>  {item.name} </h1>
                        </>
                        : "" }
                    {item.quantity ?
                 <StyleSharlSackItemQuantity>
                        <p>{item.quantity - item.quantity_in_use} </p>
                    </StyleSharlSackItemQuantity>
                        :"" }
                </StyleSharlSackItem>
                )}
        </>
    :""}
        </StyleSharlItemContainer>
            {itemPage < 3 && bagLevel > 1 ?
                <>
                    {itemPage === 1 ?
                        <h2 onClick={() => goToPageHandler(2)}>{">"}</h2>
                        :
                        <>
                            {bagLevel > 2 ?
                                <h2 onClick={() => goToPageHandler(3)}>{">"}</h2>
                                :
                                <h2 id="hide">{">"}</h2>
                            }
                        </>
                    }
                </>
                :
                <h2 id="hide">{">"}</h2>
          }

        </StyleSharlItemOuter>
        </StyleSharlImage>
            </StyleSharlSackItemContainer>
)
}

const mapStateToProps = state => {
    return {
      ...state,
      };
    };

export default connect(mapStateToProps)(SackItems);
