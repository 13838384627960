import React from 'react'
import { canMoveSharl } from './Movement'
import { ItemTypes } from './Constants'
import { useDrop } from 'react-dnd'
import moveSharlFunction from "./moveSharlAction";
import {StyleTankNoDrop, StyleTankOverDrop, StyleTankSquareMain} from "../../../../styles/tank";
import Square from "./Square";

function TankSquare({ x, y, props, sharl, children }) {

  const dropHandler = (props, monitor, [x,y]) => {
        props.dispatch(moveSharlFunction(props,monitor.itemId,[x, y],false))
    }

  const [{ isOver, canDrop }, drop] = useDrop({
        accept: ItemTypes.SELECTEDSHARL,
        drop: monitor => dropHandler(props, monitor, [x,y]),
        canDrop: (monitor) => canMoveSharl(props, x, y, monitor.itemId),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop()
    })

  })

    return (
        <StyleTankSquareMain ref={drop}>
            <Square>{children}</Square>
                {isOver && canDrop ?
                    <StyleTankOverDrop />
                : ""}

                {isOver && !canDrop ?
                    <StyleTankNoDrop />
                : ""}
        </StyleTankSquareMain>
  )
}

export default TankSquare;