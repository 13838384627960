import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    StyleGamesButton,
    StyleGamesButtonContainer,
    StyleGamesBWNBody, StyleGamesBWNButton,
    StyleGamesBWNContainer,
    StyleGamesBWNPlayLeft,
    StyleGamesBWNPlayMiddle,
    StyleGamesBWNPlaySharls, StyleGamesBWNStartButton,
    StyleGamesBWNTotal,
    StyleGamesBWNTotalButtons,
    StyleGamesBWNTotalText,
    StyleGamesContainer,
    StyleGamesFooter,
    StyleGamesOpponentImage,
    StyleGamesOpponentText,
    StyleGamesOpponentTextContainer,
    StyleGamesOpponentTextItems
} from "../../../styles/games";
import {Link} from "react-router-dom";
import calculateBalanceFunction from "../../MainApp/updateBalance";
import updateBalanceFunction from "../../../store/actions/MainApp/updateBalanceAction";
import claimBNWPrizeFunction from "../../../store/actions/Games/claimBNWPrizeAction";


function BarlWharlNarl(props) {
    const [round, setRound] = useState(1)
    const [opponent, setOpponent] = useState("Angry Sharl")
    const [prizeAmount, setPrizeAmount] = useState(0)
    const [selectedPlayer, setSelectedPlayer] = useState()
    const [opponentPlayer, setOpponentPlayer] = useState()
    const [showStartButton, setShowStartButton] = useState(false)
    const [showOutcome,setShowOutcome] = useState(false)
    const [roundResult, setRoundResult] = useState()
    const [showPrizeButtons, setshowPrizeButtons] = useState(false)
    const [highScore, setHighScore] = useState(0)

    useEffect(()=>{
        generateOpponent()
    },[])

    useEffect(()=>{
        const userHighScore = props.UserReducer.user.bnw_high_score;
        setHighScore(userHighScore);
    },[props.UserReducer])

    const generateOpponent = () =>{
        const sharls  = ['Round Sharl', 'Cheeky Sharl', 'Pancake Sharl', 'Angry Sharl', 'Sleepy Sharl', 'Cute Sharl'];
        const opponentSharl = Math.floor(Math.random() * sharls.length);
        setOpponent(sharls[opponentSharl])
    }


    const selectPlayerHandler = (player) => {
        if (showOutcome===false){
        setSelectedPlayer(player)
        setShowStartButton(true)
        }
    }

    const roundHandler = () => {
        //barl beats wharl
        //wharl beats narl
        //narl beats barl
        if (round === 1) {
            const userBal = calculateBalanceFunction(props, "-", 10)
            props.dispatch(updateBalanceFunction(props, userBal))
        }
        setShowStartButton(false)
        setShowOutcome(true)
        const Oplayers = ["Barl","Wharl","Narl"]
        const randomPlayer = Math.floor(Math.random() * 3);
        const opponentSelectedPlayer = Oplayers[randomPlayer]
        setOpponentPlayer(opponentSelectedPlayer);
        if (selectedPlayer===opponentSelectedPlayer){
            setRoundResult("Draw")
            setshowPrizeButtons(true)
        } else if (selectedPlayer==="Barl"){
            if (opponentSelectedPlayer==="Wharl"){
                setRoundResult("Win")
                setshowPrizeButtons(true)
                if (prizeAmount===0){
                    setPrizeAmount(20)
                } else {
                    setPrizeAmount(prizeAmount*2)
                }
            } else {
                setRoundResult("Lose")
            }
        } else if (selectedPlayer==="Wharl"){
            if (opponentSelectedPlayer==="Narl"){
                setRoundResult("Win")
                setshowPrizeButtons(true)
                if (prizeAmount===0){
                    setPrizeAmount(20)
                } else {
                    setPrizeAmount(prizeAmount*2)
                }
            } else {
                setRoundResult("Lose")
            }
        } else if (selectedPlayer==="Narl"){
            if (opponentSelectedPlayer==="Barl"){
                setRoundResult("Win")
                setshowPrizeButtons(true)
                if (prizeAmount===0){
                    setPrizeAmount(20)
                } else {
                    setPrizeAmount(prizeAmount*2)
                }
            } else {
                setRoundResult("Lose")
            }
        }
    }
    const resetHandler = (player) => {
        setShowStartButton(false)
        setShowOutcome(false)
        setSelectedPlayer("")
        setRound(1)
        setPrizeAmount(0)
        setOpponentPlayer("")
        setshowPrizeButtons(false)
        generateOpponent()
    }

    const claimPrizeHandler = () => {
        const userBal = calculateBalanceFunction(props,"+", prizeAmount)

        if (prizeAmount > highScore){
            props.dispatch(claimBNWPrizeFunction(props,userBal,prizeAmount))
            setHighScore(prizeAmount);
        } else {
            props.dispatch(claimBNWPrizeFunction(props,userBal,highScore))
        }
        setShowStartButton(false)
        setShowOutcome(false)
        setSelectedPlayer("")
        setRound(1)
        setPrizeAmount(0)
        setOpponentPlayer("")
        setshowPrizeButtons(false)
        generateOpponent()
    }

    const nextRoundHandler = () => {
        //need to dispatch amount won and then rsest all
        setShowStartButton(false)
        setshowPrizeButtons(false)
        setShowOutcome(false)
        setSelectedPlayer("")
        setRound(round+1)
        setOpponentPlayer("")
    }

       const bgColorHandler = (player) => {
        if (player===selectedPlayer) {
            return "#90c3f5"
        } else {
            return "#336699"
        }
    }

    return (
        <StyleGamesContainer>
           <StyleGamesBWNBody>
               <StyleGamesBWNContainer>
                <StyleGamesBWNPlayLeft>
                    <p>Select Your Player</p>
                    <StyleGamesBWNPlaySharls bgColor={bgColorHandler("Barl")} onClick={()=>selectPlayerHandler("Barl")}>
                        <img src={require("../../../assets/images/games/sharls/Barl.png")} alt={"barl"} />
                    </StyleGamesBWNPlaySharls>
                    <StyleGamesBWNPlaySharls bgColor={bgColorHandler("Wharl")} onClick={()=>selectPlayerHandler("Wharl")}>
                        <img src={require("../../../assets/images/games/sharls/Wharl.png")} alt={"wharl"} />
                    </StyleGamesBWNPlaySharls>
                    <StyleGamesBWNPlaySharls bgColor={bgColorHandler("Narl")}onClick={()=>selectPlayerHandler("Narl")}>
                        <img src={require("../../../assets/images/games/sharls/Narl.png")} alt={"narl"} />
                    </StyleGamesBWNPlaySharls>
                </StyleGamesBWNPlayLeft>
                <StyleGamesBWNPlayMiddle>
                    <StyleGamesOpponentImage>
                        <img src={require("../../../assets/images/games/sharls/"+opponent+".png")} alt={"sharl"} />
                    </StyleGamesOpponentImage>
                   <StyleGamesOpponentText>
                       <>
                       {showStartButton ?
                           <>
                            <p>Challenge {opponent} with {selectedPlayer}?</p>
                                <>
                               {round > 1 ?
                                <StyleGamesBWNStartButton onClick={()=>roundHandler()}>Fight!</StyleGamesBWNStartButton>
                                        :
                                   <StyleGamesBWNStartButton onClick={()=>roundHandler()}>10<img src={require("../../../assets/images/misc/shadollar.png")} alt={"shadollar"}/></StyleGamesBWNStartButton>
                                }
                                </>
                           </> : ""}
                       </>
                       <>
                       {showOutcome ?
                           <StyleGamesOpponentTextContainer>
                               <StyleGamesOpponentTextItems>
                                    <img src={require("../../../assets/images/games/sharls/"+selectedPlayer+".png")} alt={"sharl"} />
                               </StyleGamesOpponentTextItems>
                               <StyleGamesOpponentTextItems>
                                   <img src={require("../../../assets/images/games/sharls/"+opponentPlayer+".png")} alt={"sharl"} />
                                </StyleGamesOpponentTextItems>
                                <StyleGamesOpponentTextItems>
                                    {roundResult==="Win" ?
                                    <>
                                        <p>{opponent} selected <b>{opponentPlayer}</b></p>
                                        <p><b>{selectedPlayer}</b> beats <b>{opponentPlayer}</b></p>
                                        <p>You {roundResult}!</p>
                                     </>
                                        :""}
                                    {roundResult==="Lose" ?
                                    <>
                                        <p>{opponent} selected <b>{opponentPlayer}</b></p>
                                        <p><b>{opponentPlayer}</b> beats <b>{selectedPlayer}</b></p>
                                        <p>You {roundResult}!</p>
                                     </>
                                        :""}
                                    {roundResult==="Draw" ?
                                    <>
                                        <p>You both chose <b>{opponentPlayer}</b></p>
                                        <p>You {roundResult}!</p>
                                     </>
                                        :""}
                                </StyleGamesOpponentTextItems>
                                </StyleGamesOpponentTextContainer> : ""}
                       </>
                       </StyleGamesOpponentText>
                </StyleGamesBWNPlayMiddle>
                <StyleGamesBWNTotal>
                    <StyleGamesBWNTotalText>
                        <h1>High Score</h1>
                        {highScore ?
                            <p>{highScore}</p>
                            :
                            <p>0</p>
                        }
                        <h1>Opponent</h1>
                        <p>{opponent}</p>
                        <h1>Round</h1>
                        <p>{round}</p>
                        <h1>Prize Amount</h1>
                        <p>{prizeAmount}</p>
                      </StyleGamesBWNTotalText>
                    <StyleGamesBWNTotalButtons>
                        {showPrizeButtons ?
                        <>
                        <StyleGamesBWNButton onClick={()=> nextRoundHandler()}>Next Round</StyleGamesBWNButton>
                            {prizeAmount > 0 ?
                        <StyleGamesBWNButton onClick={()=> claimPrizeHandler()}>Claim prize</StyleGamesBWNButton>
                                :""}
                        </>
                            :""}
                        <StyleGamesBWNButton onClick={()=> resetHandler()}>Reset</StyleGamesBWNButton>
                    </StyleGamesBWNTotalButtons>
                </StyleGamesBWNTotal>
               </StyleGamesBWNContainer>
           </StyleGamesBWNBody>
            <StyleGamesFooter>
                <StyleGamesButtonContainer>
                    <Link to="/games">
                        <StyleGamesButton>Back to Games</StyleGamesButton>
                    </Link>
                </StyleGamesButtonContainer>
            </StyleGamesFooter>
        </StyleGamesContainer>

         )
}
const mapStateToProps = state => {
  return {

      ...state,
    };
  };

export default connect(mapStateToProps)(BarlWharlNarl);
