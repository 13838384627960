import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";
import {StyleTankContainer} from "../../styles/tank";
import {
    StyleHelpContainer,
    StyleHelpContent,
    StyleHelpNavigation,
    StyleHelpNavigationItem
} from "../../styles/help";
import updateHelpTopicFunction from "../../store/actions/Help/updateHelpTopicAction";
import HelpContent from "./HelpContent";


function HelpBody(props) {
    const [topic, setTopic] = useState(props.HelpReducer.topic)

    useEffect(()=>{
        setTopic(props.HelpReducer.topic)
    },[props.HelpReducer])

    const fontHandler = (selectedTopic) => {
        if (selectedTopic===topic){
            return "700"
        } else {
            return "300"
        }
    }

    const bgColor = (selectedTopic) => {
        if (selectedTopic===topic){
            return "#336699"
        } else {
            return "white"
        }
    }

    const fontColor = (selectedTopic) => {
        if (selectedTopic===topic){
            return "white"
        } else {
            return "black"
        }
    }

    const topicHandler = (newTopic) => {
        props.dispatch(updateHelpTopicFunction(newTopic))
    }

    return (
        <StyleTankContainer>
            <StyleHelpContainer>
                <StyleHelpNavigation>
                    <StyleHelpNavigationItem
                        fontWeight={fontHandler("help")}
                        bgColor={bgColor("help")}
                        fontColor={fontColor("help")}
                        borderRadius={"24px 0px 0px 0px"}
                        onClick={()=>topicHandler("help")}>
                        <p>Help</p>
                    </StyleHelpNavigationItem>
                    <StyleHelpNavigationItem
                        fontWeight={fontHandler("faq")}
                        bgColor={bgColor("faq")}
                        fontColor={fontColor("faq")}
                        onClick={()=>topicHandler("faq")}>
                        <p>FAQS</p>
                    </StyleHelpNavigationItem>
                    <StyleHelpNavigationItem
                        fontWeight={fontHandler("contact")}
                        bgColor={bgColor("contact")}
                        fontColor={fontColor("contact")}
                        onClick={()=>topicHandler("contact")}>
                        <p>Contact Us</p>
                    </StyleHelpNavigationItem>
                    <StyleHelpNavigationItem
                        fontWeight={fontHandler("terms")}
                        bgColor={bgColor("terms")}
                        fontColor={fontColor("terms")}
                        borderRadius={"0px 24px 0px 0px"}
                        onClick={()=>topicHandler("terms")}>
                        <p>T&Cs</p>
                    </StyleHelpNavigationItem>
                </StyleHelpNavigation>
                <StyleHelpContent>
                    <HelpContent/>
                </StyleHelpContent>
            </StyleHelpContainer>
        </StyleTankContainer>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(HelpBody);
