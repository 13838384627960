import React from 'react'
import { ItemTypes } from '../Constants'
import { useDrop } from 'react-dnd'
import {StyleTankNoDrop, StyleTankOverDrop, StyleTankSquareMain} from "../../../../styles/tank";
import Square from "./Square";
import selectTunaCanFunction from "../../../../store/actions/TunaCan/selectTunaCanAction";

function DragCanSquare({props, children }) {

  const dropHandler = () => {
       props.dispatch(selectTunaCanFunction())
    }


  const [{ isOver, canDrop }, drop] = useDrop({
        accept: ItemTypes.SELECTEDCAN,
        drop: monitor => dropHandler(),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
    })

  })

    return (
        <StyleTankSquareMain ref={drop}>
            <Square>{children}</Square>
                {isOver && canDrop ?
                  <StyleTankOverDrop />
                : ""}

                {isOver && !canDrop ?
                    <StyleTankNoDrop />
                : ""}
        </StyleTankSquareMain>
  )
}

export default DragCanSquare;