import React from 'react';
import ProfileBody from "./ProfileBody";

function Profile() {
  return (
        <ProfileBody/>
  );
}

export default Profile;
