import React, {useState} from 'react';
import {connect} from "react-redux";
import {
    StyleQuizForm,
    StyleQuizHome, StyleQuizInputBox,
    StyleQuizNextButton,
    StyleQuizQuestions,
    StyleQuizQuestionsHeader,
    StyleQuizQuestionsItems,
} from "../../../styles/games";
import updateQuizStageFunction from "../../../store/actions/Games/updateQuizStageAction";
import questions from "./questions";
import calculateResults from "./calculateResults";
import quizResultsFunction from "../../../store/actions/Games/quizResultsAction";

function SharlQuizQuestions(props) {
    const [quizAnswers,setQuizAnswers] = useState([
        {"question":0, "answer":""},
        {"question":1, "answer":""},
        {"question":2, "answer":""},
        {"question":3, "answer":""},
        {"question":4, "answer":""}
        ])
    const [questionNumber,setQuestionNumber] = useState(0)
    const [checked, setChecked] = useState(false)
    const [answered, setAnswered] = useState(false)

    const sharlQuestions = questions;


    const questionHandler = (e) => {
        if (answered) {
            setQuestionNumber(questionNumber + 1)
            setChecked(false)
            setAnswered(false)
        }
    }

    const answerHandler = (e) => {
        //take copy of array then make change and set again
        const quiz = quizAnswers;
        quiz[questionNumber].answer = e.target.value;
        setQuizAnswers(quiz);
        setChecked(e.target.value);
        setAnswered(true)
    }
    const finishQuizHandler = () => {
        const results = calculateResults(quizAnswers)
        props.dispatch(quizResultsFunction(results))

        props.dispatch(updateQuizStageFunction(2))
    }
        return (
            <StyleQuizHome>
                <StyleQuizQuestions>
                    <StyleQuizQuestionsHeader>
                        <h2>Question {sharlQuestions[questionNumber].id}</h2>
                        <h1>{sharlQuestions[questionNumber].question}</h1>
                    </StyleQuizQuestionsHeader>
                    <StyleQuizQuestionsItems textAlign={"flex-start"}>
                        <StyleQuizForm>
                            <div>
                                <StyleQuizInputBox type="radio" id="a1" name="radio" value="a1" checked={checked==="a1"} onChange={(e)=>answerHandler(e)}/>
                                <label htmlFor="a1">{sharlQuestions[questionNumber].a1}</label>
                            </div>
                            <div>
                                <StyleQuizInputBox type="radio" id="a2" name="radio" value="a2" checked={checked==="a2"} onChange={(e)=>answerHandler(e)}/>
                                <label htmlFor="a2">{sharlQuestions[questionNumber].a2}</label>
                            </div>
                            <div>
                                <StyleQuizInputBox type="radio" id="a3" name="radio" value="a3" checked={checked==="a3"} onChange={(e)=>answerHandler(e)}/>
                                <label htmlFor="a3">{sharlQuestions[questionNumber].a3}</label>
                            </div>
                            <div>
                                <StyleQuizInputBox type="radio" id="a4" name="radio" value="a4" checked={checked==="a4"} onChange={(e)=>answerHandler(e)}/>
                                <label htmlFor="a4">{sharlQuestions[questionNumber].a4}</label>
                            </div>
                        </StyleQuizForm>
                    </StyleQuizQuestionsItems>
                    <StyleQuizQuestionsItems textAlign={"center"} padding={"0%"}>
                        {questionNumber < 4 ?
                            <StyleQuizNextButton onClick={(e) => questionHandler(e)}>Next</StyleQuizNextButton>
                            :
                            <StyleQuizNextButton onClick={() => finishQuizHandler()}>Finish!</StyleQuizNextButton>
                        }
                    </StyleQuizQuestionsItems>
                </StyleQuizQuestions>
            </StyleQuizHome>
        )
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(SharlQuizQuestions);
