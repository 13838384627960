import React, {useEffect, useState} from 'react'
import InactiveSharl from './InactiveSharl'
import {connect} from "react-redux";


import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {StyleTankMainContainer, StyleTankSharlContainer} from "../../../../styles/tank";
import TankSquare from "./TankSquare";
import BlankSharl from "./BlankSharl";

function Tank(props) {
  const [squares,setSquares] = useState([])

   useEffect(() => {
        const newSquares = [];
        for (let i = 0; i < 16; i++) {
          newSquares.push(renderSquare(i, props))
        }
        setSquares(newSquares)
           // eslint-disable-next-line
        },[props.UserReducer]);

   useEffect(() => {
        const newSquares = [];
        for (let i = 0; i < 16; i++) {
          newSquares.push(renderSquare(i, props))
        }
        setSquares(newSquares)
        // eslint-disable-next-line
        },[]);

    function renderSquare(i, props) {
        const x = i % 4;
        const y = Math.floor(i / 4);

        const tankPosition = x + "/" + y;
        const allSharls = props.UserReducer.sharls;
        const isSharl = allSharls.filter(sharl =>
            sharl.tank_position === tankPosition
            );


        if (isSharl.length > 0) {
            return (
                <StyleTankSharlContainer key={i} id={i}>
                    <TankSquare x={x} y={y} props={props} sharl={isSharl[0]} >
                        <InactiveSharl props={props} sharl={isSharl[0]} id={isSharl[0].id}/>
                    </TankSquare>
                </StyleTankSharlContainer>
        )} else {
            return (
                <StyleTankSharlContainer key={i} id={i} >
                    <TankSquare x={x} y={y} props={props} >
                        <BlankSharl props={props} />
                    </TankSquare>
                </StyleTankSharlContainer>
        )}
    }

  return (
        <DndProvider backend={HTML5Backend}>
            <StyleTankMainContainer>
                {squares}
            </StyleTankMainContainer>
        </DndProvider>
  )}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(Tank);