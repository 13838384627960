import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import ShopCoins from "./ShopBgs";
import ShopHome from "./ShopHome";
import ShopTokens from "./ShopTokens";
import ShopHats from "./ShopHats";
import ShopCheckout from "./ShopCheckout";
import ShopCart from "./ShopCart";
import ShopComplete from "./ShopComplete";

function ShopBodyContent(props) {
    const [shopStage, setShopStage] = useState("home")

    useEffect(()=>{
        const shopStage = props.ShopReducer.stage;
        setShopStage(shopStage);
    },[props.ShopReducer])

    switch (shopStage) {
        case "home":
            return(
               <ShopHome/>
                               )
        case "coins":
            return(
                <ShopCoins/>
                )
        case "tokens":
            return(
                <ShopTokens/>
                    )
        case "hats":
            return(
                <ShopHats/>
                    )
        case "checkout":
            return(
                <ShopCheckout/>
                    )
        case "cart":
            return(
                <ShopCart/>
                    )
        case "completed":
            return(
                <ShopComplete/>
                    )
        default:
            return(
                <ShopHome/>
                )
        };

}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(ShopBodyContent);
