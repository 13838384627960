import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    StyleQuizEndButton,
    StyleQuizResults,
    StyleQuizResultsImg,
    StyleQuizResultsText,
    StyleQuizResultsTextContainer,
} from "../../../styles/games";
import updateQuizStageFunction from "../../../store/actions/Games/updateQuizStageAction";


function SharlQuizResults(props) {
    const [results,setResults] = useState(props.GameReducer.quiz_results);

    useEffect(()=>{
        setResults(props.GameReducer.quiz_results)
    },[props.GameReducer])

    const startQuizHandler = () => {
        props.dispatch(updateQuizStageFunction(0))

    }

    return (
        <StyleQuizResults>
            <StyleQuizResultsImg>
                <img src={require("../../../assets/images/games/sharls/"+results+".png")} alt={"sharl"} />
            </StyleQuizResultsImg>
            <StyleQuizResultsText>
                <StyleQuizResultsTextContainer>
                <p>It looks like you're a</p>
                <h2>{results}!!</h2>
                </StyleQuizResultsTextContainer>
            <StyleQuizEndButton onClick={()=>startQuizHandler()}>Take Quiz Again</StyleQuizEndButton>
            </StyleQuizResultsText>
        </StyleQuizResults>
);
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(SharlQuizResults);
