import React from 'react';
import {connect} from "react-redux";
import {StyleQuizHome, StyleQuizStartButton
} from "../../../styles/games";
import updateQuizStageFunction from "../../../store/actions/Games/updateQuizStageAction";


function SharlQuizHome(props) {

    const startQuizHandler = () => {
        props.dispatch(updateQuizStageFunction(1))

    }

    return (
        <StyleQuizHome>
            <h2>Which Sharl are you?</h2>
            <img src={require("../../../assets/images/misc/sharl_swim.png")} alt={"sharl"} />
            <p>Take this quiz to find out what type of Sharl you are!</p>
            <StyleQuizStartButton onClick={()=>startQuizHandler()}>Start!</StyleQuizStartButton>
        </StyleQuizHome>
);
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(SharlQuizHome);
