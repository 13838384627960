import {cleartuna} from "../../types";

export const clearTunaCanPrizeAction = () => ({
    type: cleartuna,
});


const clearTunaCanPrizeFunction = () => (dispatch) => {
    dispatch(clearTunaCanPrizeAction());
};

export default clearTunaCanPrizeFunction;