import React from 'react';
import {connect} from "react-redux";
import TankHomeBody from "./TankHomeBody";


function TankHome() {

    return (
        <TankHomeBody/>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(TankHome);
