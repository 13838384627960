import {hidesharlitems} from "../../types";

export const hideSharlItemsAction = () => ({
    type: hidesharlitems,
});


const hideSharlItemsFunction = () => (dispatch) => {
    dispatch(hideSharlItemsAction());
};

export default hideSharlItemsFunction;