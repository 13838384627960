import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    StyleFishCanvas,
    StyleFishCanvasContainer,
    StyleFishCanvasInfo, StyleCanvasInfoGameMode, StyleCanvasInfoGameModeItem,
    StyleCanvasInfoItem, StyleFishGameModeItem
} from "../../../styles/games";
import calculateBalanceFunction from "../../MainApp/updateBalance";
import claimFishPrizeFunction from "../../../store/actions/Games/claimFishPrizeAction";


function SharlFish(props) {
    const canvas = React.useRef(); // ADDED
    const [width, setWidth] = useState(1000)
    const [height, setHeight] = useState(650)

    const [sharlX, setSharlX] = useState(200)
    const [sharlY, setSharlY] = useState(200)
    const [direction, setDirection] = useState("left")

    const radius = 100;
    const velocity = 10;
  //  const [numBalls, setNumBalls] = useState()
    const [gameMode, setGameMode] = useState()
    const [balls,setBalls] = useState([]);
    const [ballRadius, setBallRadius] = useState(10);
    const ballVelocity = 20;
    const canvasBgColor = "#336699";
    const [numFish, setNumFish] = useState(0)
    const [numTrash, setNumTrash] = useState(0)
    const [energy, setEnergy] = useState(51)
    const [score, setScore] = useState(0)
    const [highScore, setHighScore] = useState(0)
    const [gameStatus, setGameStatus] = useState("play")

    const generateBalls = (quantity) => {
        const newBalls = []
        const ballColors = ["#5a93cc","#193c5e","#1b7fe0","#a2d0fc","#4b96de","#128bff", "#d2e5f7", "#d8e5f2"]
        const directions = ["plus", "minus"];

        for (let i = 0; i < quantity; i++) {
            const xPos = Math.floor(Math.random() * (width - ballRadius)) + ballRadius;
            const yPos = Math.floor(Math.random() * (height - ballRadius)) + ballRadius;
            const randomX = Math.floor(Math.random() * directions.length);
            const xDir = directions[randomX];
            const randomY = Math.floor(Math.random() * directions.length);
            const yDir = directions[randomY];
            const randomColor = Math.floor(Math.random() * ballColors.length);
            const ballColor = ballColors[randomColor];
            let trash = false;
            const trashRoll = Math.floor(Math.random() * 100);
            if (trashRoll===36){
                trash = true;
            }
            const ball = {
                "ballX": xPos,
                "ballY": yPos,
                "xDirection": xDir,
                "yDirection": yDir,
                "ballColor": ballColor,
                "trash": trash
                }
            newBalls.push(ball)
            }
        return newBalls;
    }


    const draw = (context) => {
        //draw the sharl
        context.fillStyle = canvasBgColor;
        context.fillRect(0, 0,width, height);

        const image = new Image();
        image.onload = () => {
           context.drawImage(image, sharlX, sharlY, radius, radius)
        }
        if (direction==="up") {
            image.src = require("../../../assets/images/games/up.png");
        } else if (direction==="down") {
            image.src = require("../../../assets/images/games/down.png");
        } else if (direction==="left") {
            image.src = require("../../../assets/images/games/left.png");
        } else if (direction==="right") {
            image.src = require("../../../assets/images/games/right.png");
        }
        setEnergy(energy-1)

        context.fillStyle = canvasBgColor;
        context.fillRect(0, 0, width, height);

        for (let i=0; i < balls.length; i++) {
            const isTrash = balls[i].trash
            if (isTrash){
                const trashImage = new Image();
                trashImage.onload = () => {
                    context.drawImage(trashImage, balls[i].ballX, balls[i].ballY, 30, 30)
                }
                trashImage.src = require("../../../assets/images/games/trash.png");
            } else {
                context.beginPath();
                context.fillStyle = balls[i].ballColor;
                context.arc(balls[i].ballX, balls[i].ballY, ballRadius, 0, 2 * Math.PI);
                context.fill();
                context.stroke();
            }

        }
    }

    const setUpHandler = (mode) => {
        const context = canvas.current.getContext('2d');
        drawStart(context);
        //need to draw start thing

        setEnergy(51);
        setNumFish(0);
        setNumTrash(0);
        setSharlY(275);
        setSharlX(400);
        setScore(0);

        //get the num of balls needed and reset it
        let ballsToMake = 0;
        let ballRadius = 10;
        if (mode==="eat_1"){
            ballsToMake = 100;
            ballRadius = 10;
        } else if (mode==="eat_2"){
            ballsToMake = 25;
            ballRadius = 20;
        } else if (mode==="eat_3"){
            ballsToMake = 5;
            ballRadius = 15;
        } else if (mode==="eat_4"){
            ballsToMake = 3;
            ballRadius = 10;
        } else if (mode==="regen_1"){
            ballsToMake = 10;
            ballRadius = 20;
        } else if (mode==="regen_2"){
            ballsToMake = 5;
            ballRadius = 15;
        } else if (mode==="regen_3"){
            ballsToMake = 3;
            ballRadius = 10;
        } else if (mode==="regen_4"){
            ballsToMake = 1;
            ballRadius = 10;
        }
        //setNumBalls(ballsToMake);
        setBallRadius(ballRadius);
        const gameBalls = generateBalls(ballsToMake);
        setBalls(gameBalls);
        setGameMode(mode);
        setNumTrash(0);
        setGameStatus("play");
    }

    const onKeyPressHandler = e => {
        e.preventDefault();
        if (energy > 0 && gameMode) {
            if (e.key === 'ArrowLeft') {
                if (sharlX - velocity > 0) {
                    setSharlX(sharlX - velocity)
                    setDirection("left")
                }
            } else if (e.key === "ArrowRight"){
                if (sharlX + velocity + radius < width){
                    setSharlX(sharlX + velocity)
                    setDirection("right")
                }
            } else if (e.key === "ArrowUp"){
                if (sharlY - velocity > 0) {
                    setSharlY(sharlY - velocity)
                    setDirection("up")
                }
            } else if (e.key === "ArrowDown"){
                if (sharlY + velocity + radius < height) {
                    setSharlY(sharlY + velocity)
                    setDirection("down")
                }
            }
            //now move the balls
            const updatedBalls = [];
            let currentScore = score;
            let newFish = numFish;
            let numOfTrash = numTrash;
            for (let i=0; i < balls.length; i++) {
                // need to check if there was a collision and if so do something - otherwise redraw balls
                const xDif = balls[i].ballX - sharlX
                const yDif = balls[i].ballY - sharlY
                if (balls[i].trash) {
                    if (xDif > ballRadius && xDif < (80 + ballRadius) && yDif > ballRadius && yDif < (80 + ballRadius)) {
                        numOfTrash = numOfTrash + 1;
                    } else {
                        //
                    }
                }

                if (xDif > ballRadius && xDif < (80+ballRadius) && yDif > ballRadius && yDif < (80+ballRadius)) {
                    // collision handler
                     if (balls[i].trash) {
                        ///
                     } else {
                        newFish = newFish + 1;
                        // now need to adjust score based on difficulty and if any balls left
                        if (gameMode!=="eat_4"){
                            if (energy >= 30){
                                setEnergy(50);
                            } else {
                                setEnergy(energy+20);
                            }
                        }
                        if (gameMode==="eat_1"){
                            if (balls.length === 1) {
                                currentScore = currentScore + 102
                            } else {
                                currentScore = currentScore + 2
                            }
                        } else if (gameMode==="eat_2"){
                            if (balls.length === 1) {
                            currentScore = currentScore + 155
                             } else {
                            currentScore = currentScore + 5
                             }
                        } else if (gameMode==="eat_3"){
                            if (balls.length === 1) {
                            currentScore = currentScore + 210
                             } else {
                            currentScore = currentScore + 10
                             }
                        } else if (gameMode==="eat_4"){
                            currentScore = currentScore + 350;
                        } else if (gameMode==="regen_1"){
                            currentScore = currentScore + 5;
                        } else if (gameMode==="regen_2"){
                            currentScore = currentScore + 10;
                        } else if (gameMode==="regen_3"){
                            currentScore = currentScore + 20;
                        } else if (gameMode==="regen_4"){
                            currentScore = currentScore + 40;
                        }
                        setScore(currentScore)
                        if (gameMode.includes("regen")){
                            const replacementBall = generateBalls(1);
                            updatedBalls.push(replacementBall[0])
                        }
                     }
                } else {
                    const xFactor = Math.random() * 2;
                    const yFactor = Math.random() * 2;
                    let newX = 0;
                    let newY = 0;
                    let xDir = balls[i].xDirection;
                    let yDir = balls[i].yDirection;

                    if (yDir === "plus") {
                        if (balls[i].ballY < (height - 2 * ballRadius)) {
                            newY = Math.floor(balls[i].ballY + (ballVelocity * yFactor));
                        } else {
                            newY = Math.floor(balls[i].ballY - (ballVelocity * yFactor));
                            yDir = "minus";
                        }
                    } else {
                        if (balls[i].ballY > 2 * ballRadius) {
                            newY = Math.floor(balls[i].ballY - (ballVelocity * yFactor));
                        } else {
                            newY = Math.floor(balls[i].ballY + (ballVelocity * yFactor));
                            yDir = "plus";
                        }
                    }
                    if (xDir === "plus") {
                        if (balls[i].ballX < (width - 2 * ballRadius)) {
                            newX = Math.floor(balls[i].ballX + (ballVelocity * xFactor));
                        } else {
                            newX = Math.floor(balls[i].ballX - (ballVelocity * xFactor));
                            xDir = "minus";
                        }
                    } else {
                        if (balls[i].ballX > 2 * ballRadius) {
                            newX = Math.floor(balls[i].ballX - (ballVelocity * xFactor));
                        } else {
                            newX = Math.floor(balls[i].ballX + (ballVelocity * xFactor));
                            xDir = "plus";
                        }
                    }

                    const upBall = {
                        "ballX": newX,
                        "ballY": newY,
                        "xDirection": xDir,
                        "yDirection": yDir,
                        "ballColor": balls[i].ballColor,
                        "trash": balls[i].trash
                    }
                    updatedBalls.push(upBall);
                }
            }
            setBalls(updatedBalls);
            setNumFish(newFish);
            setScore(currentScore)
            setNumTrash(numOfTrash);
        } else {
            if (gameStatus==="play"){
            //in here need to send stuff to server
                const prizeAmount = Math.floor(score/10);
                if (numTrash===0 && score < 10 && score < highScore){
                    //
                } else {
                    const userBal = calculateBalanceFunction(props,"+", prizeAmount);
                    const newTrashBal = props.UserReducer.user.trash_balance + numTrash;
                    if (score > highScore){
                        props.dispatch(claimFishPrizeFunction(props,userBal,score,newTrashBal));
                        setHighScore(score);
                    } else {
                        props.dispatch(claimFishPrizeFunction(props,userBal,highScore,newTrashBal));
                    }
                }
                setGameStatus("end")
                if (gameMode){
                    const context = canvas.current.getContext('2d');
                    context.fillStyle = canvasBgColor;
                    context.fillRect(0, 0,width, height);

                    const image = new Image();
                    image.onload = () => {
                        context.drawImage(image, 350, 175,200,200)
                     }
                    image.src = require("../../../assets/images/games/over.png");
                    }
            } else {
                if (e.key === " ") {
                    setUpHandler(gameMode);
                }
            }
        }
    };

    const drawStart = (context) => {
        context.fillStyle = canvasBgColor;
        context.fillRect(0, 0, width, height);
        context.beginPath();
        context.fillStyle = "black";
        context.arc(500, 325, 200, 0, 2 * Math.PI);
        context.fill();
        context.stroke();
    }

    useEffect(() => {
        if (gameMode){
            const context = canvas.current.getContext('2d');
            draw(context)
        }
     //eslint-disable-next-line
     },[sharlX,sharlY]);

    useEffect(() => {
      //need to get window size and adjust canavas height/width as required
      setHeight(650)
      setWidth(1000)
      const context = canvas.current.getContext('2d');
      drawStart(context)
        //eslint-disable-next-line
     },[]);

     useEffect(()=>{
        const userHighScore = props.UserReducer.user.fish_high_score;
        setHighScore(userHighScore);
    },[props.UserReducer])


    const bgColorHandler = (mode) => {
        if (mode===gameMode){
            return "#336699"
        }
    }

    const textColorHandler = (mode) => {
        if (mode===gameMode){
            return "white"
        }
    }

    return (
        <StyleFishCanvasContainer>
            <StyleFishCanvas>
                <canvas ref={canvas} width={width} height={height} tabIndex={0} onKeyDown={(e)=>onKeyPressHandler(e)}/>
                <StyleFishCanvasInfo>
                    <StyleCanvasInfoItem height={"32%"}>
                        <StyleCanvasInfoGameMode>
                            <h1>Infinite Balls</h1>
                            <StyleCanvasInfoGameModeItem>
                                <StyleFishGameModeItem bgColor={bgColorHandler("regen_4")} textColor={textColorHandler("regen_4")}>
                                    <p onClick={()=> setUpHandler("regen_4")}>Like Really Hard</p>
                                </StyleFishGameModeItem>
                                <StyleFishGameModeItem bgColor={bgColorHandler("regen_3")} textColor={textColorHandler("regen_3")}>
                                    <p onClick={()=> setUpHandler("regen_3")}>Hard</p>
                                </StyleFishGameModeItem>
                                <StyleFishGameModeItem bgColor={bgColorHandler("regen_2")} textColor={textColorHandler("regen_2")}>
                                    <p onClick={()=> setUpHandler("regen_2")}>Normal</p>
                                </StyleFishGameModeItem>
                                <StyleFishGameModeItem bgColor={bgColorHandler("regen_1")} textColor={textColorHandler("regen_1")}>
                                    <p onClick={()=> setUpHandler("regen_1")}>Easy</p>
                                </StyleFishGameModeItem>
                            </StyleCanvasInfoGameModeItem>
                        </StyleCanvasInfoGameMode>
                        <StyleCanvasInfoGameMode>
                           <h1>Clear the tank</h1>
                            <StyleCanvasInfoGameModeItem>
                                <StyleFishGameModeItem bgColor={bgColorHandler("eat_4")} textColor={textColorHandler("eat_4")}>
                                    <p onClick={()=> setUpHandler("eat_4")}>Hard</p>
                                </StyleFishGameModeItem>
                                <StyleFishGameModeItem bgColor={bgColorHandler("eat_3")} textColor={textColorHandler("eat_3")}>
                                    <p onClick={()=> setUpHandler("eat_3")}>Normal</p>
                                </StyleFishGameModeItem>
                                <StyleFishGameModeItem bgColor={bgColorHandler("eat_2")} textColor={textColorHandler("eat_2")}>
                                    <p onClick={()=> setUpHandler("eat_2")}>Easy</p>
                                </StyleFishGameModeItem>
                                <StyleFishGameModeItem bgColor={bgColorHandler("eat_1")} textColor={textColorHandler("eat_1")}>
                                    <p onClick={()=> setUpHandler("eat_1")}>Party</p>
                                </StyleFishGameModeItem>
                            </StyleCanvasInfoGameModeItem>
                        </StyleCanvasInfoGameMode>
                   </StyleCanvasInfoItem>
                    <StyleCanvasInfoItem>
                        <p>Score: {score} / High Score: {highScore}</p>
                    </StyleCanvasInfoItem>
                    <StyleCanvasInfoItem>
                            <p>Balls Caught: {numFish} / Trash Collected: {numTrash}</p>
                     </StyleCanvasInfoItem>
                    <StyleCanvasInfoItem>
                        <p>Energy</p>
                        <progress id="energy" value={energy} max="50"></progress>
                    </StyleCanvasInfoItem>
                    <StyleCanvasInfoItem>
                    </StyleCanvasInfoItem>
                </StyleFishCanvasInfo>
            </StyleFishCanvas>
        </StyleFishCanvasContainer>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(SharlFish);
