
import React from 'react';
import ResearchBody from "./ResearchBody/ResearchBody";

function Research() {

  return (
        <ResearchBody/>
  );
}

export default Research;;
