import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    StyleTankEditButtons, StyleTankEditItemsButton, StyleTankEditItemsDropDown,
    StyleTankEditPanel,
    StyleTankEditPanelBottom,
    StyleTankEditPanelTop,
    StyleTankItemContainer, StyleTankItemsContainer,
    StyleTankTestBackground,
    StyleTankTestContainer,
    StyleTankUpgradeButton, StyleTankUpgradeComplete,
    StyleTankUpgradeContainer,
    StyleTankUpgradeImg,
    StyleTankUpgradeItem,
} from "../../../styles/tanktest";
import {Link} from "react-router-dom";
import upgradeTankFunction from "../../../store/actions/Tank/upgradeTankAction";
import saveTankItemFunction from "../../../store/actions/Tank/saveTankItemAction";


function TankEdit(props) {
    const [panel, setPanel] = useState("")
    const [userBal, setUserBal] = useState();
    const [tankLevel, setTankLevel] = useState();
    const [tankItem1, setTankItem1] = useState()
    const [tankItem2, setTankItem2] = useState()
    const [tankItem3, setTankItem3] = useState()
    const [upgradeCost, setUpgradeCost] = useState()
    const [tankItems, setTankItems] = useState()
    const [editItemNumber, setEditItemNumber] = useState(0)
    const [updateItem, setUpdateItem] = useState()

    useEffect(()=>{
        const userItems = props.UserReducer.items
        if (userItems){
            const filteredItems = userItems.filter(item =>
            item.category ==="collectable" && item.quantity > item.quantity_in_use)
            setTankItems(filteredItems);
        }
        const userTank = props.UserReducer.user.tank
        if (userTank){
            setTankLevel(userTank.tank_level)
            setUserBal(props.UserReducer.user.balance)
            if (userTank.tank_item_1){
               setTankItem1(userTank.tank_item_1)
            }
            if (userTank.tank_item_2){
                setTankItem2(userTank.tank_item_2)
            }
            if (userTank.tank_item_3){
                setTankItem3(userTank.tank_item_3)
            } const tankLevel = userTank.tank_level
            if (tankLevel===1){
                setUpgradeCost(5000)
            } else if (tankLevel===2){
                setUpgradeCost(25000)
            } else if (tankLevel===3){
                setUpgradeCost(100000)
            }
        }
    },[props.UserReducer])

    const tankBGHandler = () => {
        const userTank = props.UserReducer.user.tank
        if (userTank){
            if (userTank.tank_level===1){
                return   "../../images/tank/tank_1.png"
            } else if (userTank.tank_level===2){
                return   "../../images/tank/tank_2.png"
            } else if (userTank.tank_level===3){
                return   "../../images/tank/tank_3.png"
            } else if (userTank.tank_level===4){
                return   "../../images/tank/tank_4.png"
            }
        }
    }

    const upgradeHandler = () => {
        const tankID = props.UserReducer.user.tank.id
        const tankLevel = props.UserReducer.user.tank.tank_level + 1
        const newBalance = userBal - upgradeCost;

        const data = {"tank_id": tankID, "tank_level":tankLevel, "balance": newBalance};
        props.dispatch(upgradeTankFunction(props,data))
        //need to close upgrade & show success message
        setPanel("complete")
    }

    const panelHandler = (item) => {
        setPanel(item)
    }

    const editItemHandler = (itemNumber) => {
        setEditItemNumber(itemNumber)
        setUpdateItem("")
        const userTank = props.UserReducer.user.tank
        if (userTank.tank_item_1){
            setTankItem1(userTank.tank_item_1)
        } else {
            setTankItem1("")
        }
        if (userTank.tank_item_2){
            setTankItem2(userTank.tank_item_2)
        } else {
            setTankItem2("")
        }
        if (userTank.tank_item_3){
            setTankItem3(userTank.tank_item_3)
        } else {
            setTankItem3("")
        }
    }

    const cancelEditHandler = (itemNumber) => {
        setEditItemNumber(0)
        setUpdateItem("")
        const userTank = props.UserReducer.user.tank
        if (itemNumber===1){
             if (userTank.tank_item_1){
               setTankItem1(userTank.tank_item_1)
            } else {
               setTankItem1("")
             }
        } else if (itemNumber===2){
             if (userTank.tank_item_2){
               setTankItem2(userTank.tank_item_2)
            } else {
               setTankItem2("")
             }
        }if (itemNumber===3){
             if (userTank.tank_item_3){
               setTankItem1(userTank.tank_item_3)
            } else {
               setTankItem3("")
             }
        }
    }

    const changeTankItemHandler = (itemNumber, currentItem) => {
        if (currentItem !== "blank") {
            const newItem = tankItems.filter(item =>
                item.name === currentItem)
            setUpdateItem(newItem[0])
            const updatedTankItem = {"name": newItem[0].name, "image": "https://www.sharl.info/" + newItem[0].image}
            if (itemNumber === 1) {
                setTankItem1(updatedTankItem)
            } else if (itemNumber === 2) {
                setTankItem2(updatedTankItem)
            } else if (itemNumber === 3) {
                setTankItem3(updatedTankItem)
            }
        }

    }

    const saveChangesHandler = (itemNumber) => {
        const userTank = props.UserReducer.user.tank
        let originalItemName = ""
        if (itemNumber === 1) {
            if (userTank.tank_item_1) {
                originalItemName = userTank.tank_item_1.name
            } else {
                originalItemName = "blank"
            }
        } else if (itemNumber === 2) {
            if (userTank.tank_item_2) {
                originalItemName = userTank.tank_item_2.name
            } else {
                originalItemName = "blank"
            }
        } else if (itemNumber === 3) {
            if (userTank.tank_item_3) {
                originalItemName = userTank.tank_item_3.name
            } else {
                originalItemName = "blank"
            }
        }
        const userItems = props.UserReducer.items
        const newItem = updateItem
        let updateData = ""

        if (originalItemName !== "blank") {
            const originalItem = userItems.filter(item =>
                item.name === originalItemName)[0]
            if (newItem.name === originalItem.name) {
                return
            }
            const newQuantityInUse = newItem.quantity_in_use + 1
            const oldQuantityInUse = originalItem.quantity_in_use - 1
            updateData = {
                "items": [
                    {"id": newItem.id, "quantity_in_use": newQuantityInUse},
                    {"id": originalItem.id, "quantity_in_use": oldQuantityInUse}
                ],
                "tank_item_number": itemNumber,
                "tank_id": userTank.id,
                "updated_item_id": newItem.id
            }
        } else {
            const newQuantityInUse = newItem.quantity_in_use + 1
            updateData = {
                "items": [
                    {"id": newItem.id, "quantity_in_use": newQuantityInUse}
                ],
                "tank_item_number": itemNumber,
                "tank_id": userTank.id,
                "updated_item_id": newItem.id
            }
        }
        props.dispatch(saveTankItemFunction(props,updateData))
        setEditItemNumber(0)
        setUpdateItem("")
    }

     const fontColorHandler = () => {
        const userBalance = props.UserReducer.user.balance
        if (upgradeCost <= userBalance){
            return "white"
        } return "#f7abcc";
    }

    return (
          <StyleTankTestContainer>
          <StyleTankTestBackground bgImage={tankBGHandler()}>
           <>
               {tankLevel > 1 ?
                   <StyleTankItemContainer left={"5%"} bottom={"1%"} zIndex={30} onClick={()=>panelHandler("items")}>
                       {tankItem1 ?
                           <img src={tankItem1.image} alt={tankItem1.name}/>
                           :
                           <img src={require("../../../assets/images/tank/plus.png")} alt={"add item 1"}/>
                          }
                      </StyleTankItemContainer>
                  :""}
                  { tankLevel > 2 ?
                      <StyleTankItemContainer left={"80%"} bottom={"1%"} zIndex={30} onClick={()=>panelHandler("items")}>
                          {tankItem2 ?
                              <img src={tankItem2.image} alt={tankItem2.name}/>
                              :
                              <img src={require("../../../assets/images/tank/plus.png")} alt={"add item 2"}/>
                          }
                      </StyleTankItemContainer>
                  : ""}
                  {tankLevel > 3 ?
                      <StyleTankItemContainer left={"43%"} bottom={"20%"} onClick={()=>panelHandler("items")}>
                          {tankItem3 ?
                              <img src={tankItem3.image} alt={tankItem3.name}/>
                              :
                              <img src={require("../../../assets/images/tank/plus.png")} alt={"add item 3"}/>
                          }
                      </StyleTankItemContainer>
                  :""}
              </>
          </StyleTankTestBackground>
          <StyleTankEditPanel>
              <StyleTankEditPanelTop>
                  {panel==="upgrade" ?
                      <StyleTankUpgradeContainer>
                          {tankLevel < 4 ?
                        <StyleTankUpgradeContainer>
                            <p>Upgrade</p>
                            <StyleTankUpgradeItem>
                                <p>Current Tank Level: {tankLevel}</p>
                                <p>Sharl Storage: {tankLevel*tankLevel}</p>
                                <p>Item slots: {tankLevel-1}</p>
                            </StyleTankUpgradeItem>
                            <StyleTankUpgradeItem>
                                <p>Next Tank Level: {tankLevel + 1}</p>
                                <p>Sharl Storage: {(1+tankLevel)*(1+tankLevel)}</p>
                                <p>Item slots: {tankLevel}</p>
                            </StyleTankUpgradeItem>
                            <StyleTankUpgradeItem>
                                <StyleTankUpgradeImg fontColor={fontColorHandler()}>
                                    <p><b>Cost: {upgradeCost} </b><img src={require("../../../assets/images/misc/shadollar.png")} alt={"sharl"} /></p>
                                </StyleTankUpgradeImg>
                                { userBal >= upgradeCost ?
                                <StyleTankUpgradeButton onClick={()=>upgradeHandler()}>Confirm</StyleTankUpgradeButton>
                                    : " " }
                            </StyleTankUpgradeItem>
                        </StyleTankUpgradeContainer>
                            :
                              <StyleTankUpgradeContainer>
                                <p>Your Tank is Fully Upgraded!</p>
                              </StyleTankUpgradeContainer>
                          }
                      </StyleTankUpgradeContainer>
                      : ""}
                  {panel==="items" ?
                      <StyleTankUpgradeContainer justify={"flex-start"}>
                          <StyleTankItemsContainer>
                            <p>Items</p>
                          </StyleTankItemsContainer>
                          <StyleTankItemsContainer>
                            {tankItem1 ?
                                <img src={tankItem1.image} alt={tankItem1.name}/>
                                    :
                                <img src={require("../../../assets/images/tank/plus.png")} alt={"plus"}/>
                            }
                              {editItemNumber === 1 ?
                                 <div>
                                      <StyleTankEditItemsDropDown onChange={(e)=>changeTankItemHandler(1, e.currentTarget.value)}>
                                          <option key={"blank"} value={"blank"}>----------Update Item 1----------</option>
                                          {tankItems.map(item =>
                                              <option key={item.name} id={item.name}>{item.name}</option>
                                          )}
                                      </StyleTankEditItemsDropDown>
                                      <StyleTankEditItemsButton onClick={()=>saveChangesHandler(1)}>save</StyleTankEditItemsButton>
                                      <StyleTankEditItemsButton onClick={()=>cancelEditHandler(1)}>cancel</StyleTankEditItemsButton>
                                  </div>
                                  :
                                  <StyleTankEditItemsButton onClick={()=>editItemHandler(1)}>Change Item 1</StyleTankEditItemsButton>
                                    }
                          </StyleTankItemsContainer>
                          {tankLevel > 2 ?
                          <StyleTankItemsContainer>
                             {tankItem2 ?
                           <img src={tankItem2.image} alt={tankItem2.name}/>
                           :
                           <img src={require("../../../assets/images/tank/plus.png")} alt={"plus"}/>
                          }
                            {editItemNumber === 2 ?
                                 <div>
                                      <StyleTankEditItemsDropDown onChange={(e)=>changeTankItemHandler(2, e.currentTarget.value)}>
                                          <option key={"blank"} value={"blank"}>----------Update Item 2----------</option>
                                          {tankItems.map(item =>
                                              <option key={item.name} id={item.name}>{item.name}</option>
                                          )}
                                      </StyleTankEditItemsDropDown>
                                      <StyleTankEditItemsButton onClick={()=>saveChangesHandler(2)}>save</StyleTankEditItemsButton>
                                      <StyleTankEditItemsButton onClick={()=>cancelEditHandler(2)}>cancel</StyleTankEditItemsButton>
                                  </div>
                                  :
                                  <StyleTankEditItemsButton onClick={()=>editItemHandler(2)}>Change Item 2</StyleTankEditItemsButton>
                                    }
                          </StyleTankItemsContainer>
                              :""}
                          {tankLevel > 3 ?
                          <StyleTankItemsContainer>
                              {tankItem3 ?
                           <img src={tankItem3.image} alt={tankItem3.name}/>
                           :
                           <img src={require("../../../assets/images/tank/plus.png")} alt={"plus"}/>
                          }
                            {editItemNumber === 3 ?
                                 <div>
                                      <StyleTankEditItemsDropDown onChange={(e)=>changeTankItemHandler(3, e.currentTarget.value)}>
                                          <option key={"blank"} value={"blank"}>----------Update Item 2----------</option>
                                          {tankItems.map(item =>
                                              <option key={item.name} id={item.name}>{item.name}</option>
                                          )}
                                      </StyleTankEditItemsDropDown>
                                      <StyleTankEditItemsButton onClick={()=>saveChangesHandler(3)}>save</StyleTankEditItemsButton>
                                      <StyleTankEditItemsButton onClick={()=>cancelEditHandler(3)}>cancel</StyleTankEditItemsButton>
                                  </div>
                                  :
                                  <StyleTankEditItemsButton onClick={()=>editItemHandler(3)}>Change Item 3</StyleTankEditItemsButton>
                                    }
                          </StyleTankItemsContainer>
                              :""}
                      </StyleTankUpgradeContainer>
                      : ""}
                  {panel==="complete" ?
                      <StyleTankUpgradeComplete>
                        <p>Yay! Your tank has been upgraded to level {tankLevel}!</p>
                      </StyleTankUpgradeComplete>
                      : ""}
              </StyleTankEditPanelTop>
              <StyleTankEditPanelBottom>
                  {tankLevel < 4 && panel!=="upgrade" ?
                <StyleTankEditButtons onClick={()=>panelHandler("upgrade")}>Upgrade Tank</StyleTankEditButtons>
                      :""}
                  {tankLevel > 1 && panel!=="items" ?
                <StyleTankEditButtons onClick={()=>panelHandler("items")}>Edit Tank Items</StyleTankEditButtons>
                    :""}
                <StyleTankEditButtons><Link to="/tank">Back to Tank</Link></StyleTankEditButtons>
            </StyleTankEditPanelBottom>
          </StyleTankEditPanel>
    </StyleTankTestContainer>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(TankEdit);
