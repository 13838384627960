import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";
import {StyleResearchRight, StyleResearchEnd, StyleResearchEndItem, StyleResearchEndButton } from "../../../styles/research";
import {updateResearchAction} from "../../../store/actions/Research/updateResearchAction";
import {selectResearchAction} from "../../../store/actions/Research/selectResearchAction";


function ResearchEnd(props) {
    const [researchPrize, setResearchPrize] = useState("");

    useEffect(() => {
        if (props.ResearchReducer.researchPrize) {
            setResearchPrize(props.ResearchReducer.researchPrize[0]);
        }
        // eslint-disable-next-line
        },[props.ResearchReducer]);


    const clearResearchHandler = (e) => {
        props.dispatch(updateResearchAction("start"))
        const clearFields = {"research": [], "sharl": []}
        props.dispatch(selectResearchAction(clearFields));
    }

    return (
                <StyleResearchRight>
                    {researchPrize ?
                     <StyleResearchEnd>
                         <StyleResearchEndItem>
                            <h1>Congratulations!</h1>
                         <p>Your sharl found a {researchPrize.name} while researching!</p>
                        </StyleResearchEndItem>
                       <StyleResearchEndItem>
                        <img src={"https://www.sharl.info" + researchPrize.image} alt="research prize"/>
                       </StyleResearchEndItem>
                        <StyleResearchEndItem>
                           <h2>Keep researching for more facts and prizes!</h2>
                        </StyleResearchEndItem>
                       <StyleResearchEndItem>
                         <StyleResearchEndButton onClick={()=> clearResearchHandler()}>Finish</StyleResearchEndButton>
                        </StyleResearchEndItem>
                   </StyleResearchEnd>
        : ""
        }
        </StyleResearchRight>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(ResearchEnd);
