import React from 'react';
import {connect} from "react-redux";
import {
    StyleMuseumBackButton,
    StyleMuseumHomeBook,
    StyleMuseumHomeContainer,
    StyleMuseumHomeLeft,
    StyleMuseumHomeLeftBody,
    StyleMuseumHomeLeftBodyLeft,
    StyleMuseumHomeLeftBodyRight,
    StyleMuseumHomeRight,
    StyleMuseumHomeRightBody,
    StyleMuseumHomeRightInner,
 } from "../../styles/museum";
import {selectBookFunction} from "../../store/actions/Museum/selectBookAction";
import {Link} from "react-router-dom";


function MuseumHome(props) {

    const selectBookHandler = (book) => {
        props.dispatch(selectBookFunction(book))
    }
    return (
            <StyleMuseumHomeContainer>
                <StyleMuseumHomeLeft>
                 <StyleMuseumHomeLeftBody>
                     <StyleMuseumHomeLeftBodyLeft>

                     </StyleMuseumHomeLeftBodyLeft>
                     <StyleMuseumHomeLeftBodyRight>
                         <StyleMuseumBackButton><Link to="/research">Start Researching</Link></StyleMuseumBackButton>
                     </StyleMuseumHomeLeftBodyRight>
                 </StyleMuseumHomeLeftBody>
                </StyleMuseumHomeLeft>
                <StyleMuseumHomeRight>
                    <StyleMuseumHomeRightBody>
                    <StyleMuseumHomeRightInner>
                     <StyleMuseumHomeBook bgColor={"#D7BDE2"} onClick={(e) => selectBookHandler("sharl_facts")}>
                        <p>Sharl Facts</p>
                     </StyleMuseumHomeBook>
                     <StyleMuseumHomeBook bgColor={"#F5B7B1"} onClick={(id) => selectBookHandler("friend_book")}>
                        <p>Friend Book</p>
                     </StyleMuseumHomeBook>
                     <StyleMuseumHomeBook bgColor={"#A3E4D7"} onClick={(id) => selectBookHandler("shark_secrets")}>
                        <p>Shark Secrets</p>
                     </StyleMuseumHomeBook>
                     <StyleMuseumHomeBook bgColor={"#AED6F1"} onClick={(id) => selectBookHandler("sharl_history")}>
                        <p>Sharl History</p>
                     </StyleMuseumHomeBook>
                    </StyleMuseumHomeRightInner>
                    </StyleMuseumHomeRightBody>
                    </StyleMuseumHomeRight>
            </StyleMuseumHomeContainer>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(MuseumHome);
