import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';

import {
    StyleUserProfileBody,
} from "../../styles/profile";
import {Link} from "react-router-dom";
import {clearAdoptStageFunction} from "../../store/actions/Adopt/clearAdoptStageAction";
import {
    StyleAllSharlHatContainer,
    StyleAllSharlItemDouble, StyleAllSharlItemImg, StyleAllSharlItemSingle,
    StyleAllSharlProfileItem,
    StyleAllSharlsButtons,
    StyleAllSharlsContainer, StyleSharlImageItemContainer,
    StyleSharlProfileButton
} from "../../styles/sharls";
import getSingleSharlFunction from "../../store/actions/Sharls/getSingleSharlAction";
import hungerLevelCalculator from "./hungerLevelCalculator";


function AllSharls(props) {
    const [sharls, setSharls] = useState([]);

    useEffect(() => {
        const allSharls = props.UserReducer.sharls
        const filteredSharls = allSharls.filter(sharl =>
        sharl.is_active === true)
        if (filteredSharls.length > 0) {
            for (let i = 0; i < filteredSharls.length; i++) {
                filteredSharls[i].calculated_hunger = hungerLevelCalculator(filteredSharls[i].hunger_level, filteredSharls[i].last_fed)
            }
            setSharls(filteredSharls);
        }
    // eslint-disable-next-line
    },[props.UserReducer]);

    const clearAdoptHandler = () => {
        props.dispatch(clearAdoptStageFunction());
    }

    const selectSharlHandler = (e) => {
        const sharlId = parseInt(e.currentTarget.id);
        const selectedSharl = sharls.filter(sharl =>
            sharl.id === sharlId);
            props.dispatch(getSingleSharlFunction(selectedSharl[0]))
         }


    if (sharls) {
        return (
              <>
          <StyleAllSharlsContainer>
        <StyleUserProfileBody>
          {sharls.map(sharl =>
            <StyleAllSharlProfileItem id={sharl.id} key={sharl.id} onClick={(e) => selectSharlHandler(e)}>
                <Link to={"/sharls/active"}>
                    <StyleAllSharlItemSingle>
                    </StyleAllSharlItemSingle>
                    <StyleAllSharlItemImg bgColor={sharl.color}>
                        <StyleSharlImageItemContainer>
                            <img src={sharl.sharl_avatar} alt={sharl.sharl_avatar} />
                        </StyleSharlImageItemContainer>
                        { sharl.headgear ?
                            <StyleAllSharlHatContainer>
                                { sharl.headgear.image.includes("https") ?
                                <img src={sharl.headgear.image} alt={sharl.headgear.name} />
                                :
                                <img src={"https://www.sharl.info" +sharl.headgear.image} alt={sharl.headgear.name} />
                                }
                            </StyleAllSharlHatContainer>
                        : ""
                        }
                    </StyleAllSharlItemImg>

                    <StyleAllSharlItemDouble>
                        <h2>{sharl.name}</h2>
                    </StyleAllSharlItemDouble>
                    <StyleAllSharlItemSingle>
                    </StyleAllSharlItemSingle>
                    <StyleAllSharlItemSingle>
                        <p>{sharl.genus}</p>
                    </StyleAllSharlItemSingle>
                    <StyleAllSharlItemSingle>
                        <p>Age: {sharl.age} days</p>
                    </StyleAllSharlItemSingle>
                    <StyleAllSharlItemDouble>
                        <p>Favorite Fish or Food:</p>
                        <p>{sharl.fav_fish_or_food}</p>
                    </StyleAllSharlItemDouble>
                    <StyleAllSharlItemSingle>
                        <p>Hunger Level: <b>{sharl.calculated_hunger}</b></p>
                    </StyleAllSharlItemSingle>
                </Link>
               </StyleAllSharlProfileItem>
        )}

        </StyleUserProfileBody>
        </StyleAllSharlsContainer>
        <StyleAllSharlsButtons>
          <StyleSharlProfileButton><Link to="/tank">View Tank</Link></StyleSharlProfileButton>
        <StyleSharlProfileButton onClick={() => clearAdoptHandler()}><Link to="/adopt">Adopt A New Sharl</Link></StyleSharlProfileButton>

        </StyleAllSharlsButtons>
            </>
)
} else {
    return(
          <StyleAllSharlsContainer>
        </StyleAllSharlsContainer>
     )}
}

const mapStateToProps = state => {
    return {
      ...state,
      };
    };

export default connect(mapStateToProps)(AllSharls);
