import React, {useState} from 'react';
import {connect} from "react-redux";
import {
    StyleShopBodyContentItem,
    StyleShopBuyButton, StyleShopItemQuantity,
} from "../../styles/shop";
import addToCartFunction from "../../store/actions/Shop/addToCartAction";


function ShopItem(props) {
    const [itemQuantity, setItemQuantity] = useState(1)

    const selectedItemHandler = (item) => {
        const currentCart = props.ShopReducer.cart
        if (currentCart.length === 0){
            const newCart = [{"code":item.item_code, "item": item, "quantity":itemQuantity}]
            props.dispatch(addToCartFunction(newCart))
        } else {
            //check if already in list and add quantity
            const cartCheck = currentCart.filter(oldItem =>
            oldItem.code === item.item_code)
            if (cartCheck.length > 0) {
                //then change the quantity of that one
                for (let i=0; i<currentCart.length; i++){
                    if (currentCart[i].code===item.item_code){
                        const oldQuantity = currentCart[i].quantity
                        currentCart[i].quantity = parseInt(oldQuantity) + parseInt(itemQuantity)
                    }
                }
                props.dispatch(addToCartFunction(currentCart))
            } else {
                //otherwise add item to current cart
                currentCart.push({"code":item.item_code, "item": item, "quantity":itemQuantity})
                props.dispatch(addToCartFunction(currentCart))
            }
        }
    }

    return (
        <StyleShopBodyContentItem id={props.item.id} key={props.item.id}>
            <img src={props.item.image} alt={props.item.name} />
            <p>{props.item.name}</p>
            <h1><i>{props.item.description}</i></h1>
            <h1>${props.item.cash_shop_price}.00 (USD)</h1>
            <StyleShopItemQuantity>
                <input value={itemQuantity} min={1} max={99} onChange={(e)=>setItemQuantity(e.target.value)} type="number" />
            </StyleShopItemQuantity>
            <StyleShopBuyButton onClick={()=>selectedItemHandler(props.item)}>Add to Cart</StyleShopBuyButton>
        </StyleShopBodyContentItem>
    )}


const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(ShopItem);
