import styled from "styled-components";

export const StyleHeader = styled.div`
    height: 10%;
    width: 100%;
    background-color: #336699;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
    height: 100%;
    }
    p {
    margin: 0.8vw;
    color: white;
    }
    
    a:-webkit-any-link {
    color: white;
    text-decoration: none;
    height: 60%;
    
    }
    `;

export const StyleHeaderItemLeft = styled.div`
    height: 100%;
    width: 43%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    `;

export const StyleHeaderLeftSides = styled.div`
    height: 100%;
    width: 12%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    p {
    font-size: 2.5vh;
    }
    `;
export const StyleHeaderLeftMid = styled.div`
    height: 100%;
    width: 88%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    p {
    font-size: 1.5vh;
    }
    `;


export const StyleHeaderItemMid = styled.div`
    height: 100%;
    width: 13%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
    font-size: 1.5vw;
    }
    `;

export const StyleHeaderItemRight = styled.div`
    height: 100%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    p {
    font-size: 2.5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        padding-left: 4%;
        padding-right: 2%;
        }
    }
    img {
    margin-left: 2%;
    width: 20%;
    height: 20%;
    }
    `;

export const StyleHeaderItemSharl = styled.div`
    height: 100%;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    
    a:-webkit-any-link {
    text-decoration: none;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    }
    `;

export const StylHeaderSharlItem = styled.div`
    height: 45%;
    width: 45%;
    margin: 1%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    `;

export const StylHeaderTopSharl = styled.div`
    height: 100%;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        height: 70%;
        width: auto;
    }

    a:-webkit-any-link {
    text-decoration: none;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    
    }    `
;


export const StyleHeaderSharlItemImg = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    background-color: ${props => props.bgColor || "white"};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 12px;
    img {
      height: 100%;
      width: auto;
    }
    `;


export const StyleHeaderSharlHatContainer = styled.div`
    height: 20%;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: absolute;
    bottom: 65%;
    img {
    width: 100%;
    height: auto;
    }
    `;

export const StyleShareHeader = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    `;