import {orderid} from "../../types";
import {updateItemsAction} from "../Market/buyNarlItemAction";


export const purchaseItemsAction = (orderID) => ({
    type: orderid,
    payload: orderID
});

const purchaseItemsFunction = (props, userID, purchase_data) => (dispatch) => {
    const userID = props.UserReducer.user.id
    const URL = `https://www.sharl.info/backend/api/shop/complete/${userID}/`;
    const token = props.SignInReducer.token;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    const config = {
        method: 'PATCH',
        body: JSON.stringify(purchase_data),
        headers,
    };

     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
        const paypalOrderID = purchase_data.paypal_order_id
        //clear cart/save id/send to confirmation page
        dispatch(purchaseItemsAction(paypalOrderID));
        //update items
        dispatch(updateItemsAction(data.items))
    })
};

export default purchaseItemsFunction;
