import {
    adopterror,
    adoptselect,
    chosensharl,
    clearadoptstage,
    createsharl,
    gottoadoptsharls,
    gottoadopttoken, selecttoken
} from "../types";

const initialState = {
    chosensharl: "",
    adoptstage: 1,
    adopterror: "",
    adoptselect: [],
    token: "",
  };

const AdoptReducer = (state = initialState, action) => {
  switch (action.type) {
      case chosensharl:
            return {
            ...state,
            chosensharl: action.payload,
            adoptstage: 3,
        }
        case gottoadoptsharls:
            return {
             ...state,
            adoptstage: 2,
        }
        case gottoadopttoken:
            return {
             ...state,
            adoptstage: 5,
        }
      case clearadoptstage:
          return {
              ...state,
              adoptstage: 1,
          }
      case adoptselect:
          return {
              ...state,
              adoptselect: action.payload,
          }
      case selecttoken:
          return {
              ...state,
              selecttoken: action.payload,
          }
      case createsharl:
          return {
              ...state,
              adoptstage: 4,
              adopterror: "",
          }
      case adopterror:
          return {
              ...state,
                adopterror: action.payload
          }
        default:
            return {
                ...state,
            };
  }

}

export default AdoptReducer;