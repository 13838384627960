import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    StyleProfileButton, StyleProfileContainer, StyleProfileItem,
    StyleSharlProfileItem,
    StyleUserProfileBody,
    StyleUserProfileContainer
} from "../../../styles/profile";
import renderRating from "../renderRating";
import {
    StyleAdoptItemMain,
    StyleSharlItem,
    StyleSharlItemSingle,
    StyleSharlItemContainer,
    StyleSharlSingleContainer,
    StyleSharlAdoptConfirm,
} from "../../../styles/adopt";
import selectSharlAdoptFunction from "../../../store/actions/Adopt/selectSharlAdoptAction";
import {clearAdoptStageFunction} from "../../../store/actions/Adopt/clearAdoptStageAction";
import generateSharls from "./generateSharls";
import selectSharlAdoptFirstFunction from "../../../store/actions/Adopt/selectSharlAdoptFirstAction";


function SharlGenerator(props) {
    const [sharls, setSharls] = useState([])
    const [selectedSharl, setSelectedSharl] = useState()

    useEffect(() => {
         setSharls(generateSharls());
        // eslint-disable-next-line
        }, []);

   const adoptSharlHandler = (e) => {
       const chosenSharl = sharls[selectedSharl];
       props.dispatch(selectSharlAdoptFunction(chosenSharl));
       }

   const selectSharlHandler = (e) => {
       const chosenSharlId = e.currentTarget.id;
       setSelectedSharl(chosenSharlId);
       props.dispatch(selectSharlAdoptFirstFunction(chosenSharlId));
       }

    const adoptClearHandler = (e) => {
        props.dispatch(clearAdoptStageFunction())
    }

    const bgColorHandler = (sharlID, rarity) => {
        if (sharlID===parseInt(selectedSharl)) {
                return "#336699";
        } else {
            if (rarity==="common") {
                return "#79b4ed";
            } else if (rarity==="rare") {
                return "#f5d442";
            } else if (rarity==="legendary") {
                return "#b48ce6";
            } else if (rarity==="special") {
                return "#f589d6";
            }
        }
     }


    return (
             <StyleProfileItem>
                <StyleProfileContainer>
                    <StyleAdoptItemMain>
                        <StyleUserProfileContainer>
                            <StyleUserProfileBody divHeight={"90%"}>
                                {sharls.map(sharl =>
                                    <StyleSharlProfileItem id={sharl.id} key={sharl.id} onClick={(e) => selectSharlHandler(e)}>
                                        <StyleSharlSingleContainer bgColor={bgColorHandler(sharl.id, sharl.rarity)}>
                                        <img src={sharl.sharl_avatar} alt={sharl.sharl_avatar} />
                                        <StyleSharlItemSingle>
                                            <p>{sharl.genus}</p>
                                        </StyleSharlItemSingle>
`                                        <StyleSharlItemSingle>
                                        </StyleSharlItemSingle>
                                        </StyleSharlSingleContainer>
                                        <StyleSharlItemContainer>
                                        <StyleSharlItem>
                                            <p>Roundness:</p>
                                            {renderRating(sharl.roundness)}
                                        </StyleSharlItem>
                                        <StyleSharlItem>
                                            <p>Sleepyness:</p>
                                            {renderRating(sharl.sleepyness)}
                                        </StyleSharlItem>
                                        <StyleSharlItem>
                                            <p>Cheekyness:</p>
                                            {renderRating(sharl.cheekyness)}
                                        </StyleSharlItem>
                                        <StyleSharlItem>
                                            <p>Pancakeness:</p>
                                            {renderRating(sharl.pancakeness)}
                                        </StyleSharlItem>
                                        </StyleSharlItemContainer>
                                    </StyleSharlProfileItem>
                                )}
                            </StyleUserProfileBody>
                            <StyleSharlAdoptConfirm butHeight={"10%"} butWidth={"100%"}>
                                { selectedSharl ?
                                <StyleProfileButton adoptHeight={"80%"} adoptWidth={"20%"} onClick={(e) => adoptSharlHandler(e)}>Adopt</StyleProfileButton>
                                    : "" }
                                <StyleProfileButton adoptHeight={"80%"} adoptWidth={"20%"} onClick={(e) => adoptClearHandler(e)}>Cancel</StyleProfileButton>
                            </StyleSharlAdoptConfirm>
                        </StyleUserProfileContainer>
                    </StyleAdoptItemMain>
               </StyleProfileContainer>
              </StyleProfileItem>
)
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(SharlGenerator);
