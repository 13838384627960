import React, {useState} from 'react';
import {connect} from "react-redux";
import {
    StyleShopCartBodyItem,
} from "../../styles/shop";
import addToCartFunction from "../../store/actions/Shop/addToCartAction";

function ShopCartItem(props) {
    const [itemQuantity, setItemQuantity] = useState(props.item.quantity)

    const quantityHandler = (e) => {
        setItemQuantity(e.target.value);
        const currentCart = props.ShopReducer.cart;
        for (let i=0; i < currentCart.length; i++){
            if (currentCart[i].code===props.item.code) {
                currentCart[i].quantity = parseInt(e.target.value)
            }
        }
        props.dispatch(addToCartFunction(currentCart))
    }

    const deleteHandler = (itemCode) => {
        const currentCart = props.ShopReducer.cart;
        const newCart = currentCart.filter(item=>
            item.code!==itemCode)
        props.dispatch(addToCartFunction(newCart))
    }

    const totalHandler = (price,quantity) => {
        return parseInt(price) * parseInt(quantity)
    }

    return (
        <StyleShopCartBodyItem id={props.item.code} key={props.item.code}>
            <img src={props.item.item.image} alt={props.item.item.name} />
            <h1>{props.item.item.name}</h1>
            <input value={itemQuantity} min={1} max={99} onChange={(e)=>quantityHandler(e)} type="number" />
            <p>${props.item.item.cash_shop_price}.00</p>
            <p>${totalHandler(props.item.item.cash_shop_price,props.item.quantity)}.00</p>
            <p onClick={()=>deleteHandler(props.item.code)}>Delete</p>
        </StyleShopCartBodyItem>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(ShopCartItem);
