import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";

import { DndProvider } from 'react-dnd'
import {StyleTunaMainContainer} from "../../../styles/tuna";
import {getSharlTime} from "./getSharlTime";
import {
    StyleResearchAvatarImg,
    StyleResearchCategory,
    StyleResearchCategorySharls,
    StyleResearchCatHat,
    StyleResearchCatInProgHat,
    StyleResearchCatSharlImg,
    StyleResearchCatSharlImgCont, StyleResearchSharlBox
} from "../../../styles/research";
import {
    StyleResearchCategoryBgTest,
    StyleResearchCont100,
    StyleResearchSelectedSharl
} from "../../../styles/test";
import DropSquare from "./DropSquare";
import {DragSelectedSharl} from "./DragSelectedSharl";
import updateResearchFunction from "../../../store/actions/Research/updateResearchAction";
import selectResearchFunction from "../../../store/actions/Research/selectResearchAction";
import {HTML5Backend} from "react-dnd-html5-backend";


function DropContainer(props) {
  const [squares,setSquares] = useState([])

   useEffect(() => {
       const sharlFacts = props.ResearchReducer.user_research
       if (sharlFacts.length > 0) {
            const selectedResearch = props.ResearchReducer.selectedResearch
            const selectedSharl = props.ResearchReducer.selectedSharl

            const catList = []
            let filteredAllSharls = []
            const allSharls = props.UserReducer.sharls;
            if (allSharls) {
                filteredAllSharls = allSharls.filter(sharl =>
                    sharl.is_active === true)
            }
            for (let i = 0; i < sharlFacts.length; i++) {
                let inProgress = false;
                let completed = false;
                let selected = false;
                const factNum = parseInt(sharlFacts[i].fact_code.slice(2, 4))
                const factCode = sharlFacts[i].fact_code
                sharlFacts[i].category["factNum"] = factNum - 1;
                sharlFacts[i].category["researchCode"] = factCode;
                for (let j = 0; j < filteredAllSharls.length; j++) {
                    const sharlFactCode = filteredAllSharls[j].research_code
                    if (factCode === sharlFactCode) {
                        const sharl = filteredAllSharls[j];
                        const sharlTime = getSharlTime(sharl.research_end_time)
                        if (sharlTime === "completed") {
                            completed = true;
                        } else {
                            completed = false;
                        }
                        inProgress = true;
                        sharlFacts[i].sharl = filteredAllSharls[j]
                        //check if in progress or already completed...
                    }
                    if (selectedSharl && selectedResearch){
                        if (sharlFacts[i].fact_code===selectedResearch.fact_code) {
                            selected = true;
                            sharlFacts[i].sharl = selectedSharl
                        }
                    }
                }
                if (inProgress===false && completed===false && selected===false){
                    if (sharlFacts[i].sharl){
                        delete sharlFacts[i].sharl
                    }
                }
                sharlFacts[i]["inProgress"] = inProgress;
                sharlFacts[i]["completed"] = completed;
                sharlFacts[i]["selected"] = selected;
                catList.push(sharlFacts[i]);
            }

            const newSquares = [];
            for (let i = 0; i < catList.length; i++) {
                newSquares.push(renderSquare(props, catList[i], i))
            }
            setSquares(newSquares)
        }
           // eslint-disable-next-line
        },[props.ResearchReducer, props.UserReducer]);


    const inProgressResearchHandler = (research) => {
        const selected = {"sharl":research.sharl, "research":research};
        props.dispatch(selectResearchFunction(selected))
        props.dispatch(updateResearchFunction("middle"));
    }



    function renderSquare(props, research, i) {
        const researchSharl = research.sharl;
        if (researchSharl) {
            return (
            <StyleResearchCategoryBgTest key={i} id={i}>
                <StyleResearchCategory id={research.category.name} key={research.category.name}>
                    <h1>{research.category.name}</h1>
                    {research.inProgress ?
                        getSharlTime(research.sharl.research_end_time)
                    :
                        <>
                            <h2>{research.category.description}</h2>
                            <p>({research.category.factNum} of {research.category.total_levels} complete)</p>
                        </>
                   }
                </StyleResearchCategory>

                <StyleResearchCategorySharls>
                    {research.selected ?
                        <StyleResearchSharlBox key={research.id} bgColor={research.sharl.color}>
                            <StyleResearchSelectedSharl>
                                <DropSquare props={props} research={research}>
                                    <DragSelectedSharl sharl={research.sharl} props={props} id={research.sharl.id}/>
                                </DropSquare>
                            </StyleResearchSelectedSharl>
                        </StyleResearchSharlBox>
                    :
                        <StyleResearchCont100>
                            {research.inProgress ?
                                <StyleResearchCatSharlImgCont onClick={()=>inProgressResearchHandler(research)}>
                                    {research.completed ?
                                        <StyleResearchCatSharlImg>
                                            <StyleResearchAvatarImg>
                                                <img src={research.sharl.sharl_avatar} alt={research.sharl.name} />
                                            </StyleResearchAvatarImg>
                                            {research.sharl.headgear ?
                                                <StyleResearchCatHat>
                                                    {research.sharl.headgear.image.includes("https") ?
                                                        <img src={research.sharl.headgear.image} alt={research.sharl.headgear.name} />
                                                    :
                                                        <img src={"https://www.sharl.info/" + research.sharl.headgear.image} alt={research.sharl.headgear.name} />
                                                    }
                                                </StyleResearchCatHat>
                                            :
                                                ""
                                            }
                                        </StyleResearchCatSharlImg>
                                    :
                                        <StyleResearchCatSharlImgCont>
                                            <StyleResearchCatSharlImg>
                                                <StyleResearchAvatarImg>
                                                    <img src={require("../../../assets/images/library/sharl_butt.png")} alt={"researching"}/>
                                                </StyleResearchAvatarImg>
                                                {research.sharl.headgear ?
                                                    <StyleResearchCatInProgHat>
                                                        {research.sharl.headgear.image.includes("https") ?
                                                            <img src={research.sharl.headgear.image} alt={research.sharl.headgear.name} />
                                                        :
                                                            <img src={"https://www.sharl.info/" + research.sharl.headgear.image} alt={research.sharl.headgear.name} />
                                                        }
                                                    </StyleResearchCatInProgHat>
                                                :
                                                    ""
                                                }
                                            </StyleResearchCatSharlImg>
                                        </StyleResearchCatSharlImgCont>
                                    }
                                </StyleResearchCatSharlImgCont>
                            :
                                ""
                            }
                        </StyleResearchCont100>
                    }
                </StyleResearchCategorySharls>
            </StyleResearchCategoryBgTest>
        )} else {
            return (
                <StyleResearchCategoryBgTest key={i} id={i}>
                    <StyleResearchCategory id={research.category.name} key={research.category.name}>
                        <h1>{research.category.name}</h1>
                        {research.inProgress ?
                            getSharlTime(research.sharl.research_end_time)
                        :
                            <>
                                <h2>{research.category.description}</h2>
                                <p>({research.category.factNum} of {research.category.total_levels} complete)</p>
                            </>
                        }
                    </StyleResearchCategory>
                    <StyleResearchCategorySharls>
                        <StyleResearchSharlBox key={research.id}>
                             <DropSquare props={props} research={research}/>
                        </StyleResearchSharlBox>
                    </StyleResearchCategorySharls>
                </StyleResearchCategoryBgTest>
            )}
    }

  return (
        <DndProvider backend={HTML5Backend}>
            <StyleTunaMainContainer>
                {squares}
            </StyleTunaMainContainer>
        </DndProvider>
  )}


const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(DropContainer);
