import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux'
import {
    StyleProfileButton,
    StyleProfileContainer,
    StyleProfileItem,
    StyleSharlProfileItem,
    StyleSharlProfileItemDouble,
    StyleUserProfileBody,
    StyleUserProfileContainer,
    StyleUserProfileFooter,

} from "../../styles/profile";
import {
    StyleAdoptForm,
    StyleAdoptItemMain,
    StyleAdoptFormButton,
    StyleAdoptInput,
    StyleAdoptInputBox,
    StyleSharlAdoptItem,
    StyleSharlSingleContainer,
    StyleSharlItemContainer,
    StyleSharlItem,
    StyleSharlItemSingle, StyleAdoptCheckboxContainer,
} from "../../styles/adopt";
import renderRating from "./renderRating";
import {createSharlFunction} from "../../store/actions/Adopt/createSharlAction";
import {DonutSpinner} from "../../styles/signup";
import {clearAdoptStageFunction} from "../../store/actions/Adopt/clearAdoptStageAction";
import deleteItemFunction from "../../store/actions/Sack/deleteItemAction";

function AdoptForm(props) {
    const [sharl] = useState(props.AdoptReducer.chosensharl);
    const [name, setName] = useState('');
    const [fav_fish_or_food, setFav_fish_or_food] = useState('')
    const [sharlConfirm, setSharlConfirm] = useState(false);
    const [formError, setFormError] = useState(props.AdoptReducer.adopterror)

    useEffect(() => {
        setFormError(props.AdoptReducer.adopterror.name);
        // eslint-disable-next-line
        }, [props.AdoptReducer.adopterror]);

    const adoptCompleteHandler = (e,props) => {
        // eslint-disable-next-line
        const format = /[!@#$%^&*_()+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (!name) {
            setFormError("Name required");
        } else if (name.length > 14) {
            setFormError("Name too long. Max 15 characters")
        } else if (format.test(name)) {
            setFormError("Name can only contain a-z, 0-9, and spaces")
        } else if (/^\s/.test(name)) {
            setFormError("Name cannot start with a space")
        } else if (/\s$/.test(name)) {
            setFormError("Name cannot end with a space")
        }  else if (!fav_fish_or_food) {
            setFormError("Favorite fish or food required")
        } else if (fav_fish_or_food.length > 14) {
            setFormError("Favourite too long. Max 15 characters")
        } else if (format.test(fav_fish_or_food)) {
            setFormError("Favourite  can only contain a-z, 0-9, and spaces")
        } else if (/^\s/.test(fav_fish_or_food)) {
            setFormError("Favourite cannot start with a space")
        } else if (/\s$/.test(fav_fish_or_food)) {
            setFormError("Favourite cannot end with a space")
        } else if (sharlConfirm === false) {
            setFormError("Checkbox required");
        } else {
            const sharlData = sharl;
            const formData = {
                name,
                fav_fish_or_food
            };
            const data = Object.assign(sharlData, formData);
            props.dispatch(createSharlFunction(data,props));
            setSharlConfirm(false);
            if (sharl.genus!=="Sleepy Sharl" && sharl.genus!=="Round Sharl" && sharl.genus!=="Pancake Sharl" && sharl.genus!=="Cheeky Sharl") {
                tokenQuantityHandler(sharl.genus);
            }
        }
    }

     const tokenQuantityHandler = (tokenType) => {
        const token = tokenType + " Token";
        const userItems = props.UserReducer.items;
        const filteredUserItems = userItems.filter(item =>
                item.name === token
            );
        const data = {
            "item": filteredUserItems[0].id,
            "delete_quantity": 1,
            "quantity_in_use": 0,
            "quantity": filteredUserItems[0].quantity,
        };
        props.dispatch(deleteItemFunction(props, data));
    };

    const adoptClearHandler = (e) => {
        props.dispatch(clearAdoptStageFunction())
    }

     const bgColorHandler = (sharlID, rarity) => {
            if (rarity==="common") {
                return "#79b4ed"
            } else if (rarity==="rare") {
                return "#f5d442"
            } else if (rarity==="legendary") {
                return "#b48ce6"
            } else if (rarity==="special") {
                return "#f589d6"
            }
        }

    return (
            <>
             <StyleProfileItem>
                <StyleProfileContainer>
                    <StyleAdoptItemMain>
                        <StyleUserProfileContainer>
                            <StyleUserProfileBody>
                                    <StyleSharlProfileItem id={sharl.id} key={sharl.id}>
                                        <StyleSharlSingleContainer bgColor={bgColorHandler(sharl.id, sharl.rarity)}>
                                        <img src={sharl.sharl_avatar} alt={sharl.sharl_avatar} />
                                        <StyleSharlItemSingle>
                                            <p>{sharl.genus}</p>
                                        </StyleSharlItemSingle>
`                                        <StyleSharlItemSingle>
                                        </StyleSharlItemSingle>
                                        </StyleSharlSingleContainer>
                                        <StyleSharlItemContainer>
                                        <StyleSharlItem>
                                            <p>Roundness:</p>
                                            {renderRating(sharl.roundness)}
                                        </StyleSharlItem>
                                        <StyleSharlItem>
                                            <p>Sleepyness:</p>
                                            {renderRating(sharl.sleepyness)}
                                        </StyleSharlItem>
                                        <StyleSharlItem>
                                            <p>Cheekyness:</p>
                                            {renderRating(sharl.cheekyness)}
                                        </StyleSharlItem>
                                        <StyleSharlItem>
                                            <p>Pancakeness:</p>
                                            {renderRating(sharl.pancakeness)}
                                        </StyleSharlItem>
                                        </StyleSharlItemContainer>
                                    </StyleSharlProfileItem>
                            <StyleSharlProfileItemDouble>
                            <StyleSharlAdoptItem>
                            <h2>Adoption Form</h2>
                            </StyleSharlAdoptItem>
                            { props.PageReducer.adoptLoading ?
                            <DonutSpinner></DonutSpinner> :
                       <>
                    <StyleAdoptForm
                            onSubmit={adoptCompleteHandler}>
                       <StyleAdoptInput
                            name='name'
                            onChange={(e) => setName(e.currentTarget.value)}
                            value={name}
                            placeholder='Name'
                            type='text'
                        />
                        <StyleAdoptInput
                            name='fav_fish_or_food'
                            onChange={(e) => setFav_fish_or_food(e.currentTarget.value)}
                            value={fav_fish_or_food}
                            placeholder='Favourite Fish or Food'
                            type='text'
                        />
                        <StyleAdoptCheckboxContainer>
                            <StyleAdoptInputBox
                                name='sharlConfirm'
                                id="sharlConfirm"
                                onChange={(e) => setSharlConfirm(!sharlConfirm)}
                                value={sharlConfirm}
                                type='checkbox'
                            />
                            <p>I promise to always love and care for this very special sharl!</p>
                        </StyleAdoptCheckboxContainer>
                     <h2>{formError}</h2>
                    </StyleAdoptForm>
                                </>
                                }
                            <StyleSharlAdoptItem>
                                <StyleAdoptFormButton onClick={(e) => adoptCompleteHandler(e,props)}>Complete</StyleAdoptFormButton>
                            </StyleSharlAdoptItem>

                            </StyleSharlProfileItemDouble>
                            </StyleUserProfileBody>
                            <StyleUserProfileFooter>
                                <StyleProfileButton onClick={(e) => adoptClearHandler(e)}>Cancel</StyleProfileButton>
                            </StyleUserProfileFooter>
                        </StyleUserProfileContainer>
                    </StyleAdoptItemMain>
               </StyleProfileContainer>
            </StyleProfileItem>
    </>
)
}

const mapStateToProps = state => {
    return {
      ...state,
      };
    };

export default connect(mapStateToProps)(AdoptForm);
