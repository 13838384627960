import {setsharlprize} from "../../types";


export const setSharlPrizeAction = (data) => ({
    type: setsharlprize,
    payload: data,
});



const setSharlPrizeFunction = (data) => (dispatch) => {
    setTimeout(() => {
    dispatch(setSharlPrizeAction(data))
    }, 4200)

}

export default setSharlPrizeFunction;;