import React from 'react';
import {connect} from "react-redux";
import DragCanContainer from "./DragCanContainer";

function DragCan(props) {

    return (
       <DragCanContainer props={props} />
)
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(DragCan);
