import { updatemarket} from "../../types";

export const clearNarlOrBarlAction = () => (
    {
    type: updatemarket,
    payload: "market"
});


const clearNarlOrBarlFunction = () => (dispatch) => {
    dispatch(clearNarlOrBarlAction())
}

export default clearNarlOrBarlFunction;