import React from 'react';


function CookiePolicy() {
  return (
        <>
          <p>Cookie Policy</p>
        </>
  );
}

export default CookiePolicy;;
