import React from 'react'
import {StyleSquareContainer} from "../../../../styles/tank";

export default function Square({ children }) {

    return (
        <StyleSquareContainer>
            {children}
        </StyleSquareContainer>
  )
}
