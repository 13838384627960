import {adoptloaded, adoptloading, signuploaded, signuploading, togglemainmenu} from "../types";

const initialState = {
    SignUpLoading: false,
    adoptLoading: false,
    showmainmenu: false,
  };

const PageReducer = (state = initialState, action) => {
    switch (action.type) {
        case togglemainmenu: {
        return {
             ...state,
             showmainmenu: !state.showmainmenu,
     }}
      case signuploading:
        return {
            ...state,
            SignUpLoading: true
        }
       case signuploaded:
         return {
            ...state,
            SignUpLoading: false
        }
      case adoptloading:
        return {
            ...state,
            adoptLoading: true
        }
       case adoptloaded:
         return {
            ...state,
            adoptLoading: false
        }


        default:
            return state;
  }
}

export default PageReducer;