let selectedSharlPosition = [1, 7]
let observers = []

function emitChange() {
  observers.forEach((o) => o && o(selectedSharlPosition))
}
export function observe(o) {
  observers.push(o)
  emitChange()
  return () => {
    observers = observers.filter((t) => t !== o)
  }
}
export function canMoveSharl(props,toX, toY) {
  const sharls = props.UserReducer.sharls;
  const takenPlaces = sharls.filter(sharl =>
      sharl.is_active === true);
  if (takenPlaces.length > 3) {
    return false
  }

  return true
}

export function canDropFish(props,hungerLevel) {
  if (hungerLevel==="Stuffed") {
    return false
  }
  return true
}

