import React, {useEffect, useState} from 'react';
import {
    StyleHeader,
    StyleHeaderItemLeft,
    StyleHeaderItemMid,
    StyleHeaderItemRight,
    StyleHeaderItemSharl,
    StyleHeaderLeftMid,
    StyleHeaderLeftSides, StyleHeaderSharlHatContainer,
    StyleHeaderSharlItemImg,
    StylHeaderSharlItem, StylHeaderTopSharl
} from "../../styles/header";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

import getUserItemsFunction from "../../store/actions/MainApp/getUserItemsAction";
import signOutFunction from "../../store/actions/Auth/signOutAction";
import {clearUserAction} from "../../store/actions/MainApp/clearUserAction";
import {
    StyleSharlHeaderImageItemContainer
} from "../../styles/sharls";
import getSingleSharlFunction from "../../store/actions/Sharls/getSingleSharlAction";

function Header(props) {
   const [showMainMenu,setMainMenu] = useState(false);
   const [activeSharls, setActiveSharls] = useState([]);
 //  const signUpStageHandler = (e,props) => {
 //     props.dispatch(signUpClearFunction());
 //    }


    useEffect(() => {
        if (!props.UserReducer.user.username) {
            if (props.SignInReducer.token) {
                props.dispatch(getUserItemsFunction(props));
            }
        }
        // eslint-disable-next-line
        },[props.SignInReducer.token]);


    useEffect(() => {
        const allSharls = props.UserReducer.sharls
        if (allSharls) {
            const filteredSharls = allSharls.filter(sharl =>
                sharl.is_active === true);
            setActiveSharls(filteredSharls);
        }
        // eslint-disable-next-line
        },[props.UserReducer]);


   const showMainMenuHandler = (e) => {
        e.preventDefault();
        setMainMenu(!showMainMenu);
        }
 //<Link to="/signup" onClick={(e) => {signUpStageHandler(e,props)}}>
    //SIGN UP</Link>
   // |

   // useEffect(() => {
     //  setHeaderMenu(props.HeaderReducer.showHeaderMenu);
       // },[props.HeaderReducer.showHeader]);
    const signOutHandler = () => {
         //props.dispatch(showHeaderMenuFunction());
         props.dispatch(signOutFunction(props));
         props.dispatch(clearUserAction());
         setMainMenu(false);
        }


    const selectSharlHandler = (e) => {
        const sharlId = parseInt(e.currentTarget.id);
        const selectedSharl = activeSharls.filter(sharl =>
            sharl.id === sharlId);
            props.dispatch(getSingleSharlFunction(selectedSharl[0]))
         }

  return (
    <StyleHeader>
      <StyleHeaderItemLeft>
        <StyleHeaderLeftSides>
          {props.UserReducer.user.username ? <p onClick={(e) => showMainMenuHandler(e)}>MENU</p> : "" }
        </StyleHeaderLeftSides>
          {showMainMenu ?
        <StyleHeaderLeftMid>
          <p><Link to="/town">Town</Link></p>
          <p><Link to="/tank">Tank</Link></p>
          <p><Link to="/sack">Sack</Link></p>
          <p><Link to="/adopt">Adoption</Link></p>
          <p><Link to="/games">Games</Link></p>
          <p><Link to="/research">Research</Link></p>
          <p><Link to="/museum">Museum</Link></p>
          <p><Link to="/profile">Profile</Link></p>
          <p><Link to="/shop">Shop</Link></p>
          <p onClick={() => signOutHandler()}><Link to="/">Sign Out</Link></p>
         </StyleHeaderLeftMid>
              :""}
        </StyleHeaderItemLeft>
    <StyleHeaderItemMid>
     <p>SHARL</p>
        {props.UserReducer.user.username ?
            <Link to="/town">
                <img src={require("../../assets/images/misc/header_sharl.png")} alt={"sharl"}/>
            </Link>
            :
            <Link to="/">
                <img src={require("../../assets/images/misc/header_sharl.png")} alt={"sharl"}/>
            </Link>
        }
    <p>INFO</p>
    </StyleHeaderItemMid>
    <StyleHeaderItemRight>
    {props.UserReducer.user.username ?
        <>
            <StyleHeaderItemSharl>
            <Link to="/sharls/active">
                    {activeSharls.map(sharl =>
                            <StylHeaderSharlItem id={sharl.id} key={sharl.id} onClick={(e)=>selectSharlHandler(e)}>
                                <StyleHeaderSharlItemImg bgColor={sharl.color}>
                                        <img src={sharl.sharl_avatar} alt={sharl.sharl_avatar} />
                                { sharl.headgear ?
                                    <StyleHeaderSharlHatContainer>
                                        { sharl.headgear.image.includes("https") ?
                                            <img src={sharl.headgear.image} alt={sharl.headgear.name} />
                                            :
                                            <img src={"https://www.sharl.info" +sharl.headgear.image} alt={sharl.headgear.name} />
                                        }
                                    </StyleHeaderSharlHatContainer>
                                    : ""}
                                </StyleHeaderSharlItemImg>
                            </StylHeaderSharlItem>
                          )}
            </Link>
            </StyleHeaderItemSharl>
            <StylHeaderTopSharl>
            {props.UserReducer.user.prize_top_sharl ?
               <Link to="/prize">
                <img src={require("../../assets/images/games/sharls/"+props.UserReducer.user.prize_top_sharl+".png")} alt={"sharl"} />
                </Link>   : ""
            }
            </StylHeaderTopSharl>
    <p><img src={require("../../assets/images/misc/shadollar.png")} alt={"sharl"} />{props.UserReducer.user.balance}
    <img src={require("../../assets/images/games/trash.png")} alt={"trash"} />{props.UserReducer.user.trash_balance}</p>
    <p>Sharlarm, {props.UserReducer.user.username}! </p>
    </>
    :
    <p><Link to="/signin">SIGN IN</Link></p>
    }
    </StyleHeaderItemRight>
    </StyleHeader>
);
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(Header);
