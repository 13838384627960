import React, {useEffect} from 'react';

import {connect} from "react-redux";

import {
    StyleResearchBody, StyleResearchBodyContent,
    StyleResearchContainer, StyleResearchLeft, StyleResearchMiddle,
} from "../../../styles/research";
import getResearchFunction from "../../../store/actions/Research/getResearchAction";
import DragContainer from "./DragContainer";
import DropContainer from "./DropContainer";
import ResearchContent from "./ResearchContent";
import selectResearchFunction from "../../../store/actions/Research/selectResearchAction";


function ResearchBody(props) {

    useEffect(() => {
        props.dispatch(getResearchFunction(props));
        props.dispatch(selectResearchFunction({"sharl": "", "research": ""}));
     // eslint-disable-next-line
        },[]);

    return (
        <StyleResearchContainer>
            <StyleResearchBody>
                <StyleResearchBodyContent>
                    <StyleResearchLeft>
                        <DropContainer props={props}/>
                    </StyleResearchLeft>
                    <StyleResearchMiddle>
                        <DragContainer props={props} />
                    </StyleResearchMiddle>
                </StyleResearchBodyContent>
                <ResearchContent />
            </StyleResearchBody>
        </StyleResearchContainer>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(ResearchBody);
