
export const sendHelpFunction = (helpData) => () => {
    const URL = `https://www.sharl.info/backend/api/users/help/`;

    const headers = new Headers({
        'Content-Type': 'application/json',
    });

    const config = {
        method: 'POST',
        body: JSON.stringify(helpData),
        headers
    };

     fetch(URL, config)
    .then(res => res.json())
    .then(data => {
    })
};

export default sendHelpFunction;