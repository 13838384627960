import React, {useState} from 'react';
import {connect} from "react-redux";
import {
    StyleHelpButton,
    StyleHelpContactBody,
    StyleHelpContactBodyContainer,
    StyleHelpContactBox, StyleHelpContactBoxContainer,
    StyleHelpContactFooter,
    StyleHelpContactForm,
    StyleHelpContactHeader,
    StyleHelpContactInput,
    StyleHelpContactSelect,
    StyleHelpContactText,
    StyleHelpPageContainer
} from "../../../styles/help";
import sendHelpAction from "../../../store/actions/Help/sendHelpAction";


function Contact(props) {
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [reason, setReason] = useState("")
    const [details, setDetails] = useState("")
    const [checkBox, setCheckBox] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [formSent, setFormSent] = useState(false)

    const submitHandler = () => {
        if (username && email && reason && details && checkBox) {
            const helpData = {"username": username, "email": email, "request_type":reason, "request_details": details}
            props.dispatch(sendHelpAction(helpData));
            setFormSent(true)
        } else {
            setFormSubmitted(true)
        }
    }

    const bgHandler = (field) => {
        if (formSubmitted===false){
            return "#336699"
        } else {
            if (field==="username" && username.length===0){
                return "red"
            } else if (field==="email" && email.length===0) {
                return "red"
            } else if (field==="reason" && (reason==="none" || reason.length===0)) {
                return "red"
            } else if (field==="details" && details.length===0) {
                return "red"
            }
        }
    }

    const fontHandler = () => {
        if (formSubmitted === false) {
            return "white"
        } else {
            if (checkBox === false) {
                return "red"
            } else {
                return "white"
            }
        }
    }

    return (
        <StyleHelpPageContainer>
            <StyleHelpContactHeader>
                {formSent===false ?
                    <>
                        <p>If you need to contact us about stuff use this form</p>
                        <p>One of our sharls will get back to you</p>
                    </>
                :""}
            </StyleHelpContactHeader>
            { formSent===false ?
            <StyleHelpContactBodyContainer>
                <StyleHelpContactBody>
                    <StyleHelpContactForm>
                        <p>Username</p>
                        <StyleHelpContactInput bgColor={bgHandler("username")} required={true} onChange={(e)=>setUsername(e.currentTarget.value)}/>
                        <p>Contact Email</p>
                        <StyleHelpContactInput bgColor={bgHandler("email")} required={true} type={"email"}  onChange={(e)=>setEmail(e.currentTarget.value)}/>
                        <p>Reason for Contact</p>
                        <StyleHelpContactSelect bgColor={bgHandler("reason")} required={true} onChange={(e)=>setReason(e.currentTarget.value)}>
                            <option value={"none"}>Please Select</option>
                            <option value={"general"}>I have a question</option>
                            <option value={"bug"}>I found a bug</option>
                            <option value={"purchase"}>Something went wrong with a purchase</option>
                            <option value={"delete"}>I want to delete my account permanently</option>
                        </StyleHelpContactSelect>
                        <p>Details</p>
                        <StyleHelpContactText type={"text"} bgColor={bgHandler("details")} required={true} maxLength={"250"} onChange={(e)=>setDetails(e.currentTarget.value)}/>
                        <StyleHelpContactBoxContainer checkColor={fontHandler()}>
                            <StyleHelpContactBox onChange={()=>setCheckBox(!checkBox)} type={"checkbox"} required={true}/>
                            <p>Confirm something before you send</p>
                        </StyleHelpContactBoxContainer>
                    </StyleHelpContactForm>
                </StyleHelpContactBody>
                <StyleHelpContactFooter>
                    <StyleHelpButton onClick={()=>submitHandler()}>Submit</StyleHelpButton>
                </StyleHelpContactFooter>
            </StyleHelpContactBodyContainer>
            :
                <StyleHelpContactBodyContainer>
                    <p>Thank you for your enquiry!</p>
                    <p>One of the sharls will get back to you as soon as possible!</p>
                </StyleHelpContactBodyContainer>
            }
        </StyleHelpPageContainer>
    );
}


const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(Contact);
