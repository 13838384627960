import {signin, signinerror} from "../../types";

export const signInAction = (token) => (
    {
    type: signin,
    payload: token,
});


export const signInErrorAction = (data) => (
    {
    type: signinerror,
    payload: data,
});


const URL = `https://www.sharl.info/backend/api/auth/token/`;

export const signInFunction = (data,props) => (dispatch) => {
   if (!data.email) {
       dispatch(signInErrorAction("Email cannot be empty"))
   } else if (!data.password) {
       dispatch(signInErrorAction("Password cannot be empty"))
   } else if (!data.email && !data.password) {
       dispatch(signInErrorAction("Email and Password cannot be empty"))
   } else {
    const headers = new Headers({
        'Content-Type': 'application/json'
    });

    const config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers
    };

        fetch(URL, config)
        .then(res => res.json())
        .then(data => {
            const {access} = data;
            const passwordError = data.password;
            const detailError = data.detail;
            if (access) {
            dispatch(signInAction(access));
            localStorage.setItem('token', access);
            } if (passwordError) {
            dispatch(signInErrorAction(passwordError));
            } if (detailError) {
                dispatch(signInErrorAction("The username or password is incorrect"));
            } else {
                dispatch(signInErrorAction("The username or password is incorrect"));
            }

        });
}};