import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import ResearchStart from "./ResearchStart";
import ResearchMid from "./ResearchMid";
import ResearchEnd from "./ResearchEnd";
import updateResearchFunction from "../../../store/actions/Research/updateResearchAction";


function ResearchContent(props) {
    const [researchStage, setResearchStage] = useState("start")

    useEffect(() => {
        const updatedStage = props.ResearchReducer.researchStage;
        setResearchStage(updatedStage);
           // eslint-disable-next-line
        },[props.ResearchReducer]);

    useEffect(() => {
        props.dispatch(updateResearchFunction("start"));
           // eslint-disable-next-line
        },[]);

    switch (researchStage) {
        case "start":
            return(
               <ResearchStart props={props}/>
                               )
        case "middle":
            return(
                <ResearchMid props={props}/>
                )
        case "end":
            return(
                <ResearchEnd props={props}/>
                    )
        default:
            return(
                <ResearchStart />
                )
        };
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(ResearchContent);
