import {
    passwordconfirm, passworderror,
    passwordreset, passwordresetclear, passwordtoken, passwordupdate,

} from "../types";

const initialState = {
    error: "",
    email: null,
    stage: 1,
    pw_update_msg: "",
};

const PasswordResetReducer = (state = initialState, action) => {
    switch (action.type) {
        case passwordreset: {
            return {
                ...state,
                email: action.payload,
                stage: 2,
            }
        }
        case passwordconfirm: {
            return {
                ...state,
                stage: 3,
            }
        }
        case passwordtoken: {
            return {
                ...state,
                stage: 4,
            }
        }

        case passworderror: {
            return {
                ...state,
                error: action.payload,
            }
        }

        case passwordresetclear: {
            return {
                ...state,
                stage: 1,
            }
        }   case passwordupdate: {
            return {
                ...state,
                pw_update_msg: action.payload,
            }
        }

        default: {
            return state
        }
    }
}
export default PasswordResetReducer;