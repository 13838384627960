import {resetprize} from "../../types";

export const resetPrizeAction = () => ({
    type: resetprize,
});


const resetPrizeFunction = (props) => (dispatch) => {
        dispatch(resetPrizeAction());
};
export default resetPrizeFunction;