import {infosharl} from "../../types";

export const selectTankSharlAction = (data) => ({
    type: infosharl,
    payload: data,
});


const selectTankSharlFunction = (selectedSharl) => (dispatch) => {
        dispatch(selectTankSharlAction(selectedSharl));
};

export default selectTankSharlFunction;