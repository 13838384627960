import styled, {keyframes} from "styled-components";


export const StyleTankContainer = styled.div`
    height: 100%;
    width: 100%;
    background: linear-gradient(to top, rgba(150, 255, 255, 0.7) 0%, rgba(51, 102, 153, 0.6) 100%), url('../images/backgrounds/seabg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    `;


export const StyleTank = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    `;


export const StyleTankMain = styled.div`
    height: 100%;
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: center;

    `;


export const StyleTankActiveRight = styled.div`
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;

export const StyleTankActiveLeft = styled.div`
    height: 90%;
    width: 20%;
     `;

export const StyleTankActiveBody = styled.div`
    height: 75%;
    width: 100%;
    `;

export const StyleTankActiveFooter = styled.div`
    height: 25%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    `;

export const StyleTankImg = styled.div`
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center;
    
    background-image: url(${props => props.bgImage});
    background-size: contain;
    background-repeat: no-repeat;
    `;

export const StyleTankActiveContainer = styled.div`
    height: 100%;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

export const StyleTankActiveButton = styled.button`
    height: 35%;
    width: 80%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 2.5vh;
    border: none;
    
    a:-webkit-any-link {
    color: white;
    text-decoration: none;
    }   
    a {
    color: white;
    overflow: hidden;
    }
 `;


export const StyleActiveSharlItem = styled.div`
    height: 21%;
    width: 75%;
    margin: 2%;
    display: flex;
    border-radius: 24px;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    
    img {
    height: 90%
    }
    
    :hover {
    background-color: #336699;
    }
    `;


export const StyleActiveItemSingle = styled.div`
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    h2 {
    line-height: 2;
    font-size: 2.5vh;
    overflow: hidden;
    }
   
    `;



export const StyleSharlImageTankContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    img {
    height: 70%;
    width: auto;
    }
    z-index: 0;
    `;


export const StyleTankSharlHatContainer = styled.div`
    height: 10%;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    position: absolute;
    bottom: 70%;
    img {
    height: 80%;
    width: auto;
    }

    `
;


export const StyleInactiveSharl = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius 20px;
    `;



export const StyleDragSharlContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    position: relative;
    border-radius 20px;`;

export const StyleDragMain = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;  
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    
    img {
    height: 90%;
    width: auto;
    overflow: hidden;
    border-radius: 24px;
    }
  
    p {
        font-size: 0;
    }
    
    :hover {
    p {
        font-size: 4vh;
        color: white;
        position: absolute;
        background-color: rgba(51,102,153,0.5);
        z-index: 10;
        border-radius: 24px;
        font-weight: bold;
        
    }    
    }
    `;


export const StyleDragHat = styled.div`
    height: 40%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: absolute;
    bottom: 56%;
    overflow: hidden;
    img {
    height: 90%;
    width: auto;
    }
`;


export const StyleTankSharlContainer = styled.div`
    height: 25%;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
 `;

export const StyleActiveTankSharlContainer = styled.div`
    height: 22%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
 `;


export const StyleTankMainContainer = styled.div`
    height: 100%;
    width: 60%;
    margin-left: 15%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
 `;


export const StyleTankSquareMain = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    
 `;
export const StyleActiveTankSquareMain = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    
 `;



export const StyleTankOverDrop = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-Index: 1;
    opacity: 0.5;
 `;

export const StyleTankNoDrop = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-Index: 1;
    opacity: 0.5;
    background-color: red;
 `;


export const StyleSquareContainer = styled.div`
    height: 100%;
    width: 100%;
 `;


export const StyleActiveSquareContainer = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 24px;   
 `;


export const StyleTankInfoContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;

export const StyleTankInfoInner = styled.div`
    height: 95%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 24px;
    `;


export const StyleTankFeedInner = styled.div`
    height: 95%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    border-radius: 24px;
    overflow-y: scroll;

    `;

export const StyleSharlTankFoodItem = styled.div`
    height: 30%;
    width: 80%;
    margin: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(51,102,153,0.5);
    wrap: flex-wrap;
    img {
    height: 80%;
    width: 50%;
    }
    border-radius: 20px;
    p {
    font-size: 2vh;
    font-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    `;

export const StyleShareBody = styled.div`
    height: 85%;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    `;


export const StyleTankShareBackground = styled.div`
    height: 90%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    background-image: url(${props => props.bgImage});
    background-size: fit;
    background-repeat: no-repeat;
    position: relative;
    `;


const sharlShareSwimLeft = keyframes`
  from {   
    background: url('../../images/tank/left1.png');
    background-size: 60%;
    background-repeat: no-repeat;
    left: 0;
    transform: rotateY(0deg);
  }
  25% {
    background: url('../../images/tank/left2.png');
    background-size: 60%;
    background-repeat: no-repeat;
    transform: rotateY(0deg);
  }
  45% {
     transform: rotateY(0deg);
  }
  50% {
    background: url('../../images/tank/left1.png');
    background-size: 60%;
    background-repeat: no-repeat;
    left: 80%;
    transform: rotateY(180deg);
  }
  75% {
    background: url('../../images/tank/left2.png');
    background-size: 60%;
    background-repeat: no-repeat;
    transform: rotateY(180deg);
  }
  95% {
    transform: rotateY(180deg);
    }
  to {
    background: url('../../images/tank/left1.png');
    background-size: 60%;
    background-repeat: no-repeat;
    left: 0;
    transform: rotateY(180deg);  
  }  `


const sharlShareSwimRight = keyframes`
  from {
    left: 80%;
    background: url('../../images/tank/right1.png');
    background-size: 60%;
    background-repeat: no-repeat;
    transform: rotateY(0deg);
  }
  
  25% {
    background: url('../../images/tank/right2.png');
    background-size: 60%;
    background-repeat: no-repeat;
    transform: rotateY(0deg);
  }
  45% {
      transform: rotateY(0deg);
  }
  50% {
    background: url('../../images/tank/right1.png');
    background-size: 60%;
    background-repeat: no-repeat;
    left: 0;
    transform: rotateY(180deg);
    }
  
  75% {
    background: url('../../images/tank/right2.png');
    background-size: 60%;
    background-repeat: no-repeat;
    transform: rotateY(180deg);
  }
  95% {
      transform: rotateY(180deg);
  }
  to {
    background: url('../../images/tank/right1.png');
    background-size: 60%;
    background-repeat: no-repeat;
    left: 80%;
    transform: rotateY(180deg);
  }  `

export const StyleTankShareSharlContainer = styled.div`
    height: 20%;
    width: 20%;
    z-index: 20;
    position: absolute;
    top: ${props => props.topPos || "20%"};
    
    background: url('../../images/tank/left1.png');
    background-size: 60%;
    background-repeat: no-repeat;
    :nth-child(odd) {
        animation-name: ${sharlShareSwimLeft};
        animation-duration: ${props => props.swimSpeed || "10s"};
        animation-iteration-count: infinite;
        &:hover {
            animation-play-state: paused;
            z-index: 100;
        }
    }
    :nth-child(even) {
        animation-name: ${sharlShareSwimRight};
        animation-duration: ${props => props.swimSpeed || "10s"};
        animation-iteration-count: infinite;
        &:hover {
           animation-play-state: paused;
           z-index: 100;
        }
    }
    `;

