import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux'
import SharlPrizeStart from "./SharlPrizeStart";
import SharlPrizeEnd from "./SharlPrizeEnd";
import checkDailyPrizeFunction from "../../../store/actions/Games/checkDailyPrizeAction";
import {StyleGamesPrizeClaimed} from "../../../styles/games";

function SharlPrizeBody(props) {
    const [sharlPrizeStage, setSharlPrizeStage] = useState(1);
    const [alreadyClaimed, setAlreadyClaimed] = useState(false);
    const [topSharl, setTopSharl] = useState(props.UserReducer.user.prize_top_sharl)

    useEffect(() => {
        setSharlPrizeStage(props.GameReducer.prizestage);
        setAlreadyClaimed(props.GameReducer.already_claimed);
        // eslint-disable-next-line
        },[props.GameReducer]);

    useEffect(() => {
       props.dispatch(checkDailyPrizeFunction(props));
        // eslint-disable-next-line
        },[]);

    useEffect(() => {
        const prizeSharl = props.GameReducer.prizesharl
        setTopSharl(prizeSharl);
        // eslint-disable-next-line
        }, [props.GameReducer]);

    if (alreadyClaimed===true) {
    return (
        <StyleGamesPrizeClaimed>
            <img src={require(`../../../assets/images/games/sharls/${topSharl}.png`)} alt={"top sharl"} />
            <h1>{topSharl} is your Top Sharl for today!</h1>
            <p>Come back tomorrow to choose again</p>
        </StyleGamesPrizeClaimed>
        )
    } else if (sharlPrizeStage === 1 ) {
        return (
        <SharlPrizeStart />
        )
    }
    else if (sharlPrizeStage===2 ) {
        return (
            <SharlPrizeEnd/> )
    } else if (sharlPrizeStage===3) {
        return (
            <h2>Error</h2>
        )
    } else {
        return (
            <h2>Error</h2>
        )
    }

}

const mapStateToProps = state => {
    return {
      ...state,
      };
    };

export default connect(mapStateToProps)(SharlPrizeBody);
