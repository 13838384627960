import styled from "styled-components";

export const StyleSharlProfileItemMain = styled.div`
    height: 100%;
    width: 100%;
    background: linear-gradient(to top, rgba(150, 255, 255, 0.7) 0%, rgba(51, 102, 153, 0.6) 100%), url('../images/backgrounds/seabg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
   
    `;


export const StyleSharlProfileContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;

export const StyleProfileSharl = styled.div`
    height: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    h2 {
     line-height: 3; 
     font-size: 3.5vh; 
     overflow: hidden;
    }
    li {
    line-height: 2; 
    font-size: 2.5vh;
    }
    
  
    `;

export const StyleSharlContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(to top, rgba(150, 255, 255, 0.7) 0%, rgba(51, 102, 153, 0.6) 100%), url('../images/backgrounds/seabg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    `;

export const StyleSharlContainerFooter = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    `;

export const StyleSharlActiveContainer = styled.div`
    height: 90%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

export const StyleSharlLeft = styled.div`
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    img {
    height: 50%;
    }
    color: white;
    font-weight: bold;
    font-size: 2.5vh;
    background-color: ${props => props.bgColor || "white"};
        `;
export const StyleSharlLeftBlank = styled.div`
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
        `;

export const StyleSharlRightItemShow = styled.div`
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 24px;
    margin: 2%;
    background-color: white;

        `;

export const StyleSharlOuter = styled.div`
    height: 100%;
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 1%;
    `;

export const StyleSharlMiddle = styled.div`
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0px 20px 20px 0px;
    line-height: 2;
    background-color: white;
    p {
    font-size: 2.5vh;
    }
    h1 {
    font-size: 3vh;
    font-weight: bold;
    }
     a:-webkit-any-link {
    text-decoration: none;
    color: black;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    }
   

    `;

export const StyleSharlUpdateContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;

export const StyleSharlUpdateFooter = styled.div`
    height: 12%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    `;

export const StyleSharlUpdateBody = styled.div`
    height: 76%;
    width: 100%;
    background-color: #336699;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    border-radius: 20px 0px;
    padding-top: 5%;
    `;


export const StyleSharlUpdateItem = styled.div`
    min-height: 30%;
    min-width: 70%;
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 2.5%;
    overflow: none;
    font-size: 2vh;
    img {
    height: 60%;
    float: right;    
    }
    :active {
  background-color: grey;
    }
 :hover {
   background-color: grey;
   color: white;

 }   
    `;


export const StyleSharlButton = styled.button`
    height: 50%;
    width: 45%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 2.5vh;
    border: none;
    a {
    color: white;
    }
    
 `;


export const StyleSharlHatContainer = styled.div`
    height: 20%;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: absolute;
    padding-bottom: 6%;
    img {
    height: 100%;
    }

    `
;

export const StyleSharlImageContainer = styled.div`
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    img {
    height: 50%;
    width: auto;
    }
    z-index: 0;
    `;

export const StyleAllSharlsButtons = styled.div`
    height: 100%;
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;


export const StyleSharlProfileButton = styled.button`
    height: 10%;
    width: 80%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 2.5vh;
    border: none;
    margin-top: 10%;
    margin-bottom: 10%;
    
    a:-webkit-any-link {
    color: white;
    text-decoration: none;
    }   
    a {
    color: white;
    overflow: hidden;
    }
 `;


export const StyleAllSharlsContainer = styled.div`
    height: 100%;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
    font-size: 3vh;
    line-height: 2;
    overflow: hidden;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
    }
    img {
    height: 50%;
    }
    
    `;



export const StyleAllSharlProfileItem = styled.div`
    height: 90%;
    width: 23%;
    margin: 1%;
    display: flex;
    background-color: white;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    img {
    height: 40%
    }
    p {
    font-size: 2vh;
    width: 80%;
    }
    a:-webkit-any-link {
    text-decoration: none;
    color: black;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    }
    `;

export const StyleSharlRemoveButton = styled.button`
    height: 70%;
    width: 60%;
    background-color: #336699;
    border-radius: 24px;
    color: white;
    font-size: 2.5vh;
    border: none;
    a {
    color: white;
    }
    
 `;


export const StyleAllSharlItemSingle = styled.div`
    height: 6%;
    width: 100%;
    display: flex;
    background-color: ${props => props.bgColor || "white"};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 20px 20px 0px 0px;
    
    p {
    line-height: 1.5;
    font-size: 2.5vh;
    overflow: hidden;
    }
   
    `;

export const StyleAllSharlItemDouble = styled.div`
    height: 12%;
    width: 100%;
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
      
    
    p {
    line-height: 1.5;
    font-size: 2.5vh;
    overflow: hidden;
    }
    h2 {
    line-height:1.5;
    font-size: 3.5vh;
    font-weight: bold;
    }
    `;



export const StyleAllSharlItemImg = styled.div`
    height: 40%;
    width: 100%;
    display: flex;
    background-color: ${props => props.bgColor || "white"};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    `;


export const StyleSharlImageItemContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    img {
    height: 100%;
    width: auto;
    }
    z-index: 0;
    `;

export const StyleSharlHeaderImageItemContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      height: 150%;
      width: auto;
    }
    z-index: 0;
    `;


export const StyleAllSharlHatContainer = styled.div`
    height: 20%;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: absolute;
    bottom: 63%;
    img {
      height: 80%;
      width: auto;
    }
    `;


export const StyleOtherSharlProfileItem = styled.div`
    height: 30%;
    width: 75%;
    margin: 2%;
    display: flex;
    background-color: ${props => props.bgColor || "white"};
    border-radius: 24px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    img {
    height: 40%
    }
    p {
    font-size: 2vh;
    width: 80%;
    }
    a:-webkit-any-link {
    text-decoration: none;
    color: black;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    }
    
    :hover {
    background-color: #336699;
    }
    `;


export const StyleOtherSharlItemImg = styled.div`
    height: 60%;
    width: 100%;
    display: flex;
    background-color: ${props => props.bgColor || "white"};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    `;


export const StyleOtherSharlItemSingle = styled.div`
    height: 20%;
    width: 100%;
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    h2 {
    line-height: 2;
    font-size: 2.5vh;
    overflow: hidden;
    }
   
    `;


export const StyleSharlOtherItemContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    
    img {
    height: 100%;
    width: auto;
    overflow: hidden;
    border-radius: 24px;
    }
    z-index: 0;
    `;


export const StyleOtherSharlHatContainer = styled.div`
    height: 40%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: absolute;
    bottom: 58%;
    overflow: hidden;
    img {
    height: 90%;
    width: auto;
    }

    `
;

export const StyleSharlItemSingleRating = styled.div`
    height: 15%;
    width: 100%;
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    p {
    font-size: 1.2vw;
    overflow: hidden;
    }
        `;

export const StyleSharlSackItemQuantity = styled.div`
    position: absolute;
    right: 2%;
    bottom: 5%;
    height: 20%;
    width: 30%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
     p {
     font-weight: bold;
    font-size: 1.2vw;
    }
  
    `;


export const StyleSharlSackItem = styled.div`
    border-radius: 24px;
    position: relative;
    height: 28%;
    width: 70%;
    margin-bottom: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    img {
    height: 100%;
    width: auto%;
    }
       h1 {
        font-size: 0;
    }   
    :hover {
    h1 {
        font-size: 1.4vw;
        color: white;
        width: 100%;
        text-align: center;
        position: absolute;
        background-color: rgba(51,102,153,0.9);
        z-index: 10;
        font-weight: bold;   
    }    
    }
 
    `;
