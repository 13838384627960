import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import SharlQuizHome from "./SharlQuizHome";
import SharlQuizQuestions from "./SharlQuizQuestions";
import SharlQuizResults from "./SharlQuizResults";

function SharlQuizContent(props) {
    const [quizStage, setQuizStage] = useState(0)

    useEffect(()=>{
        setQuizStage(props.GameReducer.quiz)
    },[props.GameReducer])

    switch (quizStage) {
        case 0:
            return(
               <SharlQuizHome/>
                               )
        case 1:
            return(
               <SharlQuizQuestions/>
                )
        case 2:
            return(
               <SharlQuizResults/>
                    )
        default:
            return(
               <SharlQuizHome/>
                )

}}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(SharlQuizContent);
